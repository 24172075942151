import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'app/app.config';
import { ElementResponse } from './response';
import { Voucher } from 'app/_models';

@Injectable({ providedIn: 'root' })
export class VouchersService {

    constructor(
        private http: HttpClient,
    ) {
    }

    resolveVoucherWithCode(code: string): Promise<ElementResponse<Voucher>> {
        return this.http.get<ElementResponse<Voucher>>(`${AppConfig.apiBaseUrl}/vouchers?code=${code}`).toPromise()
    }

}
