import { Component, Input } from '@angular/core';

@Component({
    selector: 'avatar',
    templateUrl: 'avatar.component.html',
    styleUrls: [
        'avatar.component.css'
    ]
})

export class AvatarComponent {

    private _imageUrl: string
    @Input()
    set imageUrl(imageUrl: string) {
        if (this._imageUrl === imageUrl) {
            return
        }
        this._imageUrl = imageUrl
        this.imageLoadingFailed = false
    }
    get imageUrl() { return this._imageUrl }

    private _fallbackText: string
    @Input()
    set fallbackText(fallbackText: string) {
        if (this._fallbackText === fallbackText) {
            return
        }
        this._fallbackText = fallbackText
        this.initials = this.makeInitials()
    }
    get fallbackText() { return this._fallbackText }

    @Input() isHighlighted: boolean

    imageLoaded = false
    imageLoadingFailed = false
    initials: string

    constructor(
    ) { }

    onLoad() {
        this.imageLoaded = true
    }

    onLoadError() {
        this.imageLoadingFailed = true
    }

    private makeInitials(): string {
        if (!this.fallbackText) { return null }

        // Split the text with multiple delimiters.
        const words = this.fallbackText.split(/[\s]+/)

        // Initialize an empty string to hold the initials
        let initials = '';

        // Loop through the words and get the first letter of each
        for (let i = 0; i < words.length && initials.length < 2; i++) {
            if (words[i].length > 0) {
                initials += words[i][0].toUpperCase();

                // Finish early if we managed to extract enough letters by now
                if (initials.length >= 2) {
                    break
                }
            }
        }


        // If we managed to make 2 letter initials, all good
        if (initials.length >= 2) {
            return initials
        }

        // Try to just take the first 2 letters
        if (this.fallbackText.length > 1) {
            return this.fallbackText.substring(0, 2).toUpperCase()
        }

        // If nothing helps, just return what we have so far
        return initials
    }

}
