<div *ngIf="errorTrendsSummaryResponse">
    <div class="d-flex">
        <div class="mr-auto font-weight-semibold">Total errors</div>
        <count [count]="errorTrendsSummaryResponse.totalErrorsCount"></count>
    </div>
    <div class="d-flex">
        <div class="mr-auto font-weight-semibold">Affected devices</div>
        <count [count]="errorTrendsSummaryResponse.totalDevicesCount"></count>
    </div>

    <hr class="my-1">

    <div class="font-size-sm nowrap">
        <div class="d-flex">
            <div class="mr-auto">Errors per device:</div>
            <count [count]="errorTrendsSummaryResponse.totalErrorsCount / errorTrendsSummaryResponse.totalDevicesCount"></count>
        </div>
    </div>
</div>
