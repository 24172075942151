<div class="content-wrapper" id="pageContent">

    <div class="content">
        <div class="row">
            <div class="col-md-12">
                <alert></alert>
            </div>
        </div>

        <div class="row" *ngIf="testRunInfos && successRate">
            <div class="col-md-12">
                <div class="card mb-2">
                    <div class="page-header border-bottom-0">
                        <div class="page-header-content header-elements-md-inline">
                            <div class="page-title">
                                <h1 class="font-weight-thin">
                                    <a [routerLink]="['../../']" class="breadcrumb-item">{{application.name}}</a>
                                    <a [routerLink]="['../']" class="breadcrumb-item">Test Cases</a>
                                    <span class="breadcrumb-item active">
                                        <a href="javascript:void(0)" class="text-default hover-expendable white-space-nowrap expanded-area" (click)="copyTestNameToClipboard()">
                                            {{testCase.suiteName}}.{{testCase.name}} <i class="icon-files-empty hover-target" style="font-size: 70%;"></i>
                                        </a>
                                    </span>
                                </h1>
                            </div>

                            <div class="header-elements" style="flex-wrap: nowrap;">
                                <header-value-item title="TOTAL RUNS" value="{{testRunInfos.length}}"></header-value-item>
                                <header-value-item title="SUCCESS RATE" value="{{successRate}}"></header-value-item>
                            </div>
                        </div>

                        <div class="progress noshadow rounded-0" style="height: 7px;">
                            <div [style.width]="passedPercentage" class="progress-bar bg-success-400"></div>
                            <div [style.width]="failedPercentage" class="progress-bar bg-pink-400"></div>
                            <div [style.width]="skippedPercentage" class="progress-bar bg-grey-400"></div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12 d-flex align-items-center">
                                <div class="w-100">
                                    <ng-container *ngIf="testCase.annotation">
                                        <markdown [content]="testCase.annotation"></markdown>
                                        <div class="text-muted font-size-sm font-weight-light mt-1 mb-0">Updated {{dateFormatter.timeAgo(testCase.annotationUpdateDate)}}</div>
                                    </ng-container>

                                    <ng-container *ngIf="testCase.annotation == null">
                                        <a href="javascript:void(0)" (click)="onAnnotateButtonClick()" class="font-weight-semibold" data-toggle="modal" data-target="#editTestCaseModal">Annotate this test case</a>
                                        <span class="text-muted"> with description, preconditions or links to an issue tracking system like JIRA or Trello.</span>
                                    </ng-container>
                                </div>
                                <button type="button" (click)="onAnnotateButtonClick()" class="btn btn-outline bg-primary border-primary text-primary-800 btn-icon" data-toggle="modal" data-target="#editTestCaseModal"><i class="icon-pencil6 mr-2"></i> Annotate</button>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row col-md-12 ml-0 p-0">

                            <ul class="nav nav-pills mb-0 mr-2">
                                <li class="nav-item">
                                    <a href="#" [ngClass]="{ 'active' : selectedTestsFilter == 'all' }" (click)="setTestsFilter('all')" class="nav-link" data-toggle="tab">
                                        All
                                        <span class="badge badge-light ml-2">{{passedCount + failedCount}}</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" [ngClass]="{ 'active' : selectedTestsFilter == 'passed' }" (click)="setTestsFilter('passed')" class="nav-link" data-toggle="tab">
                                        <span class="badge badge-mark border-success-400 mr-1"></span>
                                        Passed
                                        <span class="badge badge-light ml-2">{{passedCount}}</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" [ngClass]="{ 'active' : selectedTestsFilter == 'failed' }" (click)="setTestsFilter('failed')" class="nav-link" data-toggle="tab">
                                        <span class="badge badge-mark border-pink-400 mr-1"></span>
                                        Failed
                                        <span class="badge badge-light ml-2">{{failedCount}}</span>
                                    </a>
                                </li>
                            </ul>

                            <div class="d-flex align-items-center mr-3">
                                <div class="form-group mb-0 d-flex" *ngIf="environments.length > 1">
                                    <label class="col-form-label mr-2">Environment:</label>
                                    <select id="environmentNameSelect" select2 class="form-control select2">
                                        <option *ngFor="let environment of environments" [ngValue]="environment.name">{{environment.name}}</option>
                                    </select>
                                </div>
                            </div>

                            <ul class="ml-auto pagination-flat align-self-center justify-content-center twbs-flat pagination font-size-sm">
                                <li class="page-item" [ngClass]="{ 'active' : selectedTimeFilter == '1W' }">
                                    <a href="#" class="page-link p-1 text-muted" [ngClass]="{'text-muted' : selectedTimeFilter != '1W'}" style="min-width: 0" data-toggle="tab" (click)="setTimeFilter('1W')">1W</a>
                                </li>
                                <li class="page-item" [ngClass]="{ 'active' : selectedTimeFilter == '2W' }">
                                    <a href="#" class="page-link p-1 text-muted" [ngClass]="{'text-muted' : selectedTimeFilter != '2W'}" style="min-width: 0" data-toggle="tab" (click)="setTimeFilter('2W')">2W</a>
                                </li>
                                <li class="page-item" [ngClass]="{ 'active' : selectedTimeFilter == '1M' }">
                                    <a href="#" class="page-link p-1 text-muted" [ngClass]="{'text-muted' : selectedTimeFilter != '1M'}" style="min-width: 0" data-toggle="tab" (click)="setTimeFilter('1M')">1M</a>
                                </li>
                                <li class="page-item" [ngClass]="{ 'active' : selectedTimeFilter == '3M' }">
                                    <a href="#" class="page-link p-1 text-muted" [ngClass]="{'text-muted' : selectedTimeFilter != '3M'}" style="min-width: 0" data-toggle="tab" (click)="setTimeFilter('3M')">3M</a>
                                </li>
                                <li class="page-item" [ngClass]="{ 'active' : selectedTimeFilter == '1Y' }">
                                    <a href="#" class="page-link p-1 text-muted" [ngClass]="{'text-muted' : selectedTimeFilter != '1Y'}" style="min-width: 0" data-toggle="tab" (click)="setTimeFilter('1Y')">1Y</a>
                                </li>
                            </ul>
                        </div>

                    </div>

                    <table class="table">
                        <thead>
                            <tr role="row">
                                <th class="border-bottom-0 w-100">Test Run</th>
                                <th class="border-bottom-0">Environment</th>
                                <th class="border-bottom-0 nowrap">App version</th>
                                <th class="border-bottom-0">Device</th>
                                <th class="border-bottom-0">OS</th>
                                <th class="border-bottom-0">Locale</th>
                                <th class="border-bottom-0">Duration</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let testRunInfo of filteredTestRunInfos" (click)="navigateToTestRunInfo(testRunInfo)">
                                <td class="d-flex">
                                    <div class="mr-3 ml-1">
                                        <span [class]="statusClassForTestRun(testRunInfo)"></span>
                                    </div>
                                    <div>
                                        <a class="font-weight-semibold button-muted nowrap" [routerLink]="['../../test-executions/', testRunInfo.testExecutionSummary.serialNumber, 'runs', testRunInfo.testRunSummary.serialNumber]">
                                            <ul class="list-inline list-inline-dotted list-inline-condensed m-0">
                                                <li class="list-inline-item">#{{testRunInfo.testExecutionSummary.serialNumber}}</li>
                                                <li class="list-inline-item">{{dateFormatter.dateTimeShort(testRunInfo.testExecutionSummary.creationDate)}}</li>
                                            </ul>
                                        </a>
                                        <div class="text-muted breakall">{{statusTextForTestRun(testRunInfo)}}</div>
                                    </div>
                                </td>
                                <td>{{testRunInfo.testExecutionSummary.environment}}</td>
                                <td class="nowrap">{{testRunInfo.testExecutionSummary.testBundleVersion}}</td>
                                <td class="nowrap">{{testRunInfo.testExecutor.deviceModelName}}</td>
                                <td class="nowrap">{{testRunInfo.testExecutor.system}}</td>
                                <td>{{testRunInfo.testRunSummary.locale}}</td>
                                <td class="text-right">{{testRunDuration(testRunInfo)}}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>

            </div>
        </div>

    </div>
</div>

<edit-test-case-modal #editTestCaseModal (testCaseUpdated)="onTestCaseUpdated()"></edit-test-case-modal>
