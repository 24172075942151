import * as moment from 'moment'
import { Injectable } from '@angular/core'
import { DateRange } from 'app/_models'

@Injectable()
export class DateFormatter {

    duration(seconds: number): string {
        seconds = parseInt(seconds.toFixed(0))

        if (seconds < 60) {
            return `${seconds % 60}s`

        } else if (seconds < 60 * 60) {
            let minutes = (seconds - (seconds % 60)) / 60
            let remainingSeconds = seconds % 60
            return `${minutes}ᴍ\xa0${remainingSeconds}s`

        } else if (seconds < 60 * 60 * 24) {
            let totalMinutes = (seconds - seconds % 60) / 60
            let minutes = totalMinutes % 60
            let hours = (totalMinutes - minutes) / 60
            return `${hours}ʜ\xa0${minutes}ᴍ`

        } else {
            let totalHours = (seconds - seconds % (60 * 60)) / (60 * 60)
            let hours = totalHours % 24
            let days = (totalHours - hours) / 24
            return `${days}ᴅ\xa0${hours}`
        }
    }

    milliseconds(seconds: number): string {
        if (seconds === null || seconds === undefined) {
            return "-"
        }

        // Convert and round to milliseconds
        var milliseconds = seconds * 1_000

        if (milliseconds < 10) {
            return milliseconds.toFixed(1)
        }

        // Round the milliseconds
        milliseconds = parseFloat(milliseconds.toFixed(0))

        // Round to 2ms
        if (milliseconds < 20) {
            return (Math.round(milliseconds / 2.0) * 2).toLocaleString('en-US').replace(",", " ")

        // Round to 5ms
        } else if (milliseconds < 1000) {
            return (Math.round(milliseconds / 5.0) * 5).toLocaleString('en-US').replace(",", " ")

        // Round to 10ms
        } else {
            return (Math.round(milliseconds / 10.0) * 10).toLocaleString('en-US').replace(",", " ")
        }
    }

    // Returns seconds formatted in mm:ss.SSS format, like: 03:15.340
    stopwatchTimeShort(seconds: number): string {
        let duration = moment.duration(seconds, "seconds")

        let minutesPart = duration.minutes()
        let secondsPart = duration.seconds()
        let millisecondsPart = Math.round(duration.milliseconds())

        let minutesFormatted = minutesPart < 10 ? `0${minutesPart}` : minutesPart
        let secondsFormatted = secondsPart < 10 ? `0${secondsPart}` : secondsPart
        let millisecondsFormatted = millisecondsPart < 10 ? `00${millisecondsPart}` : (millisecondsPart < 100 ? `0${millisecondsPart}` : millisecondsPart)

        return `${minutesFormatted}:${secondsFormatted}.${millisecondsFormatted}`
    }

    timeAgo(date: Date | string, withoutSuffix: boolean = false): string {
        if (date === null || date === undefined) { return "-" }
        return moment(date).fromNow(withoutSuffix)
    }

    timeAgoShort(date: Date | string): string {
        if (date === null || date === undefined) { return "-" }

        moment.updateLocale('short', {
            relativeTime: {
                future: 'in %s',
                past: '%s ago',
                s: 'seconds',
                ss: '%ss',
                m: 'a minute',
                mm: '%dm',
                h: 'an hour',
                hh: '%dh',
                d: 'a day',
                dd: '%dd',
                M: 'a month',
                MM: '%dM',
                y: 'a year',
                yy: '%dY'
            }
        })

        return moment(date).locale("short").fromNow()
    }

    dateTime(date: Date | string): string {
        return moment(date).format('MMMM Do YYYY, HH:mm:ss')
    }

    dateTimeShort(date: Date | string): string {
        return moment(date).format('MMM Do YYYY, HH:mm:ss')
    }

    dateShort(date: Date | string): string {
        return moment(date).format('MMM Do YYYY')
    }

    time(date: Date | string): string {
        return moment(date).format('HH:mm:ss')
    }

    date(date: Date | string): string {
        return moment(date).format('MMMM Do YYYY')
    }

    dateInUTC(date: Date | string): string {
        // Switch to UTC zone without keeping local time
        return moment(date).utc(false).format('MMMM Do YYYY')
    }

    dateRange(dateRange: DateRange): string {
        let startMoment = moment(dateRange.startDate)
        let endMoment = moment(dateRange.endDate)

        // If the end date is very close to now, provide simplified/relative descriptions
        if (Math.abs(endMoment.diff(moment.now(), 'seconds', true)) < 300) {
            let minutesDiff = Math.abs(endMoment.diff(startMoment, 'minutes', true))
            if (minutesDiff >= 59 && minutesDiff <= 61) {
                return "Last hour"
            }

            if (endMoment.diff(startMoment, 'hour', true) == 24) {
                return "Last day"
            } else if (endMoment.diff(startMoment, 'weeks', true) == 1) {
                return "Last week"
            } else if (endMoment.diff(startMoment, 'months', true) == 1) {
                return "Last month"
            } else if (endMoment.diff(startMoment, 'years', true) == 1) {
                return "Last year"
            }
        }

        if (startMoment.isSame(endMoment, 'day')) {
            let day = startMoment.format('MMM D YYYY')
            let startTime = startMoment.format('HH:mm')
            let endTime = endMoment.format('HH:mm')
            return `${day} ${startTime} — ${endTime}`

        } else {
            let startTime = startMoment.format('MMM D YYYY, HH:mm')
            let endTime = endMoment.format('MMM D YYYY, HH:mm')
            return `${startTime} — ${endTime}`
        }
    }

}
