import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AlertService, BugReportsService, AuthenticationService, ApplicationService } from 'app/_services'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Application, Workspace, BugReportSummary } from 'app/_models';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { ApplicationFormatter } from 'app/_helpers';
import * as moment from 'moment'

@Component({
    templateUrl: 'bug.reports.component.html',
    styleUrls: [
        'bug.reports.component.css'
    ]
})
export class BugReportsComponent implements OnInit, OnDestroy {

    workspace: Workspace
    application: Application

    bugReports: BugReportSummary[]
    selectedBugReportSerialNumber: number | null
    filterForm: FormGroup

    @ViewChild('bugReportsList') bugReportsList: ElementRef

    isLoadingMoreRecords = false
    hasMoreRecords = false

    private navigationChangeSubscription: Subscription
    private currentApplicationSubscription: Subscription
    private resizeListener: any

    private oldestCreationDate: Date = null

    constructor(
        private bugReportsService: BugReportsService,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private router: Router,
        private authenticationService: AuthenticationService,
        private applicationService: ApplicationService,
        private formBuilder: FormBuilder,
        private applicationFormatter: ApplicationFormatter,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue

        let reporterEmail = this.route.snapshot.queryParams['reporterEmail']
        this.filterForm = this.formBuilder.group({
            reporterEmail: [reporterEmail],
        })
        this.filterForm.controls.reporterEmail.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            this.oldestCreationDate = null
            this.reloadBugReports()
        })

        this.navigationChangeSubscription = this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe( _ => {
            this.updateSelection()
        })

        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
            if (this.application != null) {
                this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | Bug Reports`)
                this.reloadBugReports()
            }
        })

        this.bugReportsService.bugReportDeletedWithId.subscribe( (bugReportId: number) => {
            this.bugReportDeletedWithId(bugReportId)
        })

        this.resizeListener = this.loadMoreBugReportsIfAtBottom.bind(this)
        window.addEventListener("resize", this.resizeListener)
    }

    ngOnDestroy() {
        this.navigationChangeSubscription.unsubscribe()
        this.currentApplicationSubscription.unsubscribe()
        window.removeEventListener("resize", this.resizeListener)
    }

    onBugReportsListScroll(event: any) {
        this.loadMoreBugReportsIfAtBottom()
    }

    bugReportDeletedWithId(deletedBugReportId: number) {
        // Remove the deleted bug report
        this.bugReports = this.bugReports.filter( bugReport => { return bugReport.id != deletedBugReportId })
        this.selectedBugReportSerialNumber = null

        this.router.navigate(['../bugs'], { relativeTo: this.route })
    }

    private updateSelection() {
        if (this.bugReports == null || this.route.firstChild == null) {
            this.selectedBugReportSerialNumber = null
            return
        }

        this.selectedBugReportSerialNumber = this.route.firstChild.snapshot.params['bugReportSlug']
    }

    private reloadBugReports() {
        if (!this.application) { return }

        this.isLoadingMoreRecords = true
        let reporterEmail = this.filterForm.controls.reporterEmail.value
        this.bugReportsService.getBugReportsFrom(this.application.id, reporterEmail, this.oldestCreationDate).then((response) => {
            this.bugReports = response.data
            this.hasMoreRecords = response.hasMoreRecords

            if (this.bugReports.length > 0) {
                this.oldestCreationDate = moment(this.bugReports[this.bugReports.length - 1].creationDate).toDate()
                this.loadMoreBugReportsIfAtBottom()
            }

            this.updateSelection()

        }).catch((error) => {
            this.alertService.handleError(error)

        }).finally(() => {
            this.isLoadingMoreRecords = false
        })
    }

    private loadMoreBugReportsIfAtBottom() {
        if (!this.application || !this.hasMoreRecords || this.isLoadingMoreRecords) {
            return
        }

        let bugReportsList = this.bugReportsList.nativeElement
        if (bugReportsList.offsetHeight + bugReportsList.scrollTop < bugReportsList.scrollHeight) {
            return
        }

        this.isLoadingMoreRecords = true
        let reporterEmail = this.filterForm.controls.reporterEmail.value
        this.bugReportsService.getBugReportsPageBefore(this.application.id, reporterEmail, this.oldestCreationDate).then((response) => {
            this.bugReports = this.bugReports.concat(response.data)
            this.oldestCreationDate = moment(this.bugReports[this.bugReports.length - 1].creationDate).toDate()
            this.hasMoreRecords = response.hasMoreRecords
            this.updateSelection()
            this.loadMoreBugReportsIfAtBottom()

        }).catch((error) => {
            this.alertService.handleError(error)

        }).finally(() => {
            this.isLoadingMoreRecords = false
        })
    }

}
