import { Component, Input, ElementRef, OnInit } from '@angular/core';
import * as QRCode from 'qrcode';

@Component({
    selector: 'qrcode',
    templateUrl: 'qrcode.component.html'
  })
export class QRCodeComponent implements OnInit {

    @Input() qrCodeData: string = ''
    @Input() scale: number = 1

    constructor(private element: ElementRef) {
    }

    ngOnInit() {
        this.updateQRCode()
    }

    private updateQRCode() {
        if (this.qrCodeData == null) {
            return
        }

        let canvas = document.getElementById("qrCodeCanvas")
        QRCode.toCanvas(canvas, this.qrCodeData, { margin: 0, scale: 4 * this.scale }, error => {
            if (error) { throw error }
        })
    }

}
