import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Application, CrashFilter, CrashGroupSegment, CrashSegmentsOrder, crashSegmentsOrderFromQuery, CrashTrendsResponse, CrashTrendsSummaryResponse, DateRange, MissingSymbolsFile } from 'app/_models';
import { AlertService, ApplicationService, CrashFiltersResponse, CrashesService, MissingSymbolsService } from 'app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationFormatter, DateFormatter, IssueStateFormatter } from 'app/_helpers';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { UploadSymbolsComponent } from 'app/_global.modals';
import * as moment from 'moment'

@Component({
    templateUrl: 'crashes.component.html',
    styleUrls: [
        'crashes.component.css'
    ]
})
export class CrashesComponent implements OnInit, OnDestroy {

    CrashSegmentsOrder = CrashSegmentsOrder

    application: Application
    crashGroupSegments: CrashGroupSegment[]

    crashFiltersResponse: CrashFiltersResponse
    crashTrendsResponse: CrashTrendsResponse
    crashTrendsSummaryResponse: CrashTrendsSummaryResponse
    missingSymbols: MissingSymbolsFile[]

    @ViewChild('pageContent') pageContent: ElementRef
    @ViewChild('uploadSymbolsModal') uploadSymbolsModal: UploadSymbolsComponent

    selectedDateRange: DateRange

    private currentApplicationSubscription: Subscription
    private queryParamsSubscription: Subscription
    private previousQueryParams: any = {}

    constructor(
        public dateFormatter: DateFormatter,
        public issueStateFormatter: IssueStateFormatter,
        private crashesService: CrashesService,
        private missingSymbolsService: MissingSymbolsService,
        private route: ActivatedRoute,
        private router: Router,
        private applicationService: ApplicationService,
        private alertService: AlertService,
        private applicationFormatter: ApplicationFormatter,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        let query = this.route.snapshot.queryParams
        this.selectedDateRange = DateRange.fromParams(query) ?? DateRange.lastMonth()
        this.updateSelect2SelectionFromQuery()

        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
            if (this.application != null) {
                this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | Crashes`)
                this.refreshFilters()
                this.reloadMissingSymbols()
            }
        })

        this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
            this.updateSelect2SelectionFromQuery()

            // If we don't have the filters response yet, no need to do anything as all the data will
            // get reloaded once the filters call finishes.
            if (!this.crashFiltersResponse) { return }

            let newDateRange = DateRange.fromParams(params) ?? DateRange.lastMonth()
            let changedParamNames = this.getChangedParamNames(params, this.previousQueryParams)

            // If ordering didn't change, it means that either date range or the filters changed,
            // and then we need to reload everything.
            if (!changedParamNames.includes('orderBy')) {
                this.selectedDateRange = newDateRange
                this.refreshCrashTrends()
                this.reloadCrashGroups()

            // If only the ordering changed, reload just the crash groups
            } else {
                this.reloadCrashGroups()
            }

            this.previousQueryParams = { ...params }
        })
    }

    ngOnDestroy() {
        this.currentApplicationSubscription.unsubscribe()
        this.queryParamsSubscription.unsubscribe()
    }

    private _didInitSelect2 = false
    ngAfterViewChecked() {
        if (!this.crashFiltersResponse) { return }

        if (!this._didInitSelect2 && $('.select2').length > 0) {
            this.initSelect2Components()
            this.updateSelect2SelectionFromQuery()
            this._didInitSelect2 = true
        }
    }

    refreshFilters() {
        this.crashFiltersResponse = null
        this.crashesService.getApplicationCrashFilters(this.application.id).then((response) => {
            this.crashFiltersResponse = response
            this.refreshCrashTrends()
            this.reloadCrashGroups()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    refreshCrashTrends() {
        if (!this.application) { return }

        // Will also switch the trends chart into loading mode
        this.crashTrendsResponse = null
        this.crashTrendsSummaryResponse = null

        let crashFilter = this.makeCurrentCrashFilter()
        let getCrashTrends = this.crashesService.getCrashTrends(this.application.workspaceId, crashFilter)
        let getCrashTrendsSummary = this.crashesService.getCrashTrendsSummary(this.application.workspaceId, crashFilter)

        Promise.all([getCrashTrends, getCrashTrendsSummary]).then((response) => {
            this.crashTrendsResponse = response[0]
            this.selectedDateRange = response[0].dateRange
            this.crashTrendsSummaryResponse = response[1]

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    reloadMissingSymbols() {
        if (!this.application) { return }

        this.missingSymbols = null
        this.missingSymbolsService.getMissingSymbolsFiles(this.application.id, null).then((response) => {
            this.missingSymbols = response.data
        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    reloadCrashGroups() {
        if (!this.application) { return }

        this.crashGroupSegments = null

        let crashFilter = this.makeCurrentCrashFilter()
        let orderBy = crashSegmentsOrderFromQuery(this.route.snapshot.queryParams) ?? CrashSegmentsOrder.COUNT_DESC
        return this.crashesService.getWorkspaceCrashGroups(this.application.workspaceId, crashFilter, orderBy).then((response) => {
            this.crashGroupSegments = response.data

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    onDateRangeChanged(dateRange: DateRange) {
        this.router.navigate([], { queryParams: { startDate: moment(dateRange.startDate).toISOString(), endDate: moment(dateRange.endDate).toISOString() }, queryParamsHandling: 'merge' })
    }

    navigateToCrashGroup(crashGroupSegment: CrashGroupSegment) {
        this.router.navigate([crashGroupSegment.serialNumber], { relativeTo: this.route, queryParamsHandling: 'merge' } )
    }

    showUploadSymbolsModal() {
        this.uploadSymbolsModal.application = this.application
        this.uploadSymbolsModal.missingSymbols = this.missingSymbols
    }

    dismissMissingSymbolsAlert() {
        this.missingSymbols = null
    }

    private makeCurrentCrashFilter(): CrashFilter {
        if (!this.application || !this.crashFiltersResponse) {
            return null
        }

        let query = this.route.snapshot.queryParams

        let crashFilter = new CrashFilter()
        crashFilter.applicationId = this.application.id
        crashFilter.startDate = moment(this.selectedDateRange.startDate).toDate()
        crashFilter.endDate = moment(this.selectedDateRange.endDate).toDate()

        if (this.crashFiltersResponse) {
            if (query['bundleIdentifier']) {
                crashFilter.bundleIdentifierId = this.crashFiltersResponse.bundleIdentifiers.find((bi) => { return bi.value == query['bundleIdentifier'] }).id
            }

            if (query['applicationVersion']) {
                crashFilter.applicationVersionId = this.crashFiltersResponse.applicationVersions.find((v) => { return v.bundleShortVersion == query['applicationVersion'] }).id
            }

            if (query['osVersion']) {
                crashFilter.osVersionId = this.crashFiltersResponse.osVersions.find((osVersion) => { return osVersion.version == query['osVersion'] }).id
            }
        }

        return crashFilter
    }

    private initSelect2Components() {
        $('#bundleIdentifierSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '240px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#applicationVersionSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '160px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#osVersionSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '140px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678

        // Fix for select2 being expanded after clicking on clear button
        $('.select2').on("select2:unselecting", (event) => {
            $(event.target).val(null).trigger('change')
            event.preventDefault()
        })

        $('#bundleIdentifierSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { bundleIdentifier: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#bundleIdentifierSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { bundleIdentifier: null }, queryParamsHandling: 'merge' })
        })

        $('#applicationVersionSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { applicationVersion: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#applicationVersionSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { applicationVersion: null }, queryParamsHandling: 'merge' })
        })

        $('#osVersionSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { osVersion: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#osVersionSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { osVersion: null }, queryParamsHandling: 'merge' })
        })
    }

    private updateSelect2SelectionFromQuery() {
        if (!this.application || !this.crashFiltersResponse) { return }

        let query = this.route.snapshot.queryParams

        $('#bundleIdentifierSelect').val(query['bundleIdentifier']).trigger('change')
        $('#applicationVersionSelect').val(query['applicationVersion']).trigger('change')
        $('#osVersionSelect').val(query['osVersion']).trigger('change')
    }

    private getChangedParamNames(newParams: any, oldParams: any): string[] {
        var changedParamNames = []
        for (const key in newParams) {
            if (newParams[key] !== oldParams[key]) {
                changedParamNames.push(key)
            }
        }
        return changedParamNames
    }

}
