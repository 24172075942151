<div id="new_widget_modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog" *ngIf="application">
        <div class="modal-content">

            <div class="modal-header">
                <h5 class="modal-title" *ngIf="widget == null">Add Widget</h5>
                <h5 class="modal-title" *ngIf="widget != null">Edit Widget</h5>
                <button #closeButton type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="row">
                    <div class="col-md-12">
                        <alert></alert>
                    </div>
                </div>

                <div class="form-group row align-items-baseline" *ngIf="widget == null">
                    <label class="col-sm-3 font-weight-semibold">Data to show:</label>
                    <ul class="nav nav-pills col-sm-9 pl-2 m-0">
                        <li class="nav-item"><a href="#bugReportsTab" id="bugReportsTabButton" (click)="generateBugReportsWidgetPreview()" class="nav-link p-1 mr-1" data-toggle="tab">Bug Reports</a></li>
                        <li class="nav-item"><a href="#testExecutionsTab" id="testExecutionsTabButton" (click)="generateTestExecutionsWidgetPreview()" class="nav-link p-1 mr-1" data-toggle="tab">Test Executions</a></li>
                        <li class="nav-item"><a href="#buildsTab" id="buildsTabButton" (click)="generateBuildsWidgetPreview()" class="nav-link p-1" data-toggle="tab">Builds</a></li>
                    </ul>
                </div>

                <div class="row">
                    <label class="col-sm-3 mt-2">Preview</label>
                    <div class="col-sm-9">
                        <div class="card noshadow border-light">
                            <widget-view [widgetView]="preview"></widget-view>
                        </div>
                    </div>
                </div>

                <div class="tab-content">
                    <div class="tab-pane fade" id="bugReportsTab">
                        <form [formGroup]="bugReportsForm">
                            <div class="form-group row">
                                <label class="col-sm-3 mt-2">Widget name <span class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                    <input type="text" formControlName="name" class="form-control">
                                    <div class="text-muted mt-1">This name will be shown in the app dashboard.</div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-sm-3 mt-2">Reporter email</label>
                                <div class="col-sm-9">
                                    <input type="email" formControlName="reporterEmail" class="form-control">
                                    <div class="text-muted mt-1">When specified, only the reports matching this email address will be shown in the widget.</div>
                                </div>
                            </div>

                            <div class="form-group row align-items-baseline">
                                <label class="col-sm-3">Time range</label>

                                <div class="col-sm-9">
                                    <ul class="pagination-flat pagination font-size-sm">
                                        <li *ngFor="let timeRange of availableTimeRanges" class="page-item" [ngClass]="{ 'active': this.bugReportsForm.controls.timeRange.value == timeRange }">
                                            <a href="javascript:void(0)" (click)="this.bugReportsForm.controls.timeRange.setValue(timeRange)" class="page-link p-1" [ngClass]="{ 'text-muted': this.bugReportsForm.controls.timeRange.value != timeRange }" style="min-width: 0">
                                                {{timeRange}}
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="text-muted mt-1">Choose the amount of history data to be shown in the widget.</div>
                                </div>
                            </div>

                            <div class="form-group row align-items-baseline">
                                <label class="col-sm-3">Availability</label>
                                <div class="col-sm-9">
                                    <div class="form-check custom-checkbox">
                                        <div class="uniform-checker">
                                            <span [ngClass]="{ 'checked': this.bugReportsForm.controls.isShared.value }">
                                                <input type="checkbox" class="form-control-styled" formControlName="isShared" id="shareBugReport">
                                            </span>
                                        </div>
                                        <label class="handcursor" for="shareBugReport">Share with everybody from {{workspace.name}}</label>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="w-100 d-flex">
                            <button type="button" class="btn btn-link text-default ml-auto mr-2" data-dismiss="modal">Cancel</button>
                            <button (click)="createBugReportsWidget()" [disabled]="bugReportsForm.invalid" type="button" class="btn bg-primary-400" *ngIf="widget == null">Create</button>
                            <button (click)="updateBugReportsWidget()" [disabled]="bugReportsForm.invalid" type="button" class="btn bg-primary-400" *ngIf="widget != null">Save</button>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="testExecutionsTab">
                        <form [formGroup]="testExecutionsForm">
                            <div class="form-group row">
                                <label class="col-sm-3 mt-2">Widget name <span class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                    <input type="text" formControlName="name" class="form-control">
                                    <div class="text-muted mt-1">This name will be shown in the app dashboard.</div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-sm-3 mt-2">Environment name</label>
                                <div class="col-sm-9">
                                    <input type="text" formControlName="environmentName" class="form-control">
                                    <div class="text-muted mt-1">If specified, only the test executions with matching environment name will be shown.</div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-sm-3 mt-2">Test plan name</label>
                                <div class="col-sm-9">
                                    <input type="text" formControlName="testPlanName" class="form-control">
                                    <div class="text-muted mt-1">If specified, only the test executions with matching test plan name will be shown.</div>
                                </div>
                            </div>

                            <div class="form-group row align-items-baseline">
                                <label class="col-sm-3">Time range</label>

                                <div class="col-sm-9">
                                    <ul class="pagination-flat pagination font-size-sm">
                                        <li *ngFor="let timeRange of availableTimeRanges" class="page-item" [ngClass]="{ 'active': this.testExecutionsForm.controls.timeRange.value == timeRange }">
                                            <a href="javascript:void(0)" (click)="this.testExecutionsForm.controls.timeRange.setValue(timeRange)" class="page-link p-1" [ngClass]="{ 'text-muted': this.testExecutionsForm.controls.timeRange.value != timeRange }" style="min-width: 0">
                                                {{timeRange}}
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="text-muted mt-1">Choose the amount of history data to be shown in the widget.</div>
                                </div>
                            </div>

                            <div class="form-group row align-items-baseline">
                                <label class="col-sm-3">Availability</label>
                                <div class="col-sm-9">
                                    <div class="form-check custom-checkbox">
                                        <div class="uniform-checker">
                                            <span [ngClass]="{ 'checked': this.testExecutionsForm.controls.isShared.value }">
                                                <input type="checkbox" class="form-control-styled" formControlName="isShared" id="shareTestExeciutions">
                                            </span>
                                        </div>
                                        <label class="handcursor" for="shareTestExeciutions">Share with everybody from {{workspace.name}}</label>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="w-100 d-flex">
                            <button type="button" class="btn btn-link text-default ml-auto mr-2" data-dismiss="modal">Cancel</button>
                            <button (click)="createTestExecutionsWidget()" [disabled]="testExecutionsForm.invalid" type="button" class="btn bg-primary-400" *ngIf="widget == null">Create</button>
                            <button (click)="updateTestExecutionsWidget()" [disabled]="testExecutionsForm.invalid" type="button" class="btn bg-primary-400" *ngIf="widget != null">Update</button>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="buildsTab">
                        <form [formGroup]="buildsForm">
                            <div class="form-group row">
                                <label class="col-sm-3 mt-2">Widget name <span class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                    <input type="text" formControlName="name" class="form-control">
                                    <div class="text-muted mt-1">This name will be shown in the app dashboard.</div>
                                </div>
                            </div>

                            <div class="form-group row align-items-baseline">
                                <label class="col-sm-3">Time range</label>

                                <div class="col-sm-9">
                                    <ul class="pagination-flat pagination font-size-sm">
                                        <li *ngFor="let timeRange of availableTimeRanges" class="page-item" [ngClass]="{ 'active': this.buildsForm.controls.timeRange.value == timeRange }">
                                            <a href="javascript:void(0)" (click)="this.buildsForm.controls.timeRange.setValue(timeRange)" class="page-link p-1" [ngClass]="{ 'text-muted': this.buildsForm.controls.timeRange.value != timeRange }" style="min-width: 0">
                                                {{timeRange}}
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="text-muted mt-1">Choose the amount of history data to be shown in the widget.</div>
                                </div>
                            </div>

                            <div class="form-group row align-items-baseline">
                                <label class="col-sm-3">Availability</label>
                                <div class="col-sm-9">
                                    <div class="form-check custom-checkbox">
                                        <div class="uniform-checker">
                                            <span [ngClass]="{ 'checked': this.buildsForm.controls.isShared.value }">
                                                <input type="checkbox" class="form-control-styled" formControlName="isShared" id="shareBuild">
                                            </span>
                                        </div>
                                        <label class="handcursor" for="shareBuild">Share with everybody from {{workspace.name}}</label>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="w-100 d-flex">
                            <button type="button" class="btn btn-link text-default ml-auto mr-2" data-dismiss="modal">Cancel</button>
                            <button (click)="createBuildsWidget()" [disabled]="buildsForm.invalid" type="button" class="btn bg-primary-400" *ngIf="widget == null">Create</button>
                            <button (click)="updateBuildsWidget()" [disabled]="buildsForm.invalid" type="button" class="btn bg-primary-400" *ngIf="widget != null">Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
