<div class="h-100">
    <div id="widgetInfo_{{chartUuid}}" class="card-body py-1" *ngIf="widgetView">
        <div class="d-flex">
            <h3 class="font-weight-semibold my-0">
                <count [count]="widgetView.currentPeriodCount"></count>
            </h3>
            <span class="badge border-grey-400 border-1 badge-pill align-self-center ml-2" *ngIf="badgeText">{{badgeText}}</span>
        </div>
        <div>{{widgetView.currentPeriodInfo}}</div>
        <div class="text-muted font-size-sm">
            <count [count]="widgetView.totalCount"></count> total
        </div>
    </div>

    <div id="canvasContainer_{{chartUuid}}" class="mx-2">
        <canvas id="{{chartUuid}}" height="90"></canvas>
    </div>
</div>
