import { CrashTrendsSummaryResponse } from 'app/_models';
import { Component, Input, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'crash-trends-summary',
    templateUrl: 'crash.trends.summary.component.html'
})
export class CrashTrendsSummaryComponent {

    _crashTrendsSummaryResponse: CrashTrendsSummaryResponse
    @Input()
    set crashTrendsSummaryResponse(crashTrendsSummaryResponse: CrashTrendsSummaryResponse) {
        if (this._crashTrendsSummaryResponse != crashTrendsSummaryResponse) {
            this._crashTrendsSummaryResponse = crashTrendsSummaryResponse
            this.changeDetector.detectChanges()
        }
    }
    get crashTrendsSummaryResponse(): CrashTrendsSummaryResponse { return this._crashTrendsSummaryResponse }

    constructor(
        private changeDetector: ChangeDetectorRef
    ) {
    }

}
