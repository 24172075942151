import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, OnInit, HostListener } from '@angular/core';
import { AlertService, ApplicationService, AuthenticationService, IntegrationsService  } from 'app/_services'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Application, BackendResponse, BugReportsTriggerConfiguration, TriggerConfigurationType, ApplicationFilter, IntegrationTrigger, IntegrationTriggerEvent, ServiceConnection, TestExecutionsTriggerConfiguration, Workspace } from 'app/_models';
import { PlatformFormatter } from 'app/_helpers'
import { TagModel } from 'ngx-chips/core/accessor';

@Component({
    selector: 'edit-integration-trigger-modal',
    templateUrl: 'edit.integration.trigger.modal.html'
})
export class EditIntegrationTriggerModal implements OnInit {

    workspace: Workspace
    applications: Application[]
    bugReportsForm: FormGroup
    testExecutionsForm: FormGroup
    private didInitialRefresh = false
    private availableEnvironmentNames: string[] = []
    private availableTestPlanNames: string[] = []

    @Input() serviceConnection: ServiceConnection

    private _integrationTrigger: IntegrationTrigger
    @Input()
    public set integrationTrigger(integrationTrigger: IntegrationTrigger) {
        this._integrationTrigger = integrationTrigger
        if (integrationTrigger == null) {
            this.setupBugReportsForm(null)
            this.setupTestExecutionsForm(null)
            this.showFormForTriggerConfigurationType(TriggerConfigurationType.BugReports)
            return
        }

        switch (integrationTrigger.triggerEvent) {
            case IntegrationTriggerEvent.BugReportCreated:
                this.setupBugReportsForm(integrationTrigger)
                this.setupTestExecutionsForm(null)
                this.showFormForTriggerConfigurationType(TriggerConfigurationType.BugReports)
                break

            case IntegrationTriggerEvent.TestExecutionFinished:
                this.setupBugReportsForm(null)
                this.setupTestExecutionsForm(integrationTrigger)
                this.showFormForTriggerConfigurationType(TriggerConfigurationType.TestExecutions)
                break
        }
    }
    public get integrationTrigger(): IntegrationTrigger {
        return this._integrationTrigger
    }

    @Output() onIntegrationTriggerCreated = new EventEmitter()
    @Output() onIntegrationTriggerUpdated = new EventEmitter()

    @ViewChild('closeButton') closeButton: ElementRef

    constructor(
        public platformFormatter: PlatformFormatter,
        private alertService: AlertService,
        private applicationsService: ApplicationService,
        private authenticationService: AuthenticationService,
        private formBuilder: FormBuilder,
        private integrationsService: IntegrationsService,
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue

        let applicationsFuture = this.applicationsService.getWorkspaceApplications()
        let filtersPromise = this.integrationsService.getFilterInfos(this.workspace.id)

        Promise.all([applicationsFuture, filtersPromise]).then((response) => {
            this.applications = response[0].data
            this.availableEnvironmentNames = response[1].environmentNames
            this.availableTestPlanNames = response[1].testPlanNames
            this.setupBugReportsForm(null)
            this.setupTestExecutionsForm(null)

        }).catch((error) => {
            this.alertService.handleError(error)
        })

        var weakThis = this
        $('#editIntegrationTriggerModal').on('show.bs.modal', function () {
            if (weakThis.integrationTrigger || weakThis.didInitialRefresh) { return }
            weakThis.showFormForTriggerConfigurationType(TriggerConfigurationType.BugReports)
            weakThis.didInitialRefresh = true
        })

        $('#editIntegrationTriggerModal').on('hidden.bs.modal', function () {
            weakThis.integrationTrigger = null
            weakThis.setupBugReportsForm(null)
            weakThis.setupTestExecutionsForm(null)
            weakThis.didInitialRefresh = false
        })
    }

    showFormForTriggerConfigurationType(type: TriggerConfigurationType) {
        switch (type) {
            case TriggerConfigurationType.BugReports:
                $('#bugReportsTab').addClass("active show")
                $('#bugReportsTabButton').addClass("active")
                $('#testExecutionsTab').removeClass("active show")
                $('#testExecutionsTabButton').removeClass("active")
                break

            case TriggerConfigurationType.TestExecutions:
                $('#bugReportsTab').removeClass("active show")
                $('#bugReportsTabButton').removeClass("active")
                $('#testExecutionsTab').addClass("active show")
                $('#testExecutionsTabButton').addClass("active")
                break
        }
    }

    createBugReportsIntegrationTrigger() {
        let f = this.bugReportsForm.controls

        this.integrationsService.createBugReportsIntegrationTrigger(this.serviceConnection.id, this.applicationFilters(this.bugReportsForm), f.reporterEmail.value).then((response) => {
            this.onIntegrationTriggerCreated.emit()
            this.closeForm()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    updateBugReportsIntegrationTrigger() {
        let f = this.bugReportsForm.controls
        this.integrationsService.updateBugReportsIntegrationTrigger(this.integrationTrigger.id, this.applicationFilters(this.bugReportsForm), f.reporterEmail.value).then((response) => {
            this.onIntegrationTriggerUpdated.emit()
            this.closeForm()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    createTestExecutionsIntegrationTrigger() {
        let f = this.testExecutionsForm.controls
        let environmentNames = (f.environmentNames.value as TagModel[]).map((tagModel) => { return tagModel['value'] })
        let testPlanNames = (f.testPlanNames.value as TagModel[]).map((tagModel) => { return tagModel['value'] })

        this.integrationsService.createTestExecutionIntegrationTrigger(this.serviceConnection.id, this.applicationFilters(this.testExecutionsForm), environmentNames, testPlanNames).then((response) => {
            this.onIntegrationTriggerCreated.emit()
            this.closeForm()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    updateTestExecutionsIntegrationTrigger() {
        let f = this.testExecutionsForm.controls
        let environmentNames = (f.environmentNames.value as TagModel[]).map((tagModel) => { return tagModel['value'] })
        let testPlanNames = (f.testPlanNames.value as TagModel[]).map((tagModel) => { return tagModel['value'] })

        this.integrationsService.updateTestExecutionIntegrationTrigger(this.integrationTrigger.id, this.applicationFilters(this.testExecutionsForm), environmentNames, testPlanNames).then((response) => {
            this.onIntegrationTriggerUpdated.emit()
            this.closeForm()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private tagModelFromString(value: string): TagModel {
        return { value: value, display: value }
    }

    private setupBugReportsForm(integrationTrigger: IntegrationTrigger) {
        let configuration = integrationTrigger == null ? null : integrationTrigger.configuration as BugReportsTriggerConfiguration

        this.bugReportsForm = this.formBuilder.group({
            reporterEmail: [configuration == null ? null : configuration.reporterEmail],
        })

        this.applications.forEach( application => {
            let isEnabled = integrationTrigger == null ? true : this.isTriggerEnabledForApplication(integrationTrigger, application)
            this.bugReportsForm.addControl(`application_${application.id}`, new FormControl(isEnabled, Validators.required))
        })
    }

    private setupTestExecutionsForm(integrationTrigger: IntegrationTrigger) {
        let configuration = integrationTrigger == null ? null : integrationTrigger.configuration as TestExecutionsTriggerConfiguration

        this.testExecutionsForm = this.formBuilder.group({
            environmentNames: [configuration == null ? [] : configuration.environmentNames.map(this.tagModelFromString)],
            testPlanNames: [configuration == null ? [] : configuration.testPlanNames.map(this.tagModelFromString)]
        })

        this.applications.forEach(application => {
            let isEnabled = integrationTrigger == null ? true : this.isTriggerEnabledForApplication(integrationTrigger, application)
            this.testExecutionsForm.addControl(`application_${application.id}`, new FormControl(isEnabled, Validators.required))
        })
    }

    private applicationFilters(form: FormGroup): ApplicationFilter[] {
        var filters: ApplicationFilter[] = []

        for (let application of this.applications) {
            let filter = new ApplicationFilter()
            filter.applicationId = application.id
            filter.isEnabled = form.controls[`application_${application.id}`].value === true
            filters.push(filter)
        }
        return filters
    }

    private isTriggerEnabledForApplication(integrationTrigger: IntegrationTrigger, application: Application):boolean {
        let matches = integrationTrigger.applicationFilters.filter(app => app.applicationId == application.id)
        if (matches.length != 1) {
            return false
        }

        return matches[0].isEnabled
    }

    private closeForm() {
        this.closeButton.nativeElement.click()
    }

}
