import { Component, Input } from '@angular/core';

export class UrlComponent {
    value: string
    renderAsPattern: boolean
}

const patternRegex = /^\{[a-zA-Z]+\}$/

@Component({
    selector: 'url-pattern',
    templateUrl: 'url.pattern.component.html'
})
export class UrlPatternComponent {

    urlComponents: UrlComponent[] = []

    private _pattern: string
    @Input()
    set pattern(pattern: string) {
        if (this._pattern !== pattern) {
            this._pattern = pattern
            this.reload()
        }
    }
    get pattern(): string { return this._pattern }

    constructor(
    ) { }

    private reload() {
        this.urlComponents = []

        if (this.pattern == null || this.pattern.length == 0) {
            return
        }

        var urlComponents: UrlComponent[] = []
        for (let component of this.pattern.split("/")) {
            let urlComponent = new UrlComponent()
            urlComponent.value = component
            urlComponent.renderAsPattern = patternRegex.test(component)
            urlComponents.push(urlComponent)
        }
        this.urlComponents = urlComponents
    }

}
