<div class="navbar navbar-expand navbar-light desktop-only">
    <div class="navbar-header navbar-dark d-none d-md-flex align-items-md-center">
        <div class="navbar-brand navbar-brand-md">
            <a [routerLink]="['/', workspace.slug]" class="d-inline-block">
                <img src="assets/img/logo_light.png" alt="">
            </a>
        </div>

        <div class="navbar-brand navbar-brand-xs">
            <a [routerLink]="['/', workspace.slug]" class="d-inline-block">
                <img src="assets/img/logo_square.png" alt="">
            </a>
        </div>
    </div>

    <subscription-notice class="ml-auto mr-2"></subscription-notice>
    <navbar-user-menu></navbar-user-menu>
</div>

<mobile-warning class="mobile-only"></mobile-warning>

<div class="page-content desktop-only">

    <div class="sidebar sidebar-dark sidebar-main sidebar-expand-md">

        <!-- Sidebar mobile toggler -->
        <div class="sidebar-mobile-toggler text-center">
            <a href="#" class="sidebar-mobile-main-toggle">
                <i class="icon-arrow-left8"></i>
            </a>
            Navigation
            <a href="#" class="sidebar-mobile-expand">
                <i class="icon-screen-full"></i>
                <i class="icon-screen-normal"></i>
            </a>
        </div>
        <!-- /sidebar mobile toggler -->

        <!-- Sidebar content -->
        <div class="sidebar-content h-100">

            <!-- Environments picker -->
            <div class="sidebar-user" *ngIf="backendEnvironment">
                <div class="card-body">
                    <div class="nav-item dropdown dropdown-user">
                        <a class="navbar-nav-link p-0 media text-white d-flex align-items-center" (click)="showQuickJump()">
                            <div class="env-icon mr-2 ">{{backendEnvironment.name.charAt(0)}}</div>

                            <div class="media-body d-flex align-items-center">
                                <div class="media-title mb-0 font-weight-semibold">{{backendEnvironment.name}}</div>
                                <i class="icon-arrow-down22 ml-auto"></i>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <!-- /Environments picker -->

            <!-- Main navigation -->
            <div class="card card-sidebar-mobile" *ngIf="backendEnvironment">
                <ul class="nav nav-sidebar" data-nav-type="accordion">

                    <!-- App Performance -->
                    <li class="nav-item-header">
                        <div class="text-uppercase font-size-xs line-height-xs">Performance</div>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="['/', workspace.slug, 'envs', backendEnvironment.slug, 'network']" [queryParams]="{environment: backendEnvironment.name}" class="nav-link" [class.active]="selectedComponent == 1"><i class="icon-connection"></i><span>Network</span></a>
                    </li>

                    <li class="nav-item-divider"></li>

                    <!-- Test Reports -->
                    <li class="nav-item-header">
                        <div class="text-uppercase font-size-xs line-height-xs">Test Reports</div>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="['/', workspace.slug, 'envs', backendEnvironment.slug, 'test-executions']" class="nav-link" [class.active]="selectedComponent == 2"><i class="icon-clipboard5"></i><span>Test Executions</span></a>
                    </li>

                    <li class="nav-item-divider"></li>

                    <!-- Settings -->
                    <li class="nav-item-header">
                        <div class="text-uppercase font-size-xs line-height-xs">Settings</div>
                    </li>

                    <li class="nav-item">
                        <a [routerLink]="['/', workspace.slug, 'envs', backendEnvironment.slug, 'settings']" class="nav-link" [class.active]="selectedComponent == 3"><i class="icon-equalizer"></i><span>Settings</span></a>
                    </li>

                    <!-- Toggle -->
                    <li class="nav-item-divider"></li>
                    <li class="nav-item-header">
                        <div class="text-uppercase font-size-xs line-height-xs">Toggle sidebar</div>
                    </li>
                </ul>
            </div>

            <div class="card-body pt-0">
                <a href="#" class="sidebar-control sidebar-main-toggle text-white">
                    <i class="icon-paragraph-justify3"></i>
                </a>
            </div>
        </div>
    </div>

    <div class="router-outlet-container">
        <router-outlet></router-outlet>
    </div>

</div>

<quick-jump-modal #quickJumpModal></quick-jump-modal>
