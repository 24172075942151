import { TimeStyle } from "app/_models";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class LocalPreferencesService {

    public set applicationSideMenuExpanded(expanded: boolean) {
        localStorage.setItem('applicationSideMenuExpanded', expanded ? "true" : "false")
    }

    public get applicationSideMenuExpanded(): boolean {
        return localStorage.getItem('applicationSideMenuExpanded') == "true"
    }

    public set showApplicationsInListView(listView: boolean) {
        localStorage.setItem('showApplicationsInListView', listView ? "true" : "false")
    }

    public get showApplicationsInListView(): boolean {
        return localStorage.getItem('showApplicationsInListView') == "true"
    }

    public set lastSelectedApplicationId(applicationId: number) {
        localStorage.setItem('lastSelectedApplicationId', applicationId + "")
    }

    public get lastSelectedApplicationId(): number {
        let value = localStorage.getItem('lastSelectedApplicationId')
        return value == null ? null : parseInt(value)
    }

    public set applicationLogsTimeStyle(timeStyle: TimeStyle) {
        localStorage.setItem('applicationLogsTimeStyle', timeStyle)
    }

    public get applicationLogsTimeStyle(): TimeStyle {
        let timeStyleString = localStorage.getItem('applicationLogsTimeStyle')
        if (timeStyleString == TimeStyle.Relative) {
            return TimeStyle.Relative
        } else {
            // Return TimeStyle.Absolute even if nothing is found in preferences
            return TimeStyle.Absolute
        }
    }

    public logout() {
        localStorage.removeItem('lastSelectedApplicationId')
    }

}
