<navbar-settings></navbar-settings>

<div class="page-content">
    <settings-sidebar selectedFeatureIndex="1"></settings-sidebar>

    <!-- Main content -->
    <div class="content-wrapper">

        <!-- Content area -->
        <div class="content">

            <div class="row">
                <div class="col-md-12">
                    <alert></alert>
                </div>
            </div>

            <!-- Applications row -->
            <div class="row" *ngIf="applications">
                <div class="col-md-12">
                    <div class="card">

                        <!-- Card header -->
                        <div class="card-header bg-transparent header-elements-inline">
                            <h1 class="font-weight-thin mb-0">{{applications.length}} Applications</h1>

                            <div class="header-elements">
                                <div class="list-icons">
                                    <div class="header-elements">
                                        <button type="button" data-toggle="modal" data-target="#create_app_modal" class="btn btn-labeled bg-primary-400 btn-labeled-left"><b><i class="icon-plus2"></i></b> New Application</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /card header -->

                    </div>
                </div>
            </div>
            <!-- /applications row -->

        </div>
        <!-- /content header -->

    </div>
    <!-- /main content -->

</div>

<new-app-modal [workspace]="workspace" (onApplicationCreated)="onApplicationCreated()"></new-app-modal>
