import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Invoice } from 'app/_models';
import { AppConfig } from 'app/app.config';
import { CollectionResponse } from './response';

export class InvoicesResponse {
    data: [Invoice]
    nextInvoiceDate: Date | null
}

@Injectable({ providedIn: 'root' })
export class InvoicesService {

    constructor(
        private http: HttpClient,
    ) {
    }

    getWorkspaceInvoices(workspaceId: number): Promise<InvoicesResponse> {
        return this.http.get<InvoicesResponse>(`${AppConfig.apiBaseUrl}/workspaces/${workspaceId}/invoices`).toPromise()
    }

    getWorkspaceInvoicesWithSlug(workspaceId: number, invoiceSlug: string): Promise<CollectionResponse<Invoice>> {
        return this.http.get<CollectionResponse<Invoice>>(`${AppConfig.apiBaseUrl}/workspaces/${workspaceId}/invoices?slug=${invoiceSlug}`).toPromise()
    }

    getInvoiceHTML(invoiceId: number): Promise<Blob>{
        return this.http.get(`${AppConfig.apiBaseUrl}/invoices/${invoiceId}?format=html`, { responseType: 'blob' }).toPromise()
    }

}
