import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AlertService, UserService, AuthenticationService, InvitationInfoResponse, InvitationsService } from 'app/_services';
import { BackendResponse } from 'app/_models';
import { RouteHelper } from 'app/_helpers';
import { Title } from '@angular/platform-browser';

@Component({templateUrl: 'register.component.html'})
export class RegisterComponent implements OnInit {

    registerForm: FormGroup
    invitationToken: string
    invitation: InvitationInfoResponse

    // Will be set to true when 2FA setup is required before logging in
    twoFactorAuthenticationSecret: string
    twoFactorAuthenticationUrl: string

    constructor(
        private routeHelper: RouteHelper,
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private invitationService: InvitationsService,
        private userService: UserService,
        private alertService: AlertService,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.titleService.setTitle("Registration")

        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate([this.routeHelper.currentWorkspaceHome()])
            return
        }

        this.invitation = this.invitationService.pendingInvitationToAccept

        if (this.invitation) {
            this.registerForm = this.formBuilder.group({
                name: [null, Validators.required],
                workspace: new FormControl({value: this.invitation.workspace.name, disabled: true}, Validators.required),
                email: new FormControl({value: this.invitation.email, disabled: true}, Validators.required),
                password: [null, [Validators.required, Validators.minLength(12)]],
                userInvitationId: [this.invitation.id],
                twoFactorAuthenticationToken: [null, null]
            })

        } else {
            this.registerForm = this.formBuilder.group({
                name: [null, Validators.required],
                workspace: [null, Validators.required],
                email: [null, Validators.required],
                password: [null, [Validators.required, Validators.minLength(12)]]
            })
        }
    }

    onSubmit() {
        if (this.invitation) {
            this.submitRegistrationFromInvitation()

        } else {
            this.submitNewWorkspaceRegistration()
        }
    }

    private submitRegistrationFromInvitation() {
        if (!this.invitation) { return }

        let f = this.registerForm.controls

        let name = f.name.value
        let email = f.email.value
        let password = f.password.value
        let twoFactorAuthenticationToken = f.twoFactorAuthenticationToken.value

        this.userService.registerInvited(this.invitation.id, name, email, password, twoFactorAuthenticationToken).then((loginResponse) => {
            this.authenticationService.setUserAuthenticated(loginResponse)
            this.router.navigate([this.routeHelper.currentWorkspaceHome()])

        }).catch((error) => {
            if (error.status == 0 || (error.error as BackendResponse).error == null) {
                this.alertService.handleError(error)
                return
            }

            let backendResponse = error.error as BackendResponse

            // Invalid 2FA token
            if (backendResponse.error.httpStatus == 406) {
                this.alertService.error(backendResponse.error.title)
                this.registerForm.controls.twoFactorAuthenticationToken.reset(null)

                // User is required to set up 2FA
            } else if (backendResponse.error.httpStatus == 412) {
                this.alertService.error(backendResponse.error.title)

                this.registerForm.controls.name.disable()
                this.registerForm.controls.email.disable()
                this.registerForm.controls.password.disable()
                this.twoFactorAuthenticationSecret = backendResponse.error.userInfo['secret']
                this.twoFactorAuthenticationUrl = backendResponse.error.userInfo["authUrl"]

            } else {
                this.alertService.error(backendResponse.error.title)
            }
        })
    }

    private submitNewWorkspaceRegistration() {
        this.userService.register(this.registerForm.value).then((response) => {
            let f = this.registerForm.controls

            this.alertService.success('Activation code was sent to your email address. Please enter the code and resume with the registration.', true)
            this.router.navigate([`/pending_activation`], { queryParams: { email: f.email.value } })
            this.invitationService.pendingInvitationToAccept = null

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
