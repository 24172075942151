<div id="rootContainer" class="root-container border-light">

    <!-- Main content -->
    <div class="media-container w-100 h-100" [style.visibility]="imageLoaded ? 'visible' : 'hidden'" [@imageLoaded]="imageLoaded">

        <!-- In case we have both image & video -->
        <ng-container *ngIf="videoFile">
            <!-- Only show the video element after image has loaded in order to avoid too many requests running in parallel -->
            <video id="video" class="w-100 h-100" (timeupdate)="onVideoTimeUpdate($event)" controls *ngIf="imageLoaded">
                <source src="{{videoFile.url}}" type="video/mp4">
            </video>

            <div [@fadeInOutAnimation] class="card-img-actions w-100 h-100" *ngIf="!hideImageOverlay">
                <a href="javascript:void(0)" (click)="onPlayVideoClick()">
                    <img class="img-fluid" src="{{imageFile.url}}" (load)="onImgLoad()">
                    <div class="card-img-actions-overlay">
                        <i class="icon-play3 icon-3x"></i>
                    </div>
                </a>
            </div>
        </ng-container>

        <!-- In case we have image only -->
        <ng-container *ngIf="videoFile == null">
            <img src="{{imageFile.url}}" (load)="onImgLoad()">
        </ng-container>
    </div>

    <!-- Loading indicator -->
    <div class="justify-content-center text-muted d-flex w-100 h-100 align-items-center" *ngIf="!imageLoaded">
        <i class="icon-spinner4 spinner align-self-center"></i>
    </div>

</div>
