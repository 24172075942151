import { Component, OnDestroy, OnInit } from '@angular/core';
import { WorkspaceService, AlertService, AuthenticationService, WorkspaceDashboardResponse, ApplicationService, BackendEnvironmentService, LocalPreferencesService } from 'app/_services';
import { Workspace, Application, BackendEnvironment } from 'app/_models';
import { AppdexFormatter, DateFormatter, PlatformFormatter, SearchUtils } from 'app/_helpers'
import { Title } from '@angular/platform-browser';
import { Observable, Subscription, timer } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
    templateUrl: 'workspace.dashboard.component.html',
    styleUrls: [
        'workspace.dashboard.component.css'
    ]
})
export class WorkspaceDashboardComponent implements OnInit, OnDestroy {

    workspace: Workspace
    searchForm: FormGroup

    allApplications: Application[]
    filteredApplications: Application[]

    allBackendEnvironments: BackendEnvironment[]
    filteredBackendEnvironments: BackendEnvironment[]

    showApplicationsInListView: boolean

    private timerObservable: Observable<number>
    private timerSubscription: Subscription | null

    private currentWorkspaceSubscription: Subscription

    constructor(
        public platformFormatter: PlatformFormatter,
        public dateFormatter: DateFormatter,
        public appdexFormatter: AppdexFormatter,
        private router: Router,
        private route: ActivatedRoute,
        private workspaceService: WorkspaceService,
        private authenticationService: AuthenticationService,
        private formBuilder: FormBuilder,
        private applicationService: ApplicationService,
        private backendEnvironmentService: BackendEnvironmentService,
        private localPreferencesService: LocalPreferencesService,
        private alertService: AlertService,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue
        this.showApplicationsInListView = this.localPreferencesService.showApplicationsInListView

        this.currentWorkspaceSubscription = this.authenticationService.currentWorkspace.subscribe(workspace => {
            this.workspace = workspace
            if (workspace != null) {
                this.titleService.setTitle(`${this.workspace.name} | Dashboard`)
            }
        })

        this.searchForm = this.formBuilder.group({
            searchQuery: [null],
        })
        this.searchForm.controls.searchQuery.valueChanges.subscribe(value => {
            this.updateFilteredEntries()
        })

        this.timerObservable = timer(0, 5000)
        this.timerSubscription = this.timerObservable.subscribe((seconds) => {
            this.reloadData()
        })
    }

    ngOnDestroy() {
        this.timerSubscription?.unsubscribe()
        this.currentWorkspaceSubscription.unsubscribe()
    }

    networkAppdexBadgeClassForApplication(application: Application): string {
        let appdexLevel = this.appdexFormatter.appdexLevel(application.latestNetworkAppdex)
        return this.appdexFormatter.appdexBadgeClass(appdexLevel)
    }

    testsAppdexBadgeClassForApplication(application: Application): string {
        let appdexLevel = this.appdexFormatter.testsAppdexLevel(application.latestTestsAppdex, application.testsSuccessThreshold)
        return this.appdexFormatter.appdexBadgeClass(appdexLevel)
    }

    networkAppdexBadgeClassForEnvironment(environment: BackendEnvironment): string {
        let appdexLevel = this.appdexFormatter.appdexLevel(environment.latestNetworkAppdex)
        return this.appdexFormatter.appdexBadgeClass(appdexLevel)
    }

    testsAppdexBadgeClassForEnvironment(environment: BackendEnvironment): string {
        let appdexLevel = this.appdexFormatter.testsAppdexLevel(environment.latestTestsAppdex, environment.testsSuccessThreshold)
        return this.appdexFormatter.appdexBadgeClass(appdexLevel)
    }

    navigateToApplication(application: Application) {
        this.applicationService.setCurrentApplication(application)
        this.router.navigate(['apps', application.slug], { relativeTo: this.route })
    }

    navigateToApplicationNetwork(application: Application) {
        this.applicationService.setCurrentApplication(application)
        this.router.navigate(['apps', application.slug, 'network'], { relativeTo: this.route })
    }

    navigateToApplicationTests(application: Application) {
        this.applicationService.setCurrentApplication(application)
        this.router.navigate(['apps', application.slug, 'test-executions'], { relativeTo: this.route })
    }

    navigateToBackendEnvironmentNetwork(backendEnvironment: BackendEnvironment) {
        this.backendEnvironmentService.setCurrentBackendEnvironment(backendEnvironment)
        this.router.navigate(['envs', backendEnvironment.slug, 'network'], { queryParams: { environment: backendEnvironment.name }, relativeTo: this.route, queryParamsHandling: 'merge' })
    }

    navigateToBackendEnvironmentTests(backendEnvironment: BackendEnvironment) {
        this.backendEnvironmentService.setCurrentBackendEnvironment(backendEnvironment)
        this.router.navigate(['envs', backendEnvironment.slug, 'test-executions'], { relativeTo: this.route })
    }

    showListView() {
        this.showApplicationsInListView = true
        this.localPreferencesService.showApplicationsInListView = true
    }

    showGridView() {
        this.showApplicationsInListView = false
        this.localPreferencesService.showApplicationsInListView = false
    }

    private reloadData() {
        this.workspaceService.getWorkspaceDashboard(this.workspace.id).then((response) => {
            this.updateData(response)

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private updateData(response: WorkspaceDashboardResponse) {
        this.allBackendEnvironments = response.environments

        if (this.allApplications == null || this.allApplications.length != response.applications.length) {
            this.allApplications = response.applications
            this.updateFilteredEntries()
            return
        }

        // Avoid triggering application icons to reload:
        for (let application of response.applications) {
            let matchingApplication = this.allApplications.filter((app) => { return app.id == application.id})[0]
            matchingApplication.latestNetworkAppdex = application.latestNetworkAppdex
            matchingApplication.latestTestsAppdex = application.latestTestsAppdex
        }

        this.updateFilteredEntries()
    }

    private updateFilteredEntries() {
        let searchQuery: string = this.searchForm.controls.searchQuery.value

        this.filteredApplications = this.matchingApplications(searchQuery)
        this.filteredBackendEnvironments = this.matchingBackendApplications(searchQuery)
    }

    private matchingApplications(searchQuery: string): Application[] {
        return this.allApplications.filter((app) => {
            let searchableFields = [app.name, app.slug, this.platformFormatter.platformName(app.platform)]
            return SearchUtils.searchQueryMatchesSearchableFields(searchQuery, searchableFields)
        })
    }

    private matchingBackendApplications(searchQuery: string): BackendEnvironment[] {
        return this.allBackendEnvironments.filter((environment) => {
            var searchableFields = [environment.name, environment.slug]
            if (environment.latestVersion) {
                searchableFields.push(environment.latestVersion.version)
            }
            return SearchUtils.searchQueryMatchesSearchableFields(searchQuery, searchableFields)
        })
    }

}
