import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output, ChangeDetectorRef, Input, OnDestroy, HostListener } from '@angular/core';
import { AlertService, ApplicationService, AuthenticationService, WidgetsService } from 'app/_services'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Application, BackendResponse, BugReportsWidgetConfiguration, TestExecutionsWidgetConfiguration, WidgetConfigurationType, TimeFilter, Widget, WidgetView, Workspace } from 'app/_models';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
    selector: 'new-widget-modal',
    templateUrl: 'new.widget.modal.html'
})
export class NewWidgetModal implements OnInit, OnDestroy {

    bugReportsForm: FormGroup
    testExecutionsForm: FormGroup
    buildsForm: FormGroup
    private didInitialRefresh = false

    application: Application
    private currentApplicationSubscription: Subscription

    workspace: Workspace
    @Output() onWidgetCreated = new EventEmitter()
    @Output() onWidgetUpdated = new EventEmitter()

    _widget: Widget
    @Input()
    set widget(widget: Widget) {
        this._widget = widget
        if (widget == null) {
            return
        }

        switch (widget.configurationType) {
            case WidgetConfigurationType.BugReports:
                this.setupBugReportsForm(widget)
                this.generateBugReportsWidgetPreview()
                this.showFormForConfigurationType(WidgetConfigurationType.BugReports)
                break

            case WidgetConfigurationType.TestExecutions:
                this.setupTestExecutionsForm(widget)
                this.generateTestExecutionsWidgetPreview()
                this.showFormForConfigurationType(WidgetConfigurationType.TestExecutions)
                break

            case WidgetConfigurationType.Builds:
                this.setupBuildsForm(widget)
                this.generateBuildsWidgetPreview()
                this.showFormForConfigurationType(WidgetConfigurationType.Builds)
                break
        }
    }
    get widget() { return this._widget }

    @ViewChild('closeButton') closeButton: ElementRef

    preview: WidgetView

    availableTimeRanges: TimeFilter[] = [
        TimeFilter.OneWeek,
        TimeFilter.TwoWeeks,
        TimeFilter.OneMonth,
        TimeFilter.TwoMonths,
        TimeFilter.ThreeMonths,
        TimeFilter.SixMonths,
        TimeFilter.OneYear
    ]

    constructor(
        private changeDetector: ChangeDetectorRef,
        private alertService: AlertService,
        private widgetsService: WidgetsService,
        private applicationService: ApplicationService,
        private authenticationService: AuthenticationService,
        private formBuilder: FormBuilder
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue

        this.setupBugReportsForm(this.widget)
        this.setupTestExecutionsForm(this.widget)
        this.setupBuildsForm(this.widget)

        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
        })

        var weakThis = this
        $('#new_widget_modal').on('show.bs.modal', function () {
            if (weakThis.widget != null || weakThis.didInitialRefresh) { return }
            weakThis.showFormForConfigurationType(WidgetConfigurationType.BugReports)
            weakThis.generateBugReportsWidgetPreview()
            weakThis.didInitialRefresh = true
        })

        $('#new_widget_modal').on('hidden.bs.modal', function () {
            weakThis.setupBugReportsForm(null)
            weakThis.setupTestExecutionsForm(null)
            weakThis.setupBuildsForm(null)
            weakThis.didInitialRefresh = false
            weakThis.widget = null
        })
    }

    ngOnDestroy() {
        this.currentApplicationSubscription.unsubscribe()
    }

    showFormForConfigurationType(type: WidgetConfigurationType) {
        switch (type) {
            case WidgetConfigurationType.BugReports:
                $('#bugReportsTab').addClass("active show")
                $('#bugReportsTabButton').addClass("active")
                $('#testExecutionsTab').removeClass("active show")
                $('#testExecutionsTabButton').removeClass("active")
                $('#buildsTab').removeClass("active show")
                $('#buildsTabButton').removeClass("active")
                break

            case WidgetConfigurationType.TestExecutions:
                $('#bugReportsTab').removeClass("active show")
                $('#bugReportsTabButton').removeClass("active")
                $('#testExecutionsTab').addClass("active show")
                $('#testExecutionsTabButton').addClass("active")
                $('#buildsTab').removeClass("active show")
                $('#buildsTabButton').removeClass("active")
                break

            case WidgetConfigurationType.Builds:
                $('#bugReportsTab').removeClass("active show")
                $('#bugReportsTabButton').removeClass("active")
                $('#testExecutionsTab').removeClass("active show")
                $('#testExecutionsTabButton').removeClass("active")
                $('#buildsTab').addClass("active show")
                $('#buildsTabButton').addClass("active")
                break
        }
    }

    createBugReportsWidget() {
        let f = this.bugReportsForm.controls
        this.widgetsService.createBugReportsWidget(this.application.id, f.name.value, f.timeRange.value, f.isShared.value, f.reporterEmail.value).then((response) => {
            this.onWidgetCreated.emit()
            this.closeForm()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    updateBugReportsWidget() {
        let f = this.bugReportsForm.controls
        this.widgetsService.updateBugReportsWidget(this.widget.id, f.name.value, f.timeRange.value, f.isShared.value, f.reporterEmail.value).then((response) => {
            this.onWidgetUpdated.emit()
            this.closeForm()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    createTestExecutionsWidget() {
        let f = this.testExecutionsForm.controls
        this.widgetsService.createTestExecutionsWidget(this.application.id, f.name.value, f.timeRange.value, f.isShared.value, f.environmentName.value, f.testPlanName.value).then((response) => {
            this.onWidgetCreated.emit()
            this.closeForm()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    updateTestExecutionsWidget() {
        let f = this.testExecutionsForm.controls
        this.widgetsService.updateTestExecutionsWidget(this.widget.id, f.name.value, f.timeRange.value, f.isShared.value, f.environmentName.value, f.testPlanName.value).then((response) => {
            this.onWidgetUpdated.emit()
            this.closeForm()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    createBuildsWidget() {
        let f = this.buildsForm.controls
        this.widgetsService.createBuildsWidget(this.application.id, f.name.value, f.timeRange.value, f.isShared.value).then((response) => {
            this.onWidgetCreated.emit()
            this.closeForm()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    updateBuildsWidget() {
        let f = this.buildsForm.controls
        this.widgetsService.updateBuildsWidget(this.widget.id, f.name.value, f.timeRange.value, f.isShared.value).then((response) => {
            this.onWidgetUpdated.emit()
            this.closeForm()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private setupBugReportsForm(widget: Widget) {
        let configuration = widget == null ? null : widget.configuration as BugReportsWidgetConfiguration

        this.bugReportsForm = this.formBuilder.group({
            name: [widget == null ? null : widget.name, Validators.required],
            reporterEmail: [configuration == null ? null : configuration.reporterEmail],
            timeRange: [widget == null ? TimeFilter.OneMonth : widget.timeRange, Validators.required],
            isShared: [widget == null ? false : widget.isShared, Validators.required],
        })

        this.bugReportsForm.controls.reporterEmail.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            this.generateBugReportsWidgetPreview()
        })

        this.bugReportsForm.controls.timeRange.valueChanges.subscribe(value => {
            this.generateBugReportsWidgetPreview()
        })
    }

    private setupTestExecutionsForm(widget: Widget) {
        let configuration = widget == null ? null : widget.configuration as TestExecutionsWidgetConfiguration

        this.testExecutionsForm = this.formBuilder.group({
            name: [widget == null ? null : widget.name, Validators.required],
            environmentName: [configuration == null ? null : configuration.environmentName],
            testPlanName: [configuration == null ? null : configuration.testPlanName],
            timeRange: [widget == null ? TimeFilter.OneMonth : widget.timeRange, Validators.required],
            isShared: [widget == null ? false : widget.isShared, Validators.required],
        })

        this.testExecutionsForm.controls.environmentName.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            this.generateTestExecutionsWidgetPreview()
        })

        this.testExecutionsForm.controls.testPlanName.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            this.generateTestExecutionsWidgetPreview()
        })

        this.testExecutionsForm.controls.timeRange.valueChanges.subscribe(value => {
            this.generateTestExecutionsWidgetPreview()
        })
    }

    private setupBuildsForm(widget: Widget) {
        this.buildsForm = this.formBuilder.group({
            name: [widget == null ? null : widget.name, Validators.required],
            timeRange: [widget == null ? TimeFilter.OneMonth : widget.timeRange, Validators.required],
            isShared: [widget == null ? false : widget.isShared, Validators.required],
        })

        this.buildsForm.controls.timeRange.valueChanges.subscribe(value => {
            this.generateBuildsWidgetPreview()
        })
    }

    private generateBugReportsWidgetPreview() {
        let f = this.bugReportsForm.controls
        this.widgetsService.bugReportsWidgetPreview(this.application.id, f.timeRange.value, f.reporterEmail.value).then((response) => {
            this.preview = response.data
            this.changeDetector.detectChanges()
        }).catch((error) => {})
    }

    private generateTestExecutionsWidgetPreview() {
        let f = this.testExecutionsForm.controls
        this.widgetsService.testExecutionsWidgetPreview(this.application.id, f.timeRange.value, f.environmentName.value, f.testPlanName.value).then((response) => {
            this.preview = response.data
            this.changeDetector.detectChanges()
        }).catch((error) => { })
    }

    private generateBuildsWidgetPreview() {
        let f = this.buildsForm.controls
        this.widgetsService.buildsWidgetPreview(this.application.id, f.timeRange.value).then((response) => {
            this.preview = response.data
            this.changeDetector.detectChanges()
        }).catch((error) => { })
    }

    private closeForm() {
        this.closeButton.nativeElement.click()
    }

}
