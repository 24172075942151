<navbar-settings></navbar-settings>

<div class="page-content">
    <settings-sidebar selectedFeatureIndex="8"></settings-sidebar>

    <!-- Main content -->
    <div class="content-wrapper">

        <!-- Content area -->
        <div class="content">

            <div class="row">
                <div class="col-md-12">
                    <alert></alert>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12" *ngIf="invoices && subscriptionTemplates">
                    <div class="card">
                        <div class="card-header">
                            <h1 class="font-weight-thin mb-0">Current subscription: <span class="badge badge-dark align-self-center ml-auto">{{workspace.subscription.displayName}}</span></h1>
                            <div *ngIf="isTrialSubscription">Expires <strong>{{dateFormatter.timeAgo(workspace.subscription.notValidAfter)}}</strong> - {{dateFormatter.date(workspace.subscription.notValidAfter)}}.</div>

                            <div *ngIf="!isFreeSubscription && !isTrialSubscription && !isDemoSubscription">
                                <div *ngIf="workspace.subscription.automaticRenewalEnabled">
                                    Renews automatically until canceled. Next renewal {{dateFormatter.timeAgo(workspace.subscription.notValidAfter)}} - <strong>{{dateFormatter.date(workspace.subscription.notValidAfter)}}</strong>. You can <a href="javascript:void(0)" (click)="updateSubscriptionAutorenewal(false)">cancel next renewal</a> up to 24h before expiration date.
                                </div>

                                <div *ngIf="!workspace.subscription.automaticRenewalEnabled">
                                    Expires <strong>{{dateFormatter.timeAgo(workspace.subscription.notValidAfter)}}</strong> - {{dateFormatter.date(workspace.subscription.notValidAfter)}} unless renewed. You can <a href="javascript:void(0)" (click)="updateSubscriptionAutorenewal(true)">enable automatic renewal</a> at any time.
                                </div>
                            </div>

                            <div *ngIf="isDemoSubscription">
                                Expires <strong>{{dateFormatter.timeAgo(workspace.subscription.notValidAfter)}}</strong> - {{dateFormatter.date(workspace.subscription.notValidAfter)}} unless renewed.
                            </div>

                        </div>
                        <div class="card-body mt-3" *ngIf="isFreeSubscription || isTrialSubscription">
                            <div class="media align-items-center align-items-md-start flex-column flex-md-row">
                                <div class="mr-md-3 mb-3 mb-md-0">
                                    <i class="icon-question7 text-primary border-primary border-2 rounded-round p-2"></i>
                                </div>

                                <div class="media-body text-center text-md-left">
                                    <h6 class="media-title font-weight-semibold">Need more time to fully evaluate the product?</h6>
                                    Feel free to drop us an email at <a href="mailto:support@appelium.com">support@appelium.com</a> and we can extend your trial subscription.
                                </div>

                                <a href="mailto:support@appelium.com" class="btn bg-primary ml-md-3 mt-3 mt-md-0">Contact us</a>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="isFreeSubscription || isTrialSubscription">
                        <div class="mb-3 pt-2">
                            <h6 class="mb-0 font-weight-semibold">
                                Upgrade options
                            </h6>
                            <span class="text-muted d-block">Select the best plan for your team</span>
                        </div>

                        <div class="text-center">
                            <div class="d-flex justify-content-center mb-1">
                                <ul class="nav nav-pills nav-pills-bordered nav-pills-toolbar text-center justify-content-center mr-3">
                                    <li class="nav-item"><a href="javascript:void(0)" (click)="setYearlyBilling(false)" class="nav-link rounded-left-round" data-toggle="tab">Monthly</a></li>
                                    <li class="nav-item"><a href="javascript:void(0)" (click)="setYearlyBilling(true)" class="nav-link rounded-right-round active" data-toggle="tab">Annually</a></li>
                                </ul>

                                <ul class="nav nav-pills nav-pills-bordered nav-pills-toolbar text-center justify-content-center mr-3">
                                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link rounded-left-round active" data-toggle="tab">CHF</a></li>
                                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link disabled" data-toggle="tab">EUR</a></li>
                                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link rounded-right-round disabled" data-toggle="tab">USD</a></li>
                                </ul>
                            </div>

                            <span class="d-block mb-3">Save 20% with annual subscription</span>
                        </div>

                        <div class="row">
                            <div class="col-lg-4">
                                <div class="card">
                                    <div class="card-body text-center">
                                        <i class="icon-trophy2 icon-3x text-primary mt-1 mb-3"></i>
                                        <h4 class="font-weight-semibold">BASIC</h4>
                                        <p class="mb-3">Ease the integration into your current systems and drive app quality efficiently.</p>

                                        <h1 class="pricing-table-price" *ngIf="!yearlyBilling">{{subscriptionTemplates.basic.pricePerMonthMonthly}}<span class="ml-1">/mo</span></h1>
                                        <h1 class="pricing-table-price" *ngIf="yearlyBilling">{{subscriptionTemplates.basic.pricePerMonthAnnually}}<span class="ml-1">/mo</span></h1>

                                        <ul class="pricing-table-list list-unstyled mb-3">
                                            <li>Bug reporting & user feedback</li>
                                            <li>App distribution</li>
                                            <li>Integrations (Slack, Microsoft Teams)</li>
                                            <li>up to <strong>{{subscriptionTemplates.basic.maximumUsers}}</strong> users</li>
                                            <li><strong>{{subscriptionTemplates.basic.applicationsIncluded}}</strong> apps included</li>
                                            <li><strong>{{dataRetentionTierFormatter.dataRetentionString(subscriptionTemplates.basic.dataRetentionTier)}}</strong> data retention</li>
                                            <li><strong>{{subscriptionTemplates.basic.pricePerMonthAdditionalApplication}} /month</strong> for additional app</li>
                                        </ul>
                                        <a href="javascript:void(0)" (click)="onSubscriptionUpgrade(subscriptionTemplates.basic)" data-toggle="modal" data-target="#subscription_summary_modal" class="btn bg-primary btn-lg text-uppercase font-size-sm font-weight-semibold">Upgrade</a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="card border-primary">
                                    <div class="card-body text-center">
                                        <div class="ribbon-container">
                                            <div class="ribbon bg-primary">Top value</div>
                                        </div>
                                        <i class="icon-trophy3 icon-3x text-primary mt-1 mb-3"></i>
                                        <h4 class="font-weight-semibold">PRO</h4>
                                        <p class="mb-3">Get maximum value while driving the highest quality of your apps efficiently using advanced capabilities.</p>

                                        <h1 class="pricing-table-price" *ngIf="!yearlyBilling">{{subscriptionTemplates.pro.pricePerMonthMonthly}}<span class="ml-1">/mo</span></h1>
                                        <h1 class="pricing-table-price" *ngIf="yearlyBilling">{{subscriptionTemplates.pro.pricePerMonthAnnually}}<span class="ml-1">/mo</span></h1>

                                        <ul class="pricing-table-list list-unstyled mb-3">
                                            <li>Bug reporting & user feedback</li>
                                            <li>App distribution</li>
                                            <li>Integrations (Slack, Microsoft Teams)</li>
                                            <li><strong>UI automated tests recording</strong></li>
                                            <li>up to <strong>{{subscriptionTemplates.pro.maximumUsers}}</strong> users</li>
                                            <li><strong>{{subscriptionTemplates.pro.applicationsIncluded}}</strong> apps included</li>
                                            <li><strong>{{dataRetentionTierFormatter.dataRetentionString(subscriptionTemplates.pro.dataRetentionTier)}}</strong> data retention</li>
                                            <li><strong>{{subscriptionTemplates.pro.pricePerMonthAdditionalApplication}} /month</strong> for additional app</li>
                                        </ul>
                                        <a href="javascript:void(0)" (click)="onSubscriptionUpgrade(subscriptionTemplates.pro)" data-toggle="modal" data-target="#subscription_summary_modal" class="btn bg-primary btn-lg text-uppercase font-size-sm font-weight-semibold">Upgrade</a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="card">
                                    <div class="card-body text-center">
                                        <i class="icon-trophy4 icon-3x text-primary mt-1 mb-3"></i>
                                        <h4 class="font-weight-semibold">ENTERPRISE</h4>
                                        <p class="mb-3">Get maximum security and control by self hosting Appelium in your enterprise environment.</p>
                                        <h1 class="pricing-table-price"><span class="mr-1"></span>Let's talk</h1>
                                        <h1></h1>
                                        <ul class="pricing-table-list list-unstyled mb-3">
                                            <li>Bug reporting & user feedback</li>
                                            <li>App distribution</li>
                                            <li>Integrations (Slack, Microsoft Teams)</li>
                                            <li>UI automated tests recording</li>
                                            <li><strong>Self hosted</strong></li>
                                            <li><strong>unlimited</strong> users</li>
                                            <li><strong>unlimited</strong> apps</li>
                                        </ul>
                                        <a href="mailto:support@appelium.com" class="btn bg-primary btn-lg text-uppercase font-size-sm font-weight-semibold">Contact us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <div class="card" *ngIf="invoices.length > 0 && false">
                        <div class="card-header bg-transparent">
                            <h1 class="font-weight-thin mb-0">Invoices</h1>
                            <div class="font-weight-thin text-muted" *ngIf="nextInvoiceDate">Next invoice {{nextInvoiceDate}}</div>
                        </div>

                        <table class="table table-lg">
                            <thead>
                                <tr>
                                    <th class="border-0">#</th>
                                    <th class="border-0">Period</th>
                                    <th class="border-0">Status</th>
                                    <th class="border-0">Issue date</th>
                                    <th class="border-0">Due date</th>
                                    <th class="border-0">Amount</th>
                                    <th class="border-0 text-right">Actions</th>
                                </tr>
                            </thead>
                            <tbody class="invoices">
                                <tr *ngFor="let invoice of invoices">
                                    <td>{{invoice.serialNumber}}</td>
                                    <td>{{invoice.title}}</td>
                                    <td>
                                        <span [class]="invoiceStateFormatter.stateClass(invoice.state)">{{invoiceStateFormatter.stateName(invoice.state)}}</span>
                                    </td>
                                    <td>
                                        {{dateFormatter.dateInUTC(invoice.issueDate)}}
                                    </td>
                                    <td>
                                        {{dateFormatter.dateInUTC(invoice.dueDate)}}
                                    </td>
                                    <td>
                                        <h6 class="mb-0 font-weight-bold">{{invoice.total}}</h6>
                                    </td>
                                    <td class="text-right">
                                        <a href="javascript:void(0)" (click)="downloadInvoice(invoice)" class="list-icons-item ml-2 mr-2"><i class="icon-file-eye" style="font-size: 24px;"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
        <!-- /content header -->

    </div>
    <!-- /main content -->

</div>

<div id="subscription_summary_modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h6 class="modal-title text-uppercase">Subscription upgrade</h6>
                <button type="button" class="close" #closeButton data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <h6 class="font-weight-semibold">Subscription summary</h6>
                <p>After confirming the purchase you will receive an invoice. Please note that the subscription renews automatically unless cancelled. The cancelation is possible up to 24h before the renewal.</p>

                <form [formGroup]="voucherForm">
                    <div class="input-group mt-2">
                        <input type="text" formControlName="voucherCode" class="form-control" placeholder="Do you have a voucher?" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false">
                        <span class="input-group-append">
                            <button (click)="updateSubscriptionPreview()" [disabled]="voucherForm.invalid" class="btn btn-light">Redeem</button>
                        </span>
                    </div>
                    <div class="text-danger mt-1" *ngIf="voucherErrorMessage">{{voucherErrorMessage}}</div>
                    <div class="text-success mt-1" *ngIf="voucherRedeemed">Voucher successfully redeemed</div>
                    <div class="text-muted mt-1" *ngIf="!voucherRedeemed && voucherErrorMessage == null">You can redeem one voucher per purchase.</div>
                </form>
            </div>

            <div class="mb-3" *ngIf="billingPreview">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="border-0"><h6 class="font-weight-semibold mb-0">Invoice preview</h6></th>
                            <th class="border-0 text-right">Amount CHF</th>
                        </tr>
                    </thead>
                    <tbody class="invoice-preview">
                        <tr *ngFor="let billingItem of billingPreview.billingItems">
                            <td>
                                <h5 class="font-weight-light m-0">{{billingItem.title}}</h5>
                                <div class="font-size-sm text-muted">{{billingItem.description}}</div>
                            </td>
                            <td class="text-right">{{billingItem.totalPrice}}</td>
                        </tr>
                        <tr class="total-section-start">
                            <td>Total without VAT</td>
                            <td class="text-right">{{billingPreview.totalExcludingVat}}</td>
                        </tr>
                        <tr class="no-border">
                            <td>VAT {{billingPreview.vatPercentage}}</td>
                            <td class="text-right">{{billingPreview.vat}}</td>
                        </tr>
                        <tr class="total-section-summary">
                            <td>Total including VAT</td>
                            <td class="text-right">{{billingPreview.total}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="modal-footer mt-3">
                <button type="button" class="btn btn-link" data-dismiss="modal">Close</button>
                <button type="button" (click)="onConfirmSubscription()" class="btn bg-primary">Confirm purchase</button>
            </div>
        </div>
    </div>
</div>
