import { Injectable } from "@angular/core"
import { DataRetentionTier } from "app/_models"

@Injectable()
export class DataRetentionTierFormatter {

    dataRetentionString(dataRetention: DataRetentionTier): string {
        switch (dataRetention) {
            case DataRetentionTier.OneMonth: return "1 month"
            case DataRetentionTier.ThreeMonths: return "3 months"
            case DataRetentionTier.SixMonths: return "6 months"
            case DataRetentionTier.OneYear: return "1 year"
            case DataRetentionTier.Unlimited: return "unlimited"
            default:
                return dataRetention
        }
    }

}
