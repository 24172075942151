import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from 'app/app.config';

@Injectable()
export class TokenAuthInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let shouldIntercept = request.url.includes(AppConfig.apiBaseUrl)
        if (!shouldIntercept) {
            return next.handle(request);
        }

        // Add Bearer header from UserLoginResponse
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.authenticationToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.authenticationToken}`
                }
            });
        }

        return next.handle(request);
    }
}
