import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { AlertService, IntegrationsService } from 'app/_services'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Workspace, BackendResponse } from 'app/_models';

@Component({
    selector: 'new-teams-webhook-modal',
    templateUrl: 'new.teams.webhook.modal.html'
})
export class NewTeamsWebhookModal implements OnInit {
    form: FormGroup;

    @Input() public workspace: Workspace
    @Output() onWebhookCreated = new EventEmitter()

    @ViewChild('closeButton') closeButton: ElementRef

    constructor(
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private integrationsService: IntegrationsService
    ) {
    }

    ngOnInit() {

        const urlRegex = '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$'
        this.form = this.formBuilder.group({
            integrationName: [null, Validators.required],
            teamsWebhookUrl: [null, [Validators.required, Validators.pattern(urlRegex)]]
        })
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls }

    submitForm() {
        this.integrationsService.createTeamsServiceConnection(this.f.integrationName.value, this.f.teamsWebhookUrl.value, this.workspace.id).then((response) => {
            this.f.integrationName.reset()
            this.f.teamsWebhookUrl.reset()

            this.onWebhookCreated.emit()

            // Close the modal dialog
            this.closeButton.nativeElement.click()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
