import { BarChartStyle, WidgetView } from 'app/_models';
import { AfterViewChecked, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Chart, ChartDataset } from 'chart.js';
import { UUIDUtils } from 'app/_helpers';

// const zeroCompensation = {
//     renderZeroCompensation: function (chartInstance: any, d: any) {
//         const view = d._view
//         const context = chartInstance.chart.ctx
//         const startX = view.x - view.width / 2
//         context.beginPath();
//         context.strokeStyle = '#ccc';
//         context.moveTo(startX, view.y);
//         context.lineTo(startX + view.width, view.y);
//         context.stroke();
//     },

//     afterDatasetsDraw: function (chart: any, easing: any) {
//         // get data meta, we need the location info in _view property.
//         const meta = chart.getDatasetMeta(0)
//         // also you need get datasets to find which item is 0.
//         const dataSet = chart.config.data.datasets[0].data
//         meta.data.forEach((d: any, index: any) => {
//             // for the item which value is 0, reander a line.
//             if (dataSet[index] === 0) {
//                 this.renderZeroCompensation(chart, d)
//             }
//         })
//     }
// };

@Component({
    selector: 'widget-view',
    templateUrl: 'widget.view.component.html',
    styleUrls: [
        'widget.view.component.css'
    ]
})
export class WidgetViewComponent implements OnInit, OnDestroy, AfterViewChecked {

    private _widgetView: WidgetView
    @Input()
    set widgetView(widgetView: WidgetView) {
        if (widgetView && this._widgetView !== widgetView) {
            this._widgetView = widgetView
            this.reloadData()
            if (this.chart) {
                this.reloadChart()
            }
        }
    }
    get widgetView(): WidgetView { return this._widgetView }

    private chart: Chart
    chartUuid: string = UUIDUtils.uuidv4()
    badgeText: string

    private resizeListener: any

    constructor(private elementRef: ElementRef) {
    }

    ngOnInit() {
        this.resizeListener = this.updateSize.bind(this)
        window.addEventListener("resize", this.resizeListener)
    }

    ngOnDestroy() {
        window.removeEventListener("resize", this.resizeListener)
    }

    // Charts can only be created once the their canvases are rendered, and canvases
    // will only be shown now after all the data is received
    ngAfterViewChecked() {
        if (!this.widgetView || this.chart) { return }
        if ($(`#${this.chartUuid}`).length > 0) {
            this.reloadChart()
            this.updateSize()
        }
    }

    private reloadData() {
        if (this.widgetView.currentPeriodCount == 0 || this.widgetView.previousPeriodCount == 0) {
            this.badgeText = null
        } else {
            let change = ((this.widgetView.currentPeriodCount / this.widgetView.previousPeriodCount) - 1) * 100

            // Don't show change badge if the change is unrealistically big
            if (change >= 1000 || change <= -1000) {
                this.badgeText = null
            } else {
                this.badgeText = (change >= 0 ? '+' : '') + change.toFixed(0) + '%'
            }
        }
    }

    private reloadChart() {
        if (this.chart) {
            this.chart.destroy()
        }

        this.chart = new Chart(this.chartUuid, {
            type: 'bar',
            data: {
                labels: this.widgetView.barChart.labels,
                datasets: this.makeChartDatasets()
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                animation: false,
                scales: {
                    x: {
                        display: false,
                        stacked: true
                    },
                    y: {
                        display: false,
                        stacked: true,
                        max: this.widgetView.barChart.maxValue,
                    }
                }
            }
            // plugins: [zeroCompensation]
        })
    }

    private makeChartDatasets(): ChartDataset[] {
        var chartDatasets: ChartDataset[] = []
        for (let dataset of this.widgetView.barChart.datasets) {
            let chartDataset = {
                data: dataset.series,
                backgroundColor: this.backgroundColor(dataset.style),
                fill: true,
            }
            chartDatasets.push(chartDataset)
        }

        return chartDatasets
    }

    private backgroundColor(style: BarChartStyle): string {
        switch (style) {
            case BarChartStyle.Danger: return "#ed4e7e"
            case BarChartStyle.Success: return "#45ba78"
            case BarChartStyle.Neutral: return "#03a9f4"
        }
    }

    private updateSize() {
        let chartContainer = document.getElementById(`canvasContainer_${this.chartUuid}`)
        let widgetInfoContainer = document.getElementById(`widgetInfo_${this.chartUuid}`)
        if (!chartContainer || !widgetInfoContainer) {
            return
        }

        let chartHeight = chartContainer.getBoundingClientRect().height
        let widgetComponentHeight = this.elementRef.nativeElement.getBoundingClientRect().height
        let widgetInfoHeight = widgetInfoContainer.getBoundingClientRect().height

        let margin = Math.max(0, widgetComponentHeight - chartHeight - widgetInfoHeight)
        chartContainer.style.marginTop = `${margin}px`
    }

}
