import { ErrorTrendsSummaryResponse } from 'app/_models';
import { Component, Input, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'error-trends-summary',
    templateUrl: 'error.trends.summary.component.html'
})
export class ErrorTrendsSummaryComponent {

    _errorTrendsSummaryResponse: ErrorTrendsSummaryResponse
    @Input()
    set errorTrendsSummaryResponse(errorTrendsSummaryResponse: ErrorTrendsSummaryResponse) {
        if (this._errorTrendsSummaryResponse != errorTrendsSummaryResponse) {
            this._errorTrendsSummaryResponse = errorTrendsSummaryResponse
            this.changeDetector.detectChanges()
        }
    }
    get errorTrendsSummaryResponse(): ErrorTrendsSummaryResponse { return this._errorTrendsSummaryResponse }

    constructor(
        private changeDetector: ChangeDetectorRef
    ) {
    }

}
