import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Chart, ChartDataset } from 'chart.js';
import { ByteCountFormatter, UUIDUtils } from 'app/_helpers';
import * as moment from 'moment';
import { DateRange } from 'app/_models';

@Component({
    selector: 'payload-over-time-chart',
    templateUrl: 'payload.over.time.chart.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayloadOverTimeChart implements AfterViewChecked {

    private _requestPayloadSizes: number[]
    @Input()
    set requestPayloadSizes(requestPayloadSizes: number[]) {
        if (requestPayloadSizes && this._requestPayloadSizes !== requestPayloadSizes) {
            this._requestPayloadSizes = requestPayloadSizes
            if (this.chart) {
                this.reloadChart()
            }
        }
    }
    get requestPayloadSizes(): number[] { return this._requestPayloadSizes }

    private _responsePayloadSizes: number[]
    @Input()
    set responsePayloadSizes(responsePayloadSizes: number[]) {
        if (responsePayloadSizes && this._responsePayloadSizes !== responsePayloadSizes) {
            this._responsePayloadSizes = responsePayloadSizes
            if (this.chart) {
                this.reloadChart()
            }
        }
    }
    get responsePayloadSizes(): number[] { return this._responsePayloadSizes }

    private _datePoints: string[]
    @Input()
    set datePoints(datePoints: string[]) {
        if (datePoints && this._datePoints !== datePoints) {
            this._datePoints = datePoints
            if (this.chart) {
                this.reloadChart()
            }
        }
    }
    get datePoints(): string[] { return this._datePoints }

    @Output() onDateRangeChange = new EventEmitter<DateRange>()

    private chart: Chart
    chartUuid: string = UUIDUtils.uuidv4()

    constructor(
        private changeDetector: ChangeDetectorRef,
        private byteCountFormatter: ByteCountFormatter
    ) {
    }

    // Charts can only be created once the their canvases are rendered, and canvases
    // will only be shown now after all the data is received
    ngAfterViewChecked() {
        if (!this.requestPayloadSizes || !this.responsePayloadSizes || this.chart) { return }
        if ($(`#${this.chartUuid}`).length > 0) {
            this.reloadChart()
        }
    }

    private reloadChart() {
        if (this.chart) {
            this.chart.destroy()
        }

        if (this.requestPayloadSizes == null || this.responsePayloadSizes == null) {
            return
        }

        let labels: string[][] = this.datePoints.map((datePoint) => {
            let date = moment(datePoint)
            return [date.format('MMM Do'), date.format('HH:mm')]
        })

        var max: number = 0
        for (let i = 0; i < this.requestPayloadSizes.length; i++) {
            let sum = this.requestPayloadSizes[i] + this.responsePayloadSizes[i]
            if (sum > max) { max = sum }
        }
        // Use 1kb in case there is no data
        max = max == 0 ? 1000 : max

        let byteCountFormatter = this.byteCountFormatter

        this.chart = new Chart(this.chartUuid, {
            type: 'line',
            data: {
                labels: labels,
                datasets: this.makeChartDatasets()
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    },
                    zoom: {
                        zoom: {
                            drag: { enabled: true },
                            mode: 'x',
                            onZoomComplete: this.onZoomComplete.bind(this)
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                animation: false,
                scales: {
                    x: {
                        display: true,
                        ticks: {
                            maxRotation: 0,
                            minRotation: 0,
                            maxTicksLimit: 15,
                            color: '#aaa',
                            font: { size: 12 }
                        }
                    },
                    y: {
                        display: true,
                        stacked: true,
                        suggestedMin: 0,
                        suggestedMax: max,
                        ticks: {
                            callback: function (value: number): string {
                                return byteCountFormatter.stringFromByteCount(value)
                            },
                            maxTicksLimit: 8,
                            color: '#aaa',
                            font: { size: 12 }
                        }
                    }
                }
            }
        })
        this.changeDetector.detectChanges()
    }

    onZoomComplete(context: { chart: Chart }) {
        const min = Math.min(Math.max(context.chart.scales.x.min, 0), this.datePoints.length - 1)
        const max = Math.min(Math.max(context.chart.scales.x.max, 0), this.datePoints.length - 1)

        let dateRange = new DateRange()
        dateRange.startDate = moment(this.datePoints[min]).toDate()
        dateRange.endDate = moment(this.datePoints[max]).toDate()
        this.onDateRangeChange.emit(dateRange)
    }

    private makeChartDatasets(): ChartDataset[] {
        var ctx = (document.getElementById(this.chartUuid) as any).getContext("2d")

        // Getting the chart height using getBoundingClientRect() was giving wrong results
        let chartHeight = 190

        // Offset by 70% as most of the payloads are always due to downloads
        var requestPayloadsGradient = ctx.createLinearGradient(0, chartHeight * 0.7, 0, chartHeight)
        requestPayloadsGradient.addColorStop(0, "#74cfff")
        requestPayloadsGradient.addColorStop(1, "#0c97ff")

        // Additional padding caused by artificially increasing maxValue in chart setup (see reloadChart())
        var responsePayloadsGradient = ctx.createLinearGradient(0, chartHeight * 0.3, 0, chartHeight);
        responsePayloadsGradient.addColorStop(0, "#509af1");
        responsePayloadsGradient.addColorStop(1, "#2755af");

        let chartDatasets: ChartDataset[] = [
            {
                data: this.requestPayloadSizes,
                backgroundColor: requestPayloadsGradient,
                borderWidth: 0.001,
                fill: true,
                pointRadius: 0,
                tension: 0.4
            },
            {
                data: this.responsePayloadSizes,
                backgroundColor: responsePayloadsGradient,
                borderWidth: 0.001,
                fill: true,
                pointRadius: 0,
                tension: 0.4
            }
        ]

        return chartDatasets
    }

}
