import { Component, Input } from '@angular/core';

@Component({
    selector: 'header-value-item',
    templateUrl: 'header.value.item.html'
})
export class HeaderValueItem {

    @Input() public title: string
    @Input() public value: string

}
