import { NetworkTrendsSummaryResponse } from 'app/_models';
import { Component, Input, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'error-rate-summary',
    templateUrl: 'error.rate.summary.component.html'
})
export class ErrorRateSummaryComponent {

    overallErrorPercentage: string
    overallSuccessPercentage: string

    clientPercentage: string
    businessPercentage: string
    serverPercentage: string

    _networkTrendsSummaryResponse: NetworkTrendsSummaryResponse
    @Input()
    set networkTrendsSummaryResponse(networkTrendsSummaryResponse: NetworkTrendsSummaryResponse) {
        if (this._networkTrendsSummaryResponse != networkTrendsSummaryResponse) {
            this._networkTrendsSummaryResponse = networkTrendsSummaryResponse
            this.reload()
        }
    }
    get networkTrendsSummaryResponse(): NetworkTrendsSummaryResponse { return this._networkTrendsSummaryResponse }

    constructor(
        private changeDetector: ChangeDetectorRef
    ) {
    }

    private reload() {
        let summary = this.networkTrendsSummaryResponse as any
        if (summary == null) { return }

        this.overallErrorPercentage = summary.overalErrorRate * 100 + "%"
        this.overallSuccessPercentage = (1 - summary.overalErrorRate) * 100 + "%"

        this.clientPercentage = summary.clientErrorsShare * 100 + "%"
        this.businessPercentage = summary.businessErrorsShare * 100 + "%"
        this.serverPercentage = summary.serverErrorsShare * 100 + "%"
        this.changeDetector.detectChanges()
    }

}
