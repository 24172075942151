import { NetworkInterface } from "app/_models"
import { Injectable } from "@angular/core"

@Injectable()
export class NetworkInterfaceFormatter {

    displayName(networkInterface: NetworkInterface): string {
        switch (networkInterface) {
            case NetworkInterface.None: return "None"
            case NetworkInterface.WiFi: return "WiFi"
            case NetworkInterface.WiredEthernet: return "Ethernet"
            case NetworkInterface.CellularUnknown: return "Cellular"
            case NetworkInterface.Cellular2G: return "2G"
            case NetworkInterface.Cellular3G: return "3G"
            case NetworkInterface.Cellular4G: return "4G"
            case NetworkInterface.Cellular5G: return "5G"
            case NetworkInterface.Loopback: return "Loopback"
            case NetworkInterface.Unknown: return "Unknown"
            default:
                console.warn("Unknown network interface: " + networkInterface)
                return "?"
        }
    }

    networkInterfaceWithName(name: string): NetworkInterface {
        if (name == "None") return NetworkInterface.None
        if (name == "WiFi") return NetworkInterface.WiFi
        if (name == "Ethernet") return NetworkInterface.WiredEthernet
        if (name == "Cellular") return NetworkInterface.CellularUnknown
        if (name == "2G") return NetworkInterface.Cellular2G
        if (name == "3G") return NetworkInterface.Cellular3G
        if (name == "4G") return NetworkInterface.Cellular4G
        if (name == "5G") return NetworkInterface.Cellular5G
        if (name == "Loopback") return NetworkInterface.Loopback

        console.warn("Unknown network interface: " + name)
        return NetworkInterface.Unknown
    }

}
