export enum WidgetConfigurationType {
    BugReports = 1,
    TestExecutions = 2,
    Builds = 3,
}

export class BugReportsWidgetConfiguration {
    reporterEmail: string | null
}

export class TestExecutionsWidgetConfiguration {
    environmentName: string | null
    testPlanName: string | null
}

export class BuildsWidgetConfiguration {
}
