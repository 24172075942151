import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BackendEnvironment, TestPlan } from 'app/_models';
import { AppConfig } from 'app/app.config';
import { CollectionResponse, ElementResponse } from './response';
import { BehaviorSubject, Observable } from 'rxjs';

export class BackendEnvironmentFilterInfos {
    testPlans: TestPlan[]
}

@Injectable({ providedIn: 'root' })
export class BackendEnvironmentService {

    private currentBackendEnvironmentSubject: BehaviorSubject<BackendEnvironment>
    public currentBackendEnvironment: Observable<BackendEnvironment>

    setCurrentBackendEnvironment(backendEnvironment: BackendEnvironment | null) {
        // Update the value only if we don't have any yet, or if the ID is different
        if (this.currentBackendEnvironmentSubject.value == null || backendEnvironment == null || this.currentBackendEnvironmentSubject.value.id != backendEnvironment.id) {
            this.currentBackendEnvironmentSubject.next(backendEnvironment)
        }
    }

    public get currentBackendEnvironmentValue(): BackendEnvironment {
        return this.currentBackendEnvironmentSubject.value
    }

    constructor(
        private http: HttpClient,
    ) {
        this.currentBackendEnvironmentSubject = new BehaviorSubject<BackendEnvironment>(null)
        this.currentBackendEnvironment = this.currentBackendEnvironmentSubject.asObservable()
    }

    getBackendEnvironments(): Promise<CollectionResponse<BackendEnvironment>> {
        return this.http.get<CollectionResponse<BackendEnvironment>>(`${AppConfig.apiBaseUrl}/backendEnvironments`).toPromise()
    }

    getFilterInfos(): Promise<BackendEnvironmentFilterInfos> {
        return this.http.get<BackendEnvironmentFilterInfos>(`${AppConfig.apiBaseUrl}/backendEnvironments/filterInfos`).toPromise()
    }

    getWorkspaceEnvironmentWithSlug(backendEnvironmentSlug: string): Promise<CollectionResponse<BackendEnvironment>> {
        return this.http.get<CollectionResponse<BackendEnvironment>>(`${AppConfig.apiBaseUrl}/backendEnvironments?slug=${backendEnvironmentSlug}`).toPromise()
    }

    updateBackendEnvironment(backendEnvironmentId: number, testsSuccessThreshold: number, testPlansAffectingStatus: string[]): Promise<ElementResponse<BackendEnvironment>> {
        let requestBody = {
            testsSuccessThreshold: testsSuccessThreshold,
            testPlansAffectingStatus: testPlansAffectingStatus
        }

        return this.http.patch<ElementResponse<BackendEnvironment>>(`${AppConfig.apiBaseUrl}/backendEnvironments/${backendEnvironmentId}`, requestBody).toPromise()
    }

}
