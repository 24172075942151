<div class="navbar navbar-expand navbar-light d-flex justify-content-between">
    <navbar-app-feature appFeatureName="Resolver"></navbar-app-feature>

    <div class="d-flex justify-content-center align-items-center" *ngIf="workspace">
        <h3 class="m-0">
            <span class="font-weight-semibold">{{workspace.name}}</span>
        </h3>
    </div>

    <navbar-user-menu></navbar-user-menu>
</div>

<div class="page-content">

    <div class="content-wrapper" id="pageContent">

        <div class="content">
            <div class="row">
                <div class="col-md-12">
                    <alert></alert>
                </div>
            </div>

            <div class="row justify-content-md-center" *ngIf="workspace && titleText">
                <div class="col-md-4">
                    <div class="card text-center">
                        <div class="card-body">
                            <i class="icon-alarm icon-2x text-primary-400 border-primary-400 border-3 rounded-round p-3 mb-3 mt-1"></i>
                            <h5 class="mb-0"><i class="icon-spinner4 spinner text-muted mr-1"></i> {{titleText}}</h5>
                            <span class="d-block text-muted">{{subtitleText}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
