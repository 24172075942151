import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Chart, ChartDataset, Color } from 'chart.js';
import { DateFormatter, UUIDUtils } from 'app/_helpers';
import { BarChartData } from 'app/_models';
import * as Chroma from 'chroma-js';

// https://cssgradient.io
// https://gka.github.io/chroma.js/
const histogramColorScale = Chroma.scale([
    '#03c764', // 1. Green
    '#03c764', // 2. Green
    '#03c764', // 3. Green
    '#FFA726', // 4. Light Orange
    '#FF7043', // 5. Orange
    '#FF7043', // 6. Orange
    '#FF7043', // 7. Orange
    '#FF7043', // 8. Orange
    '#E53935', // 9 red
    '#E53935', // 10 red
    '#E53935', // 11. red
]);

@Component({
    selector: 'app-launch-distribution-chart',
    templateUrl: 'app.launch.distribution.chart.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppLaunchDistributionChartComponent implements AfterViewChecked {

    private _barChart: BarChartData
    @Input()
    set barChart(barChart: BarChartData) {
        if (barChart && this._barChart !== barChart) {
            this._barChart = barChart
            if (this.chart) {
                this.reloadChart()
            }
        }
    }
    get barChart(): BarChartData { return this._barChart }

    private _targetLaunchDuration: number
    @Input()
    set targetLaunchDuration(targetLaunchDuration: number) {
        if (targetLaunchDuration && this._targetLaunchDuration !== targetLaunchDuration) {
            this._targetLaunchDuration = targetLaunchDuration
            if (this.chart) {
                this.reloadChart()
            }
        }
    }
    get targetLaunchDuration(): number { return this._targetLaunchDuration }

    private chart: Chart
    chartUuid: string = UUIDUtils.uuidv4()

    constructor(
        private changeDetector: ChangeDetectorRef,
        private dateFormatter: DateFormatter
    ) {
    }

    // Charts can only be created once the their canvases are rendered, and canvases
    // will only be shown now after all the data is received
    ngAfterViewChecked() {
        if (!this.barChart || this.chart) { return }
        if ($(`#${this.chartUuid}`).length > 0) {
            this.reloadChart()
        }
    }

    private reloadChart() {
        if (this.chart) {
            this.chart.destroy()
        }

        if (this.barChart == null || this.targetLaunchDuration == null) { return }

        // Add a bit of extra so that the max value doesn't touch the top edge of the chart
        let maxValueY = (Math.max(...this.barChart.datasets[0].series) * 1.1).toFixed(0)

        this.chart = new Chart(this.chartUuid, {
            type: 'bar',
            data: {
                labels: this.barChart.labels,
                datasets: this.makeChartDatasets()
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                animation: false,
                scales: {
                    x: {
                        display: true,
                        ticks: {
                            callback: function (value: string): string | null {
                                return parseFloat(value as string).toFixed(1) + " sec"
                            },
                            maxRotation: 0,
                            minRotation: 0,
                            maxTicksLimit: 20,
                            color: '#aaa',
                            font: { size: 12 }
                        }
                    },
                    y: {
                        display: true,
                        max: maxValueY,
                        ticks: {
                            callback: function (value: number): string {
                                return value == 0 ? null : value + ""
                            },
                            maxTicksLimit: 7,
                            color: '#aaa',
                            font: { size: 12 }
                        }
                    }
                }
            }
        })
        this.changeDetector.detectChanges()
    }

    private makeChartDatasets(): ChartDataset[] {
        var backgroundColors: Color[] = []
        let scaleLength = this.barChart.datasets[0].series.length
        for (let i = 0; i < scaleLength; i++) {
            backgroundColors.push(histogramColorScale(i / scaleLength).hex())
        }

        let chartDatasets: ChartDataset[] = [
            {
                data: this.barChart.datasets[0].series,
                backgroundColor: backgroundColors,
                fill: true,
                minBarLength: 1,
            }
        ]

        return chartDatasets
    }

}
