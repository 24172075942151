import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, InvitationsService, InvitationInfoResponse, Relationship, AlertService } from 'app/_services';
import { RouteHelper } from 'app/_helpers';
import { Title } from '@angular/platform-browser';

@Component({ templateUrl: 'accept.invitation.component.html' })
export class AcceptInvitationComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private alertService: AlertService,
        private invitationsService: InvitationsService,
        private authenticationService: AuthenticationService,
        private routeHelper: RouteHelper,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.titleService.setTitle("Accept Invitation")
        let invitationToken = this.route.snapshot.queryParams['token']

        // 1. Ping the BE and see what needs to be done
        //
        // A: User is already part of the workspace
        //    2. If not logged in, log in
        //    3. Switch to the workspace's home page
        //
        // B: User doesn't have an account
        //    2. Register user (prefill email and company but disable it)
        //    3. Let the user continue with the standard registration flow

        this.invitationsService.getInvitationInfo(invitationToken).then((response) => {
            this.handleInvitationInfo(response)

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private handleInvitationInfo(invitationInfo: InvitationInfoResponse) {
        let isLoggedIn = this.authenticationService.currentUserValue != null

        switch (invitationInfo.relationship) {
            case Relationship.AlreadyMember:
                this.router.navigate([`/${invitationInfo.workspace.slug}`]);
                break

            case Relationship.NotUser:
                // This means that the user is trying to accept not his own invitation
                if (isLoggedIn) {
                    this.router.navigate([this.routeHelper.currentWorkspaceHome()])
                    return
                }

                this.invitationsService.pendingInvitationToAccept = invitationInfo
                this.router.navigate([`/register`]);
                break
        }
    }

}
