import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { AlertService, ErrorsService } from 'app/_services'
import { FormGroup, FormBuilder } from '@angular/forms';
import { ErrorGroup } from 'app/_models';

@Component({
    selector: 'edit-error-group-modal',
    templateUrl: 'edit.error.group.modal.html'
})
export class EditErrorGroupModal {

    form: FormGroup

    private _errorGroup: ErrorGroup
    @Input()
    public set errorGroup(ErrorGroup: ErrorGroup) {
        this._errorGroup = ErrorGroup
        this.reloadForm()
    }
    public get errorGroup(): ErrorGroup {
        return this._errorGroup
    }

    @Output() errorGroupUpdated = new EventEmitter()

    @ViewChild('closeButton') closeButton: ElementRef

    constructor(
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private ErrorsService: ErrorsService,
    ) {
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls }

    private reloadForm() {
        this.form = this.formBuilder.group({
            annotation: [this.errorGroup.annotation]
        })
    }

    saveErrorGroup() {
        this.ErrorsService.updateErrorGroup(this.errorGroup.id, this.f.annotation.value, this.errorGroup.state).then((response) => {
            this.closeButton.nativeElement.click()
            this.errorGroupUpdated.emit()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
