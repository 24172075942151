import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { Application, ErrorGroup, ErrorReport, IssueState, ErrorTrendsResponse, ErrorTrendsSummaryResponse, ErrorFilter, DateRange } from 'app/_models';
import { AlertService, ErrorsService, ApplicationService } from 'app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationFormatter, DateFormatter, IssueStateFormatter } from 'app/_helpers';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { EditErrorGroupModal } from '../edit.error.group.modal/edit.error.group.modal';

@Component({
    templateUrl: 'error.group.component.html',
    styleUrls: [
        'error.group.component.css'
    ]
})
export class ErrorGroupComponent implements OnInit, OnDestroy {

    application: Application
    errorGroup: ErrorGroup
    errorReports: ErrorReport[]
    errorTrendsResponse: ErrorTrendsResponse
    errorTrendsSummaryResponse: ErrorTrendsSummaryResponse
    didFinishLoading = false

    selectedDateRange: DateRange

    @ViewChild('editErrorGroupModal') editErrorGroupModal: EditErrorGroupModal

    private queryParamsSubscription: Subscription
    private routeParamsSubscription: Subscription
    private currentApplicationSubscription: Subscription

    constructor(
        public dateFormatter: DateFormatter,
        public issueStateFormatter: IssueStateFormatter,
        private errorsService: ErrorsService,
        private route: ActivatedRoute,
        private router: Router,
        private applicationService: ApplicationService,
        private alertService: AlertService,
        private applicationFormatter: ApplicationFormatter,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        let query = this.route.snapshot.queryParams
        this.selectedDateRange = DateRange.fromParams(query) ?? DateRange.lastMonth()

        this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
            // this.updateSelect2SelectionFromQuery()

            // In case time filter changed, or any other filter, refresh everything
            this.selectedDateRange = DateRange.fromParams(params) ?? DateRange.lastMonth()
            this.reloadErrorReports()
        })

        this.routeParamsSubscription = this.route.params.subscribe(params => {
            this.reloadErrorGroup()
        })

        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
            if (this.application != null) {
                this.reloadErrorGroup()
            }
        })
    }

    ngOnDestroy() {
        this.currentApplicationSubscription.unsubscribe()
        this.queryParamsSubscription.unsubscribe()
        this.routeParamsSubscription.unsubscribe()
    }

    refreshErrorTrends() {
        if (!this.application) { return }

        // Will also switch the trends chart into loading mode
        this.errorTrendsResponse = null

        let errorFilter = this.makeCurrentErrorFilter()
        this.errorsService.getErrorTrends(this.application.workspaceId, errorFilter).then((response) => {
            this.errorTrendsResponse = response
            this.selectedDateRange = response.dateRange

            this.errorsService.getErrorTrendsSummary(this.application.workspaceId, errorFilter).then((response) => {
                this.errorTrendsSummaryResponse = response
            })

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    onDateRangeChanged(dateRange: DateRange) {
        this.router.navigate([], { queryParams: { startDate: moment(dateRange.startDate).toISOString(), endDate: moment(dateRange.endDate).toISOString() }, queryParamsHandling: 'merge' })
    }

    reloadErrorGroup() {
        if (!this.application) { return }

        let errorGroupSlug = this.route.snapshot.params['errorGroupSlug']
        this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | Error Group #${errorGroupSlug}`)

        this.errorsService.getWorkspaceErrorGroupWithSlug(this.application.workspaceId, errorGroupSlug).then((response) => {
            this.errorGroup = response.data
            this.reloadErrorReports()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    reloadErrorReports() {
        if (!this.errorGroup) { return }

        this.errorReports = null

        let errorFilter = this.makeCurrentErrorFilter()
        this.errorsService.getErrorGroupErrorReports(this.errorGroup.id, errorFilter).then((result) => {
            this.errorReports = result.data
            this.didFinishLoading = true

        }).catch((error) => {
            this.alertService.handleError(error)
        })

        this.refreshErrorTrends()
    }

    onErrorGroupUpdated() {
        this.reloadErrorGroup()
    }

    updateIssueState(state: IssueState) {
        this.errorsService.updateErrorGroup(this.errorGroup.id, this.errorGroup.annotation, state).then((response) => {
            this.errorGroup = response.data

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    navigateToErrorReport(errorReport: ErrorReport) {
        this.router.navigate(['error-reports', errorReport.serialNumber], { relativeTo: this.route })
    }

    private makeCurrentErrorFilter(): ErrorFilter {
        let query = this.route.snapshot.queryParams

        let errorFilter = new ErrorFilter()
        errorFilter.applicationId = this.application.id
        errorFilter.errorGroupId = this.errorGroup.id
        errorFilter.startDate = moment(this.selectedDateRange.startDate).toDate()
        errorFilter.endDate = moment(this.selectedDateRange.endDate).toDate()

        return errorFilter
    }

}
