class IdSlug {
    id: number
    slug: string
    scope: string
}

export class IdSlugCache {

    private idSlugs: IdSlug[] = []

    constructor(
    ) {
        this.load()
    }

    clearCache() {
        this.idSlugs = []
        this.save()
    }

    record(id: number, slug: string, scope: string) {
        let newIdSlug = new IdSlug()
        newIdSlug.id = id
        newIdSlug.slug = slug
        newIdSlug.scope = scope
        this.idSlugs.push(newIdSlug)
        this.save()
    }

    slugForIdInScope(id: number, scope: string): string {
        for (let idSlug of this.idSlugs) {
            if (idSlug.scope == scope && idSlug.id == id) {
                return idSlug.slug
            }
        }

        return null
    }

    idForSlugInScope(slug: string, scope: string): number {
        for (let idSlug of this.idSlugs) {
            if (idSlug.scope == scope && idSlug.slug == slug) {
                return idSlug.id
            }
        }

        return null
    }

    private load() {
        let cachedWorkspacesString = localStorage.getItem("slugResolverCache")
        if (cachedWorkspacesString == null) {
            this.idSlugs = []
            return
        }

        let cachedWorkspaces: [IdSlug] = JSON.parse(cachedWorkspacesString)
        if (cachedWorkspaces == null) {
            this.idSlugs = []
            return
        }

        this.idSlugs = cachedWorkspaces
    }

    private save() {
        localStorage.setItem("slugResolverCache", JSON.stringify(this.idSlugs))
    }

}
