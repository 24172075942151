import { Component, Input, OnInit } from '@angular/core';
import * as Showdown from 'showdown'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'markdown',
    templateUrl: 'markdown.component.html',
    styleUrls: [
        'markdown.component.css'
    ]
})
export class MarkdownComponent implements OnInit {

    _content: string
    @Input()
    set content(content: string) {
        this._content = content
        this.updateContent()
    }
    get content() { return this._content }

    generatedHtml: SafeHtml

    private converter: Showdown.Converter

    constructor(
        private sanitizer: DomSanitizer
    ) {
        this.converter = new Showdown.Converter({
            simplifiedAutoLink: true,
            strikethrough: true,
            tasklists: true,
            simpleLineBreaks: true
        })
    }

    ngOnInit() {
        this.updateContent()
    }

    private updateContent() {
        if (this.content == null) {
            this.generatedHtml = null
            return
        }

        let generatedHtml = this.converter.makeHtml(this.content)
        this.generatedHtml = this.sanitizer.bypassSecurityTrustHtml(generatedHtml)
    }

}
