<div class="content-wrapper" #pageContent>

    <div class="content">
        <div class="row">
            <div class="col-md-12">
                <alert></alert>
            </div>
        </div>

        <div class="row" *ngIf="application && errorFiltersResponse">
            <div class="col-md-12">
                <div class="card">
                    <div class="page-header border-bottom-light">
                        <div class="page-header-content header-elements-md-inline">
                            <div class="page-title">
                                <h1 class="font-weight-thin">
                                    <a [routerLink]="['../']" class="breadcrumb-item">{{application.name}}</a>
                                    <span class="breadcrumb-item active">Error Groups</span>
                                </h1>

                                <div class="mb-0" *ngIf="errorTrendsSummaryResponse">
                                    <span class="badge badge-light badge-pill mr-1"><count [count]="errorTrendsSummaryResponse.totalErrorsCount"></count> reports</span>
                                    <span class="badge badge-light badge-pill mr-1"><count [count]="errorTrendsSummaryResponse.totalDevicesCount"></count> users</span>
                                </div>
                                <div class="mb-0" *ngIf="errorTrendsSummaryResponse == null">
                                    <span class="badge badge-light">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- TIME FILTER -->
                    <div class="card-body d-flex align-items-center align-items-baseline">
                        <div class="d-flex align-items-baseline">
                            <h5 class="mb-0 pb-0 mr-2">Filters</h5>

                            <div class="form-group mb-0 d-flex mr-2" *ngIf="errorFiltersResponse.bundleIdentifiers.length > 0">
                                <select id="bundleIdentifierSelect" select2 data-placeholder="App identifier" data-allow-clear="true" class="form-control select2">
                                    <option *ngFor="let bundleIdentifier of errorFiltersResponse.bundleIdentifiers">{{bundleIdentifier.value}}</option>
                                </select>
                            </div>

                            <div class="form-group mb-0 d-flex mr-2" *ngIf="errorFiltersResponse.applicationVersions.length > 0">
                                <select id="applicationVersionSelect" select2 data-placeholder="App version" data-allow-clear="true" class="form-control select2">
                                    <option *ngFor="let applicationVersion of errorFiltersResponse.applicationVersions">{{applicationVersion.bundleShortVersion}}</option>
                                </select>
                            </div>

                            <div class="form-group mb-0 d-flex mr-2" *ngIf="errorFiltersResponse.osVersions.length > 0">
                                <select id="osVersionSelect" select2 data-placeholder="OS version" data-allow-clear="true" class="form-control select2">
                                    <option *ngFor="let osVersion of errorFiltersResponse.osVersions">{{osVersion.version}}</option>
                                </select>
                            </div>
                        </div>

                        <ul class="ml-auto pagination">
                            <li class="page-item active">
                                <date-range [dateRange]="this.selectedDateRange" (onDateRangeChange)="onDateRangeChanged($event)"></date-range>
                            </li>
                        </ul>
                    </div>

                    <!-- TRENDS -->
                    <div class="card-body py-0 my-0">
                        <!-- <h5 class="mb-0 pb-0">Trends</h5> -->

                        <!-- TRENDS LOADING -->
                        <div class="justify-content-center text-muted d-flex w-100 align-items-center" style="min-height: 280px" *ngIf="!errorTrendsResponse || !errorTrendsSummaryResponse">
                            <div class="align-self-center">
                                <i class="icon-spinner4 spinner mr-2"></i> Loading trends...
                            </div>
                        </div>

                        <!-- TRENDS LOADED -->
                        <div class="d-flex" *ngIf="errorTrendsResponse && errorTrendsSummaryResponse">

                            <!-- TRENDS -->
                            <div class="w-100" style="margin-top: 20px; margin-bottom: 20px;">
                                <errors-over-time-chart [datePoints]="errorTrendsResponse.datePoints" [errorCounts]="errorTrendsResponse.errorCounts" (onDateRangeChange)="onDateRangeChanged($event)"></errors-over-time-chart>
                            </div>

                            <!-- SUMMARY -->
                            <div class="border-left-light pl-3" style="padding-top: 20px;">
                                <h5 class="mb-0 pb-3">Summary</h5>
                                <div style="width: 180px;">
                                    <error-trends-summary [errorTrendsSummaryResponse]="errorTrendsSummaryResponse"></error-trends-summary>
                                </div>
                            </div>
                        </div>
                    </div>

                    <table class="table" *ngIf="errorGroupSegments && errorGroupSegments.length > 0">
                        <thead>
                            <tr role="row">
                                <th class="border-0">#</th>
                                <th class="border-bottom-0">Error group</th>
                                <th class="border-bottom-0 nowrap text-right">
                                    <sortable [name]="'Errors'" [ascValue]="ErrorSegmentsOrder.COUNT_ASC" [descValue]="ErrorSegmentsOrder.COUNT_DESC" [default]="ErrorSegmentsOrder.COUNT_DESC"></sortable>
                                </th>
                                <th class="border-bottom-0 nowrap text-right">
                                    <sortable [name]="'Users'" [ascValue]="ErrorSegmentsOrder.AFFECTED_USERS_ASC" [descValue]="ErrorSegmentsOrder.AFFECTED_USERS_DESC"></sortable>
                                </th>
                                <th class="border-bottom-0 nowrap text-center">App versions</th>
                                <th class="border-bottom-0 nowrap text-center">First seen</th>
                                <th class="border-bottom-0 nowrap text-center">Last seen</th>
                                <th class="border-bottom-0 nowrap text-center">Annotation</th>
                                <th class="border-bottom-0 nowrap text-right">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let errorGroupSegment of errorGroupSegments" (click)="navigateToErrorGroup(errorGroupSegment)">
                                <td>
                                    <a [routerLink]="[errorGroupSegment.serialNumber]" class="button-muted mt-2 mb-2">#{{errorGroupSegment.serialNumber}}</a>
                                </td>
                                <td>
                                    <div class="d-flex breakall">
                                        <div class="mr-2">
                                            <span class="badge badge-mark border-warning-300"></span>
                                        </div>
                                        <div class="button-muted breakall">{{errorGroupSegment.errorMessage}}</div>
                                    </div>
                                </td>
                                <td class="text-right" [ngClass]="{ 'text-muted' : errorGroupSegment.state == 2 }">
                                    <count [count]="errorGroupSegment.errorsCount"></count>
                                </td>
                                <td class="text-right" [ngClass]="{ 'text-muted' : errorGroupSegment.state == 2 }">
                                    <count [count]="errorGroupSegment.distinctDeviceIds"></count>
                                </td>
                                <td class="text-center" *ngIf="errorGroupSegment.earliestErrorVersion == errorGroupSegment.latestErrorVersion">
                                    <span class="badge badge-light badge-pill">{{errorGroupSegment.earliestErrorVersion}}</span>
                                </td>
                                <td class="text-center" *ngIf="errorGroupSegment.earliestErrorVersion != errorGroupSegment.latestErrorVersion">
                                    <span class="badge badge-light badge-pill">{{errorGroupSegment.earliestErrorVersion}}</span>…<span class="badge badge-light badge-pill">{{errorGroupSegment.latestErrorVersion}}</span>
                                </td>
                                <td class="nowrap text-center" [ngClass]="{ 'text-muted' : errorGroupSegment.state == 2 }">{{dateFormatter.timeAgoShort(errorGroupSegment.earliestErrorDate)}}</td>
                                <td class="nowrap text-center" [ngClass]="{ 'text-muted' : errorGroupSegment.state == 2 }">{{dateFormatter.timeAgoShort(errorGroupSegment.latestErrorDate)}}</td>
                                <td class="text-center" [ngClass]="{ 'text-muted' : errorGroupSegment.state == 2 }">
                                    <i class="icon-attachment" *ngIf="errorGroupSegment.hasAnnotation"></i>
                                    <span class="text-muted" *ngIf="errorGroupSegment.hasAnnotation == false">-</span>
                                </td>
                                <td class="text-right" [ngClass]="{ 'text-success-600' : errorGroupSegment.state == 1, 'text-muted' : errorGroupSegment.state == 2 }">{{issueStateFormatter.displayName(errorGroupSegment.state)}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

    </div>
</div>
