import { Component, Input, OnInit } from '@angular/core';
import { ApplicationLog, ApplicationLogEvent } from 'app/_models/console.logs.response';

@Component({
    selector: 'application-log-content',
    templateUrl: 'application.log.content.html'
})
export class ApplicationLogContent implements OnInit {

    @Input() public log: ApplicationLog
    logContent: string

    ngOnInit() {
        this.logContent = this.makeLogContent()
    }

    private makeLogContent(): string | null {
        switch (this.log.event) {
            // iOS events
            case ApplicationLogEvent.DidBecomeActive: return "Application did become active"
            case ApplicationLogEvent.DidEnterBackground: return "Application did enter background"
            case ApplicationLogEvent.DidFinishLaunching: return "Application did finish launching"
            case ApplicationLogEvent.WillEnterForeground: return "Application will enter foreground"
            case ApplicationLogEvent.WillResignActive: return "Application will resign active"
            case ApplicationLogEvent.WillTerminate: return "Application will terminate"
            case ApplicationLogEvent.DidFinishLoading:
                let duration = Number(this.log.info["duration"]).toFixed(3);
                return "Application did finished loading. Loading time: " + duration + "s"

            // Android events
            case ApplicationLogEvent.OnCreate: return "Activity created"
            case ApplicationLogEvent.OnStart: return "Activity started"
            case ApplicationLogEvent.OnResume: return "Activity resumed"
            case ApplicationLogEvent.OnPause: return "Activity paused"
            case ApplicationLogEvent.OnStop: return "Activity stopped"
            case ApplicationLogEvent.OnDestroy: return "Activity destroyed"

            // Common events
            case ApplicationLogEvent.ScreenWillAppear: return null
            case ApplicationLogEvent.ScreenDidAppear: return `${this.log.info["screenName"]} did appear`
        }

        return null
    }

}
