<navbar-settings></navbar-settings>

<div class="page-content">
    <settings-sidebar selectedFeatureIndex="5"></settings-sidebar>

    <!-- Main content -->
    <div class="content-wrapper">

        <!-- Content area -->
        <div class="content">

            <div class="row">
                <div class="col-md-12">
                    <alert></alert>
                </div>
            </div>

            <!-- Applications row -->
            <div class="row">
                <div class="col-md-12">
                    <div class="card">

                        <!-- Card header -->
                        <div class="card-header bg-transparent header-elements-inline">
                            <h1 class="font-weight-thin mb-0">Integrations</h1>

                            <div class="header-elements">
                                <div class="list-icons">
                                    <div class="header-elements" *ngIf="integrationConfig">
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-primary dropdown-toggle bg-primary-400" data-toggle="dropdown"><i class="icon-plus2"></i> New Integration</button>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <a href="{{integrationConfig.slackAuthorizationUrl}}" class="dropdown-item">Slack</a>
                                                <a href="#" class="dropdown-item" data-toggle="modal" data-target="#newTeamsWebhookModal">Microsoft Teams</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /card header -->

                        <!-- Card body -->
                        <div *ngIf="serviceConnections.length > 0">
                            <ul class="media-list">
                                <li class="card-body media" *ngFor="let serviceConnection of serviceConnections">
                                    <div class="media-body">
                                        <div class="d-flex justify-content-baseline">
                                            <h5 class="media-title mb-3">{{serviceConnection.name}}
                                                <div class="text-muted font-size-xs"><span class="badge badge-mark border-success-400"></span> {{serviceConnection.serviceName}} connection</div>
                                            </h5>

                                            <div class="list-icons-item dropdown ml-auto align-self-center">
                                                <a href="#" class="list-icons-item dropdown-toggle caret-0" data-toggle="dropdown"><i class="icon-menu9"></i></a>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a (click)="onDeleteServiceConnectionButtonClick(serviceConnection)" class="dropdown-item">Delete Integration</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-1" *ngIf="serviceConnection.integrationTriggers.length > 0">
                                            <div class="col-lg-6">
                                                <div class="font-size-xs text-uppercase text-muted">Triggers</div>
                                            </div>
                                            <div class="col-lg-5">
                                                <div class="font-size-xs text-uppercase text-muted">Application Filters</div>
                                            </div>
                                        </div>

                                        <div class="row" *ngFor="let integrationTrigger of serviceConnection.integrationTriggers">
                                            <div class="col-lg-6">
                                                <span class="font-weight-semibold" *ngIf="integrationTrigger.triggerEvent == integrationTriggerEvent.BugReportCreated">Bug reported</span>
                                                <span class="font-weight-semibold" *ngIf="integrationTrigger.triggerEvent == integrationTriggerEvent.TestExecutionFinished">Test execution finished</span>

                                                <span *ngIf="integrationTrigger.configurationSummary"> {{integrationTrigger.configurationSummary}}</span>
                                            </div>
                                            <div class="col-lg-4">
                                                {{applicationsFilterSummary(integrationTrigger)}}
                                            </div>
                                            <div class="col-lg-2 d-flex">
                                                <ul class="list-inline list-inline-dotted ml-auto">
                                                    <li class="list-inline-item">
                                                        <a href="javascript:void(0)" (click)="onEditIntegrationTriggerButtonClick(serviceConnection, integrationTrigger)" data-toggle="modal" data-target="#editIntegrationTriggerModal">edit</a>
                                                    </li>
                                                    <li class="list-inline-item">
                                                        <a href="javascript:void(0)" (click)="onDeleteIntegrationTriggerButtonClick(integrationTrigger)">delete</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="row mt-1">
                                            <div class="col-lg-12">
                                                <a href="javascript:void(0)" (click)="onCreateIntegrationTriggerButtonClick(serviceConnection)" data-toggle="modal" data-target="#editIntegrationTriggerModal">Add trigger</a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <!-- /Card body -->
                    </div>

                </div>
            </div>
            <!-- /applications row -->

        </div>
        <!-- /content header -->

    </div>
    <!-- /main content -->

</div>

<edit-integration-trigger-modal #editIntegrationTriggerModal (onIntegrationTriggerCreated)="onIntegrationTriggerCreated()" (onIntegrationTriggerUpdated)="onIntegrationTriggerUpdated()"></edit-integration-trigger-modal>
<new-teams-webhook-modal #newTeamsWebhookModal [workspace]="workspace" (onWebhookCreated)="onWebhookCreated()"></new-teams-webhook-modal>
