import { Component, Input } from '@angular/core';
import { HTTPFormatter } from 'app/_helpers'

@Component({
    selector: 'status-code',
    templateUrl: 'status.code.component.html'
})

export class StatusCodeComponent {

    statusCodeNumber: string
    statusCodeText: string
    statusCodeBackgroundColor: string

    private _statusCode: number
    @Input()
    set statusCode(statusCode: number) {
        if (this._statusCode !== statusCode) {
            this._statusCode = statusCode
            this.reload()
        }
    }
    get statusCode(): number { return this._statusCode }

    private _errorSpecifier: string | null
    @Input()
    set errorSpecifier(errorSpecifier: string | null) {
        if (this._errorSpecifier !== errorSpecifier) {
            this._errorSpecifier = errorSpecifier
            this.reload()
        }
    }
    get errorSpecifier(): string | null { return this._errorSpecifier }

    constructor(
        private httpFormatter: HTTPFormatter
    ) { }

    private reload() {
        if (this.statusCode == 200299) {
            this.statusCodeNumber = "2XX"
        } else {
            this.statusCodeNumber = this.statusCode.toString()
        }

        if (this.statusCode < 200) {
            this.statusCodeBackgroundColor = "#777"

        } else if (this.statusCode < 400 || this.statusCode == 200299) {
            this.statusCodeBackgroundColor = "#45ba78"

        } else if (this.statusCode < 500) {
            this.statusCodeBackgroundColor = "#FFA726"

        } else {
            this.statusCodeBackgroundColor = "#E53935"
        }

        if (this.errorSpecifier) {
            this.statusCodeText = this.errorSpecifier
        } else {
            this.statusCodeText = this.httpFormatter.httpStatusCodeText(this.statusCode)
        }
    }

}
