<div *ngIf="networkTrendsSummaryResponse">
    <div class="d-flex">
        <div class="mr-auto font-weight-semibold mb-1">Total</div>
        <bytes [count]="networkTrendsSummaryResponse.totalPayloadSize"></bytes>
    </div>

    <div class="progress noshadow mb-1" style="height: 22px; width: 180px;">
        <div class="progress-bar progress-bar-striped" style="background-color: #0Cafffdd" [style.width]="requestPercentage"></div>
        <div class="progress-bar progress-bar-striped" style="background-color: #2a52bedd" [style.width]="responsePercentage"></div>
    </div>

    <div class="font-size-sm nowrap">
        <div class="d-flex">
            <div class="mr-auto"><span class="badge badge-mark border-0 mr-1" style="background-color: #0Cafffdd"></span>Requests</div>
            <bytes [count]="networkTrendsSummaryResponse.totalRequestPayloadSize"></bytes>
        </div>
        <div class="d-flex">
            <div class="mr-auto"><span class="badge badge-mark border-0 mr-1" style="background-color: #2a52bedd"></span>Responses</div>
            <bytes [count]="networkTrendsSummaryResponse.totalResponsePayloadSize"></bytes>
        </div>
    </div>
</div>
