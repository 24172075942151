import { BackendResponse } from 'app/_models';
import { Router, NavigationStart } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

export enum AlertMessageType {
    Success,
    Error
}

export class AlertMessage {
    type: AlertMessageType
    text: string
}

@Injectable({ providedIn: 'root' })
export class AlertService {

    private subject = new BehaviorSubject<AlertMessage | null>(null)
    private keepAfterNavigationChange = false

    constructor(
        private router: Router
    ) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false
                } else {
                    // clear alert
                    this.clear()
                }
            }
        })
    }

    success(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange
        this.subject.next({ type: AlertMessageType.Success, text: message })
    }

    error(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange
        this.subject.next({ type: AlertMessageType.Error, text: message })
    }

    getMessage(): Observable<AlertMessage> {
        return this.subject.asObservable()
    }

    clear() {
        this.subject.next(null)
    }

    handleError(error: any) {
        if (error instanceof HttpErrorResponse == false) {
            this.error(`Unknown error has occurred: (${error}).`)
            return
        }

        let httpError = error as HttpErrorResponse
        if (httpError.status == 0 || httpError.error == null) {
            this.error(`Unknown HTTP error occurred, please make sure your internet connection is functional and try again. If the error persists, contact us at support@appelium.com.`)
            return
        }

        let backendResponse = error.error as BackendResponse
        if (backendResponse.error == null) {
            this.error("Unknown error has occurred.")
            return
        }

        this.error(backendResponse.error.title)
    }

}
