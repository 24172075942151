<div class="content-wrapper" id="pageContent">

    <div class="content">
        <div class="row">
            <div class="col-md-12">
                <alert></alert>
            </div>
        </div>

        <div class="justify-content-center text-muted d-flex w-100 align-items-center" style="height: 90vh" *ngIf="loadedAllData == false">
            <div class="align-self-center">
                <i class="icon-spinner4 spinner mr-2"></i> Loading test execution...
            </div>
        </div>

        <div class="row" [@fadeInAnimation] *ngIf="loadedAllData">
            <div class="col-md-12">
                <div class="card mb-1">
                    <div class="page-header border-bottom-light">
                        <div class="page-header-content header-elements-md-inline">
                            <div class="page-title">
                                <h1 class="font-weight-thin">
                                    <a [routerLink]="['../../']" class="breadcrumb-item">{{application.name}} {{platformFormatter.platformName(application.platform)}}</a>
                                    <a [routerLink]="['../']" class="breadcrumb-item">Test Executions</a>
                                    <span class="breadcrumb-item active">#{{testExecution.serialNumber}}</span>
                                </h1>
                                <div class="text-muted text-sm font-weight-thin">{{dateFormatter.dateTime(testExecution.creationDate)}}</div>
                            </div>

                            <div class="header-elements" style="flex-wrap: nowrap;">
                                <header-value-item title="ENVIRONMENT" value="{{testExecution.environmentName}} {{testExecution.environmentVersion}}" *ngIf="testExecution.environmentName"></header-value-item>
                                <header-value-item title="APP VERSION" value="{{testExecution.testBundleVersion}}" *ngIf="testExecution.testBundleVersion"></header-value-item>
                                <header-value-item title="TEST PLAN" value="{{testExecution.testPlanName}}" *ngIf="testExecution.testPlanName"></header-value-item>
                                <header-value-item title="RUN TIME" value="{{testsDuration}}"></header-value-item>
                                <header-value-item title="SUCCESS RATE" value="{{successRate}}"></header-value-item>
                            </div>
                        </div>

                        <!-- <div class="progress rounded-0 noshadow" style="height: 7px;">
                            <div [style.width]="percentagePassed" class="progress-bar progress-bar-striped bg-success-400"></div>
                            <div [style.width]="percentageFailed" class="progress-bar progress-bar-striped bg-pink-400"></div>
                        </div> -->
                    </div>

                    <div class="card-body py-2">
                        <ul class="nav nav-pills mb-0">
                            <li class="nav-item">
                                <a href="" [ngClass]="{ 'active' : selectedTestsFilter == TestsFilter.All }" (click)="setTestsFilter(TestsFilter.All)" class="nav-link" data-toggle="tab">
                                    All<span class="badge badge-light ml-2">{{testExecution.stats.total}}</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="" [ngClass]="{ 'active' : selectedTestsFilter == TestsFilter.Failed }" (click)="setTestsFilter(TestsFilter.Failed)" class="nav-link" data-toggle="tab">
                                    <span class="badge badge-mark border-pink-400 mr-1"></span>
                                    Failures<span class="badge badge-light ml-2">{{testExecution.stats.failed}}</span>
                                </a>
                            </li>
                            <li class="nav-item" *ngIf="testExecutionMetricsSummary.resourceAllocation">
                                <a href="" [ngClass]="{ 'active' : selectedTestsFilter == TestsFilter.Efficiency}" (click)="setTestsFilter(TestsFilter.Efficiency)" class="nav-link" data-toggle="tab">
                                    Efficiency<span class="badge badge-light ml-2">{{testExecutionMetricsSummary.resourceAllocation.toFixed(2)}}</span>
                                </a>
                            </li>
                            <li class="nav-item" *ngIf="testExecutionMetricsSummary.networkAppdex">
                                <a href="" [ngClass]="{ 'active' : selectedTestsFilter == TestsFilter.Network}" (click)="setTestsFilter(TestsFilter.Network)" class="nav-link" data-toggle="tab">
                                    Network<span class="badge badge-light ml-2">{{testExecutionMetricsSummary.networkAppdex.toFixed(2)}}</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="card-body" *ngIf="selectedTestsFilter == 'all' || selectedTestsFilter == 'failed'">
                        <div class="py-1" *ngFor="let allocation of testExecutorAllocations">
                            <ul class="list-inline list-inline-dotted list-inline-condensed mb-0" *ngIf="!compactExecutorsMap">
                                <li class="list-inline-item text-dark">{{allocation.testExecutor.name}}</li>
                                <li class="list-inline-item text-muted">Tests executed: {{allocation.numberOfTests}}</li>
                            </ul>
                            <div class="progress noshadow" [style.height]="compactExecutorsMap ? '10px' : '18px'">
                                <ng-container *ngFor="let segment of allocation.testRunProgress.segments">
                                    <div *ngIf="segment.label != null"
                                        class="{{classForProgressBarSegment(segment)}}"
                                        style.width="{{(segment.size * 100) + '%'}}"
                                        (click)="navigateToProgressBarSegmentTestRun(segment)"
                                        popper="{{segment.label}}"
                                    ></div>

                                    <!-- Initiating popper was slowing down this page a lot -->
                                    <div *ngIf="segment.label == null"
                                        class="{{classForProgressBarSegment(segment)}}"
                                        style.width="{{(segment.size * 100) + '%'}}"
                                    ></div>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <table class="table" *ngIf="selectedTestsFilter == 'all' && filteredTestCases.length > 0">
                        <thead>
                            <tr role="row">
                                <th class="border-bottom-0 w-100 sortasc" [appSort]="allTestRuns" [dataFunction]="testCaseNameForTestRun.bind(this)">Test runs<i class="icon-sort"></i></th>
                                <th class="border-bottom-0 text-center" *ngIf="hasTestConfigurations">Configuration</th>
                                <th class="border-bottom-0 text-center">Device</th>
                                <th class="border-bottom-0 text-center">OS</th>
                                <th class="border-bottom-0 text-center">Locale</th>
                                <th class="border-bottom-0 text-right nowrap nosort" [appSort]="allTestRuns" data-name="duration">Duration<i class="icon-sort"></i></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let testRun of allTestRuns" class="selectable hoverable" (click)="navigateToTestRun(testRun)">
                                <td>
                                    <div class="d-flex">
                                        <div class="mr-3 ml-1">
                                            <span [class]="statusClassForTestRun(testRun)"></span>
                                        </div>
                                        <div>
                                            <a class="font-weight-semibold breakall button-muted" [routerLink]="['runs', testRun.serialNumber]">{{testCaseNameForTestRun(testRun)}}
                                                <span class="font-weight-light text-muted" *ngIf="hasTestCasesWithMultipleRuns">&nbsp;·&nbsp;Iteration&nbsp;#{{testRun.iteration}}</span>
                                            </a>
                                            <div class="text-muted breakall">{{statusTextForTestRun(testRun)}}</div>
                                        </div>
                                    </div>
                                </td>
                                <td class="nowrap text-center" *ngIf="hasTestConfigurations">{{testRun.testConfiguration}}</td>
                                <td class="nowrap text-center">{{testExecutorForTestRun(testRun).deviceModelName}}</td>
                                <td class="nowrap text-center">{{testExecutorForTestRun(testRun).system}}</td>
                                <td class="nowrap text-center">{{testRun.locale}}</td>
                                <td class="text-right">{{testRunDuration(testRun)}}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table class="table" *ngIf="selectedTestsFilter == 'failed' && testFailureGroups.length > 0">
                        <thead>
                            <tr role="row">
                                <th class="border-bottom-0 sortasc w-100" colspan="2">Test runs</th>
                                <th class="border-bottom-0 text-center" *ngIf="hasTestConfigurations">Configuration</th>
                                <th class="border-bottom-0 text-center">Device</th>
                                <th class="border-bottom-0 text-center">OS</th>
                                <th class="border-bottom-0 text-center">Locale</th>
                                <th class="border-bottom-0 text-right">Duration</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let testFailureGroup of testFailureGroups">
                                <tr class="table-active">
                                    <td [attr.colspan]="hasTestConfigurations ? 7 : 6">
                                        <h6 class="mb-0">
                                            <ul class="list-inline list-inline-dotted list-inline-condensed mb-0 d-flex">
                                                <li class="list-inline-item">
                                                    <a [routerLink]="['../../test-failure-groups', testFailureGroup.serialNumber]" class="button-muted">
                                                        Failure group #{{testFailureGroup.serialNumber}}
                                                    </a>
                                                </li>
                                                <li class="list-inline-item" [ngClass]="{ 'text-success-600' : testFailureGroup.state == 1, 'text-muted' : testFailureGroup.state == 2 }">{{issueStateFormatter.displayName(testFailureGroup.state)}}</li>
                                                <li class="list-inline-item" *ngIf="testFailureGroup.annotation"><i class="icon-attachment text-muted font-size-sm"></i></li>
                                            </ul>
                                        </h6>
                                        <ul class="list-inline list-inline-dotted list-inline-condensed font-size-sm font-weight-light text-muted mb-0">
                                            <li class="list-inline-item">{{testFailureGroup.failureLocation}}</li>
                                            <li class="list-inline-item">{{numberOfFailuresForTestFailureGroup(testFailureGroup.id)}} failures</li>
                                        </ul>
                                    </td>
                                </tr>

                                <tr *ngFor="let testRun of testRunsForTestFailureGroup(testFailureGroup.id)" class="selectable hoverable" (click)="navigateToTestRun(testRun)">
                                    <td class="pr-0">
                                        <span [class]="statusClassForTestRun(testRun)"></span>
                                    </td>
                                    <td class="w-100">
                                        <a class="font-weight-semibold breakall button-muted" [routerLink]="['runs', testRun.serialNumber]">{{testCaseNameForTestRun(testRun)}}
                                            <span class="font-weight-light text-muted" *ngIf="hasTestCasesWithMultipleRuns">&nbsp;·&nbsp;Iteration&nbsp;#{{testRun.iteration}}</span>
                                        </a>
                                        <div class="text-muted breakall">{{statusTextForTestRun(testRun)}}</div>
                                    </td>
                                    <td class="nowrap text-center" *ngIf="hasTestConfigurations">{{testRun.testConfiguration}}</td>
                                    <td class="nowrap text-center">{{testExecutorForTestRun(testRun).deviceModelName}}</td>
                                    <td class="nowrap text-center">{{testExecutorForTestRun(testRun).system}}</td>
                                    <td class="nowrap text-center">{{testRun.locale}}</td>
                                    <td class="text-right">{{testRunDuration(testRun)}}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>

                    <div class="card-body" *ngIf="selectedTestsFilter == 'efficiency'">
                        <h4 class="mb-0">Overall resource allocation: {{(testExecutionMetricsSummary.resourceAllocation * 100).toFixed(0) + '%'}}</h4>
                        <div class="font-weight-thin">Average test duration: {{dateFormatter.duration(testExecutionDetails.averageTestRunDuration)}}</div>
                        <div class="font-weight-thin mb-2">Total number of test runs: {{this.testExecutionDetails.testRuns.length}}</div>

                        <div class="mb-2 text-muted">In order to improve parallelization it is recommended to split test suites into smaller ones so that neither of them takes a significant portion of overall execution time. Use the birds eye view of the whole execution to analyze potential scheduling issues and optimize resource allocation.</div>

                        <!-- TEST SUITES MAP -->
                        <div *ngFor="let allocation of testExecutorAllocations">
                            <div class="d-flex justify-content-between">
                                <h6 class="mb-0">{{allocation.testExecutor.name}}</h6>
                                <h6 class="mb-0">{{allocation.executorAllocation}}% ({{dateFormatter.duration(allocation.executionTime)}})</h6>
                            </div>
                            <div class="progress noshadow" style="height: 20px;">
                                <div *ngFor="let segment of allocation.testSuiteProgress.segments"
                                    [class]="classForProgressBarSegment(segment)"
                                    [style.width]="(segment.size * 100) + '%'"
                                    popper="{{segment.label}}"
                                    [popperDisabled]="segment.label == null || showLabelForProgressBarSegment(segment)">
                                        <span class="progress-bar-segment-label font-size-base font-weight-semibold" *ngIf="showLabelForProgressBarSegment(segment)">{{segment.label}}</span>
                                </div>
                            </div>
                            <ul class="list-inline list-inline-dotted list-inline-condensed font-size-sm text-muted mb-0">
                                <li class="list-inline-item">Time to first test: {{dateFormatter.duration(allocation.timeToFirstTest)}}</li>
                            </ul>
                        </div>
                        <div class="d-flex align-items-center mt-2">
                            <div class="progress" style="width: 1.125rem;">
                                <div class="progress-bar progress-bar-striped bg-success-400 w-100"></div>
                            </div>
                            <span class="ml-1">Optimal test suite length</span>

                            <div class="progress noshadow ml-2" style="width: 1.125rem;">
                                <div class="progress-bar progress-bar-striped bg-orange-400 w-100"></div>
                            </div>
                            <span class="ml-1">Long</span>

                            <div class="progress noshadow ml-2" style="width: 1.125rem;">
                                <div class="progress-bar progress-bar-striped bg-orange-800 w-100"></div>
                            </div>
                            <span class="ml-1">Very long</span>
                        </div>
                    </div>

                    <ng-container *ngIf="selectedTestsFilter == 'network'">
                        <div class="card-body">
                            <network-trends (onNetworkTrendTypeChanged)="onNetworkTrendTypeChanged($event)" [networkTrendType]="networkTrendType" [networkTrendsResponse]="networkTrendsResponse" [networkTrendsSummaryResponse]="networkTrendsSummaryResponse"></network-trends>
                        </div>

                        <div class="card-body d-flex align-items-baseline">
                            <h5 class="mb-0 pb-0 mr-2">Dimensions</h5>
                            <ul class="nav nav-pills nav-pills-bordered">
                                <li class="nav-item ml-0 mr-1 mb-1">
                                    <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : networkDimension == NetworkDimension.Overview}" (click)="setNetworkDimension(NetworkDimension.Overview)">Overview</a>
                                </li>
                                <li class="nav-item ml-0 mr-1 mb-1">
                                    <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : networkDimension == NetworkDimension.Operations}" (click)="setNetworkDimension(NetworkDimension.Operations)">Operations</a>
                                </li>
                                <li class="nav-item ml-0 mr-1 mb-1">
                                    <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : networkDimension == NetworkDimension.ResponseCodes}" (click)="setNetworkDimension(NetworkDimension.ResponseCodes)">Responses</a>
                                </li>
                                <li class="nav-item ml-0 mr-1 mb-1">
                                    <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : networkDimension == NetworkDimension.NetworkProtocols }" (click)="setNetworkDimension(NetworkDimension.NetworkProtocols)">Protocols</a>
                                </li>
                            </ul>
                        </div>

                        <network-segments (onFindNetworkLog)="onFindNetworkLog($event)" (onEditNetworkOperation)="onEditNetworkOperation($event)" [networkDimension]="networkDimension" [networkSegments]="networkSegments" [allowFiltering]="false"></network-segments>
                    </ng-container>
                </div>
            </div>
        </div>

    </div>
</div>

<edit-network-operation-modal #editNetworkOperationModal (networkOperationUpdated)="onNetworkOperationUpdated()"></edit-network-operation-modal>
<request-preview-modal #requestPreviewModal></request-preview-modal>
