import { Component, Input, Output, ViewChild, ElementRef, EventEmitter, HostListener } from "@angular/core"
import { FormGroup, FormBuilder, Validators } from "@angular/forms"
import { AuthToken, BackendResponse } from "app/_models"
import { AlertService, AuthTokenService } from "app/_services"

@Component({
    selector: 'edit-auth-token-modal',
    templateUrl: 'edit.auth.token.modal.html'
})
export class EditAuthTokenModal {

    form: FormGroup

    private _authToken: AuthToken
    @Input()
    public set authToken(authToken: AuthToken) {
        this._authToken = authToken
        this.reloadForm()
    }
    public get authToken(): AuthToken {
        return this._authToken
    }

    @Output() authTokenUpdated = new EventEmitter()

    @ViewChild('closeButton') closeButton: ElementRef

    constructor(
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private authTokenService: AuthTokenService,
    ) {
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls }

    private reloadForm() {
        this.form = this.formBuilder.group({
            authTokenName: [this.authToken.name, Validators.required]
        })
    }

    saveAuthToken() {
        this.authTokenService.updateAuthToken(this.authToken.id, this.f.authTokenName.value).then((response) => {
            this.alertService.success("API Key successfully updated")
            this.closeButton.nativeElement.click()
            this.authTokenUpdated.emit()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
