<div class="justify-content-center text-muted d-flex pb-3 align-items-center" style="height: 100px" *ngIf="!networkSegments">
    <div class="align-self-center">
        <i class="icon-spinner4 spinner mr-2"></i> Loading data...
    </div>
</div>

<table class="table" *ngIf="networkSegments">
    <thead>
        <tr role="row">
            <th class="border-bottom-0 w-100 nosort" [appSort]="networkSegments" data-name="urlPattern" *ngIf="networkDimension == NetworkDimension.Overview">
                Top {{networkSegments.length}} groups<i class="icon-sort"></i>
            </th>

            <th class="border-bottom-0 w-100 nosort" [appSort]="networkSegments" data-name="urlPattern" *ngIf="networkDimension == NetworkDimension.Operations">
                {{networkSegments.length}} URL patterns<i class="icon-sort"></i>
            </th>

            <th class="border-bottom-0 w-100 nosort" [appSort]="networkSegments" data-name="responseCode" *ngIf="networkDimension == NetworkDimension.ResponseCodes">
                {{networkSegments.length}} Responses<i class="icon-sort"></i>
            </th>

            <th class="border-bottom-0 w-100 nosort" [appSort]="networkSegments" data-name="name" *ngIf="networkDimension == NetworkDimension.Environments">
                {{networkSegments.length}} Environments<i class="icon-sort"></i>
            </th>

            <th class="border-bottom-0 w-100 nosort" [appSort]="networkSegments" data-name="version" *ngIf="networkDimension == NetworkDimension.EnvironmentVersions">
                {{networkSegments.length}} Environment versions<i class="icon-sort"></i>
            </th>

            <th class="border-bottom-0 w-100 nosort" [appSort]="networkSegments" data-name="networkInterface" *ngIf="networkDimension == NetworkDimension.NetworkInterfaces">
                {{networkSegments.length}} Radios<i class="icon-sort"></i>
            </th>

            <th class="border-bottom-0 w-100 nosort" [appSort]="networkSegments" data-name="networkProtocol" *ngIf="networkDimension == NetworkDimension.NetworkProtocols">
                {{networkSegments.length}} Network protocols<i class="icon-sort"></i>
            </th>

            <th class="border-bottom-0 w-100 nosort" [appSort]="networkSegments" data-name="networkHost" *ngIf="networkDimension == NetworkDimension.NetworkHosts">
                {{networkSegments.length}} Network domains<i class="icon-sort"></i>
            </th>

            <th class="border-bottom-0 w-100 nosort" [appSort]="networkSegments" data-name="bundleShortVersion" *ngIf="networkDimension == NetworkDimension.ApplicationVersions">
                {{networkSegments.length}} App versions<i class="icon-sort"></i>
            </th>

            <th class="border-bottom-0 w-100 nosort" [appSort]="networkSegments" data-name="slug" *ngIf="networkDimension == NetworkDimension.Applications">
                {{networkSegments.length}} Apps<i class="icon-sort"></i>
            </th>

            <th class="border-bottom-0 text-right nowrap nosort" [appSort]="networkSegments" data-name="appdex"><span>Appdex</span><i class="icon-sort"></i></th>
            <th class="border-bottom-0 text-right nowrap nosort" [appSort]="networkSegments" data-name="durationAvg"><span>Response time<sub>avg</sub></span><i class="icon-sort"></i></th>
            <th class="border-bottom-0 text-center nowrap nosort" [appSort]="networkSegments" [dataFunction]="totalFailuresCount.bind(this)">Failures<i class="icon-sort"></i></th>
            <th class="border-bottom-0 text-right nowrap nosort" [appSort]="networkSegments" data-name="payloadSize">Payload<i class="icon-sort"></i></th>
            <th class="border-bottom-0 text-right nowrap sortdesc" [appSort]="networkSegments" data-name="requestsCount">Count<i class="icon-sort"></i></th>
        </tr>
    </thead>

    <tbody>
        <ng-container *ngFor="let segment of networkSegments">
            <!-- <tr [style.background]="'linear-gradient(to right, rgba(33, 150, 243,.06) ' + (segment.relativeSize * 100).toFixed(1) + '%, #FFF ' + (segment.relativeSize * 100).toFixed(1) + '%'"> -->
            <tr>
                <td class="hover-expendable" *ngIf="networkDimension == NetworkDimension.Overview">
                    <a href="javascript:void(0)" (click)="editNetworkOperation(segment.networkOperationId)" class="button-muted font-size-lg breakall" data-toggle="modal" data-target="#editNetworkOperationModal" *ngIf="segment.urlPattern">
                        <span class="text-muted">{{segment.method}}</span>&nbsp;<url-pattern [pattern]="segment.urlPattern"></url-pattern>
                    </a>
                    <div class="d-flex">
                        <ul class="list-inline list-inline-dotted list-inline-condensed mb-0">
                            <li class="list-inline-item">
                                <status-code [statusCode]="200299" *ngIf="segment.responseCode == 200"></status-code>
                                <status-code [statusCode]="segment.responseCode" *ngIf="segment.responseCode != 200"></status-code>
                            </li>
                            <li class="list-inline-item" *ngIf="segment.name">
                                <a href="javascript:void(0)" (click)="editNetworkOperation(segment.networkOperationId)" class="button-muted breakall" data-toggle="modal" data-target="#editNetworkOperationModal">{{segment.name}}</a>
                            </li>
                        </ul>

                        <a [routerLink]="['./']" [queryParams]="{networkOperation: segment.networkOperationId, responseStatusCode: segment.responseCode}" queryParamsHandling="merge" class="hover-target badge badge-flat badge-pill border-primary text-primary ml-2" *ngIf="allowFiltering && segment.responseCode != 200">
                            <i class="icon-filter3 mr-1" style="font-size: 90%;"></i>Add to filter
                        </a>

                        <a href="javascript:void(0)" (click)="findNetworkLog(segment)" class="hover-target badge badge-flat badge-pill border-primary text-primary ml-2" data-toggle="modal" data-target="#requestPreviewModal">
                            <i class="icon-search4 mr-1" style="font-size: 90%;"></i>View latest
                        </a>
                    </div>
                </td>

                <td class="hover-expendable" *ngIf="networkDimension == NetworkDimension.Operations">
                    <a href="javascript:void(0)" (click)="editNetworkOperation(segment.id)" class="button-muted font-size-lg breakall" data-toggle="modal" data-target="#editNetworkOperationModal">
                        <span class="text-muted">{{segment.method}}</span>&nbsp;<url-pattern [pattern]="segment.urlPattern"></url-pattern>
                    </a>
                    <div class="d-flex align-items-baseline">
                        <ul class="list-inline list-inline-dotted list-inline-condensed mb-0">
                            <li class="list-inline-item" *ngIf="segment.name">
                                <a href="javascript:void(0)" (click)="editNetworkOperation(segment.id)" class="button-muted breakall" data-toggle="modal" data-target="#editNetworkOperationModal">
                                    {{segment.name}}
                                </a>
                            </li>
                            <li class="list-inline-item text-muted">
                                <a href="javascript:void(0)" (click)="editNetworkOperation(segment.id)" class="button-muted breakall" data-toggle="modal" data-target="#editNetworkOperationModal">
                                    <span class="font-weight-light">Target is </span>{{segment.targetDuration.toFixed(1)}} sec
                                </a>
                            </li>
                        </ul>

                        <a [routerLink]="['./']" [queryParams]="{networkOperation: segment.id}" queryParamsHandling="merge" class="hover-target badge badge-flat badge-pill border-primary text-primary ml-2" *ngIf="allowFiltering">
                            <i class="icon-filter3 mr-1" style="font-size: 90%;"></i>Add to filter
                        </a>

                        <a href="javascript:void(0)" (click)="findNetworkLog(segment)" class="hover-target badge badge-flat badge-pill border-primary text-primary ml-2" data-toggle="modal" data-target="#requestPreviewModal">
                            <i class="icon-search4 mr-1" style="font-size: 90%;"></i>View latest
                        </a>
                    </div>
                </td>

                <td class="hover-expendable align-items-baseline" *ngIf="networkDimension == NetworkDimension.Environments">
                    <span>{{segment.name}}</span>
                    <a [routerLink]="['./']" [queryParams]="{environment: segment.name}" queryParamsHandling="merge" class="hover-target badge badge-flat badge-pill border-primary text-primary ml-2" *ngIf="allowFiltering">
                        <i class="icon-filter3 mr-1" style="font-size: 90%;"></i>Add to filter
                    </a>
                </td>

                <td class="hover-expendable align-items-baseline" *ngIf="networkDimension == NetworkDimension.EnvironmentVersions">
                    <span>{{segment.version}}</span>
                    <a [routerLink]="['./']" [queryParams]="{environmentVersion: segment.version}" queryParamsHandling="merge" class="hover-target badge badge-flat badge-pill border-primary text-primary ml-2" *ngIf="allowFiltering">
                        <i class="icon-filter3 mr-1" style="font-size: 90%;"></i>Add to filter
                    </a>
                </td>

                <td class="hover-expendable align-items-baseline" *ngIf="networkDimension == NetworkDimension.NetworkInterfaces">
                    <span>{{networkInterfaceFormatter.displayName(segment.networkInterface)}}</span>
                    <a [routerLink]="['./']" [queryParams]="{networkInterface: networkInterfaceFormatter.displayName(segment.networkInterface)}" queryParamsHandling="merge" class="hover-target badge badge-flat badge-pill border-primary text-primary ml-2" *ngIf="allowFiltering">
                        <i class="icon-filter3 mr-1" style="font-size: 90%;"></i>Add to filter
                    </a>
                </td>

                <td class="hover-expendable align-items-baseline" *ngIf="networkDimension == NetworkDimension.NetworkProtocols">
                    <span>{{segment.name}}</span>
                    <a [routerLink]="['./']" [queryParams]="{networkProtocol: segment.id}" queryParamsHandling="merge" class="hover-target badge badge-flat badge-pill border-primary text-primary ml-2" *ngIf="allowFiltering">
                        <i class="icon-filter3 mr-1" style="font-size: 90%;"></i>Add to filter
                    </a>
                </td>

                <td class="hover-expendable align-items-baseline" *ngIf="networkDimension == NetworkDimension.NetworkHosts">
                    <span>{{segment.name}}</span>
                    <a [routerLink]="['./']" [queryParams]="{networkHost: segment.id}" queryParamsHandling="merge" class="hover-target badge badge-flat badge-pill border-primary text-primary ml-2" *ngIf="allowFiltering">
                        <i class="icon-filter3 mr-1" style="font-size: 90%;"></i>Add to filter
                    </a>
                </td>

                <td class="hover-expendable align-items-baseline" *ngIf="networkDimension == NetworkDimension.ResponseCodes">
                    <status-code [statusCode]="segment.responseCode" [errorSpecifier]="segment.errorSpecifier"></status-code>

                    <a [routerLink]="['./']" [queryParams]="{responseStatusCode: segment.responseCode}" queryParamsHandling="merge" class="hover-target badge badge-flat badge-pill border-primary text-primary ml-2" *ngIf="allowFiltering">
                        <i class="icon-filter3 mr-1" style="font-size: 90%;"></i>Add to filter
                    </a>

                    <a href="javascript:void(0)" (click)="findNetworkLog(segment)" class="hover-target badge badge-flat badge-pill border-primary text-primary ml-2" data-toggle="modal" data-target="#requestPreviewModal">
                        <i class="icon-search4 mr-1" style="font-size: 90%;"></i>View latest
                    </a>

                    <!-- <a href="#" class="hover-target p-0 badge badge-flat badge-pill border-primary text-primary ml-2" data-toggle="dropdown">
                        <i class="icon-arrow-down12" style="font-size: 160%; padding-top: 4px; padding-left: 4px; padding-right: 4px;"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-left border-primary">
                        <div class="dropdown-header text-uppercase font-size-xs line-height-xs">MORE</div>
                        <div class="dropdown-divider opacity-75"></div>
                        <a href="javascript:void(0)" (click)="findNetworkLog(segment)" class="dropdown-item d-block" data-toggle="modal" data-target="#requestPreviewModal">
                            <div class="font-weight-semibold"><i class="icon-search4 mr-1"></i>Find recent report</div>
                            <div class="text-muted">Find the most recent report matching this response status code.</div>
                        </a>
                    </div> -->
                </td>

                <td class="hover-expendable align-items-baseline" *ngIf="networkDimension == NetworkDimension.ApplicationVersions">
                    <span>{{segment.bundleShortVersion}}</span>
                    <a [routerLink]="['./']" [queryParams]="{appVersion: segment.bundleShortVersion}" queryParamsHandling="merge" class="hover-target badge badge-flat badge-pill border-primary text-primary ml-2" *ngIf="allowFiltering">
                        <i class="icon-filter3 mr-1" style="font-size: 90%;"></i>Add to filter
                    </a>
                </td>

                <td class="hover-expendable align-items-baseline" *ngIf="networkDimension == NetworkDimension.Applications">
                    {{segment.applicationName}} <span class="text-muted"> {{platformFormatter.platformName(segment.platform)}}</span>
                    <a [routerLink]="['./']" [queryParams]="{app: segment.id}" queryParamsHandling="merge" class="hover-target badge badge-flat badge-pill border-primary text-primary ml-2" *ngIf="allowFiltering">
                        <i class="icon-filter3 mr-1" style="font-size: 90%;"></i>Add to filter
                    </a>
                </td>

                <td class="nowrap text-right font-weight-bold">
                    <appdex [value]="segment.appdex"></appdex>
                </td>
                <td class="nowrap text-right">
                    <milliseconds [duration]="segment.durationAvg"></milliseconds>
                </td>
                <td class="nowrap text-center">
                    <network-failures-progress [networkSegment]="segment"></network-failures-progress>
                </td>
                <td class="nowrap text-right">
                    <bytes [count]="segment.payloadSize"></bytes>
                </td>
                <td class="nowrap text-right">
                    <count [count]="segment.requestsCount"></count><span class="text-muted mx-1">·</span><percentage [value]="segment.relativeSize"></percentage>
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>
