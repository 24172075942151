import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BugReport, BackendResponse, Application } from 'app/_models';
import * as bootbox from 'bootbox'
import { BugReportsService, AlertService, ApplicationService, NetworkOperationsService, AuthenticationService } from 'app/_services';
import { ByteCountFormatter, PlatformFormatter, DateFormatter, ApplicationFormatter } from 'app/_helpers';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationLogsComponent } from 'app/_components';
import { ImageVideoComponent } from 'app/_components/image.video.component/image.video.component';
import * as moment from 'moment'
import { Title } from '@angular/platform-browser';
import { NetworkResponseLog } from 'app/_models/console.logs.response';

@Component({
    templateUrl: 'bug.report.component.html'
})
export class BugReportComponent implements OnInit, OnDestroy {

    application: Application
    bugReport: BugReport

    @ViewChild('applicationLogs') applicationLogs: ApplicationLogsComponent
    @ViewChild('imageVideo') imageVideo: ImageVideoComponent

    private currentApplicationSubscription: Subscription
    private routeParamsSubscription: Subscription

    constructor(
        public dateFormatter: DateFormatter,
        public byteCountFormatter: ByteCountFormatter,
        private route: ActivatedRoute,
        private router: Router,
        public platformFormatter: PlatformFormatter,
        private bugReportsService: BugReportsService,
        private applicationService: ApplicationService,
        private alertService: AlertService,
        private applicationFormatter: ApplicationFormatter,
        private authenticationService: AuthenticationService,
        private networkOperationsService: NetworkOperationsService,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.routeParamsSubscription = this.route.params.subscribe(params => {
            this.loadBugReport()
        })

        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
            if (this.application != null) {
                this.loadBugReport()
            }
        })
    }

    ngOnDestroy() {
        this.currentApplicationSubscription.unsubscribe()
        this.routeParamsSubscription.unsubscribe()
    }

    onBugReportDeleteButtonClick() {
        let weakThis = this

        bootbox.dialog({
            title: `Delete Bug Report #${this.bugReport.serialNumber}?`,
            message: "The Bug Report will be permanently deleted. This action is not reversible.",
            size: 'small',
            buttons: {
                cancel: { label: 'Cancel', className: 'btn-link' },
                delete: { label: 'Delete', className: 'btn-danger', callback: function() {
                    weakThis.deleteBugReport()
                }}
            }
        })
    }

    deleteBugReport() {
        this.bugReportsService.deleteBugReport(this.bugReport.id).then((response) => {
            this.bugReportsService.bugReportDeletedWithId.emit(this.bugReport.id)

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    onVideoProgressChange(progress: number) {
        this.applicationLogs.setTimelinePosition(progress, false)
    }

    onTimelinePositionChanged(position: number) {
        this.imageVideo.setVideoPosition(position)
    }

    appLaunchTimeText(): string {
        if (this.bugReport.appLaunchDate == null) {
            return "-"
        }

        let diff = moment(this.bugReport.creationDate).diff(this.bugReport.appLaunchDate) / 1000
        return `${this.dateFormatter.duration(diff)} prior to report`
    }

    onAnalyzeRequest(responseLog: NetworkResponseLog) {
        if (!this.bugReport.environmentName) { return }

        this.networkOperationsService.resolveNetworkRequest(this.authenticationService.currentWorkspaceValue.id, responseLog.method, responseLog.url.toString()).then((response) => {
            this.router.navigate(
                ['/', this.authenticationService.currentWorkspaceValue.id, 'envs', this.bugReport.environmentSlug, 'network'],
                {
                    queryParams: {
                        // environment: this.bugReport.environmentName,
                        networkOperation: response.networkOperationId,
                        history: '1D'
                    },
                    queryParamsHandling: 'merge'
                }
            )

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private loadBugReport() {
        if (!this.application) { return }

        let bugReportSlug = this.route.snapshot.params['bugReportSlug']
        if (bugReportSlug == null || (this.bugReport && this.bugReport.serialNumber == bugReportSlug)) {
            return
        }

        this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | Bug Report #${bugReportSlug}`)

        this.bugReportsService.getBugReport(this.application.id, bugReportSlug).then((bugReport) => {
            this.bugReport = bugReport.data

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
