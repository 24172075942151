<div id="editErrorGroupModal" class="modal fade" *ngIf="errorGroup">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Error Group #{{errorGroup.serialNumber}}</h5>
                <button #closeButton type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <form [formGroup]="form">
                    <div class="form-group">
                        <div class="font-size-xs text-uppercase text-muted mb-1">Annotation</div>
                        <textarea formControlName="annotation" rows="4" cols="4" placeholder="Add annotation" class="form-control"></textarea>
                        <div class="text-muted mt-1">Attach useful contextual information related to this error group. This may include links to your issue tracking system such as JIRA or Trello. <code>Markdown</code> syntax is supported.</div>
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <button (click)="saveErrorGroup()" [disabled]="form.invalid" type="button" class="btn bg-primary-400">Save</button>
            </div>
        </div>
    </div>
</div>
