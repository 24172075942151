<div>
    <div class="text-default font-weight-semibold" *ngIf="title">
        {{title}}
    </div>
    <div class="text-muted font-weight-semibold" *ngIf="title == null">
        No description provided
    </div>

    <div style="display: flex;">
        <span class="text-muted font-size-sm font-weight-light mr-auto">{{email}}</span>
        <span class="text-muted font-size-sm font-weight-light"> {{time}}</span>
    </div>
</div>
