<div id="create_app_modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header">
                <h5 class="modal-title">Create a new application</h5>
                <button #closeButton type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <alert></alert>
                    </div>
                </div>

                <div *ngIf="!canCreateApplications" class="alert alert-warning alert-styled-left border-top-0 border-bottom-0 border-right-0">
                    <p class="font-weight-semibold">Subscription limits reached.</p>
                    <p>Your workspace has reached the maximum of {{workspace.limits.maximumApplications}} apps. Please consider <a href="javascript:void(0)" (click)="navigateToSubscriptionSettings()">upgrading your subscription</a> or <a href="mailto:support@appelium.com">contact support</a> for more information.</p>
                </div>

                <form [formGroup]="form">
                    <div class="form-group">
                        <label>Application name:</label>
                        <input type="text" autocomplete="off" formControlName="appName" class="form-control">
                        <span class="form-text text-muted">The display name of the application <strong>without</strong> the platform suffix (Android, iOS, tvOS). The suffix will be added automatically.</span>
                    </div>
                    <div class="form-group">
                        <label>Platform:</label>
                        <ul class="navbar-nav">
                            <li class="nav-item dropdown" style="border: 1px solid #ddd; border-radius: .1875rem;">
                                <div class="btn-group w-100">
                                    <button type="button" class="btn btn-link dropdown-toggle" style="color: black;" data-toggle="dropdown">
                                        <span *ngIf="this.form.controls.platform.value">{{platformFormatter.platformName(this.form.controls.platform.value)}}</span>
                                        <span *ngIf="!this.form.controls.platform.value">Select platform</span>
                                    </button>
                                    <div class="dropdown-menu">
                                        <div *ngFor="let platform of platformFormatter.availablePlatforms()">
                                            <a (click)="this.form.controls.platform.setValue(platform)" class="dropdown-item">
                                                {{platformFormatter.platformName(platform)}}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                </form>
            </div>

            <div class="modal-footer">
                <button (click)="submitAppCreationForm()" [disabled]="form.invalid || !canCreateApplications" type="button" class="btn bg-primary-400">Create</button>
            </div>
        </div>
    </div>
</div>
