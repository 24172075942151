export class ProgressBar {
    segments: ProgressBarSegment[]
}

export class ProgressBarSegment {
    size: number
    type: ProgressBarSegmentType
    label: string | null
    ref: string | null
}

export enum ProgressBarSegmentType {
    Blank = 0,
    TestPassPreliminary = 1,
    TestPassFinal = 2,
    TestFailurePreliminary = 3,
    TestFailureFinal = 4,
    TestInProgress = 5,
    TestCase = 6
}
