import { Component, Input, OnInit } from '@angular/core';
import { ErrorReport } from 'app/_models';
import { DateFormatter } from 'app/_helpers';

@Component({
    selector: 'error-report-list-item',
    templateUrl: 'error.report.list.item.html',
    styleUrls: [
        'error.report.list.item.css'
    ]
})
export class ErrorReportListItem implements OnInit {

    @Input() public errorReport: ErrorReport

    public title: string
    public time: string

    constructor(
        public dateFormatter: DateFormatter
    ) {
    }

    ngOnInit() {
        this.title = this.errorReport.deviceModelName
        this.time = this.dateFormatter.timeAgoShort(this.errorReport.errorDate)
    }

}
