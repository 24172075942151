<td (click)="onTestExecutionClick()">
    <a [routerLink]="['/', workspace.slug, 'apps', testExecution.applicationSlug, 'test-executions', testExecution.serialNumber]" class="button-muted nowrap">
        {{dateFormatter.dateTimeShort(testExecution.creationDate)}}
    </a>
</td>

<td (click)="onTestExecutionClick()" class="nowrap" *ngIf="showAppColumn">{{testExecution.applicationName}}<span class="ml-1 text-muted">{{platformFormatter.platformName(testExecution.platform)}}</span></td>
<td (click)="onTestExecutionClick()" class="nowrap" *ngIf="showEnvironmentColumn">
    <span *ngIf="testExecution.environmentName">{{testExecution.environmentName}} {{testExecution.environmentVersion}}</span>
    <span *ngIf="!testExecution.environmentName">-</span>
</td>
<td (click)="onTestExecutionClick()" class="nowrap">{{testExecution.testBundleVersion}}</td>
<td (click)="onTestExecutionClick()" class="nowrap">{{testExecution.testPlanName}}</td>
<td (click)="onTestExecutionClick()">
    <test-execution-progress [testExecution]="testExecution"></test-execution-progress>
</td>
<td (click)="onTestExecutionClick()">{{duration}}</td>
<td class="text-right">
    <div class="list-icons">
        <div class="list-icons-item dropdown">
            <a href="#" class="list-icons-item dropdown-toggle caret-0" aria-expanded="true" data-toggle="dropdown"><i class="icon-menu9"></i></a>
            <div class="dropdown-menu dropdown-menu-right">
                <a (click)="onTestExecutionDeleteButtonClick()" class="dropdown-item text-pink-400"><i class="icon-cross2"></i> Delete Execution</a>
            </div>
        </div>
    </div>
</td>
