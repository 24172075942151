import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'app/app.config';
import { ElementResponse } from './response';

export class DemoAccountResponse {
    demoEmail: string
    demoPassword: string
}

@Injectable({ providedIn: 'root' })
export class DemoService {

    constructor(
        private http: HttpClient,
    ) {
    }

    createDemoAccount(workspaceName: String, email: String | null): Promise<ElementResponse<DemoAccountResponse>> {
        let requestBody = {
            workspaceName: workspaceName,
            email: email
        }

        return this.http.post<ElementResponse<DemoAccountResponse>>(`${AppConfig.apiBaseUrl}/demos`, requestBody).toPromise()
    }

}
