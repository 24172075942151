<div *ngIf="networkTrendsSummaryResponse">
    <div class="d-flex">
        <div class="mr-auto font-weight-semibold mb-1">Response&nbsp;time<sub>avg</sub></div>
        <milliseconds [duration]="networkTrendsSummaryResponse.overallDuration"></milliseconds>
    </div>
    <div class="progress noshadow mb-1" style="height: 22px; width: 180px;">
        <div class="progress-bar progress-bar-striped" style="background-color: #4cc9f0" [style.width]="dnsPercentage"></div>
        <div class="progress-bar progress-bar-striped" style="background-color: #7209b7" [style.width]="connectionPercentage"></div>
        <div class="progress-bar progress-bar-striped" style="background-color: #4361ee" [style.width]="requestPercentage"></div>
        <div class="progress-bar progress-bar-striped" style="background-color: #bbb" [style.width]="serverPercentage"></div>
        <div class="progress-bar progress-bar-striped" style="background-color: #3a0ca3" [style.width]="responsePercentage"></div>
        <div class="progress-bar progress-bar-striped" style="background-color: #888" [style.width]="clientPercentage"></div>
    </div>

    <div class="font-size-sm nowrap">
        <div class="d-flex">
            <div class="mr-auto"><span class="badge badge-mark border-0 mr-1" style="background-color: #4cc9f0"></span>DNS lookup</div>
            <milliseconds [duration]="networkTrendsSummaryResponse.dnsDuration"></milliseconds>
        </div>
        <div class="d-flex">
            <div class="mr-auto"><span class="badge badge-mark border-0 mr-1" style="background-color: #7209b7"></span>Connection</div>
            <milliseconds [duration]="networkTrendsSummaryResponse.connectionDuration"></milliseconds>
        </div>
        <div class="d-flex">
            <div class="mr-auto"><span class="badge badge-mark border-0 mr-1" style="background-color: #4361ee"></span>Request send</div>
            <milliseconds [duration]="networkTrendsSummaryResponse.requestDuration"></milliseconds>
        </div>
        <div class="d-flex">
            <div class="mr-auto"><span class="badge badge-mark border-0 mr-1" style="background-color: #aaa"></span>Waiting for server</div>
            <milliseconds [duration]="networkTrendsSummaryResponse.serverDuration"></milliseconds>
        </div>
        <div class="d-flex">
            <div class="mr-auto"><span class="badge badge-mark border-0 mr-1" style="background-color: #3a0ca3"></span>Response download</div>
            <milliseconds [duration]="networkTrendsSummaryResponse.responseDuration"></milliseconds>
        </div>
        <div class="d-flex">
            <div class="mr-auto"><span class="badge badge-mark border-0 mr-1" style="background-color: #888"></span>Client processing</div>
            <milliseconds [duration]="networkTrendsSummaryResponse.clientDuration"></milliseconds>
        </div>
    </div>
</div>
