import { NetworkRequestSource } from "app/_models"
import { Injectable } from "@angular/core"

@Injectable()
export class NetworkRequestSourceFormatter {

    displayName(source: NetworkRequestSource): string {
        switch (source) {
            case NetworkRequestSource.App: return "App"
            case NetworkRequestSource.Test: return "Test runner"
            default:
                console.warn("Unknown network request source: " + source)
                return "-"
        }
    }

    networkRequestSourceWithName(name: string): NetworkRequestSource {
        if (name == this.displayName(NetworkRequestSource.App)) return NetworkRequestSource.App
        if (name == this.displayName(NetworkRequestSource.Test)) return NetworkRequestSource.Test

        console.warn("Unknown network request source: " + name)
        return null
    }

}
