import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApplicationDistribution, Build, BuildDistribution } from 'app/_models';
import { AppConfig } from 'app/app.config';
import { CollectionResponse, ElementResponse } from './response';

@Injectable({ providedIn: 'root' })
export class ApplicationDistributionsService {

    constructor(private http: HttpClient) {
    }

    getApplicationDistributions(workspaceSlug: string): Promise<CollectionResponse<ApplicationDistribution>> {
        let url = `${AppConfig.apiBaseUrl}/applicationDistributions?workspaceSlug=${workspaceSlug}`
        return this.http.get<CollectionResponse<ApplicationDistribution>>(url).toPromise()
    }

    resolveApplicationDistribution(workspaceSlug: string, applicationSlug: string): Promise<ElementResponse<ApplicationDistribution>> {
        let url = `${AppConfig.apiBaseUrl}/applicationDistributions/resolveApplication?workspaceSlug=${workspaceSlug}&applicationSlug=${applicationSlug}`
        return this.http.get<ElementResponse<ApplicationDistribution>>(url).toPromise()
    }

    getApplicationDistributionBuilds(applicationId: number): Promise<CollectionResponse<BuildDistribution>> {
        let url = `${AppConfig.apiBaseUrl}/applicationDistributions/${applicationId}/builds`
        return this.http.get<CollectionResponse<BuildDistribution>>(url).toPromise()
    }

    resolveApplicationDistributionBuild(applicationId: number, serialNumber: number): Promise<ElementResponse<BuildDistribution>> {
        let url = `${AppConfig.apiBaseUrl}/applicationDistributions/${applicationId}/resolveBuild?serialNumber=${serialNumber}`
        return this.http.get<ElementResponse<BuildDistribution>>(url).toPromise()
    }

}
