<navbar-settings></navbar-settings>

<div class="page-content">
    <settings-sidebar selectedFeatureIndex="0"></settings-sidebar>

    <!-- Main content -->
    <div class="content-wrapper">

        <!-- Content area -->
        <div class="content">

            <div class="row">
                <div class="col-md-12">
                    <alert></alert>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="card">

                        <div class="card-header">
                            <h1 class="font-weight-thin mb-0">Invite new team member</h1>
                        </div>

                        <div class="card-body" *ngIf="showInvitationForm">
                            <div *ngIf="!canCreateUsers" class="alert alert-warning alert-styled-left border-top-0 border-bottom-0 border-right-0">
                                <p class="font-weight-semibold">Subscription limits reached.</p>
                                <p>Your workspace has reached the maximum of {{workspace.limits.maximumUsers}} users. Please consider <a [routerLink]="['/settings/subscription']">upgrading your subscription</a> or <a href="mailto:support@appelium.com">contact support</a> for more information.</p>
                            </div>

                            <form [formGroup]="newInvitationForm">
                                <div class="input-group">
                                    <span class="input-group-prepend">
                                        <span class="input-group-text"><i class="icon-mention"></i></span>
                                    </span>
                                    <input type="text" formControlName="emailInput" class="form-control" placeholder="Team member email" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false">
                                    <div class="input-group-append">
                                        <button type="button" class="btn btn-light dropdown-toggle" data-toggle="dropdown">{{roleFormatter.roleName(selectedRole)}}</button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <div *ngFor="let role of invitationRoles;let i = index">
                                                <a (click)="selectedRole = role" class="dropdown-item">{{roleFormatter.roleName(role)}}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <button (click)="sendInvitation()" [disabled]="newInvitationForm.invalid || !canCreateUsers" class="btn bg-primary-400 ml-sm-2 mb-sm-0">Invite <i class="icon-paperplane ml-2"></i></button>
                                </div>
                            </form>

                            <span class="form-text text-muted">Note that the account will only become active upon accepting invitation and completing account registration. Read more about <a [href]="rolesDocumentationUrl">user roles</a>.</span>
                        </div>
                    </div>

                    <div class="card">

                        <div class="card-header">
                            <h1 class="font-weight-thin mb-0" *ngIf="membersCount">Team members</h1>
                        </div>

                        <!-- Card body -->
                        <div class="card-body">

                            <ul class="media-list mb-3">
                                <li class="media font-weight-semibold border-0 py-1" *ngIf="agent">Agent</li>

                                <li class="media hoverable" *ngIf="agent">
                                    <avatar class="member-avatar mr-3" [imageUrl]="agent.imageUrl.toString()" [fallbackText]="agent.name"></avatar>
                                    <div class="media-body">
                                        <div class="media-title font-weight-semibold">{{agent.name}}</div>
                                        <span class="text-muted">{{agent.email}}</span>
                                        <mfa-badge [mfaEnabled]="agent.mfaEnabled"></mfa-badge>
                                    </div>
                                </li>

                                <li class="media font-weight-semibold border-0 py-1" *ngIf="admins.length > 0">Admins</li>
                                <li class="media hoverable" *ngFor="let admin of admins">
                                    <avatar class="member-avatar mr-3" [imageUrl]="admin.imageUrl.toString()" [fallbackText]="admin.name"></avatar>
                                    <div class="media-body">
                                        <div class="media-title font-weight-semibold">{{admin.name}}</div>
                                        <span class="text-muted">{{admin.email}}</span>
                                        <mfa-badge [mfaEnabled]="admin.mfaEnabled"></mfa-badge>
                                    </div>
                                    <div class="align-self-center ml-3" *ngIf="canManageAdminUsers">
                                        <ul class="list-inline list-inline-dotted mb-3 mb-lg-2">
                                            <li class="list-inline-item"><button type="button" (click)="onEditMemberClick(admin)" data-toggle="modal" data-target="#userEditor" class="btn btn-link text-default">Edit</button></li>
                                        </ul>
                                    </div>
                                </li>

                                <li class="media font-weight-semibold border-0 py-1" *ngIf="members.length > 0">Members</li>
                                <li class="media hoverable" *ngFor="let member of members">
                                    <avatar class="member-avatar mr-3" [imageUrl]="member.imageUrl.toString()" [fallbackText]="member.name" ></avatar>
                                    <div class="media-body">
                                        <div class="media-title font-weight-semibold">{{member.name}}</div>
                                        <span class="text-muted">{{member.email}}</span>
                                        <mfa-badge [mfaEnabled]="member.mfaEnabled"></mfa-badge>
                                    </div>
                                    <div class="align-self-center ml-3" *ngIf="canManageMemberUsers">
                                        <ul class="list-inline list-inline-dotted mb-3 mb-lg-2">
                                            <li class="list-inline-item"><button type="button" (click)="onEditMemberClick(member)" data-toggle="modal" data-target="#userEditor" class="btn btn-link text-default">Edit</button></li>
                                        </ul>
                                    </div>
                                </li>

                                <li class="media font-weight-semibold border-0 py-1" *ngIf="testers.length > 0">Testers</li>
                                <li class="media hoverable" *ngFor="let tester of testers">
                                    <avatar class="member-avatar mr-3" [imageUrl]="tester.imageUrl.toString()" [fallbackText]="tester.name" ></avatar>
                                    <div class="media-body">
                                        <div class="media-title font-weight-semibold">{{tester.name}}</div>
                                        <span class="text-muted">{{tester.email}}</span>
                                        <mfa-badge [mfaEnabled]="tester.mfaEnabled"></mfa-badge>
                                    </div>
                                    <div class="align-self-center ml-3" *ngIf="canManageMemberUsers">
                                        <ul class="list-inline list-inline-dotted mb-3 mb-lg-2">
                                            <li class="list-inline-item"><button type="button" (click)="onEditMemberClick(tester)" data-toggle="modal" data-target="#userEditor" class="btn btn-link text-default">Edit</button></li>
                                        </ul>
                                    </div>
                                </li>

                                <li class="media font-weight-semibold border-0 py-1" *ngIf="invitations.length > 0">Pending</li>
                                <li class="media hoverable" *ngFor="let invitation of invitations">
                                    <avatar class="member-avatar mr-3" [imageUrl]="invitation.imageUrl.toString()" [fallbackText]="invitation.email" ></avatar>
                                    <div class="media-body">
                                        <div class="media-title">{{invitation.email}}</div>
                                        <div class="text-danger" *ngIf="invitationExpired(invitation)">Expired on {{invitationExpiration(invitation.expirationDate)}}</div>
                                        <div class="text-muted" *ngIf="!invitationExpired(invitation)">Expires on {{invitationExpiration(invitation.expirationDate)}}</div>
                                    </div>
                                    <div class="align-self-center ml-3" *ngIf="canManageMemberUsers">
                                        <ul class="list-inline list-inline-dotted mb-3 mb-lg-2">
                                            <li class="list-inline-item"><button type="button" (click)="resendInvitation(invitation)" class="btn btn-link">Resend</button></li>
                                            <li class="list-inline-item"><button type="button" (click)="cancelInvitation(invitation)" class="btn btn-link">Cancel</button></li>
                                        </ul>
                                    </div>
                                </li>

                            </ul>

                            <div class="text-muted" *ngIf="workspace && membersCount">Currently using {{membersCount}} out of {{workspace.limits.maximumUsers}} accounts. Need more accounts? <a href="mailto:support@appelium.com" class="text-default">Contact us</a>.</div>
                        </div>
                        <!-- /Card body -->

                    </div>

                </div>
            </div>

        </div>
        <!-- /content header -->

    </div>
    <!-- /main content -->

</div>

<user-editor #userEditor></user-editor>
