<!-- Main content -->
<div class="content-wrapper">

    <!-- Content area -->
    <div class="content">

        <div class="row">
            <div class="col-md-12">
                <alert></alert>
            </div>
        </div>

        <div class="row" *ngIf="application">
            <div class="col-md-12">
                <div class="card">

                    <div class="page-header border-bottom-light">
                        <div class="page-header-content header-elements-md-inline">
                            <div class="page-title">
                                <h1 class="font-weight-thin">
                                    <a [routerLink]="['../']" class="breadcrumb-item">{{application.name}} {{platformFormatter.platformName(application.platform)}}</a>
                                    <span class="breadcrumb-item active">Settings</span>
                                </h1>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <form [formGroup]="form">
                            <div class="form-group">
                                <div class="font-size-xs text-uppercase text-muted mb-1">Display name</div>
                                <input type="text" formControlName="applicationName" class="form-control">
                            </div>

                            <div class="form-group">
                                <div class="font-size-xs text-uppercase text-muted mb-1">Alias</div>
                                <div class="input-group">
                                    <input type="text" formControlName="applicationAlias" class="form-control" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false">
                                    <span class="input-group-append">
                                        <button (click)="copyApplicationAliasToClipboard()" class="btn btn-light" type="button"><i class="icon-copy3"></i></button>
                                    </span>
                                </div>

                                <div class="text-muted mt-1">Stable identifier, used to uniquely identify your application in REST APIs.</div>
                            </div>

                            <div class="form-group">
                                <div class="font-size-xs text-uppercase text-muted mb-1">App secret key</div>
                                <div class="input-group">
                                    <input type="text" value="{{application.token}}" class="form-control" disabled />
                                    <span class="input-group-append">
                                        <button (click)="copyAppSecretToClipboard()" class="btn btn-light" type="button"><i class="icon-copy3"></i></button>
                                    </span>
                                </div>
                                <div class="text-muted mt-1">Pass this value into SDKs during initialization.</div>
                            </div>

                            <div class="form-group">
                                <div class="font-size-xs text-uppercase text-muted mb-1">Application icon</div>
                                <div class="input-group file-caption-main">
                                    <div class="form-control">
                                        <span class="file-caption-icon"><i class="glyphicon glyphicon-file"></i></span>
                                        <div class="text-muted" *ngIf="selectedFile">{{selectedFile.name}}</div>
                                        <div class="text-muted" *ngIf="selectedFile == null">Select file...</div>
                                    </div>
                                    <div class="input-group-append">
                                        <div (click)="onBrowseApplicationIconClick()" class="btn btn-light btn-file"><i class="icon-file-plus mr-2"></i> Browse
                                            <input #fileUpload type="file" id="fileUpload" name="fileUpload" accept="image/*" class="file-input" style="display:none;">
                                        </div>
                                    </div>
                                </div>
                                <div class="text-muted mt-1">Allowed file types: .png, .jpg, .jpeg</div>
                            </div>

                            <div class="form-group">
                                <div class="font-size-xs text-uppercase text-muted mb-1">Tests success threshold</div>
                                <input type="text" formControlName="testsSuccessThreshold" class="form-control">
                                <div class="text-muted mt-1">The minimum percentage of passed tests required to mark the execution as successful for this app.</div>
                            </div>

                            <div class="form-group">
                                <div class="font-size-xs text-uppercase text-muted mb-1">Target launch duration</div>
                                <input type="text" formControlName="targetLaunchDuration" class="form-control">
                                <div class="text-muted mt-1">
                                    The target launch duration (T) will be used to calculate appdex for each app launch. An app launch occurrence is considered:
                                    <ul>
                                        <li><span class="font-weight-bold">Satisfying:</span> if its duration ≤ T</li>
                                        <li><span class="font-weight-bold">Tolerable:</span> if its duration > T and ≤ 4T</li>
                                        <li><span class="font-weight-bold">Frustrating:</span> if its duration > 4T</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="font-size-xs text-uppercase text-muted mb-1">Test plans affecting status</div>
                                <tag-input style="height: auto;" formControlName="testPlanNames" secondaryPlaceholder="Test plan name" placeholder='+ Test plan' [onTextChangeDebounce]="0" [maxItems]='10' [separatorKeyCodes]="[9, 44, 188]" [animationDuration]="{enter: '0ms', leave: '0ms'}" theme='minimal' class="form-control pl-1 py-0">
                                    <tag-input-dropdown [displayBy]="'display'" [identifyBy]="'value'" [autocompleteItems]="availableTestPlanNames"></tag-input-dropdown>
                                </tag-input>
                                <div class="text-muted mt-1">If specified, only the test executions with matching test plan will affect the app status. Use comma or tab to enter multiple values.</div>
                            </div>

                            <div class="text-right">
                                <button (click)="onDeleteApplicationClick()" type="button" class="btn bg-danger mr-2" *ngIf="canEditApplication">Delete Application</button>
                                <button (click)="saveApplication()" [disabled]="form.invalid || !canEditApplication" type="button" class="btn bg-primary-400 ml-auto">Save Changes</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>

    </div>
    <!-- /content header -->

</div>
<!-- /main content -->
