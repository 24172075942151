<div id="userEditor" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content" *ngIf="workspaceMember">

            <div class="modal-header">
                <h5 class="modal-title">Edit user</h5>
                <button #closeButton type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <alert></alert>
                    </div>
                </div>

                <form [formGroup]="form">
                    <div class="form-group">
                        <div class="font-size-xs text-uppercase text-muted mb-1">Full name:</div>
                        <input type="text" autocomplete="off" formControlName="name" class="form-control">
                    </div>

                    <div class="form-group">
                        <div class="font-size-xs text-uppercase text-muted mb-1">Role:</div>
                        <ul class="navbar-nav">
                            <li class="nav-item dropdown" style="border: 1px solid #ddd; border-radius: .1875rem;">
                                <div class="btn-group w-100">
                                    <button type="button" class="btn btn-link dropdown-toggle" style="color: black;" data-toggle="dropdown">
                                        {{roleFormatter.roleName(this.form.controls.role.value)}}
                                    </button>
                                    <div class="dropdown-menu">
                                        <div *ngFor="let role of availableRoles">
                                            <a (click)="this.form.controls.role.setValue(role)" class="dropdown-item">
                                                {{roleFormatter.roleName(role)}}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <span class="form-text text-muted">Learn more about <a [href]="rolesDocumentationUrl">user roles</a>.</span>
                    </div>
                </form>
            </div>

            <div class="modal-footer d-fles">
                <button (click)="onDeleteUserClick()" type="button" class="btn btn btn-outline-danger">Delete user</button>
                <button (click)="saveChanges()" [disabled]="form.invalid" type="button" class="btn bg-primary-400">Save changes</button>
            </div>
        </div>
    </div>
</div>
