import { Directive, Input, ElementRef, Renderer2, HostListener, Output, EventEmitter } from '@angular/core';
import { Sort } from '../util/sort';

// Inspired by:
//https://sankhadip.medium.com/how-to-sort-table-rows-according-column-in-angular-9-b04fdafb4140

@Directive({
    selector: '[appSort]'
})
export class SortDirective {

    @Input() appSort: Array<any>;
    @Input() dataFunction: any;

    constructor(private renderer: Renderer2, private targetElem: ElementRef) {
    }

    // @Output() dataFunction = new EventEmitter<any>()

    @HostListener("click")
    sortData() {
        // Create Object of Sort Class
        const sort = new Sort();

        // Get Reference Of Current Clicked Element
        const element = this.targetElem.nativeElement;

        // Get The Property Type specially set [data-type=date] if it is date field
        const customDataType = element.getAttribute("data-type");

        for (let childNode of element.parentElement.childNodes) {
            if (typeof childNode !== typeof element) { continue }
            if (childNode === element) { continue }
            if (childNode == null || childNode.classList == null) { continue }

            childNode.classList.remove('sortasc')
            childNode.classList.remove('sortdesc')
            childNode.classList.add('nosort')
        }

        element.classList.remove('nosort')
        if (element.classList.contains('sortdesc')) {
            element.classList.remove('sortdesc');
            if (this.dataFunction != null) {
                this.appSort.sort(sort.sortingFunctionWithDataFunction(this.dataFunction, "asc", customDataType));
            } else {
                // Get The Property Name from Element Attribute
                const property = element.getAttribute("data-name");
                this.appSort.sort(sort.sortingFunction(property, "asc", customDataType));
            }
            element.classList.add('sortasc');

        } else {
            element.classList.remove('sortasc');
            if (this.dataFunction != null) {
                this.appSort.sort(sort.sortingFunctionWithDataFunction(this.dataFunction, "desc", customDataType));
            } {
                // Get The Property Name from Element Attribute
                const property = element.getAttribute("data-name");
                this.appSort.sort(sort.sortingFunction(property, "desc", customDataType));
            }
            element.classList.add('sortdesc');
        }
    }
}
