import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService, AlertService, IntegrationsConfig, IntegrationsService } from 'app/_services'
import { Workspace, BackendResponse, IntegrationTrigger, IntegrationTriggerEvent, ServiceConnection, WidgetConfigurationType, BugReportsWidgetConfiguration, TestExecutionsWidgetConfiguration } from 'app/_models';
import * as bootbox from 'bootbox'
import { EditIntegrationTriggerModal } from './edit.integration.trigger.modal';
import { Title } from '@angular/platform-browser';

@Component({
    templateUrl: 'integrations.component.html'
})
export class IntegrationsComponent implements OnInit {

    workspace: Workspace
    integrationConfig: IntegrationsConfig

    serviceConnections: ServiceConnection[] = []
    integrationTriggerEvent = IntegrationTriggerEvent

    @ViewChild('editIntegrationTriggerModal') editIntegrationTriggerModal: EditIntegrationTriggerModal

    constructor(
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private integrationsService: IntegrationsService,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.titleService.setTitle("Settings | Integrations")
        this.workspace = this.authenticationService.currentWorkspaceValue
        this.reloadConfig()
        this.reloadServiceConnections()
    }

    private reloadConfig() {
        this.integrationsService.getConfig(this.workspace).then((response) => {
            this.integrationConfig = response

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    onCreateIntegrationTriggerButtonClick(serviceConnection: ServiceConnection) {
        this.editIntegrationTriggerModal.serviceConnection = serviceConnection
        this.editIntegrationTriggerModal.integrationTrigger = null
    }

    onDeleteServiceConnectionButtonClick(serviceConnection: ServiceConnection) {
        let weakThis = this

        bootbox.dialog({
            title: `Delete Integration?`,
            message: "The integration with all configured triggers will be permanently deleted. You can always create a new one.",
            size: 'small',
            buttons: {
                cancel: { label: 'Cancel', className: 'btn-link' },
                delete: { label: 'Delete', className: 'btn-danger', callback: function() {
                    weakThis.deleteServiceConnection(serviceConnection)
                }}
            }
        })
    }

    onEditIntegrationTriggerButtonClick(serviceConnection: ServiceConnection, integrationTrigger: IntegrationTrigger) {
        this.editIntegrationTriggerModal.serviceConnection = serviceConnection
        this.editIntegrationTriggerModal.integrationTrigger = integrationTrigger
    }

    onDeleteIntegrationTriggerButtonClick(integrationTrigger: IntegrationTrigger) {
        let weakThis = this

        bootbox.dialog({
            title: `Delete Trigger?`,
            message: "The trigger will be deactivated and removed.",
            size: 'small',
            buttons: {
                cancel: { label: 'Cancel', className: 'btn-link' },
                delete: {
                    label: 'Delete', className: 'btn-danger', callback: function () {
                        weakThis.deleteIntegrationTrigger(integrationTrigger)
                    }
                }
            }
        })
    }

    onIntegrationTriggerCreated() {
        this.reloadServiceConnections()
    }

    onIntegrationTriggerUpdated() {
        this.reloadServiceConnections()
    }

    onWebhookCreated() {
        this.reloadServiceConnections()
    }

    applicationsFilterSummary(integrationTrigger: IntegrationTrigger): string {
        var enabledCount = 0
        integrationTrigger.applicationFilters.forEach(filter => {
            if (filter.isEnabled) {
                enabledCount += 1
            }
        })

        if (enabledCount == 0) {
            return "Disabled for all applications"

        } else if (enabledCount == integrationTrigger.applicationFilters.length) {
            return "Enabled for all applications"

        } else {
            return `Enabled for ${enabledCount} out of ${integrationTrigger.applicationFilters.length} applications`
        }
    }

    private reloadServiceConnections() {
        this.integrationsService.getWorkspaceServiceConnections(this.workspace).then((response) => {
            this.serviceConnections = response.data

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private deleteServiceConnection(serviceConnection: ServiceConnection) {
        this.integrationsService.deleteServiceConnection(serviceConnection).then((response) => {
            this.reloadServiceConnections()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private deleteIntegrationTrigger(integrationTrigger: IntegrationTrigger) {
        this.integrationsService.deleteIntegrationTrigger(integrationTrigger).then((response) => {
            this.reloadServiceConnections()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
