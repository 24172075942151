export enum NetworkTrendType {
    Appdex = 'APPDEX',
    DurationAvg = 'DURATION_AVG',
    DurationP90 = 'DURATION_P90',
    DurationP95 = 'DURATION_P95',
    DurationP99 = 'DURATION_P99',
    Requests = 'REQUESTS',
    Errors = 'ERRORS',
    Payload = 'PAYLOAD',
}
