<div *ngIf="networkTrendsSummaryResponse">
    <div class="progress noshadow mb-1" style="height: 22px; width: 180px;">
        <div class="progress-bar progress-bar-striped" style="background-color: #45ba78" [style.width]="overallSuccessPercentage"></div>
        <div class="progress-bar progress-bar-striped" style="background-color: #E53935" [style.width]="overallErrorPercentage"></div>
    </div>

    <div class="d-flex">
        <div class="mr-auto"><span class="badge badge-mark border-0 mr-1" style="background-color: #45ba78"></span>Success rate</div>
        <percentage [value]="1 - networkTrendsSummaryResponse.overalErrorRate"></percentage>
    </div>
    <div class="d-flex">
        <div class="mr-auto"><span class="badge badge-mark border-0 mr-1" style="background-color: #E53935"></span>Error rate</div>
        <percentage [value]="networkTrendsSummaryResponse.overalErrorRate"></percentage>
    </div>

    <hr class="p-0 my-1">

    <div class="font-weight-semibold mb-1">Errors breakdown</div>
    <div class="progress noshadow mb-1" style="height: 22px; width: 180px;">
        <div class="progress-bar progress-bar-striped" style="background-color: #777" [style.width]="clientPercentage"></div>
        <div class="progress-bar progress-bar-striped" style="background-color: #FFA726" [style.width]="businessPercentage"></div>
        <div class="progress-bar progress-bar-striped" style="background-color: #E53935" [style.width]="serverPercentage"></div>
    </div>

    <div class="font-size-sm nowrap">
        <div class="d-flex">
            <div class="mr-auto"><span class="badge badge-mark border-0 mr-1" style="background-color: #777"></span>Client</div>
            <percentage [value]="networkTrendsSummaryResponse.clientErrorsShare"></percentage>
        </div>
        <div class="d-flex">
            <div class="mr-auto"><span class="badge badge-mark border-0 mr-1" style="background-color: #FFA726"></span>Business</div>
            <percentage [value]="networkTrendsSummaryResponse.businessErrorsShare"></percentage>
        </div>
        <div class="d-flex">
            <div class="mr-auto"><span class="badge badge-mark border-0 mr-1" style="background-color: #E53935"></span>Server</div>
            <percentage [value]="networkTrendsSummaryResponse.serverErrorsShare"></percentage>
        </div>
    </div>
</div>
