import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import * as Dropzone from 'dropzone';

@Component({
    selector: 'dropzone',
    templateUrl: 'dropzone.component.html'
})

export class DropzoneComponent implements OnInit {

    private dropzone: Dropzone
    selectedFile: Dropzone.DropzoneFile

    _uploadParamName: string
    @Input()
    set uploadParamName(uploadParamName: string) {
        this._uploadParamName = uploadParamName
        this.reset()
    }
    get uploadParamName() { return this._uploadParamName }

    @Input() uploadParams?: {}

    _uploadUrl: string
    @Input()
    set uploadUrl(uploadUrl: string) {
        this._uploadUrl = uploadUrl
        this.reset()
    }
    get uploadUrl() { return this._uploadUrl }

    @Output() fileAdded = new EventEmitter()
    @Output() uploadSucceeded = new EventEmitter()
    @Output() uploadFailed = new EventEmitter<string>()

    progressVisible = false
    percentageUploaded = "0%"
    uploadTitle = "Uploading..."

    constructor(private ref: ChangeDetectorRef) { }

    ngOnInit() {
        let dz = Dropzone
        dz.autoDiscover = false

        this.setupDropzone()
    }

    reset() {
        this.destroyDropzone()
        this.setupDropzone()
    }

    startUpload(additionalUploadParams: Object | null) {
        if (!this.selectedFile) { return }

        this.progressVisible = true
        this.percentageUploaded = "0%"
        this.uploadTitle = `Uploading ${this.selectedFile.name}`

        $("div#dropzoneContainer").empty()
        if (additionalUploadParams) {
            this.dropzone.options.params = additionalUploadParams
        }
        this.dropzone.uploadFile(this.selectedFile)

        this.ref.detectChanges()
    }

    private setupDropzone() {
        if (this.dropzone != null) {
            this.destroyDropzone()
        }

        if (this.uploadUrl == null || this.uploadParamName == null) {
            return
        }

        this.progressVisible = false
        this.percentageUploaded = "0%"
        this.uploadTitle = "Uploading..."
        this.ref.detectChanges()

        let dropzoneContainer = $("div#dropzoneContainer")
        dropzoneContainer.append('<div id="dropzone" class="dropzone"></div>')

        let currentUser = JSON.parse(localStorage.getItem('currentUser'))
        var authHeader = ""
        if (currentUser && currentUser.authenticationToken) {
            authHeader = `Bearer ${currentUser.authenticationToken}`
        }

        let previewTemplate = `
        <div class="align-items-center text-center" style="margin: auto;">
            <i class="icon-file-empty icon-3x text-slate-400 mb-2"></i>
            <div class="dz-details text-center">
                <div class="dz-filename font-weight-semibold"><span data-dz-name></span></div>
                <div class="dz-size"><span data-dz-size></span></div>
            </div>
        </div>
        `

        this.dropzone = new Dropzone("div#dropzone", {
            url: this.uploadUrl,
            method: "POST",
            paramName: this.uploadParamName,
            maxFilesize: 200, // MB
            maxFiles: 1,
            headers: { "cache-control": null, "Authorization": authHeader },
            params: this.uploadParams,
            previewTemplate: previewTemplate,
            dictDefaultMessage: `Drop a file here or click to upload`,
            uploadMultiple: false,
            autoProcessQueue: false
        })

        var weakThis = this

        this.dropzone.on("addedfile", (file) => {
            this.selectedFile = file
            this.fileAdded.emit()
        })

        this.dropzone.on("error", (file, message: any) => {
            if (message.error != null && message.error.title != null) {
                this.uploadFailed.emit(message.error.title)
            } else {
                this.uploadFailed.emit(message.toString())
            }

            setTimeout(() => {
                this.reset()
            }, 1000)
        })

        this.dropzone.on("success", (file) => {
            this.uploadSucceeded.emit()

            setTimeout(() => {
                this.reset()
            }, 1000)
        })

        this.dropzone.on("totaluploadprogress", (progress) => {
            this.percentageUploaded = `${progress.toFixed(1)}%`
            weakThis.ref.detectChanges()
        })

        this.ref.detectChanges()
    }

    destroyDropzone() {
        if (this.dropzone == null) {
            return
        }

        this.dropzone.removeAllFiles()
        this.dropzone.destroy()
        this.dropzone = null

        let dropzoneContainer = $("div#dropzoneContainer")
        dropzoneContainer.empty()
    }

}
