import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'app/app.config';
import { Application, ApplicationVersion, BackendEnvironment, BackendEnvironmentVersion, NetworkDimension, NetworkError, NetworkFilter, NetworkHost, NetworkInterface, NetworkOperation, NetworkProtocol, NetworkRequestSource, NetworkTrendsResponse, NetworkTrendsSummaryResponse, NetworkTrendType, TestExecutionSummary, TestRun } from 'app/_models';
import { NetworkRequestLog, NetworkResponseLog } from 'app/_models/console.logs.response';

export class NetworkFiltersResponse {
    environments: BackendEnvironment[]
    environmentVersions: BackendEnvironmentVersion[]
    applications: Application[]
    applicationVersions: ApplicationVersion[]
    networkOperations: NetworkOperation[]
    networkErrors: NetworkError[]
    networkProtocols: NetworkProtocol[]
    networkHosts: NetworkHost[]
    networkInterfaces: NetworkInterface[]
    responseStatusCodes: number[]
}

export class NetworkLogSearchResult {
    request: NetworkRequestLog | null
    response: NetworkResponseLog
    testRun: TestRun
    testExecution: TestExecutionSummary
    application: Application
}

export class NetworkLogsSearchResultsResponse {
    result: NetworkLogSearchResult
    alternativeResult: NetworkLogSearchResult | null
}

export class NetworkRequestResolverResponse {
    networkOperationId: number
}

@Injectable({ providedIn: 'root' })
export class NetworkOperationsService {

    constructor(private http: HttpClient) {
    }

    getNetworkOperation(id: number): Promise<NetworkOperation> {
        return this.http.get<NetworkOperation>(`${AppConfig.apiBaseUrl}/networkOperations/${id}`).toPromise()
    }

    getApplicationNetworkFilters(applicationId: number): Promise<NetworkFiltersResponse> {
        return this.http.get<NetworkFiltersResponse>(`${AppConfig.apiBaseUrl}/applications/${applicationId}/networkFilters`).toPromise()
    }

    getWorkspaceNetworkFilters(workspaceId: number): Promise<NetworkFiltersResponse> {
        return this.http.get<NetworkFiltersResponse>(`${AppConfig.apiBaseUrl}/workspaces/${workspaceId}/networkFilters`).toPromise()
    }

    getNetworkTrends(workspaceId: number, type: NetworkTrendType, filter: NetworkFilter): Promise<NetworkTrendsResponse> {
        let url = `${AppConfig.apiBaseUrl}/workspaces/${workspaceId}/networkTrends?type=${type}`
        url = this.urlByApplyingNetworkFilter(url, filter)

        return this.http.get<NetworkTrendsResponse>(url).toPromise()
    }

    getNetworkTrendsSummary(workspaceId: number, type: NetworkTrendType, filter: NetworkFilter): Promise<NetworkTrendsSummaryResponse> {
        let url = `${AppConfig.apiBaseUrl}/workspaces/${workspaceId}/networkTrendsSummary?type=${type}`
        url = this.urlByApplyingNetworkFilter(url, filter)

        return this.http.get<NetworkTrendsSummaryResponse>(url).toPromise()
    }

    getNetworkSegments(workspaceId: number, dimension: NetworkDimension, filter: NetworkFilter): Promise<Object> {
        var url = `${AppConfig.apiBaseUrl}/workspaces/${workspaceId}/networkSegments?dimension=${dimension}`
        url = this.urlByApplyingNetworkFilter(url, filter)

        return this.http.get<Object>(url).toPromise()
    }

    findNetworkLogs(workspaceId: number, filter: NetworkFilter): Promise<NetworkLogsSearchResultsResponse> {
        var url = `${AppConfig.apiBaseUrl}/workspaces/${workspaceId}/findNetworkLogs?`
        url = this.urlByApplyingNetworkFilter(url, filter)

        return this.http.get<NetworkLogsSearchResultsResponse>(url).toPromise()
    }

    resolveNetworkRequest(workspaceId: number, requestMethod: string, requestUrl: string): Promise<NetworkRequestResolverResponse> {
        let url = `${AppConfig.apiBaseUrl}/workspaces/${workspaceId}/resolveNetworkRequest`

        let requestBody = {
            method: requestMethod,
            url: requestUrl
        }

        return this.http.post<NetworkRequestResolverResponse>(url, requestBody).toPromise()
    }

    updateNetworkOperation(networkOperationId: number, name: string | null, annotation: string | null, targetDuration: number, updateAllNetworkOperations: boolean): Promise<Object> {
        let requestBody = {
            name: name,
            annotation: annotation,
            targetDuration: targetDuration,
            updateAllNetworkOperations: updateAllNetworkOperations
        }
        return this.http.patch(`${AppConfig.apiBaseUrl}/networkOperations/${networkOperationId}`, requestBody).toPromise()
    }

    private urlByApplyingNetworkFilter(baseUrl: string, filter: NetworkFilter): string {
        var url = baseUrl

        url += `&startDate=${filter.startDate.toISOString()}`
        url += `&endDate=${filter.endDate.toISOString()}`

        if (filter.source != null) { url += `&source=${filter.source}` }
        if (filter.applicationId != null) { url += `&applicationId=${filter.applicationId}` }
        if (filter.testExecutionId != null) { url += `&testExecutionId=${filter.testExecutionId}` }
        if (filter.applicationVersionId != null) { url += `&applicationVersionId=${filter.applicationVersionId}` }
        if (filter.backendEnvironmentVersionId != null) { url += `&backendEnvironmentVersionId=${filter.backendEnvironmentVersionId}` }
        if (filter.backendEnvironmentId != null) { url += `&backendEnvironmentId=${filter.backendEnvironmentId}` }
        if (filter.networkInterfaceId != null) { url += `&networkInterfaceId=${filter.networkInterfaceId}` }
        if (filter.networkOperationId != null) { url += `&networkOperationId=${filter.networkOperationId}` }
        if (filter.networkErrorId != null) { url += `&networkErrorId=${filter.networkErrorId}` }
        if (filter.networkProtocolId != null) { url += `&networkProtocolId=${filter.networkProtocolId}` }
        if (filter.networkHostId != null) { url += `&networkHostId=${filter.networkHostId}` }
        if (filter.responseStatusCode !== null && filter.responseStatusCode !== undefined) { url += `&responseStatusCode=${filter.responseStatusCode}` }

        return url
    }

}
