<div id="distributeBuildModal" class="modal fade" tabindex="-1">
    <div class="modal-dialog" *ngIf="build && application">
        <div class="modal-content">

            <div class="modal-header">
                <h5 class="modal-title">Distribute build</h5>
                <button #closeButton type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="row">
                    <div class="col-md-12">
                        <alert></alert>
                    </div>
                </div>

                <h4 class="font-weight-thin mb-1">Add distribution groups as destinations</h4>

                <form [formGroup]="form">
                    <div class="input-group mb-2">
                        <tag-input style="height: auto;" formControlName="distributionGroups" secondaryPlaceholder="Add group" placeholder='+ Add group' [onTextChangeDebounce]="0" [maxItems]='10' [separatorKeyCodes]="[9, 44, 188]" [animationDuration]="{enter: '0ms', leave: '0ms'}" theme='minimal' class="form-control pl-1 py-0">
                            <tag-input-dropdown [displayBy]="'display'" [identifyBy]="'value'" [autocompleteItems]="distributionGroupNames"></tag-input-dropdown>
                        </tag-input>

                        <button (click)="submitForm()" [disabled]="form.invalid" class="btn bg-primary-400 ml-sm-2 mb-sm-0">Distribute <i class="icon-paperplane ml-2"></i></button>
                    </div>

                    <div class="input-group">
                        <label><input formControlName="notifyTesters" type="checkbox"> Notify testers</label>
                    </div>
                </form>

                <span class="form-text text-muted">The build will become immediately available to all members of selected distribution groups.</span>
            </div>

        </div>
    </div>
</div>
