export enum ProvisioningProfileType {
    IosDevelopment = 1,
    IosAdhoc = 2,
    IosInHouse = 3,
    IosAppStore = 4
}

export class RawFile {
    name: string
    url: string
    fileSize: number
}

export class ProvisioningProfile {
    id: string
    name: string
    type: ProvisioningProfileType
    appIdName: string
    teamId: string
    teamName: string
    creationDate: Date
    expirationDate: Date
    provisionedDevices: String[]
    entitlements: string
    file: RawFile
}
