<div class="content-wrapper" (scroll)="onPageContentScroll($event)" #pageContent>

    <div class="content">
        <div class="row">
            <div class="col-md-12">
                <alert></alert>
            </div>
        </div>

        <div class="row" *ngIf="backendEnvironment && testExecutions && testPlans && applications">
            <div class="col-md-12">
                <div class="card">
                    <div class="page-header border-bottom-light">
                        <div class="page-header-content header-elements-md-inline">
                            <div class="page-title">
                                <h1 class="font-weight-thin">
                                    <span class="breadcrumb-item">{{backendEnvironment.name}}</span>
                                    <span class="breadcrumb-item active">Test Executions</span>
                                </h1>
                            </div>
                        </div>
                    </div>

                    <div class="card-body d-flex align-items-center" *ngIf="showFilters">
                        <h5 class="mb-0 pb-0 mr-2">Filters</h5>

                        <div class="form-group mb-0 d-flex mr-1" *ngIf="applications.length > 1">
                            <select id="applicationSelect" select2 data-placeholder="App" data-allow-clear="true" class="form-control select2">
                                <option *ngFor="let application of applications">{{application.name}} {{platformFormatter.platformName(application.platform)}}</option>
                            </select>
                        </div>

                        <div class="form-group mb-0 d-flex mr-3" *ngIf="testPlans.length > 1">
                            <select id="testPlanSelect" select2 data-placeholder="Test plan" data-allow-clear="true" class="form-control select2">
                                <option *ngFor="let testPlan of testPlans" [ngValue]="testPlan.name">{{testPlan.name}}</option>
                            </select>
                        </div>
                    </div>

                    <table class="table">
                        <thead>
                            <tr role="row">
                                <th class="border-0">Date</th>
                                <th class="border-0 nowrap">App</th>
                                <th class="border-0 nowrap">Version</th>
                                <th class="border-0 nowrap">Test plan</th>
                                <th class="border-0" style="width: 50%">Results</th>
                                <th class="border-0">Duration</th>
                                <th class="border-0 text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let testExecution of testExecutions" my-tr [showEnvironmentColumn]="false" [showAppColumn]="true" [testExecution]="testExecution" (navigateToTestExecution)="navigateToTestExecution($event)" (testExecutionShouldDelete)="testExecutionShouldDelete($event)"></tr>
                        </tbody>
                    </table>
                </div>

                <div class="text-center">
                    <div class="text-muted d-block" *ngIf="isLoadingMoreRecords">
                        <i class="icon-spinner4 spinner mr-2"></i> Loading...
                    </div>
                    <div class="text-muted d-block" *ngIf="!isLoadingMoreRecords && !hasMoreRecords">
                        Showing all matching test executions
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>
