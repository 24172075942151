import { Component, Input } from '@angular/core';
import { XCTQueryLog } from 'app/_models/console.logs.response';

@Component({
    selector: 'xct-query-log-content',
    templateUrl: 'xct.query.log.content.html'
})
export class XCTQueryLogContent {

    @Input() public log: XCTQueryLog | null
    logId = Math.floor((Math.random() * 1_000_000))

    constructor(
    ) {
    }

}
