import { Injectable } from "@angular/core";
import { TimeFilter } from "app/_models";

@Injectable()
export class TimeFilterFormatter {

    longFormat(timeFilter: TimeFilter): string {
        switch (timeFilter) {
            case TimeFilter.OneHour: return "1 hour"
            case TimeFilter.OneDay: return "1 day"
            case TimeFilter.OneMonth: return "1 month"
            case TimeFilter.OneWeek: return "1 week"
            case TimeFilter.OneYear: return "1 year"
            case TimeFilter.SixMonths: return "6 months"
            case TimeFilter.ThreeMonths: return "3 months"
            case TimeFilter.TwoMonths: return "2 months"
            case TimeFilter.TwoWeeks: return "2 weeks"
            default: return timeFilter
        }
    }
}
