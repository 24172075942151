import { NetworkDimension, NetworkSegment } from 'app/_models';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateFormatter, NetworkInterfaceFormatter, PlatformFormatter } from 'app/_helpers';

@Component({
    selector: 'network-segments',
    templateUrl: 'network.segments.component.html',
    styleUrls: ['network.segments.component.css']
})

export class NetworkSegmentsComponent {

    NetworkDimension = NetworkDimension

    @Input() networkDimension: NetworkDimension
    @Input() networkSegments: NetworkSegment[]
    @Input() allowFiltering: boolean = true

    @Output() onFindNetworkLog = new EventEmitter<NetworkSegment>()
    @Output() onEditNetworkOperation = new EventEmitter<number>()

    constructor(
        public dateFormatter: DateFormatter,
        public platformFormatter: PlatformFormatter,
        public networkInterfaceFormatter: NetworkInterfaceFormatter,
    ) {
    }

    totalFailuresCount(networkSegment: NetworkSegment): number {
        return networkSegment.clientErrors + networkSegment.serverErrors
    }

    findNetworkLog(networkSegment: NetworkSegment) {
        this.onFindNetworkLog.emit(networkSegment)
    }

    editNetworkOperation(networkOperationId: number) {
        this.onEditNetworkOperation.emit(networkOperationId)
    }

}
