<div class="border-bottom-light bg-light logs-header px-3 py-1">
    <div class="d-flex align-items-center">
        <i class="icon-filter3 text-grey-300"></i>

        <!-- Sources dropdown -->
        <div class="dropdown" *ngIf="showSources">
            <a href="#" class="btn btn-link text-default dropdown-toggle" data-toggle="dropdown">
                Sources | <span class="font-weight-bold">{{sourcesSummaryText()}}</span>
            </a>
            <div class="dropdown-menu" x-placement="bottom-start">
                <a href="javascript:void(0)" class="dropdown-item" (click)="toggleApplicationLogs()" *ngIf=hasApplicationLogs>
                    <samp class="badge mr-2" [ngClass]="{ 'badge-primary' : applicationLogsEnabled, 'badge-light' : !applicationLogsEnabled }" style="font-size: 100%;">A</samp> App
                </a>
                <a href="javascript:void(0)" class="dropdown-item" (click)="toggleTestLogs()" *ngIf="hasTestLogs">
                    <samp class="badge mr-2" [ngClass]="{ 'badge-primary' : testLogsEnabled, 'badge-light' : !testLogsEnabled }" style="font-size: 100%;">T</samp> Test
                </a>
                <a href="javascript:void(0)" class="dropdown-item" (click)="toggleLogcatLogs()" *ngIf="hasLogcatLogs">
                    <samp class="badge mr-2" [ngClass]="{ 'badge-primary' : logcatLogsEnabled, 'badge-light' : !logcatLogsEnabled }" style="font-size: 100%;">L</samp> Logcat
                </a>
            </div>
        </div>

        <!-- Log level dropdown -->
        <div class="dropdown">
            <a href="#" class="btn btn-link text-default dropdown-toggle" data-toggle="dropdown">
                Console Logs | <span class="font-weight-bold">{{logLevelFormatter.displayName(selectedLogLevel)}}</span>
            </a>
            <div class="dropdown-menu" x-placement="bottom-start">
                <a *ngFor="let logLevel of allLogLevels" href="javascript:void(0)" class="dropdown-item" [ngClass]="{ 'active' : selectedLogLevel == logLevel }" (click)="setConsoleLogLevel(logLevel)">
                    <samp class="badge badge-light mr-2" style="font-size: 100%;">{{logLevelFormatter.symbol(logLevel)}}</samp> {{logLevelFormatter.displayName(logLevel)}}
                </a>
            </div>
        </div>

        <!-- Test logs picker -->
        <div class="dropdown" *ngIf="testLogsEnabled && hasXCTestLogs">
            <a href="#" class="btn btn-link text-default dropdown-toggle" data-toggle="dropdown">
                XCTest logs | <span class="font-weight-bold">{{xctLogsSummaryText()}}</span>
            </a>
            <div class="dropdown-menu" x-placement="bottom-start">
                <a href="javascript:void(0)" class="dropdown-item" (click)="toggleXCTActionLogs()">
                    <samp class="badge mr-2" [ngClass]="{ 'badge-primary' : xctActionLogsEnabled, 'badge-light' : !xctActionLogsEnabled }"><i class="icon-touch"></i></samp> Actions
                </a>
                <a href="javascript:void(0)" class="dropdown-item" (click)="toggleXCTQueryLogs()" *ngIf="hasTestLogs">
                    <samp class="badge mr-2" [ngClass]="{ 'badge-primary' : xctQueryLogsEnabled, 'badge-light' : !xctQueryLogsEnabled }"><i class="icon-screen-full"></i></samp> Queries
                </a>
            </div>
        </div>

        <!-- Time style dropdown -->
        <div class="dropdown">
            <a href="#" class="btn btn-link text-default dropdown-toggle" data-toggle="dropdown">
                Time style | <span class="font-weight-bold">{{timeStyleFormatter.displayName(selectedTimeStyle)}}</span>
            </a>
            <div class="dropdown-menu" x-placement="bottom-start">
                <a href="javascript:void(0)" class="dropdown-item" [ngClass]="{ 'active' : selectedTimeStyle == timeStyle.Absolute }" (click)="setTimeStyle(timeStyle.Absolute)">{{timeStyleFormatter.displayName(timeStyle.Absolute)}}</a>
                <a href="javascript:void(0)" class="dropdown-item" [ngClass]="{ 'active' : selectedTimeStyle == timeStyle.Relative }" (click)="setTimeStyle(timeStyle.Relative)">{{timeStyleFormatter.displayName(timeStyle.Relative)}}</a>
            </div>
        </div>

    </div>
    <div class="my-1" *ngIf="videoFile">
        <video-timeline [videoTimelineFile]="videoTimelineFile" [position]="timelinePosition" (positionChanged)="onTimelinePositionChanged($event)"></video-timeline>

        <div class="mt-1">
            <button type="button" class="btn btn-link current-time-btn p-0 text-default align-items-baseline font-size-sm font-weight-light" (click)="onTimeButtonClick()">
                <samp *ngIf="currentVideoTime">
                    <span class="text-default">{{currentVideoTime}}</span>
                    <span class="text-default mx-1">/</span>
                    <span class="text-muted">{{totalVideoTime}}</span>
                </samp>

                <i class="icon-arrow-right14 text-default ml-1"></i>
            </button>
        </div>
    </div>
</div>

<div class="d-flex pl-1 pr-2 pt-2 pb-2" [@fadeInOutAnimation] *ngIf="filteredLogs">
    <i id="timeMarker" class="icon-play4 text-danger time-marker mr-1" [style.visibility]="selectedTimelineLog ? 'visible' : 'hidden'"></i>

    <table class="w-100">
        <thead>
            <td></td>
            <td *ngIf="showSources"></td>
            <td></td>
            <td class="w-100"></td>
        </thead>
        <tbody>
            <tr id="{{logIdentifier(log)}}" *ngFor="let log of filteredLogs">
                <ng-container *ngIf="log.type == 'CONSOLE'">
                    <td class="log-type">
                        <i class="icon-terminal text-grey-300 mr-1"></i>
                    </td>
                    <td class="log-date" (click)="onLogDateClick(log)">
                        <samp class="font-size-sm text-muted mr-2">{{logTime(log)}}</samp>
                    </td>
                    <td class="log-source" *ngIf="showSources">
                        <log-source [source]="log.source"></log-source>
                    </td>
                    <td>
                        <console-log-content [log]="log"></console-log-content>
                    </td>
                </ng-container>
                <ng-container *ngIf="log.type == 'NETWORK_RESPONSE'">
                    <td class="log-type">
                        <i class="icon-power3 text-grey-300 mr-1"></i>
                    </td>
                    <td class="log-date" (click)="onLogDateClick(log)">
                        <samp class="font-size-sm text-muted mr-2">{{logTime(log)}}</samp>
                    </td>
                    <td class="log-source" *ngIf="showSources">
                        <log-source [source]="log.source"></log-source>
                    </td>
                    <td>
                        <network-log-content (analyzeRequestHandler)="onAnalyzeRequest($event)" [requestLog]="networkRequestLogWithId(log.requestId)" [responseLog]="log"></network-log-content>
                    </td>
                </ng-container>
                <ng-container *ngIf="log.type == 'APPLICATION' && log.event != 'SCREEN_WILL_APPEAR'">
                    <td class="log-type">
                        <i class="icon-mobile2 text-grey-300 mr-1"></i>
                    </td>
                    <td class="log-date" (click)="onLogDateClick(log)">
                        <samp class="font-size-sm text-muted mr-2">{{logTime(log)}}</samp>
                    </td>
                    <td class="log-source" *ngIf="showSources">
                        <log-source [source]="log.source"></log-source>
                    </td>
                    <td>
                        <application-log-content [log]="log"></application-log-content>
                    </td>
                </ng-container>
                <ng-container *ngIf="log.type == 'XCT_ACTION'">
                    <td class="log-type">
                        <i class="icon-touch text-grey-300 mr-1"></i>
                    </td>
                    <td class="log-date" (click)="onLogDateClick(log)">
                        <samp class="font-size-sm text-muted mr-2">{{logTime(log)}}</samp>
                    </td>
                    <td class="log-source" *ngIf="showSources">
                        <log-source [source]="log.source"></log-source>
                    </td>
                    <td>
                        <xct-action-log-content [log]="log"></xct-action-log-content>
                    </td>
                </ng-container>
                <ng-container *ngIf="log.type == 'XCT_QUERY'">
                    <td class="log-type">
                        <i class="icon-screen-full text-grey-300 mr-1"></i>
                    </td>
                    <td class="log-date" (click)="onLogDateClick(log)">
                        <samp class="font-size-sm text-muted mr-2">{{logTime(log)}}</samp>
                    </td>
                    <td class="log-source" *ngIf="showSources">
                        <log-source [source]="log.source"></log-source>
                    </td>
                    <td>
                        <xct-query-log-content [log]="log"></xct-query-log-content>
                    </td>
                </ng-container>
                <ng-container *ngIf="log.type == 'NETWORK_STATE'">
                    <td class="log-type">
                        <i class="icon-power3 text-grey-300 mr-1"></i>
                    </td>
                    <td class="log-date" (click)="onLogDateClick(log)">
                        <samp class="font-size-sm text-muted mr-2">{{logTime(log)}}</samp>
                    </td>
                    <td class="log-source" *ngIf="showSources">
                        <log-source [source]="log.source"></log-source>
                    </td>
                    <td>
                        <network-state-log-content [log]="log"></network-state-log-content>
                    </td>
                </ng-container>
            </tr>
        </tbody>
    </table>
</div>

<div class="card-body pt-0 pb-2" *ngIf="filteredLogs">
    <div class="d-flex align-items-center mt-2">
        <ng-container *ngIf="showSources">
            <samp class="badge badge-light" style="font-size: 100%;">A</samp> <span class="ml-1 mr-2">App logs</span>
            <samp class="badge badge-light" style="font-size: 100%;">T</samp> <span class="ml-1 mr-3">Test logs</span>
        </ng-container>
        <ng-container *ngFor="let logLevel of allLogLevels">
            <samp class="badge badge-light" style="font-size: 100%;">{{logLevelFormatter.symbol(logLevel)}}</samp> <span class="ml-1 mr-2">{{logLevelFormatter.displayName(logLevel)}}</span>
        </ng-container>
    </div>
</div>
