import { Component, OnInit } from '@angular/core';
import { AlertService, UserService, AuthenticationService } from 'app/_services';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { BackendResponse } from 'app/_models';
import { RouteHelper } from 'app/_helpers';
import { Title } from '@angular/platform-browser';

@Component({ templateUrl: 'password.recovery.component.html' })
export class PasswordRecoveryComponent implements OnInit {

    requestPasswordResetForm: FormGroup
    newPasswordForm: FormGroup

    showRequestPasswordResetForm = true
    showNewPasswordForm = false

    // Will be set to true when OTP is required
    showOneTimePasswordInput: boolean = false

    // Will be set to true when 2FA setup is required before logging in
    twoFactorAuthenticationSecret: string
    twoFactorAuthenticationUrl: string

    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private routeHelper: RouteHelper,
        private alertService: AlertService,
        private titleService: Title
    ) {
        this.requestPasswordResetForm = this.formBuilder.group({
            email: new FormControl('', Validators.compose([Validators.required])),
        })

        this.newPasswordForm = this.formBuilder.group({
            passwordResetCode: new FormControl('', Validators.compose([Validators.required])),
            newPassword: new FormControl('', Validators.compose([Validators.required, Validators.minLength(12)])),
            twoFactorAuthenticationToken: [null, null]
        })
    }

    ngOnInit() {
        this.titleService.setTitle("Password Recovery")
    }

    onRequestPasswordResetFormSubmit() {
        let email = this.requestPasswordResetForm.controls.email.value
        this.userService.requestPasswordReset(email).then((response) => {
            this.alertService.success(`Password reset email successfully sent to ${email}.`)
            this.showRequestPasswordResetForm = false
            this.showNewPasswordForm = true

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    onNewPasswordFormSubmit() {
        let email = this.requestPasswordResetForm.controls.email.value
        let passwordResetCode = this.newPasswordForm.controls.passwordResetCode.value
        let newPassword = this.newPasswordForm.controls.newPassword.value
        let twoFactorAuthenticationToken = this.newPasswordForm.controls.twoFactorAuthenticationToken.value

        this.userService.resetPassword(email, passwordResetCode, newPassword, twoFactorAuthenticationToken).then((response) => {
            this.authenticationService.setUserAuthenticated(response)
            this.router.navigate([this.routeHelper.currentWorkspaceHome()])

            this.alertService.success("Password successfully updated.")


        }).catch((error) => {
            if (error.status == 0 || (error.error as BackendResponse).error == null) {
                this.alertService.handleError(error)
                return
            }

            let backendResponse = error.error as BackendResponse

            // Invalid 2FA token
            if (backendResponse.error.httpStatus == 406) {
                this.alertService.error(backendResponse.error.title)
                this.newPasswordForm.controls.twoFactorAuthenticationToken.reset(null)

            // User is required to set up 2FA
            } else if (backendResponse.error.httpStatus == 412) {
                this.alertService.error(backendResponse.error.title)

                this.newPasswordForm.controls.passwordResetCode.disable()
                this.newPasswordForm.controls.newPassword.disable()

                this.twoFactorAuthenticationSecret = backendResponse.error.userInfo['secret']
                this.twoFactorAuthenticationUrl = backendResponse.error.userInfo["authUrl"]

            // User has 2FA enabled, redirect him to 2FA login form
            } else if (backendResponse.error.httpStatus == 307) {
                this.newPasswordForm.controls.passwordResetCode.disable()
                this.newPasswordForm.controls.newPassword.disable()
                this.showOneTimePasswordInput = true

            } else {
                this.alertService.error(backendResponse.error.title)
            }
        })
    }

}
