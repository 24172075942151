<div class="content-wrapper" (scroll)="onPageContentScroll($event)" #pageContent>

    <div class="content">
        <div class="row">
            <div class="col-md-12">
                <alert></alert>
            </div>
        </div>

        <div class="row" *ngIf="application && testFailureGroups">
            <div class="col-md-12">
                <div class="card">
                    <div class="page-header border-bottom-light">
                        <div class="page-header-content header-elements-md-inline">
                            <div class="page-title">
                                <h1 class="font-weight-thin">
                                    <a [routerLink]="['../']" class="breadcrumb-item">{{application.name}}</a>
                                    <span class="breadcrumb-item active">Failure Groups</span>
                                </h1>
                            </div>
                        </div>
                    </div>

                    <table class="table">
                        <thead>
                            <tr role="row">
                                <th class="border-0">#</th>
                                <th class="border-bottom-0 w-100">Description</th>
                                <th class="border-bottom-0 nowrap text-right">Failures</th>
                                <th class="border-bottom-0 nowrap text-center">Annotation</th>
                                <th class="border-bottom-0 nowrap text-center">Status</th>
                                <th class="border-bottom-0 nowrap text-right">Last report</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let group of testFailureGroups" (click)="navigateToTestFailureGroup(group)">
                                <td>
                                    <a [routerLink]="[group.serialNumber]" class="button-muted mt-2 mb-2">#{{group.serialNumber}}</a>
                                </td>
                                <td>
                                    <div class="d-flex">
                                        <div class="mr-3 ml-1">
                                            <span class="badge badge-mark border-pink-400"></span>
                                        </div>
                                        <div>
                                            <div class="font-weight-semibold button-muted">{{group.failureLocation}}</div>
                                            <div class="text-muted breakall">{{group.failureDescription}}</div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-right" [ngClass]="{ 'text-muted' : group.state == 2 }">{{group.numberOfFailures}}</td>
                                <td class="text-center" [ngClass]="{ 'text-muted' : group.state == 2 }">
                                    <i class="icon-attachment" *ngIf="group.annotation"></i>
                                    <span class="text-muted" *ngIf="group.annotation == null">-</span>
                                </td>
                                <td class="text-center" [ngClass]="{ 'text-success-600' : group.state == 1, 'text-muted' : group.state == 2 }">{{issueStateFormatter.displayName(group.state)}}</td>
                                <td class="nowrap text-right" [ngClass]="{ 'text-muted' : group.state == 2 }">{{dateFormatter.timeAgo(group.lastReportDate)}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="text-center">
                    <div class="text-muted d-block" *ngIf="isLoadingMoreRecords">
                        <i class="icon-spinner4 spinner mr-2"></i> Loading...
                    </div>
                    <div class="text-muted d-block" *ngIf="!isLoadingMoreRecords && !hasMoreRecords">
                        Showing all failure groups
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>
