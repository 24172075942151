import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TwoFactorAuthenticationService, TwoFactorAuthenticationStatusResponse } from 'app/_services/two.factor.authentication.service';
import { AlertService, UserService } from 'app/_services';
import { BackendResponse } from 'app/_models';
import { Title } from '@angular/platform-browser';

@Component({
    templateUrl: 'security.settings.component.html',
    styleUrls: [
        'security.settings.component.css'
    ]
})
export class SecuritySettingsComponent implements OnInit {

    updatePasswordForm: FormGroup
    enable2FAForm: FormGroup
    disable2FAForm: FormGroup
    twoFactorAuthenticationStatus: TwoFactorAuthenticationStatusResponse

    constructor(
        private formBuilder: FormBuilder,
        private twoFactorAuthenticationService: TwoFactorAuthenticationService,
        private userService: UserService,
        private alertService: AlertService,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.titleService.setTitle("Settings | Security")

        this.enable2FAForm = this.formBuilder.group({
            token: [null, Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6)])],
        })

        this.updatePasswordForm = this.formBuilder.group({
            oldPassword: [null, [Validators.required]],
            newPassword: [null, [Validators.required, Validators.minLength(12)]],
            newPasswordConfirmation: [null, [Validators.required, Validators.minLength(12)]]
        })

        this.disable2FAForm = this.formBuilder.group({
            password: [null, Validators.required],
            token: [null, Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6)])],
        })

        this.update2FAStatus()
    }

    private update2FAStatus() {
        this.twoFactorAuthenticationService.getStatus().then((response) => {
            this.twoFactorAuthenticationStatus = response

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    submitUpdatePasswordForm() {
        let f = this.updatePasswordForm.controls

        this.userService.updatePassword(f.oldPassword.value, f.newPassword.value, f.newPasswordConfirmation.value).then((response) => {
            this.alertService.success("Password updated successfully")

        }).catch((error) => {
            this.alertService.handleError(error)

        }).finally(() => {
            f.oldPassword.reset("")
            f.newPassword.reset("")
            f.newPasswordConfirmation.reset("")
        })
    }

    submitEnable2FAForm() {
        let f = this.enable2FAForm.controls
        this.twoFactorAuthenticationService.enable2FA(f.token.value).then((response) => {
            this.alertService.success("2FA Successfully enabled")
            this.update2FAStatus()

        }).catch((error) => {
            f.token.reset("")

            this.alertService.handleError(error)
        })
    }

    submitDisable2FAForm() {
        let f = this.disable2FAForm.controls
        this.twoFactorAuthenticationService.disable2FA(f.password.value, f.token.value).then((response) => {
            this.alertService.success("2FA Successfully disabled")
            this.update2FAStatus()

        }).catch((error) => {
            f.token.reset("")
            f.password.reset("")

            this.alertService.handleError(error)
        })
    }

}
