import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output, HostListener } from '@angular/core';
import { AlertService, AuthenticationService, AuthTokenService } from 'app/_services'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Workspace } from 'app/_models';

@Component({
    selector: 'new-auth-token-modal',
    templateUrl: 'new.auth.token.modal.html'
})
export class NewAuthTokenModal implements OnInit {

    form: FormGroup
    createdAuthToken: string

    workspace: Workspace
    @Output() onAuthTokenCreated = new EventEmitter()

    @ViewChild('closeButton') closeButton: ElementRef

    constructor(
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private authTokenService: AuthTokenService,
        private authenticationService: AuthenticationService
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue

        this.form = this.formBuilder.group({
            authTokenName: [null, Validators.required]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls }

    submitForm() {

        this.authTokenService.createAuthToken(this.f.authTokenName.value, this.workspace.id).then((response) => {
            this.f.authTokenName.reset()

            this.createdAuthToken = response.originalToken
            this.onAuthTokenCreated.emit()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    copyToClipboard() {
        navigator.clipboard.writeText(this.createdAuthToken)
    }

    closeForm() {
        this.createdAuthToken = null
        this.f.authTokenName.setValue(null)

        // Close the modal dialog
        this.closeButton.nativeElement.click()
    }

}
