import { Component, Input } from '@angular/core';
import { CountFormatter } from 'app/_helpers'

@Component({
    selector: 'count',
    templateUrl: 'count.component.html'
})

export class CountComponent {

    @Input() count: number

    constructor(
        public countFormatter: CountFormatter
    ) { }

}
