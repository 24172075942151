import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'app/app.config';
import { ProgressBar, TestExecutor } from 'app/_models';


@Injectable({ providedIn: 'root' })
export class TestExecutorsService {

    constructor(private http: HttpClient) {
    }

}
