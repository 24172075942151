<div *ngIf="crashTrendsSummaryResponse">
    <div class="d-flex">
        <div class="mr-auto font-weight-semibold">Total crashes</div>
        <count [count]="crashTrendsSummaryResponse.totalCrashesCount"></count>
    </div>
    <div class="d-flex">
        <div class="mr-auto font-weight-semibold">Affected users</div>
        <count [count]="crashTrendsSummaryResponse.totalDevicesCount"></count>
    </div>

    <hr class="my-1">

    <div class="font-size-sm nowrap">
        <div class="d-flex">
            <div class="mr-auto">Crashes per user:</div>
            <count [count]="crashTrendsSummaryResponse.totalCrashesCount / _crashTrendsSummaryResponse.totalDevicesCount"></count>
        </div>
    </div>
</div>
