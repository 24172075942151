import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WorkspaceSubscription, SubscriptionTemplate, SubscriptionType, BillingItem } from 'app/_models';
import { AppConfig } from 'app/app.config';

export class SubscriptionTemplatesResponse {
    free: SubscriptionTemplate
    basic: SubscriptionTemplate
    pro: SubscriptionTemplate
    enterprise: SubscriptionTemplate
}

export class WorkspaceSubscriptionPreview {
    billingItems: BillingItem[]
    totalExcludingVat: string
    vatPercentage: string
    vat: string
    total: string
}

@Injectable({ providedIn: 'root' })
export class WorkspaceSubscriptionService {

    constructor(
        private http: HttpClient,
    ) {
    }

    getSubscriptionTemplates(currency: string): Promise<SubscriptionTemplatesResponse> {
        return this.http.get<SubscriptionTemplatesResponse>(`${AppConfig.apiBaseUrl}/subscriptionTemplates?currency=${currency}`).toPromise()
    }

    getWorkspaceSubscriptionPreview(workspaceId: number, subscriptionType: SubscriptionType, annual: boolean, currency: string, voucherCode: string | null): Promise<WorkspaceSubscriptionPreview> {
        let requestBody = {
            workspaceId: workspaceId,
            subscriptionType: subscriptionType,
            isAnnualSubscription: annual,
            voucherCode: voucherCode,
            currency: currency
        }

        return this.http.post<WorkspaceSubscriptionPreview>(`${AppConfig.apiBaseUrl}/workspaceSubscriptions/preview`, requestBody).toPromise()
    }

    createWorkspaceSubscription(workspaceId: number, subscriptionType: SubscriptionType, annual: boolean, currency: string, voucherCode: string | null): Promise<WorkspaceSubscription> {
        let requestBody = {
            workspaceId: workspaceId,
            subscriptionType: subscriptionType,
            isAnnualSubscription: annual,
            voucherCode: voucherCode,
            currency: currency
        }

        return this.http.post<WorkspaceSubscription>(`${AppConfig.apiBaseUrl}/workspaceSubscriptions`, requestBody).toPromise()
    }

    updateWorkspaceSubscription(workspaceSubscriptionId: string, automaticRenewalEnabled: boolean): Promise<WorkspaceSubscription> {
        let requestBody = {
            automaticRenewalEnabled: automaticRenewalEnabled,
        }

        return this.http.put<WorkspaceSubscription>(`${AppConfig.apiBaseUrl}/workspaceSubscriptions/${workspaceSubscriptionId}`, requestBody).toPromise()
    }

}
