import { AppConfig } from "app/app.config";
import { Injectable } from "@angular/core";
import { Role } from "app/_models";

@Injectable()
export class RoleFormatter {

    roleName(role: Role): string {
        if (role === undefined || role === null) { return null }
        if (role.toString() == '0') { return "Not a member" }

        switch (role) {
            case Role.Tester: return "Tester"
            case Role.Member: return "Member"
            case Role.Admin: return "Admin"
            case Role.Agent: return "Agent"
            default:
                if (AppConfig.isDebug) {
                    alert("Unknown role: " + role)
                } else {
                    console.warn("Unknown role: " + role)
                }

                return "Unknown"
        }
    }

    rolesManagedBy(role: Role): Role[] {
        if (!role) { return [] }

        // Compose a list of allowed roles base on the user's role in the workspace
        switch (role) {
            case Role.Agent: return [Role.Admin, Role.Member, Role.Tester]
            case Role.Admin: return [Role.Member, Role.Tester]
            case Role.Member: return []
            case Role.Tester: return []
        }
    }
}
