<table class="table" *ngIf="appLaunchSegments">
    <thead>
        <tr role="row">
            <!-- OPTIONAL FIELDS -->
            <th class="border-bottom-0 w-100 nosort" [appSort]="appLaunchSegments" data-name="name" *ngIf="appLaunchDimension == AppLaunchDimension.ApplicationVersion">
                {{appLaunchSegments.length}} App versions<i class="icon-sort"></i>
            </th>

            <th class="border-bottom-0 w-100 nosort" [appSort]="appLaunchSegments" data-name="name" *ngIf="appLaunchDimension == AppLaunchDimension.OsVersion">
                {{appLaunchSegments.length}} OS versions<i class="icon-sort"></i>
            </th>

            <th class="border-bottom-0 w-100 nosort" [appSort]="appLaunchSegments" data-name="name" *ngIf="appLaunchDimension == AppLaunchDimension.OsVersionMajor">
                {{appLaunchSegments.length}} Major OS versions<i class="icon-sort"></i>
            </th>

            <th class="border-bottom-0 w-100 nosort" [appSort]="appLaunchSegments" data-name="name" *ngIf="appLaunchDimension == AppLaunchDimension.OsVersionMinor">
                {{appLaunchSegments.length}} Minor OS versions<i class="icon-sort"></i>
            </th>

            <th class="border-bottom-0 w-100 nosort" [appSort]="appLaunchSegments" data-name="name" *ngIf="appLaunchDimension == AppLaunchDimension.Device">
                Top {{appLaunchSegments.length}} Devices<i class="icon-sort"></i>
            </th>

            <!-- ALWAYS PRESENT FIELDS -->
            <th class="border-bottom-0 text-right nowrap nosort" [appSort]="appLaunchSegments" data-name="appdex">
                Appdex<i class="icon-sort"></i>
            </th>

            <th class="border-bottom-0 text-right nowrap nosort" [appSort]="appLaunchSegments" data-name="loadDuration">
                <span>Load time<sub>avg</sub></span><i class="icon-sort"></i>
            </th>

            <th class="border-bottom-0 text-right nowrap nosort" [appSort]="appLaunchSegments" data-name="firstFrameDuration">
                <span>First frame time<sub>avg</sub></span><i class="icon-sort"></i>
            </th>

            <th class="border-bottom-0 text-center nowrap sortdesc" [appSort]="appLaunchSegments" data-name="launchesCount">
                Count<i class="icon-sort"></i>
            </th>
        </tr>
    </thead>

    <tbody>
        <ng-container *ngFor="let segment of appLaunchSegments">
            <!-- <tr [style.background]="'linear-gradient(to right, rgba(33, 150, 243,.06) ' + (segment.relativeSize * 100).toFixed(1) + '%, #FFF ' + (segment.relativeSize * 100).toFixed(1) + '%'"> -->
            <tr>
                <td *ngIf="appLaunchDimension == AppLaunchDimension.ApplicationVersion">
                    {{segment.bundleShortVersion}}
                </td>

                <td *ngIf="appLaunchDimension == AppLaunchDimension.OsVersion">
                    {{platformFormatter.platformName(segment.platform)}} {{segment.version}}
                </td>

                <td *ngIf="appLaunchDimension == AppLaunchDimension.OsVersionMajor">
                    {{platformFormatter.platformName(segment.platform)}} {{segment.version}}
                </td>

                <td *ngIf="appLaunchDimension == AppLaunchDimension.OsVersionMinor">
                    {{platformFormatter.platformName(segment.platform)}} {{segment.version}}
                </td>

                <td *ngIf="appLaunchDimension == AppLaunchDimension.Device">
                    {{segment.name}}
                </td>

                <td class="nowrap text-right font-weight-bold">
                    <appdex [value]="segment.appdex"></appdex>
                </td>
                <td class="nowrap text-right">
                    <milliseconds [duration]="segment.loadDuration"></milliseconds>
                </td>
                <td class="nowrap text-right">
                    <milliseconds [duration]="segment.firstFrameDuration"></milliseconds>
                </td>
                <td class="nowrap text-center">
                    <count [count]="segment.launchesCount"></count><span class="text-muted mx-1">·</span><span class="text-muted font-weight-light">{{(segment.relativeSize * 100).toFixed(1)}}%</span>
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>
