import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { RouteHelper } from 'app/_helpers'

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent implements OnInit {

    constructor(
        private router: Router,
        private routeHelper: RouteHelper
    ) {
    }

    ngOnInit() {
        this.router.navigate([this.routeHelper.currentWorkspaceHome()])
    }

}
