import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, AuthenticationService, DemoService } from 'app/_services';
import { BackendResponse } from 'app/_models';
import { RouteHelper } from 'app/_helpers';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';

@Component({
    templateUrl: 'demo.component.html',
})
export class DemoComponent implements OnInit {

    demoForm: FormGroup
    demoModeLoading = false

    constructor(
        private router: Router,
        private routeHelper: RouteHelper,
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private authenticationService: AuthenticationService,
        private demoService: DemoService,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.titleService.setTitle("Demo Registration")

        this.demoForm = this.formBuilder.group({
            workspaceName: [null, Validators.required],
            email: [null],
        })
    }

    onSubmit() {
        let workspaceName = this.demoForm.controls.workspaceName.value
        let email = this.demoForm.controls.email.value

        this.demoModeLoading = true
        this.demoService.createDemoAccount(workspaceName, email).then((response) => {
            if (email == null) {
                this.demoForm.controls.email.setValue(response.data.demoEmail)
            }
            return this.authenticationService.login(response.data.demoEmail, response.data.demoPassword, null, null)

        }).then((response) => {
            this.authenticationService.setUserAuthenticated(response)
            this.router.navigate([this.routeHelper.currentWorkspaceHome()])

        }).catch((error) => {
            this.demoModeLoading = false

            this.alertService.handleError(error)
        })
    }

}
