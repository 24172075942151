import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { BackendEnvironmentService, AuthenticationService, AlertService } from 'app/_services'
import { BackendEnvironment, Workspace, BackendResponse, Role, TestPlan } from 'app/_models';
import { Title } from '@angular/platform-browser';
import { EditBackendEnvironmentModal } from './edit.backend.environment.modal';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TagModel } from 'ngx-chips/core/accessor';
import { DateFormatter } from 'app/_helpers';

@Component({
    templateUrl: 'backend.environment.settings.component.html'
})
export class BackendEnvironmentSettingsComponent implements OnInit, OnDestroy {

    workspace: Workspace
    canEditEnvironment = false
    backendEnvironment: BackendEnvironment
    form: FormGroup

    private currentBackendEnvironmentSubscription: Subscription
    public availableTestPlanNames: string[] = []

    constructor(
        public dateFormatter: DateFormatter,
        private backendEnvironmentService: BackendEnvironmentService,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue
        this.canEditEnvironment = this.workspace.role >= Role.Admin

        this.currentBackendEnvironmentSubscription = this.backendEnvironmentService.currentBackendEnvironment.subscribe((environment) => {
            this.backendEnvironment = environment
            if (this.backendEnvironment != null) {
                this.titleService.setTitle(`${this.backendEnvironment.name} | Settings`)
                this.reloadForm()
            }
        })

        this.backendEnvironmentService.getFilterInfos().then((response) => {
            this.availableTestPlanNames = response.testPlans.map((testPlan) => { return testPlan.name })
            this.reloadForm()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    ngOnDestroy() {
        this.currentBackendEnvironmentSubscription.unsubscribe()
    }

    saveBackendEnvironment() {
        let f = this.form.controls
        let testsSuccessThreshold = parseFloat(f.testsSuccessThreshold.value.replace("%", "")) / 100
        let testPlanNames = (f.testPlanNames.value as TagModel[]).map((tagModel) => { return tagModel['value'] })

        this.backendEnvironmentService.updateBackendEnvironment(this.backendEnvironment.id, testsSuccessThreshold, testPlanNames).then((response) => {
            this.backendEnvironment = response.data
            this.reloadForm()
            this.alertService.success("Settings successfully updated")

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private reloadForm() {
        if (!this.backendEnvironment) { return }

        this.form = this.formBuilder.group({
            testsSuccessThreshold: new FormControl(
                {
                    value: (this.backendEnvironment.testsSuccessThreshold * 100).toFixed(1) + "%",
                    disabled: !this.canEditEnvironment
                },
                Validators.compose([
                    Validators.required,
                    Validators.minLength(1),
                    Validators.pattern(/^\d{1,3}(\.\d)?%?$/)
                ])
            ),
            testPlanNames: new FormControl(
                {
                    value: this.backendEnvironment.testPlansAffectingStatus.map(this.tagModelFromString),
                    disabled: !this.canEditEnvironment
                },
                null
            )
        })
    }

    private tagModelFromString(value: string): TagModel {
        return { value: value, display: value }
    }

    testPlansSummary(environment: BackendEnvironment): string {
        return environment.testPlansAffectingStatus.join(", ");
    }

}
