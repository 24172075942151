<navbar-unregistered></navbar-unregistered>

<div class="page-content">
    <div class="content-wrapper">

        <div class="content d-flex justify-content-center align-items-center">
            <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="card mb-0">
                    <div class="card-body">

                        <!-- REGULAR TITLE -->
                        <div class="text-center mb-3" *ngIf="twoFactorAuthenticationSecret == null">
                            <i class="icon-user icon-2x text-primary-400 border-primary-400 border-3 rounded-round p-3 mb-3 mt-1"></i>
                            <h5 class="mb-0">Login to your account</h5>
                            <span class="d-block text-muted">Your credentials</span>
                        </div>

                        <!-- 2FA ACTIVATION TITLE -->
                        <div class="text-center mb-3" *ngIf="twoFactorAuthenticationSecret != null">
                            <i class="icon-shield-check icon-2x text-primary-400 border-primary-400 border-3 rounded-round p-3 mb-3 mt-1"></i>
                            <h5 class="mb-0">2FA Activation Required</h5>
                            <span class="d-block text-muted mb-2">2FA is mandatory for your organization. Please scan the QR code below with your 2FA app, then fill in the activation token.</span>
                        </div>

                        <!-- ALERT FOR ERRORS -->
                        <alert></alert>

                        <!-- EMAIL INPUT -->
                        <div class="form-group form-group-feedback form-group-feedback-left" *ngIf="twoFactorAuthenticationSecret == null">
                            <input type="text" formControlName="username" class="form-control" placeholder="Email">
                            <div class="form-control-feedback">
                                <i class="icon-mention text-muted"></i>
                            </div>
                        </div>

                        <!-- PASSWORD INPUT -->
                        <div class="form-group form-group-feedback form-group-feedback-left" *ngIf="twoFactorAuthenticationSecret == null">
                            <input type="password" formControlName="password" class="form-control" placeholder="Password">
                            <div class="form-control-feedback">
                                <i class="icon-lock4 text-muted"></i>
                            </div>
                        </div>

                        <!-- OTP INPUT -->
                        <div class="form-group form-group-feedback form-group-feedback-left" *ngIf="showOneTimePasswordInput">
                            <input autofocus type="text" formControlName="oneTimePassword" maxlength="6" class="form-control" placeholder="One time password">
                            <div class="form-control-feedback">
                                <i class="icon-lock4 text-muted"></i>
                            </div>
                        </div>

                        <!-- 2FA SETUP INSTRUCTIONS -->
                        <div *ngIf="twoFactorAuthenticationSecret">
                            <div class="text-center mb-1">
                                <qrcode [qrCodeData]="twoFactorAuthenticationUrl"></qrcode>
                            </div>

                            <div class="text-center mb-2">
                                <a href="#activation-code-link" class="font-weight-semibold text-default py-2" data-toggle="collapse">
                                    Can't scan the code?
                                </a>
                            </div>

                            <div class="collapse text-center mb-2" id="activation-code-link">
                                <div class="mb-1">You can add the code to your application manually using the following <strong>Activation Key:</strong></div>
                                <code>{{twoFactorAuthenticationSecret}}</code>
                            </div>

                            <div class="form-group form-group-feedback form-group-feedback-left">
                                <input autofocus type="text" formControlName="twoFactorAuthenticationToken" maxlength="6" class="form-control" placeholder="2FA activation token">
                                <div class="form-control-feedback">
                                    <i class="icon-lock4 text-muted"></i>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <button type="submit" [disabled]="loginForm.invalid" class="btn bg-primary-400 btn-block">
                                <span *ngIf="twoFactorAuthenticationSecret == null">Login <i class="icon-circle-right2 ml-2"></i></span>
                                <span *ngIf="twoFactorAuthenticationSecret != null">Enable 2FA <i class="icon-circle-right2 ml-2"></i></span>
                            </button>
                        </div>

                        <div class="text-center" *ngIf="showOneTimePasswordInput == false && twoFactorAuthenticationSecret == null">
                            <a routerLink="/password_recovery">Forgot password?</a>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
