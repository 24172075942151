<div class="content">

    <div class="row">
        <div class="col-md-12">
            <alert></alert>
        </div>
    </div>

    <div class="card" *ngIf="distributionGroup">
        <div class="page-header border-bottom-light">
            <div class="page-header-content header-elements-md-inline">
                <div class="page-title">
                    <h1 class="font-weight-thin">
                        <span class="breadcrumb-item">{{distributionGroup.name}}</span>
                    </h1>

                    <div class="text-muted">
                        <span *ngIf="distributionGroup.isPublic">
                            <i class="icon-unlocked2 font-size-sm mr-1"></i><span class="mr-1">Public page:</span>
                        </span>
                        <span *ngIf="!distributionGroup.isPublic">
                            <i class="icon-lock2 font-size-sm mr-1"></i><span class="mr-1">Private page:</span>
                        </span>
                        <a [routerLink]="groupDistributionRouterComponents" class="text-default">{{groupDistributionDisplayUrl}}</a>
                    </div>
                </div>

                <div class="header-elements" *ngIf="canManageTesters">
                    <div class="dropdown">
                        <a href="#" class="list-icons-item dropdown-toggle" data-toggle="dropdown"><i class="icon-menu7"></i></a>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a (click)="onEditDistributionGroupClick()" class="dropdown-item" data-toggle="modal" data-target="#distributionGroupEditor">Edit Distribution Group</a>
                            <a (click)="onDeleteDistributionGroupClick()" class="dropdown-item text-danger">Delete Distribution Group</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- MEMBERS, BUILDS -->
        <div class="card-body pb-0" *ngIf="distributionGroupBuilds && distributionGroupUsers">
            <ul class="nav nav-pills pb-0 align-items-baseline">
                <li class="nav-item active">
                    <a href="#membersTab" class="nav-link active" data-toggle="tab">
                        Members<span class="badge badge-light ml-2">{{distributionGroupUsers.length}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="#buildsTab" class="nav-link" data-toggle="tab">
                        Builds<span class="badge badge-light ml-2">{{distributionGroupBuilds.length}}</span>
                    </a>
                </li>
            </ul>
        </div>

        <div class="tab-content">
            <div class="card-body pt-0 tab-pane fade active show" id="membersTab" *ngIf="distributionGroupUsers">
                <div *ngIf="canManageTesters">
                    <h4 class="font-weight-thin mb-1">Invite testers</h4>

                    <form [formGroup]="newInvitationForm">
                        <div class="input-group">
                            <span class="input-group-prepend">
                                <span class="input-group-text"><i class="icon-mention"></i></span>
                            </span>
                            <tag-input style="height: auto;" formControlName="emailInput" secondaryPlaceholder="Add testers" placeholder='+ Add testers' [onTextChangeDebounce]="0" [maxItems]='10' [separatorKeyCodes]="[9, 44, 188]" [animationDuration]="{enter: '0ms', leave: '0ms'}" theme='minimal' class="form-control pl-1 py-0">
                                <tag-input-dropdown [displayBy]="'display'" [identifyBy]="'value'" [autocompleteItems]="workspaceMemberNames"></tag-input-dropdown>
                            </tag-input>

                            <button (click)="sendInvitation()" [disabled]="newInvitationForm.invalid" class="btn bg-primary-400 ml-sm-2 mb-sm-0">Invite <i class="icon-paperplane ml-2"></i></button>
                        </div>
                    </form>

                    <span class="form-text text-muted">Invited members will be assigned a <span class="font-weight-semibold">Tester</span> role (without Dashboard access) unless they are already members of this workspace. Read more about <a [href]="rolesDocumentationUrl">user roles</a>.</span>
                </div>

                <li class="media font-weight-semibold border-0 py-1" *ngIf="distributionGroupUsers.length > 0">Members</li>
                <li class="media hoverable mt-2" *ngFor="let membership of distributionGroupUsers">
                    <avatar class="member-avatar mr-3" [imageUrl]="membership.membership.imageUrl.toString()" [fallbackText]="membership.membership.name"></avatar>
                    <div class="media-body">
                        <div class="media-title font-weight-semibold">{{membership.membership.name}}</div>
                        <span class="text-muted">{{membership.membership.email}}</span>
                    </div>
                    <div class="align-self-center ml-3" *ngIf="canManageTesters">
                        <ul class="list-inline list-inline-dotted mb-3 mb-lg-2">
                            <li class="list-inline-item"><button type="button" (click)="onRemoveMemberClick(membership)" class="btn btn-link text-pink-400">Remove</button></li>
                        </ul>
                    </div>
                </li>

                <li class="media font-weight-semibold border-0 py-1" *ngIf="invitations.length > 0">Pending invitations</li>
                <li class="media hoverable" *ngFor="let invitation of invitations">
                    <div class="mr-3"><img class="rounded-circle" src="{{invitation.imageUrl}}" width="40" height="40" alt=""></div>
                    <div class="media-body">
                        <div class="media-title">{{invitation.email}}</div>
                        <div class="text-danger" *ngIf="invitationExpired(invitation)">Expired on {{invitationExpiration(invitation.expirationDate)}}</div>
                        <div class="text-muted" *ngIf="!invitationExpired(invitation)">Expires on {{invitationExpiration(invitation.expirationDate)}}</div>
                    </div>
                    <div class="align-self-center ml-3" *ngIf="canManageTesters">
                        <ul class="list-inline list-inline-dotted mb-3 mb-lg-2">
                            <li class="list-inline-item"><button type="button" (click)="resendInvitation(invitation)" class="btn btn-link">Resend</button></li>
                            <li class="list-inline-item"><button type="button" (click)="cancelInvitation(invitation)" class="btn btn-link">Cancel</button></li>
                        </ul>
                    </div>
                </li>
            </div>

            <div class="tab-pane fade" id="buildsTab" *ngIf="distributionGroupBuilds">
                <div class="w-100 h-100 p-3" *ngIf="canManageTesters && distributionGroupBuilds.length == 0">
                    <div class="card wizard w-100 h-100 p-3 bg-transparent">
                        <div class="card-body text-center d-flex justify-content-center align-items-center">
                            <div class="wizard-content">
                                <i class="icon-plus3 icon-2x text-primary border-primary border-3 rounded-pill p-3 mb-3 mt-1"></i>
                                <h5 class="mt-2 mb-0">Add builds</h5>
                                <p class="mb-3 text-muted">Use the build distribution wizard in the <a class="text-default" [routerLink]="['../../builds']">Builds</a> section.</p>

                                <a [routerLink]="['../../builds']" class="btn bg-primary-400 mt-2">View Available Builds</a>
                            </div>
                        </div>
                    </div>
                </div>

                <table class="table" *ngIf="distributionGroupBuilds.length > 0">
                    <thead>
                        <tr role="row">
                            <th class="border-0">#</th>
                            <th class="border-0">Version</th>
                            <th class="border-0">Creation date</th>
                            <th class="border-0 text-right">Downloads</th>
                            <th class="border-0 text-right" *ngIf="canManageTesters">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let buildMembership of distributionGroupBuilds">
                            <td (click)="navigateToBuild(buildMembership)"><a [routerLink]="['../../builds/', buildMembership.build.serialNumber]" class="button-muted">#{{buildMembership.build.serialNumber}}</a></td>
                            <td (click)="navigateToBuild(buildMembership)" class="nowrap">{{buildMembership.build.shortVersion}} ({{buildMembership.build.version}})</td>
                            <td (click)="navigateToBuild(buildMembership)" class="nowrap">{{dateFormatter.dateTimeShort(buildMembership.build.creationDate)}}</td>
                            <td (click)="navigateToBuild(buildMembership)" class="text-right"><count [count]="buildMembership.build.downloads"></count></td>
                            <td class="text-right" *ngIf="canManageTesters">
                                <div class="list-icons">
                                    <div class="list-icons-item dropdown">
                                        <a href="#" class="list-icons-item dropdown-toggle caret-0" data-toggle="dropdown"><i class="icon-menu9"></i></a>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a (click)="removeBuildFromGroup(buildMembership)" class="dropdown-item text-danger"><i class="icon-cross2"></i> Remove from group</a>
                                            <a [routerLink]="buildDistributionRouteComponents(buildMembership)" class="dropdown-item"><i class="icon-new-tab"></i> Distribution page</a>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

<distribution-group-editor #distributionGroupEditor></distribution-group-editor>
