import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Chart, ChartDataset, Color } from 'chart.js';
import { DateFormatter, UUIDUtils } from 'app/_helpers';
import * as moment from 'moment';
import { DateRange } from 'app/_models';

@Component({
    selector: 'response-time-over-time-chart',
    templateUrl: 'response.time.over.time.chart.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResponseTimeOverTimeChart implements AfterViewChecked {

    private _responseTimes: number[]
    @Input()
    set responseTimes(responseTimes: number[]) {
        if (responseTimes && this._responseTimes !== responseTimes) {
            this._responseTimes = responseTimes
            if (this.chart) {
                this.reloadChart()
            }
        }
    }
    get responseTimes(): number[] { return this._responseTimes }

    private _serverResponseTimes: number[]
    @Input()
    set serverResponseTimes(serverResponseTimes: number[]) {
        if (serverResponseTimes && this._serverResponseTimes !== serverResponseTimes) {
            this._serverResponseTimes = serverResponseTimes
            if (this.chart) {
                this.reloadChart()
            }
        }
    }
    get serverResponseTimes(): number[] { return this._serverResponseTimes }

    private _datePoints: string[]
    @Input()
    set datePoints(datePoints: string[]) {
        if (datePoints && this._datePoints !== datePoints) {
            this._datePoints = datePoints
            if (this.chart) {
                this.reloadChart()
            }
        }
    }
    get datePoints(): string[] { return this._datePoints }

    @Output() onDateRangeChange = new EventEmitter<DateRange>()

    private chart: Chart
    chartUuid: string = UUIDUtils.uuidv4()

    constructor(
        private changeDetector: ChangeDetectorRef,
        private dateFormatter: DateFormatter
    ) {
    }

    // Charts can only be created once the their canvases are rendered, and canvases
    // will only be shown now after all the data is received
    ngAfterViewChecked() {
        if (!this.responseTimes || this.chart) { return }
        if ($(`#${this.chartUuid}`).length > 0) {
            this.reloadChart()
        }
    }

    private reloadChart() {
        if (this.chart) {
            this.chart.destroy()
        }

        if (this.responseTimes == null) {
            return
        }

        let labels: string[][] = this.datePoints.map((datePoint) => {
            let date = moment(datePoint)
            return [date.format('MMM Do'), date.format('HH:mm')]
        })

        var max: number = 0
        for (let i = 0; i < this.responseTimes.length; i++) {
            let sum = this.responseTimes[i]
            if (sum > max) { max = sum }
        }

        // Use 500ms as max in case there is no data
        max = max == 0 ? 500 : max

        let dateFormatter = this.dateFormatter

        this.chart = new Chart(this.chartUuid, {
            type: 'line',
            data: {
                labels: labels,
                datasets: this.makeChartDatasets()
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: true,
                        callbacks: {
                            title: function (tooltipItems): string {
                                if (tooltipItems.length == 0) { return null }
                                return tooltipItems[0].label.replace(",", " ")
                            },
                            label: function (tooltipItem): string {
                                return dateFormatter.milliseconds(tooltipItem.parsed.y) + " ms"
                            }
                        }
                    },
                    zoom: {
                        zoom: {
                            drag: { enabled: true },
                            mode: 'x',
                            onZoomComplete: this.onZoomComplete.bind(this)
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                animation: false,
                scales: {
                    x: {
                        display: true,
                        ticks: {
                            maxRotation: 0,
                            minRotation: 0,
                            maxTicksLimit: 15,
                            color: '#aaa',
                            font: { size: 12 }
                        }
                    },
                    y: {
                        display: true,
                        suggestedMin: 0,
                        suggestedMax: max,
                        ticks: {
                            callback: function (value: number): string {
                                return dateFormatter.milliseconds(value) + " ms"
                            },
                            maxTicksLimit: 8,
                            color: '#aaa',
                            font: { size: 12 }
                        }
                    }
                }
            }
        })
        this.changeDetector.detectChanges()
    }

    onZoomComplete(context: { chart: Chart }) {
        const min = Math.min(Math.max(context.chart.scales.x.min, 0), this.datePoints.length - 1)
        const max = Math.min(Math.max(context.chart.scales.x.max, 0), this.datePoints.length - 1)

        let dateRange = new DateRange()
        dateRange.startDate = moment(this.datePoints[min]).toDate()
        dateRange.endDate = moment(this.datePoints[max]).toDate()
        this.onDateRangeChange.emit(dateRange)
    }

    private makeChartDatasets(): ChartDataset[] {
        var pointColors: Color[] = this.responseTimes.map(value => {
            if (value < 0.5) return "#03c764"
            else if (value < 1) return "#FFA726"
            else return "#E53935"
        })

        let chartDatasets: ChartDataset[] = [
            {
                data: this.serverResponseTimes,
                fill: true
            },
            {
                data: this.responseTimes,
                fill: false,
                pointBackgroundColor: pointColors,
                tension: 0.4
            }
        ]

        return chartDatasets
    }

}
