import { Component, Input } from '@angular/core';

@Component({
    selector: 'mfa-badge',
    templateUrl: 'mfa.component.html'
})

export class MFAComponent {

    @Input() mfaEnabled: boolean | null | undefined

    constructor(
    ) { }

}
