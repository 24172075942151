import { AppdexFormatter } from 'app/_helpers';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'appdex',
    templateUrl: 'appdex.component.html'
})

export class AppdexComponent {

    _enableTextColoring: boolean = true
    @Input()
    set enableTextColoring(enableTextColoring: boolean) {
        if (this._enableTextColoring !== enableTextColoring) {
            this._enableTextColoring = enableTextColoring
            this.updateTextClass()
        }
    }
    get enableTextColoring() { return this._enableTextColoring }

    _value: number
    @Input()
    set value(value: number) {
        if (this._value !== value) {
            this._value = value
            this.updateTextClass()
        }
    }
    get value() { return this._value }

    textClass: string

    constructor(
        public appdexFormatter: AppdexFormatter
    ) { }

    private updateTextClass() {
        if (this.enableTextColoring) {
            let appdexLevel = this.appdexFormatter.appdexLevel(this.value)
            this.textClass = this.appdexFormatter.appdexTextClass(appdexLevel)

        } else {
            this.textClass = ""
        }
    }

}
