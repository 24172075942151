import { Injectable } from '@angular/core'
import { NetworkRequestLog, NetworkResponseLog } from 'app/_models/console.logs.response'

@Injectable()
export class HTTPFormatter {

    formatNetworkRequestLogPayload(request: NetworkRequestLog): string {
        let headers = this.formatHTTPHeaders(request.headers)
        let body = this.formatHTTPBody(request.body)
        let content = [headers, body].filter(Boolean).join("\n\n")

        return content.length > 0 ? content : null
    }

    formatNetworkResponseLogPayload(response: NetworkResponseLog): string {
        let headers = this.formatHTTPHeaders(response.headers)
        let body = this.formatHTTPBody(response.body)
        let error = this.formatHTTPErrorInfo(response.errorInfo)
        // TODO: Error is shown 2x in preview
        let content = [headers, body, error].filter(Boolean).join("\n\n")

        return content.length > 0 ? content : null
    }

    formatNetworkRequestAndResponseLogsForClipboard(request: NetworkRequestLog, response: NetworkResponseLog): string {
        const requestContent = this.formatNetworkRequestLogForClipboard(request)
        const responseContent = this.formatNetworkResponseLogForClipboard(response)

        return [requestContent, responseContent].filter(Boolean).join("\n\n\n")
    }

    formatNetworkRequestLogForClipboard(request: NetworkRequestLog): string {
        const payloadContent = this.formatNetworkRequestLogPayload(request)

        var content = `${request.method} ${request.url}`
        if (payloadContent != null) {
            content += `\n${payloadContent}`
        }

        return content
    }

    formatNetworkResponseLogForClipboard(response: NetworkResponseLog): string {
        const payloadContent = this.formatNetworkResponseLogPayload(response)
        const statusCodeText = this.httpStatusCodeText(response.statusCode)

        var content = `${response.statusCode}`
        if (statusCodeText != null) {
            content += ` (${statusCodeText})`
        }
        content += ` · ${response.method} ${response.url}`

        if (payloadContent != null) {
            content += `\n${payloadContent}`
        }

        return content
    }

    formatHTTPHeaders(headers: {string: string} | null): string | null {
        if (headers == null) {
            return null
        }

        const headersMap = new Map<string, string>(Object.entries(headers))
        let sortedHeaderNames = Array.from(headersMap.keys()).sort()
        if (sortedHeaderNames.length == 0) {
             return null
        }

        var components = new Array<string>()
        for (let headerName of sortedHeaderNames) {
            components.push(`${headerName}: ${headersMap.get(headerName)}`)
        }

        return components.join("\n")
    }

    formatHTTPBody(body: string | null): string | null {
        if (body == null || body.length == 0) {
            return null
        }

        try {
            let jsonBody = JSON.parse(body)
            if (jsonBody != null) {
                return JSON.stringify(jsonBody, null, 2)
            }
        } catch { }

        return body
    }

    formatHTTPErrorInfo(errorInfo: { string: string } | null): string | null {
        if (errorInfo == null || Object.keys(errorInfo).length == 0) {
            return null
        }

        return JSON.stringify(errorInfo, null, 2)
    }

    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
    httpStatusCodeText(statusCode: number): string | null {
        switch (statusCode) {
            case 0: return "No Response"

            case 100: return "Continue"
            case 101: return "Switching Protocol"
            case 102: return "Processing"
            case 103: return "Early Hints"

            case 200: return "OK"
            case 201: return "Created"
            case 202: return "Accepted"
            case 203: return "Non-Authoritative Information"
            case 204: return "No Content"
            case 205: return "Reset Content"
            case 206: return "Partial Content"
            case 207: return "Multi-Status"
            case 208: return "Already Reported"
            case 226: return "IM Used"

            case 300: return "Multiple Choice"
            case 301: return "Moved Permanently"
            case 302: return "Found"
            case 303: return "See Other"
            case 304: return "Not Modified"
            case 305: return "Use Proxy"
            case 307: return "Temporary Redirect"
            case 308: return "Permanent Redirect"

            case 400: return "Bad Request"
            case 401: return "Unauthorized"
            case 402: return "Payment Required"
            case 403: return "Forbidden"
            case 404: return "Not Found"
            case 405: return "Method Not Allowed"
            case 406: return "Not Acceptable"
            case 407: return "Proxy Authentication Required"
            case 408: return "Request Timeout"
            case 409: return "Conflict"
            case 410: return "Gone"
            case 411: return "Length Required"
            case 412: return "Precondition Failed"
            case 413: return "Payload Too Large"
            case 414: return "URI Too Long"
            case 415: return "Unsupported Media Type"
            case 416: return "Range Not Satisfiable"
            case 417: return "Expectation Failed"
            case 418: return "I'm a teapot"
            case 421: return "Misdirected Request"
            case 422: return "Unprocessable Entity"
            case 423: return "Locked"
            case 424: return "Failed Dependency"
            case 425: return "Too Early"
            case 426: return "Upgrade Required"
            case 428: return "Precondition Required"
            case 429: return "Too Many Requests"
            case 431: return "Request Header Fields Too Large"
            case 451: return "Unavailable For Legal Reasons"

            case 500: return "Internal Server Error"
            case 501: return "Not Implemented"
            case 502: return "Bad Gateway"
            case 503: return "Service Unavailable"
            case 504: return "Gateway Timeout"
            case 505: return "HTTP Version Not Supported"
            case 506: return "Variant Also Negotiates"
            case 507: return "Insufficient Storage"
            case 508: return "Loop Detected"
            case 510: return "Not Extended"
            case 511: return "Network Authentication Required"
            case 520: return "Unknown Web Server Error"

            case 200299: return "Success"
        }

        return null
    }

}
