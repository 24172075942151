import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BackendEnvironment, TestCase, TimeFilter } from 'app/_models';
import { AppConfig } from 'app/app.config';
import { CollectionResponse } from './response';

export class TestCaseServiceInfo {
    testCase: TestCase
    testRunsCount: number
    failuresCount: number
    successRate: number
}

export class SuccessfulTestRunInfo {
    testRunSlug: number | null
    testExecutionSlug: number | null
}

export class TestCaseFiltersResponse {
    environments: [BackendEnvironment]
}

@Injectable({ providedIn: 'root' })
export class TestCasesService {

    constructor(private http: HttpClient) {
    }

    getTestCasesSummary(applicationId: number, history: TimeFilter): Promise<CollectionResponse<TestCaseServiceInfo>> {
        var url = `${AppConfig.apiBaseUrl}/applications/${applicationId}/testCases?history=${history}`
        return this.http.get<CollectionResponse<TestCaseServiceInfo>>(url).toPromise()
    }

    getTestCase(applicationId: number, testCaseSlug: string): Promise<CollectionResponse<TestCase>> {
        return this.http.get<CollectionResponse<TestCase>>(`${AppConfig.apiBaseUrl}/applications/${applicationId}/testCases?slug=${testCaseSlug}`).toPromise()
    }

    getLatestSuccessfulTestRunInfo(testCaseId: number): Promise<SuccessfulTestRunInfo> {
        return this.http.get<SuccessfulTestRunInfo>(`${AppConfig.apiBaseUrl}/testCases/${testCaseId}/latestSuccessfulTestRunInfo`).toPromise()
    }

    updateTestCase(testCaseId: number, annotation: string): Promise<Object> {
        let body = {
            annotation: annotation
        }

        return this.http.put(`${AppConfig.apiBaseUrl}/testCases/${testCaseId}`, body).toPromise()
    }

    getTestCaseFilters(testCaseId: number): Promise<TestCaseFiltersResponse> {
        return this.http.get<TestCaseFiltersResponse>(`${AppConfig.apiBaseUrl}/testCases/${testCaseId}/filters`).toPromise()
    }

}
