import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { AlertService, CrashesService } from 'app/_services'
import { FormGroup, FormBuilder } from '@angular/forms';
import { CrashGroup } from 'app/_models';

@Component({
    selector: 'edit-crash-group-modal',
    templateUrl: 'edit.crash.group.modal.html'
})
export class EditCrashGroupModal {

    form: FormGroup

    private _crashGroup: CrashGroup
    @Input()
    public set crashGroup(crashGroup: CrashGroup) {
        this._crashGroup = crashGroup
        this.reloadForm()
    }
    public get crashGroup(): CrashGroup {
        return this._crashGroup
    }

    @Output() crashGroupUpdated = new EventEmitter()

    @ViewChild('closeButton') closeButton: ElementRef

    constructor(
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private crashesService: CrashesService,
    ) {
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls }

    saveCrashGroup() {
        this.crashesService.updateCrashGroup(this.crashGroup.id, this.f.annotation.value, this.crashGroup.state).then((response) => {
            this.closeButton.nativeElement.click()
            this.crashGroupUpdated.emit()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private reloadForm() {
        if (!this.crashGroup) { return }

        this.form = this.formBuilder.group({
            annotation: [this.crashGroup.annotation]
        })
    }


}
