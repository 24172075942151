<div class="d-flex align-items-center">
    <samp>
        <a id="response_log_href_{{responseLog.requestId}}" class="text-default" data-toggle="collapse" href="#response_{{responseLog.requestId}}">
            <span class="breakall">
                <status-code class="ml-1" [statusCode]="responseLog.statusCode"></status-code>
                <span class="text-muted mx-1">·</span>{{responseLog.method}} {{responseLog.url}}<span class="text-muted mx-1">·</span><span class="text-secondary">{{responseLog.duration}}s</span>
            </span>
        </a>
    </samp>

    <div class="justify-content-baseline">
        <button type="button" class="btn more-button text-primary btn-icon rounded-pill ml-1" data-toggle="dropdown">
            <i class="m-0 icon-menu" style="font-size: 90%;"></i>
        </button>
        <div class="dropdown-menu">
            <div class="dropdown-header text-uppercase font-size-xs line-height-xs m-0 py-0">COPY</div>
            <a href="javascript:void(0)" (click)="copyUrlToClipboard()" class="dropdown-item"><i class="icon-lan3"></i>URL</a>
            <a href="javascript:void(0)" (click)="copyRequestAndResponseToClipboard()" class="dropdown-item"><i class="icon-files-empty pr-1"></i>Request and response</a>
            <a href="javascript:void(0)" (click)="copyLogLink()" class="dropdown-item"><i class="icon-link pr-1"></i>Link to this log</a>

            <div class="dropdown-divider opacity-75"></div>

            <div class="dropdown-header text-uppercase font-size-xs line-height-xs m-0 py-1">MORE</div>
            <a href="javascript:void(0)" (click)="onAnalyzeRequest()" class="dropdown-item"><i class="icon-atom2 pr-1"></i>Analyze request</a>
        </div>
    </div>
</div>

<div id="response_{{responseLog.requestId}}" class="collapse mb-1" *ngIf="requestPayloadContent || responsePayloadContent || errorContent">
    <ng-container *ngIf="requestPayloadContent">
        <p class="font-weight-semibold my-1">Request:</p>
        <pre class="language-javascript border-0">{{requestPayloadContent}}</pre>
    </ng-container>

    <ng-container *ngIf="errorContent == null">
        <p class="font-weight-semibold mt-2 mb-1">Response:</p>
        <pre class="language-javascript border-0">{{responsePayloadContent}}</pre>
    </ng-container>

    <ng-container *ngIf="errorContent">
        <p class="font-weight-semibold mt-2 mb-1">Request error:</p>
        <pre class="language-javascript border-0">{{errorContent}}</pre>
    </ng-container>
</div>
