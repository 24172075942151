export enum NetworkDimension {
    Overview = 'OVERVIEW',
    Operations = 'OPERATIONS',
    Environments = 'ENVIRONMENTS',
    EnvironmentVersions = 'ENVIRONMENT_VERSIONS',
    NetworkInterfaces = 'NETWORK_INTERFACES',
    Applications = 'APPLICATIONS',
    ApplicationVersions = 'APPLICATION_VERSIONS',
    ResponseCodes = 'RESPONSE_CODES',
    NetworkProtocols = 'NETWORK_PROTOCOLS',
    NetworkHosts = 'NETWORK_HOSTS'
}
