import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertMessage, AlertMessageType, AlertService } from 'app/_services';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'alert',
    templateUrl: 'alert.component.html'
})

export class AlertComponent implements OnInit, OnDestroy {

    message: AlertMessage
    alertMessageType = AlertMessageType

    private alertMessageSubscription: Subscription

    constructor(
        private alertService: AlertService
    ) {
    }

    ngOnInit() {
        this.alertMessageSubscription = this.alertService.getMessage().subscribe(message => {
            this.message = message
        })

        // Automatically dismiss success alerts after 8s delay
        this.alertService.getMessage().pipe(debounceTime(8000)).subscribe(message => {
            if (message != null && message.type == AlertMessageType.Success) {
                this.alertService.clear()
            }
        })

        // Automatically dismiss error alerts after 20s delay
        this.alertService.getMessage().pipe(debounceTime(20000)).subscribe(message => {
            if (message != null && message.type == AlertMessageType.Error) {
                this.alertService.clear()
            }
        })
    }

    ngOnDestroy() {
        if (this.alertMessageSubscription) {
            this.alertMessageSubscription.unsubscribe()
        }
    }

    onClose() {
        this.alertService.clear()
    }

}
