import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AuthenticationService } from 'app/_services';
import { BackendResponse } from 'app/_models';
import { RouteHelper } from 'app/_helpers';
import { Title } from '@angular/platform-browser';

@Component({templateUrl: 'login.component.html'})
export class LoginComponent implements OnInit {

    loginForm: FormGroup
    returnUrl: string

    // Will be set to true when OTP is required
    showOneTimePasswordInput: boolean = false

    // Will be set to true when 2FA setup is required before logging in
    twoFactorAuthenticationSecret: string
    twoFactorAuthenticationUrl: string

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private routeHelper: RouteHelper,
        private alertService: AlertService,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.titleService.setTitle("Login")

        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate([this.routeHelper.currentWorkspaceHome()])
            return
        }

        this.loginForm = this.formBuilder.group({
            username: [null, Validators.required],
            password: [null, Validators.required],
            oneTimePassword: [null, null],
            twoFactorAuthenticationToken: [null, null]
        })

        // get return url from route parameters
        let returnUrlQuery = this.route.snapshot.queryParams['returnUrl'] || null
        if (returnUrlQuery) {
            this.returnUrl = decodeURI(returnUrlQuery)
        }
    }

    onSubmit() {
        let f = this.loginForm.controls

        let twoFactorAuthenticationToken = f.twoFactorAuthenticationToken.value
        this.authenticationService.login(f.username.value, f.password.value, f.oneTimePassword.value, twoFactorAuthenticationToken).then((response) => {
            this.authenticationService.setUserAuthenticated(response)
            this.onSuccessfulLogin()

        }).catch((error) => {
            if (error.status == 0 || (error.error as BackendResponse).error == null) {
                this.alertService.handleError(error)
                return
            }

            let backendResponse = error.error as BackendResponse

            // Invalid 2FA token
            if (backendResponse.error.httpStatus == 406) {
                this.alertService.error(backendResponse.error.title)
                this.loginForm.controls.oneTimePassword.reset(null)
                this.loginForm.controls.twoFactorAuthenticationToken.reset(null)

            // User is pending activation
            } else if (backendResponse.error.httpStatus == 303) {
                this.router.navigate([`/pending_activation`], { queryParams: { email: this.loginForm.controls.username.value} })

            // User is required to set up 2FA
            } else if (backendResponse.error.httpStatus == 412) {
                this.alertService.error(backendResponse.error.title)

                this.loginForm.controls.username.disable()
                this.loginForm.controls.password.disable()
                this.twoFactorAuthenticationSecret = backendResponse.error.userInfo['secret']
                this.twoFactorAuthenticationUrl = backendResponse.error.userInfo["authUrl"]

            // User has 2FA enabled, redirect him to 2FA login form
            } else if (backendResponse.error.httpStatus == 307) {
                this.loginForm.controls.username.disable()
                this.loginForm.controls.password.disable()
                this.showOneTimePasswordInput = true

            } else {
                this.alertService.error(backendResponse.error.title)
            }
        })
    }

    private onSuccessfulLogin() {
        if (this.returnUrl) {
            this.router.navigateByUrl(this.returnUrl)
        } else {
            this.router.navigate([this.routeHelper.currentWorkspaceHome()])
        }
    }
}
