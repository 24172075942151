<div class="navbar navbar-expand-lg navbar-static navbar-light desktop-only" *ngIf="workspace">
    <div class="navbar-header navbar-dark d-none d-md-flex align-items-md-center">
        <div class="navbar-brand navbar-brand-md">
            <a [routerLink]="['/', workspace.slug]" class="d-inline-block">
                <img src="assets/img/logo_light.png" alt="">
            </a>
        </div>

        <div class="navbar-brand navbar-brand-xs">
            <a [routerLink]="['/', workspace.slug]" class="d-inline-block">
                <img src="assets/img/logo_square.png" alt="">
            </a>
        </div>
    </div>

    <subscription-notice class="ml-auto mr-2"></subscription-notice>
    <navbar-user-menu></navbar-user-menu>
</div>

<mobile-warning class="mobile-only"></mobile-warning>

<!-- Page content -->
<div class="page-content desktop-only">

    <!-- Main sidebar -->
    <div class="sidebar sidebar-dark sidebar-main sidebar-expand-lg">

        <!-- Sidebar mobile toggler -->
        <div class="sidebar-mobile-toggler text-center">
            <a href="#" class="sidebar-mobile-main-toggle">
                <i class="icon-arrow-left8"></i>
            </a>
            Navigation
            <a href="#" class="sidebar-mobile-expand">
                <i class="icon-screen-full"></i>
                <i class="icon-screen-normal"></i>
            </a>
        </div>
        <!-- /sidebar mobile toggler -->

        <!-- Sidebar content -->
        <div class="sidebar-content">

            <!-- Application picker -->
            <div class="sidebar-section sidebar-user" *ngIf="application">
                <div class="card-body pb-2">
                    <div class="nav-item dropdown dropdown-user">
                        <a class="navbar-nav-link p-0 media text-white d-flex align-items-center" (click)="showQuickJump()">
                            <div class="mr-2">
                                <img class="app-icon" style="border-color: #252b38;" src="{{application.icon.url}}" width="38" height="38" *ngIf="application.icon">
                                <img class="app-icon" style="border-color: #252b38;" src="assets/img/default_app_icon.jpeg" width="38" height="38" *ngIf="!application.icon">
                            </div>

                            <div class="media-body d-flex align-items-center">
                                <div class="media-title mb-0 font-weight-semibold">{{application.name}}<span class="ml-1 font-weight-thin">{{platformFormatter.platformName(application.platform)}}</span></div>
                                <i class="icon-arrow-down22 ml-auto"></i>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <!-- /Application picker -->

            <!-- Main navigation -->
            <div class="sidebar-section" *ngIf="application">
                <ul class="nav nav-sidebar" data-nav-type="accordion">

                    <!-- Dashboard -->
                    <li class="nav-item">
                        <a [routerLink]="['/', workspace.slug, 'apps', application.slug]" class="nav-link" [class.active]="selectedComponent == 0"><i class="icon-grid"></i><span>App Dashboard</span></a>
                    </li>

                    <li class="nav-item-divider"></li>

                    <!-- User Feedback -->
                    <li class="nav-item-header">
                        <div class="text-uppercase font-size-xs line-height-xs">User Feedback and Issues</div>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="['/', workspace.slug, 'apps', application.slug, 'bugs']" class="nav-link" [class.active]="selectedComponent == 1"><i class="icon-bug2"></i><span>Bug Reports</span></a>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="['/', workspace.slug, 'apps', application.slug, 'crash-groups']" class="nav-link" [class.active]="selectedComponent == 9"><i class="icon-spam"></i><span>Crash Reports</span></a>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="['/', workspace.slug, 'apps', application.slug, 'error-groups']" class="nav-link" [class.active]="selectedComponent == 10"><i class="icon-warning2"></i><span>Error Reports</span></a>
                    </li>

                    <li class="nav-item-divider"></li>

                    <!-- App Performance -->
                    <li class="nav-item-header">
                        <div class="text-uppercase font-size-xs line-height-xs">Performance</div>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="['/', workspace.slug, 'apps', application.slug, 'app-launch']" class="nav-link" [class.active]="selectedComponent == 6"><i class="icon-rocket"></i><span>App Launch</span></a>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="['/', workspace.slug, 'apps', application.slug, 'network']" class="nav-link" [class.active]="selectedComponent == 5"><i class="icon-connection"></i><span>Network</span></a>
                        <!-- TODO: Add live badges? -->
                        <!-- <appdex class="font-weight-semibold" [value]="application.latestNetworkAppdex" [enableTextColoring]="false"></appdex> -->
                    </li>

                    <li class="nav-item-divider"></li>

                    <!-- Test Reports -->
                    <li class="nav-item-header">
                        <div class="text-uppercase font-size-xs line-height-xs">Test Reports</div>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="['/', workspace.slug, 'apps', application.slug, 'test-executions']" class="nav-link" [class.active]="selectedComponent == 2"><i class="icon-clipboard5"></i><span>Test Executions</span></a>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="['/', workspace.slug, 'apps', application.slug, 'test-cases']" class="nav-link" [class.active]="selectedComponent == 3"><i class="icon-drawer3"></i><span>Test Cases</span></a>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="['/', workspace.slug, 'apps', application.slug, 'test-failure-groups']" class="nav-link" [class.active]="selectedComponent == 4"><i class="icon-make-group"></i><span>Failure Groups</span></a>
                    </li>

                    <li class="nav-item-divider"></li>

                    <!-- App Distribution -->
                    <li class="nav-item-header">
                        <div class="text-uppercase font-size-xs line-height-xs">Distribution</div>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="['/', workspace.slug, 'apps', application.slug, 'builds']" class="nav-link" [class.active]="selectedComponent == 7"><i class="icon-server"></i><span>Builds</span></a>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="['/', workspace.slug, 'apps', application.slug, 'distribution-groups']" class="nav-link" [class.active]="selectedComponent == 11"><i class="icon-users"></i><span>Distribution groups</span></a>
                    </li>

                    <li class="nav-item-divider"></li>

                    <!-- Settings -->
                    <li class="nav-item-header">
                        <div class="text-uppercase font-size-xs line-height-xs">Settings</div>
                    </li>

                    <li class="nav-item">
                        <a [routerLink]="['/', workspace.slug, 'apps', application.slug, 'settings']" class="nav-link" [class.active]="selectedComponent == 8"><i class="icon-equalizer"></i><span>Settings</span></a>
                    </li>

                    <!-- Toggle -->
                    <li class="nav-item-divider"></li>
                    <li class="nav-item-header">
                        <div class="text-uppercase font-size-xs line-height-xs">Toggle sidebar</div>
                    </li>
                </ul>

            </div>

            <div class="card-body pt-0">
                <a href="#" class="sidebar-control sidebar-main-toggle text-white">
                    <i class="icon-paragraph-justify3"></i>
                </a>
            </div>
        </div>
    </div>

    <!-- Main content -->
    <div class="router-outlet-container">
        <router-outlet></router-outlet>
    </div>

</div>

<quick-jump-modal #quickJumpModal></quick-jump-modal>
