<!-- Main content -->
<div class="content-wrapper">

    <!-- Content area -->
    <div class="content">

        <div class="row">
            <div class="col-md-12">
                <alert></alert>
            </div>
        </div>

        <div class="row" *ngIf="backendEnvironment">
            <div class="col-md-12">
                <div class="card">

                    <div class="page-header border-bottom-light">
                        <div class="page-header-content header-elements-md-inline">
                            <div class="page-title">
                                <h1 class="font-weight-thin">
                                    <span class="breadcrumb-item">{{backendEnvironment.name}}</span>
                                    <span class="breadcrumb-item active">Settings</span>
                                </h1>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <form [formGroup]="form" *ngIf="form">
                            <div class="form-group">
                                <div class="font-size-xs text-uppercase text-muted mb-1">Tests success threshold <span class="text-danger">*</span></div>

                                <input type="text" formControlName="testsSuccessThreshold" class="form-control">
                                <div class="text-muted mt-1">The minimum percentage of passed tests required to mark the execution as successful for this environment.</div>
                            </div>

                            <div class="form-group">
                                <div class="font-size-xs text-uppercase text-muted mb-1">Test plans affecting status</div>
                                <tag-input style="height: auto;" formControlName="testPlanNames" secondaryPlaceholder="Test plan name" placeholder='+ Test plan' [onTextChangeDebounce]="0" [maxItems]='10' [separatorKeyCodes]="[9, 44, 188]" [animationDuration]="{enter: '0ms', leave: '0ms'}" theme='minimal' class="form-control pl-1 py-0">
                                    <tag-input-dropdown [displayBy]="'display'" [identifyBy]="'value'" [autocompleteItems]="availableTestPlanNames"></tag-input-dropdown>
                                </tag-input>
                                <div class="text-muted mt-1">If specified, only the test executions with matching test plan will affect the environment status. Use comma or tab to enter multiple values.</div>
                            </div>

                            <div class="text-right">
                                <button (click)="saveBackendEnvironment()" [disabled]="form.invalid || !canEditEnvironment" type="button" class="btn bg-primary-400 ml-auto">Save Changes</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>

    </div>
    <!-- /content header -->

</div>
<!-- /main content -->
