import { Component, Input } from '@angular/core';
import { AlertService } from 'app/_services';
import { LogFile, BackendResponse } from 'app/_models';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'test-runner-logs',
    templateUrl: 'test.runner.logs.component.html'
})

export class TestRunnerLogsComponent {

    _logFile: LogFile
    @Input()
    set logFile(logFile: LogFile) {
        this._logFile = logFile
        this.reloadLogs()
    }
    get logFile() { return this._logFile }

    public logFileContent: string

    constructor(
        private http: HttpClient,
        private alertService: AlertService
    ) { }

    private reloadLogs() {
        if (this.logFile == null) {
            this.logFileContent == null
            return
        }

        this.http.get(this.logFile.url, { responseType: 'text' }).subscribe(
            response => {
                this.logFileContent = response

            }, error => {
                this.alertService.handleError(error)
        })
    }

}
