<navbar-unregistered></navbar-unregistered>

<div class="page-content">
    <div class="content-wrapper">
        <div class="content d-flex justify-content-center align-items-center">
            <form class="login-form" [formGroup]="activateAccountForm" (ngSubmit)="onSubmit()">
                <div class="card mb-0">
                    <div class="card-body">
                        <div class="text-center mb-3">
                            <i class="icon-shield-check icon-2x text-primary-400 border-primary-400 border-3 rounded-round p-3 mb-3 mt-1"></i>
                            <h5 class="mb-0">Account pending activation</h5>
                            <span class="d-block text-muted">Enter activation code</span>
                        </div>

                        <alert></alert>

                        <div class="form-group form-group-feedback form-group-feedback-left">
                            <input autofocus type="text" formControlName="activationCode" class="form-control" placeholder="Activation code" maxlength="6">
                            <div class="form-control-feedback">
                                <i class="icon-hash text-muted"></i>
                            </div>
                        </div>

                        <div class="form-group">
                            <button type="submit" [disabled]="activateAccountForm.invalid" class="btn bg-primary-400 btn-block">
                                Activate
                            </button>
                        </div>

                        <div class="form-group text-center text-muted content-divider">
                            <span class="px-2">Haven't received the code?</span>
                        </div>

                        <span class="form-text text-muted">
                            No problem, we are happy to send you a new one! Just click on the button below:
                        </span>
                        <div class="text-center">
                            <button type="button" class="btn btn-link" (click)="resendActivationCode()">Resend activation email</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    </div>
</div>
