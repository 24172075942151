import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MissingSymbolsFile } from 'app/_models';
import { AppConfig } from 'app/app.config';
import { CollectionResponse } from './response';

@Injectable({ providedIn: 'root' })
export class MissingSymbolsService {

    constructor(private http: HttpClient) {
    }

    getMissingSymbolsFiles(applicationId: number, crashReportId: string | null): Promise<CollectionResponse<MissingSymbolsFile>> {
        var url = `${AppConfig.apiBaseUrl}/missingSymbolsFiles?applicationId=${applicationId}`
        if (crashReportId != null) {
            url += `&crashReportId=${crashReportId}`
        }
        return this.http.get<CollectionResponse<MissingSymbolsFile>>(url).toPromise()
    }

}
