import { Component, ViewChild, ElementRef, OnInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { AlertService, AuthenticationService, NetworkLogSearchResult, NetworkOperationsService } from 'app/_services'
import { ApplicationNetworkSegment, ApplicationVersionNetworkSegment, EnvironmentNetworkSegment, EnvironmentVersionNetworkSegment, OverviewNetworkSegment, NetworkDimension, NetworkFilter, NetworkInterfaceNetworkSegment, NetworkOperationNetworkSegment, NetworkSegment, ResponseNetworkSegment, Workspace } from 'app/_models';
import { DateFormatter, HTTPFormatter } from 'app/_helpers';
import { Router } from '@angular/router';

@Component({
    selector: 'request-preview-modal',
    templateUrl: 'request.preview.modal.html',
    styleUrls: [
        'request.preview.modal.css'
    ]
})
export class RequestPreviewModal implements OnInit {

    @ViewChild('closeButton') closeButton: ElementRef

    isSearching = false
    isShowingAlternative = false
    searchResult: NetworkLogSearchResult | null
    alternativeSearchResult: NetworkLogSearchResult | null

    private workspace: Workspace
    private networkFilter: NetworkFilter

    constructor(
        public dateFormatter: DateFormatter,
        private router: Router,
        private alertService: AlertService,
        private changeDetector: ChangeDetectorRef,
        private authenticationService: AuthenticationService,
        private networkOperationsService: NetworkOperationsService,
        private httpFormatter: HTTPFormatter
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue
    }

    findByNetworkFilter(networkFilter: NetworkFilter, searchedSegment: NetworkSegment) {
        // Keep track of networkFilter so that we can reuse it later when searching for a
        // successful network request matching the same filters.
        this.networkFilter = networkFilter

        // Enrich the current network filter with the one form the requested network segment for lookup
        switch (searchedSegment.dimension) {
            case NetworkDimension.Applications: this.networkFilter.applicationId = (searchedSegment as ApplicationNetworkSegment).id; break;
            case NetworkDimension.ApplicationVersions: this.networkFilter.applicationVersionId = (searchedSegment as ApplicationVersionNetworkSegment).id; break;
            case NetworkDimension.EnvironmentVersions: this.networkFilter.backendEnvironmentVersionId = (searchedSegment as EnvironmentVersionNetworkSegment).id; break;
            case NetworkDimension.Environments: this.networkFilter.backendEnvironmentId = (searchedSegment as EnvironmentNetworkSegment).id; break;
            case NetworkDimension.NetworkInterfaces: this.networkFilter.networkInterfaceId = (searchedSegment as NetworkInterfaceNetworkSegment).id; break;
            case NetworkDimension.Operations: this.networkFilter.networkOperationId = (searchedSegment as NetworkOperationNetworkSegment).id; break;
            case NetworkDimension.ResponseCodes: this.networkFilter.responseStatusCode = (searchedSegment as ResponseNetworkSegment).responseCode; break;
            case NetworkDimension.Overview:
                let segment = searchedSegment as OverviewNetworkSegment

                // Override only if not already set by a filter
                if (this.networkFilter.networkOperationId === null || this.networkFilter.networkOperationId === undefined) {
                    this.networkFilter.networkOperationId = segment.networkOperationId
                }
                if (this.networkFilter.responseStatusCode === null || this.networkFilter.responseStatusCode === undefined) {
                    if (segment.responseCode == 200) {
                        this.networkFilter.responseStatusCode = 200299
                    } else {
                        this.networkFilter.responseStatusCode = segment.responseCode
                    }
                }
                break;
        }

        this.searchResult = null
        this.alternativeSearchResult = null
        this.isShowingAlternative = false
        this.isSearching = true
        this.changeDetector.detectChanges()

        this.networkOperationsService.findNetworkLogs(this.workspace.id, this.networkFilter).then((response) => {
            if (response) {
                this.searchResult = response.result
                this.alternativeSearchResult = response.alternativeResult
            }

        }).catch((error) => {
            this.alertService.handleError(error)

        }).finally(() => {
            this.isSearching = false
            this.changeDetector.detectChanges()
        })
    }

    copyRequestAndResponseToClipboard(searchResult: NetworkLogSearchResult) {
        const content = this.httpFormatter.formatNetworkRequestAndResponseLogsForClipboard(searchResult.request, searchResult.response)
        navigator.clipboard.writeText(content)
    }

    showAlternativeLog() {
        this.isShowingAlternative = true
        this.changeDetector.detectChanges()
    }

    navigateToSearchResult(searchResult: NetworkLogSearchResult) {
        this.closeButton.nativeElement.click()

        let path = ['/', this.workspace.slug, 'apps', searchResult.application.slug, 'test-executions', searchResult.testExecution.serialNumber, 'runs', searchResult.testRun.serialNumber]
        let queryParams = { requestId: searchResult.response.requestId }

        setTimeout(() => {
            this.router.navigate(path, { queryParams: queryParams })
        }, 250)
    }

}
