<navbar-settings></navbar-settings>

<div class="page-content">
    <settings-sidebar selectedFeatureIndex="3"></settings-sidebar>

    <div class="content-wrapper">
        <!-- Content area -->
        <div class="content" *ngIf="twoFactorAuthenticationStatus">
            <div class="row">
                <div class="col-md-12">
                    <alert></alert>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="card">

                        <!-- Change password section -->
                        <div class="card-header">
                            <h1 class="font-weight-thin mb-0">Change password</h1>
                        </div>

                        <div class="card-body">
                            <form [formGroup]="updatePasswordForm" (ngSubmit)="submitUpdatePasswordForm()">
                                <div class="form-group">
                                    <label>Old password</label>
                                    <input type="password" formControlName="oldPassword" class="form-control">
                                </div>
                                <div class="form-group">
                                    <label>New password</label>
                                    <input type="password" formControlName="newPassword" class="form-control">
                                </div>
                                <div class="form-group">
                                    <label>Confirm new password</label>
                                    <input type="password" formControlName="newPasswordConfirmation" class="form-control">
                                    <span class="form-text text-muted">Minimum 12 characters and should include uppercase and lowercase letters</span>
                                </div>

                                <button type="submit" [disabled]="updatePasswordForm.invalid" class="btn btn-light">Update password</button>
                            </form>
                        </div>
                    </div>

                    <div class="card">

                        <!-- 2FA section -->
                        <div class="card-header d-flex align-items-center">
                            <h1 class="font-weight-thin mb-0">Two-factor authentication</h1>
                            <span class="ml-2 badge badge-success" *ngIf="twoFactorAuthenticationStatus.enabled">Enabled</span>
                        </div>

                        <!-- Disable 2FA Form  -->
                        <div *ngIf="twoFactorAuthenticationStatus.enabled">
                            <div class="card-body">
                                <label>To disable 2FA, please provide password and the current authentication token:</label>
                                <form [formGroup]="disable2FAForm">
                                    <div class="form-group">
                                        <label>Current password</label>
                                        <input type="password" formControlName="password" class="form-control">
                                    </div>
                                    <div class="form-group">
                                        <label>Current authentication token</label>
                                        <input type="text" formControlName="token" maxlength="6" class="form-control">
                                    </div>
                                </form>
                                <button (click)="submitDisable2FAForm()" type="button" [disabled]="disable2FAForm.invalid" class="btn bg-danger">Disable Two Factor Authentication</button>
                            </div>
                        </div>

                        <div *ngIf="twoFactorAuthenticationStatus.enabled == false">
                            <!-- Download the App -->
                            <div class="card-body">
                                <p>
                                    Two-factor authentication adds an extra layer of security to your account. In addition to your username and password, you’ll need to enter a code from an app on your phone.
                                </p>
                                <label>Setting up two-step verification is easy, just follow the steps below.</label>

                                <!-- Download the app -->
                                <div class="media">
                                    <i class="mr-3 icon-cloud-download text-primary-400 icon-2x mt-1"></i>

                                    <div class="media-body">
                                        <h6 class="media-title font-weight-semibold">Download a two-step verification app</h6>
                                        <ul>
                                            <li><a href="https://authy.com/download/">Authy</a></li>
                                            <li><a href="https://www.microsoft.com/en/security/mobile-authenticator-app">Microsoft Authenticator</a></li>
                                        </ul>
                                    </div>
                                </div>

                                <hr>

                                <!-- Scan/enter code -->
                                <div class="media">
                                    <i class="mr-3 icon-enlarge text-primary-400 icon-2x mt-1"></i>

                                    <div class="media-body">
                                        <h6 class="media-title font-weight-semibold mb-2">Scan this QR code with your verification app</h6>

                                        <div class="row">
                                            <div class="col-xs-6">
                                                <qrcode class="ml-2 mr-2" [qrCodeData]="twoFactorAuthenticationStatus.authUrl"></qrcode>
                                            </div>
                                            <div class="col-xs-6">
                                                <p></p>
                                                <p><strong>Can't scan the code?</strong> You can add the code to your application manually using the following details:</p>
                                                <p><strong>Account:</strong> {{twoFactorAuthenticationStatus.account}}</p>
                                                <p><strong>Key:</strong><span><code>{{twoFactorAuthenticationStatus.secret}}</code></span></p>
                                                <p><strong>Time based:</strong> Yes</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr>

                                <!-- Enter confirmation  -->
                                <div class="media">
                                    <i class="mr-3 icon-file-text2 text-primary-400 icon-2x mt-1"></i>

                                    <div class="media-body">
                                        <h6 class="media-title font-weight-semibold">Enter the resulting verification code</h6>

                                        <form [formGroup]="enable2FAForm">
                                            <div class="form-group">
                                                <input type="text" formControlName="token" maxlength="6" class="form-control">
                                            </div>
                                        </form>
                                        <button (click)="submitEnable2FAForm()" type="button" [disabled]="enable2FAForm.invalid" class="btn bg-success-400">Enable Two Factor Authentication</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
        <!-- /content header -->

    </div>

</div>
