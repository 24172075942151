<div class="media mb-2 mr-1">
    <div class="mr-2 mt-1">
        <span class="badge badge-flat border-grey-300 text-grey-300 badge-icon"><i class="{{icon}}"></i></span>
    </div>
    <div class="hide-overflow">
        <div class="text-muted text-uppercase font-weight-light font-size-base">{{title}}</div>
        <div class="font-weight-semibold hide-overflow breakall" *ngIf="value">{{value}}</div>
        <div class="text-muted font-weight-light font-size-base" *ngIf="!value">—</div>
    </div>
</div>
