import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BugReport, BugReportSummary } from 'app/_models';
import { AppConfig } from 'app/app.config';
import { CollectionResponse, ElementResponse } from './response';

@Injectable({ providedIn: 'root' })
export class BugReportsService {

    @Output() bugReportDeletedWithId = new EventEmitter<number>()

    constructor(private http: HttpClient) {
    }

    getBugReportsFrom(applicationId: number, reporterEmail: string | null, from: Date | null): Promise<CollectionResponse<BugReportSummary>> {
        var url = `${AppConfig.apiBaseUrl}/applications/${applicationId}/bugReports`
        if (from !== null) {
            url += `?from=${from.toISOString()}`
        } else {
            url += `?limit=20`
        }

        if (reporterEmail) { url += `&reporterEmail=${reporterEmail}` }

        return this.http.get<CollectionResponse<BugReport>>(url).toPromise()
    }

    getBugReportsPageBefore(applicationId: number, reporterEmail: string | null, pageBefore: Date | null): Promise<CollectionResponse<BugReportSummary>> {
        var url = `${AppConfig.apiBaseUrl}/applications/${applicationId}/bugReports`
        if (pageBefore !== null) {
            url += `?pageBefore=${pageBefore.toISOString()}`
        }

        if (reporterEmail) { url += `&reporterEmail=${reporterEmail}` }

        return this.http.get<CollectionResponse<BugReport>>(url).toPromise()
    }

    getBugReport(applicationId: number, bugReportSlug: string): Promise<ElementResponse<BugReport>> {
        let bugReportSlugSanitized = this.trimNonDigits(bugReportSlug)
        return this.http.get<ElementResponse<BugReport>>(`${AppConfig.apiBaseUrl}/applications/${applicationId}/bugReports?slug=${bugReportSlugSanitized}`).toPromise()
    }

    deleteBugReport(bugReportId: number): Promise<Object> {
        return this.http.delete(`${AppConfig.apiBaseUrl}/bugReports/${bugReportId}`).toPromise()
    }

    private trimNonDigits(input: string): string {
        // Use a regular expression to match and replace non-digit characters at both ends
        return input.replace(/^\D+|\D+$/g, '')
    }

}
