import { EventEmitter, Injectable, Output } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Role, UserLoginResponse } from 'app/_models'
import { AppConfig } from 'app/app.config'
import { WorkspaceMember } from './workspace.service'

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    @Output() userDeleted = new EventEmitter<void>()
    @Output() userEdited = new EventEmitter<void>()

    register(formData: Object): Promise<Object> {
        return this.http.post(`${AppConfig.apiBaseUrl}/users/register`, formData).toPromise()
    }

    registerInvited(userInvitationId: string, name: string, email: string, password: string, twoFactorAuthenticationToken: string | null): Promise<UserLoginResponse> {
        let requestBody = {
            userInvitationId: userInvitationId,
            name: name,
            email: email,
            password: password,
            twoFactorAuthenticationToken: twoFactorAuthenticationToken
        }

        return this.http.post<UserLoginResponse>(`${AppConfig.apiBaseUrl}/users/registerInvited`, requestBody).toPromise()
    }

    resendActivationToken(userEmail: string): Promise<Object> {
        let requestBody = {
            email: userEmail
        }
        return this.http.post(`${AppConfig.apiBaseUrl}/users/resendActivationToken`, requestBody).toPromise()
    }

    updatePassword(oldPassword: string, newPassword: string, newPasswordConfirmation: string): Promise<Object> {
        let requestBody = {
            oldPassword: oldPassword,
            newPassword: newPassword,
            newPasswordConfirmation: newPasswordConfirmation
        }

        return this.http.put(`${AppConfig.apiBaseUrl}/profile/updatePassword`, requestBody).toPromise()
    }

    activateAccount(userEmail: string, activationCode: string): Promise<UserLoginResponse> {
        let requestBody = {
            email: userEmail,
            activationCode: activationCode
        }

        return this.http.patch<UserLoginResponse>(`${AppConfig.apiBaseUrl}/users/activate`, requestBody).toPromise()
    }

    requestPasswordReset(userEmail: string): Promise<Object> {
        let requestBody = {
            email: userEmail
        }
        return this.http.post(`${AppConfig.apiBaseUrl}/users/requestPasswordReset`, requestBody).toPromise()
    }

    resetPassword(userEmail: string, passwordResetCode: string, newPassword: string, twoFactorAuthenticationToken: string | null): Promise<UserLoginResponse> {
        let requestBody = {
            email: userEmail,
            passwordResetCode: passwordResetCode,
            newPassword: newPassword,
            twoFactorAuthenticationToken: twoFactorAuthenticationToken
        }
        return this.http.patch<UserLoginResponse>(`${AppConfig.apiBaseUrl}/users/resetPassword`, requestBody).toPromise()
    }

    updateUser(userId: string, name: string, role: Role): Promise<Object> {
        let body = {
            name: name,
            role: role
        }

        return this.http.put<Object>(`${AppConfig.apiBaseUrl}/users/${userId}`, body).toPromise()
    }

}
