import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Build, ProvisioningProfile } from 'app/_models';
import { AppConfig } from 'app/app.config';
import { ElementResponse } from './response';

@Injectable({ providedIn: 'root' })
export class ProvisioningProfilesService {

    constructor(private http: HttpClient) {
    }

    getProvisioningProfile(id: string): Promise<ElementResponse<ProvisioningProfile>> {
        var url = `${AppConfig.apiBaseUrl}/provisioningProfiles/${id}`
        return this.http.get<ElementResponse<ProvisioningProfile>>(url).toPromise()
    }

}
