import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Build } from 'app/_models';
import { AppConfig } from 'app/app.config';
import { CollectionResponse, ElementResponse } from './response';

export class BuildsListResponse {
    builds: [Build]
    beforeCount: number
}

@Injectable({ providedIn: 'root' })
export class BuildService {

    constructor(private http: HttpClient) {
    }

    getBuildsFrom(from: Date | null, applicationId: number): Promise<CollectionResponse<Build>> {
        var url = `${AppConfig.apiBaseUrl}/applications/${applicationId}/builds`
        if (from !== null) {
            url += `?from=${from.toISOString()}`
        } else {
            url += `?limit=20`
        }

        return this.http.get<CollectionResponse<Build>>(url).toPromise()
    }

    getBuildsPageBefore(pageBefore: Date | null, applicationId: number): Promise<CollectionResponse<Build>> {
        var url = `${AppConfig.apiBaseUrl}/applications/${applicationId}/builds`
        if (pageBefore !== null) {
            url += `?pageBefore=${pageBefore.toISOString()}`
        }

        return this.http.get<CollectionResponse<Build>>(url).toPromise()
    }

    getBuildsWithSlug(applicationId: number, buildSlug: string): Promise<CollectionResponse<Build>> {
        return this.http.get<CollectionResponse<Build>>(`${AppConfig.apiBaseUrl}/applications/${applicationId}/builds?slug=${buildSlug}`).toPromise()
    }

    updateBuild(buildId: number, tags: string[]): Promise<ElementResponse<Build>> {
        let requestBody = {
            tags: tags
        }

        return this.http.put<ElementResponse<Build>>(`${AppConfig.apiBaseUrl}/builds/${buildId}`, requestBody).toPromise()
    }

    deleteBuild(build: Build) {
        return this.http.delete(`${AppConfig.apiBaseUrl}/builds/${build.id}`)
    }

    distributeBuild(buildId: number, distributionGroupIds: number[], notifyTesters: boolean): Promise<Object> {
        let requestBody = {
            distributionGroupIds: distributionGroupIds,
            notifyTesters: notifyTesters
        }

        return this.http.post<Object>(`${AppConfig.apiBaseUrl}/builds/${buildId}/distribute`, requestBody).toPromise()
    }

}
