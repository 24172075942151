<div class="content-wrapper" #pageContent>

    <div class="content">
        <div class="row">
            <div class="col-md-12">
                <alert></alert>
            </div>
        </div>

        <div class="row" *ngIf="application && crashFiltersResponse">
            <div class="col-md-12">
                <div class="card">
                    <div class="page-header border-bottom-light">
                        <div class="page-header-content header-elements-md-inline">
                            <div class="page-title">
                                <h1 class="font-weight-thin">
                                    <a [routerLink]="['../']" class="breadcrumb-item">{{application.name}}</a>
                                    <span class="breadcrumb-item active">Crash Groups</span>
                                </h1>

                                <div class="mb-0" *ngIf="crashTrendsSummaryResponse">
                                    <span class="badge badge-light badge-pill mr-1"><count [count]="crashTrendsSummaryResponse.totalCrashesCount"></count> reports</span>
                                    <span class="badge badge-light badge-pill mr-1"><count [count]="crashTrendsSummaryResponse.totalDevicesCount"></count> users</span>
                                </div>
                                <div class="mb-0" *ngIf="crashTrendsSummaryResponse == null">
                                    <span class="badge badge-light">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body pb-0" *ngIf="missingSymbols != null && missingSymbols.length > 0">
                        <div class="alert alert-dismissible alert-warning">
                            <span>Some debug symbols are missing. <a href="javascript:void(0)" class="alert-link" (click)="showUploadSymbolsModal()" data-target="#uploadSymbolsModal" data-toggle="modal">View details</a></span>
                            <button type="button" (click)="dismissMissingSymbolsAlert()" class="close"><span>×</span></button>
                        </div>
                    </div>

                    <!-- FILTERS -->
                    <div class="card-body d-flex align-items-center align-items-baseline">
                        <div class="d-flex align-items-baseline">
                            <h5 class="mb-0 pb-0 mr-2">Filters</h5>

                            <div class="form-group mb-0 d-flex mr-2" *ngIf="crashFiltersResponse.bundleIdentifiers.length > 0">
                                <select id="bundleIdentifierSelect" select2 data-placeholder="App identifier" data-allow-clear="true" class="form-control select2">
                                    <option *ngFor="let bundleIdentifier of crashFiltersResponse.bundleIdentifiers">{{bundleIdentifier.value}}</option>
                                </select>
                            </div>

                            <div class="form-group mb-0 d-flex mr-2" *ngIf="crashFiltersResponse.applicationVersions.length > 0">
                                <select id="applicationVersionSelect" select2 data-placeholder="App version" data-allow-clear="true" class="form-control select2">
                                    <option *ngFor="let applicationVersion of crashFiltersResponse.applicationVersions">{{applicationVersion.bundleShortVersion}}</option>
                                </select>
                            </div>

                            <div class="form-group mb-0 d-flex mr-2" *ngIf="crashFiltersResponse.osVersions.length > 0">
                                <select id="osVersionSelect" select2 data-placeholder="OS version" data-allow-clear="true" class="form-control select2">
                                    <option *ngFor="let osVersion of crashFiltersResponse.osVersions">{{osVersion.version}}</option>
                                </select>
                            </div>
                        </div>

                        <ul class="ml-auto pagination">
                            <li class="page-item active">
                                <date-range [dateRange]="this.selectedDateRange" (onDateRangeChange)="onDateRangeChanged($event)"></date-range>
                            </li>
                        </ul>
                    </div>

                    <!-- TRENDS -->
                    <div class="card-body py-0 my-0">

                        <!-- TRENDS LOADING -->
                        <div class="justify-content-center text-muted d-flex w-100 align-items-center" style="min-height: 280px" *ngIf="!crashTrendsResponse || !crashTrendsSummaryResponse">
                            <div class="align-self-center">
                                <i class="icon-spinner4 spinner mr-2"></i> Loading trends...
                            </div>
                        </div>

                        <!-- TRENDS LOADED -->
                        <!-- <h5 class="mb-0 pb-0">Trends</h5> -->
                        <div class="d-flex" *ngIf="crashTrendsResponse && crashTrendsSummaryResponse">

                            <!-- TRENDS -->
                            <div class="w-100" style="margin-top: 20px; margin-bottom: 20px;">
                                <crashes-over-time-chart [datePoints]="crashTrendsResponse.datePoints" [crashCounts]="crashTrendsResponse.crashCounts" (onDateRangeChange)="onDateRangeChanged($event)"></crashes-over-time-chart>
                            </div>

                            <!-- SUMMARY -->
                            <div class="border-left-light pl-3" style="padding-top: 20px;">
                                <h5 class="mb-0 pb-3">Summary</h5>

                                <div style="width: 180px;">
                                    <crash-trends-summary [crashTrendsSummaryResponse]="crashTrendsSummaryResponse"></crash-trends-summary>
                                </div>
                            </div>
                        </div>
                    </div>

                    <table class="table" *ngIf="crashGroupSegments && crashGroupSegments.length > 0">
                        <thead>
                            <tr role="row">
                                <th class="border-0">#</th>
                                <th class="border-bottom-0">Crash group</th>
                                <th class="border-bottom-0 nowrap text-right"><sortable [name]="'Crashes'" [ascValue]="CrashSegmentsOrder.COUNT_ASC" [descValue]="CrashSegmentsOrder.COUNT_DESC" [default]="CrashSegmentsOrder.COUNT_DESC"></sortable></th>
                                <th class="border-bottom-0 nowrap text-right"><sortable [name]="'Users'" [ascValue]="CrashSegmentsOrder.AFFECTED_USERS_ASC" [descValue]="CrashSegmentsOrder.AFFECTED_USERS_DESC"></sortable></th>
                                <th class="border-bottom-0 nowrap text-center">App versions</th>
                                <th class="border-bottom-0 nowrap text-center">First seen</th>
                                <th class="border-bottom-0 nowrap text-center">Last seen</th>
                                <th class="border-bottom-0 nowrap text-center">Annotation</th>
                                <th class="border-bottom-0 nowrap text-right">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let crashGroupSegment of crashGroupSegments" (click)="navigateToCrashGroup(crashGroupSegment)">
                                <td>
                                    <a [routerLink]="[crashGroupSegment.serialNumber]" class="button-muted mt-2 mb-2">#{{crashGroupSegment.serialNumber}}</a>
                                </td>
                                <td>
                                    <div class="d-flex">
                                        <div class="mr-2">
                                            <span class="badge badge-mark border-pink-400"></span>
                                        </div>
                                        <div class="button-muted breakall">
                                            <div class="align-items-baseline">
                                                <span class="font-weight-semibold breakall">{{crashGroupSegment.exceptionSymbol}}</span>
                                                <span class="text-muted ml-1">{{crashGroupSegment.sourceCodeLocation}}</span>
                                            </div>
                                            <div class="text-muted">
                                                <span *ngIf="crashGroupSegment.exceptionName">{{crashGroupSegment.exceptionName}}</span>
                                                <span class="breakall" *ngIf="crashGroupSegment.exceptionName && crashGroupSegment.exceptionMessage">:</span>
                                                {{crashGroupSegment.exceptionMessage}}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-right" [ngClass]="{ 'text-muted' : crashGroupSegment.state == 2 }">
                                    <count [count]="crashGroupSegment.crashesCount"></count>
                                </td>
                                <td class="text-right" [ngClass]="{ 'text-muted' : crashGroupSegment.state == 2 }">
                                    <count [count]="crashGroupSegment.distinctDeviceIds"></count>
                                </td>
                                <td class="text-center" *ngIf="crashGroupSegment.earliestCrashVersion == crashGroupSegment.latestCrashVersion">
                                    <span class="badge badge-light badge-pill">{{crashGroupSegment.earliestCrashVersion}}</span>
                                </td>
                                <td class="text-center" *ngIf="crashGroupSegment.earliestCrashVersion != crashGroupSegment.latestCrashVersion">
                                    <span class="badge badge-light badge-pill">{{crashGroupSegment.earliestCrashVersion}}</span>…<span class="badge badge-light badge-pill">{{crashGroupSegment.latestCrashVersion}}</span>
                                </td>
                                <td class="nowrap text-center" [ngClass]="{ 'text-muted' : crashGroupSegment.state == 2 }">{{dateFormatter.timeAgoShort(crashGroupSegment.earliestCrashDate)}}</td>
                                <td class="nowrap text-center" [ngClass]="{ 'text-muted' : crashGroupSegment.state == 2 }">{{dateFormatter.timeAgoShort(crashGroupSegment.latestCrashDate)}}</td>
                                <td class="text-center" [ngClass]="{ 'text-muted' : crashGroupSegment.state == 2 }">
                                    <i class="icon-attachment" *ngIf="crashGroupSegment.hasAnnotation"></i>
                                    <span class="text-muted" *ngIf="crashGroupSegment.hasAnnotation == false">-</span>
                                </td>
                                <td class="text-right" [ngClass]="{ 'text-success-600' : crashGroupSegment.state == 1, 'text-muted' : crashGroupSegment.state == 2 }">{{issueStateFormatter.displayName(crashGroupSegment.state)}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

        </div>

    </div>
</div>

<upload-symbols-modal #uploadSymbolsModal></upload-symbols-modal>
