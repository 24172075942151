import { Injectable } from "@angular/core";

@Injectable()
export class PercentageFormatter {

    percentage(value: number): string {
        value = value * 100

        if (value >= 100) { return parseInt(value as any).toFixed(0) }
        else if (value >= 10 ) { return value.toFixed(1) }
        else return value.toFixed(2)
    }

}
