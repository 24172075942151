import { BackendEnvironment, DateRange, NetworkDimension, NetworkFilter, NetworkOperation, NetworkSegment, NetworkTrendsResponse, NetworkTrendsSummaryResponse, NetworkTrendType, TimeFilter, Workspace } from 'app/_models';
import { AlertService, AuthenticationService, BackendEnvironmentService, CollectionResponse, NetworkFiltersResponse, NetworkOperationsService } from 'app/_services';
import { Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Subscription } from 'rxjs';
import { DateFormatter, NetworkInterfaceFormatter, NetworkRequestSourceFormatter, PlatformFormatter, SearchUtils } from 'app/_helpers';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { SearchOptions, OptionData } from 'select2';
import { RequestPreviewModal } from 'app/network/request.preview.modal/request.preview.modal';
import { EditNetworkOperationModal } from 'app/network/edit.network.operation.modal/edit.network.operation.modal';

@Component({
    templateUrl: 'backend.environment.network.component.html'
})
export class BackendEnvironmentNetworkComponent implements OnInit, OnDestroy {

    TimeFilter = TimeFilter
    NetworkDimension = NetworkDimension

    workspace: Workspace

    selectedDateRange: DateRange
    networkTrendType: NetworkTrendType
    networkDimension: NetworkDimension

    networkFiltersResponse: NetworkFiltersResponse
    networkTrendsResponse: NetworkTrendsResponse
    networkTrendsSummaryResponse: NetworkTrendsSummaryResponse
    networkSegments: NetworkSegment[]

    @ViewChild('editNetworkOperationModal') editNetworkOperationModal: EditNetworkOperationModal
    @ViewChild('requestPreviewModal') requestPreviewModal: RequestPreviewModal

    private queryParamsSubscription: Subscription
    private currentBackendEnvironmentSubscription: Subscription

    constructor(
        public platformFormatter: PlatformFormatter,
        public networkInterfaceFormatter: NetworkInterfaceFormatter,
        public networkRequestSourceFormatter: NetworkRequestSourceFormatter,
        public dateFormatter: DateFormatter,
        private route: ActivatedRoute,
        private router: Router,
        private networkOperationsService: NetworkOperationsService,
        private backendEnvironmentService: BackendEnvironmentService,
        private alertService: AlertService,
        private authenticationService: AuthenticationService,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue

        let query = this.route.snapshot.queryParams
        this.selectedDateRange = DateRange.fromParams(query) ?? DateRange.lastDay()
        this.networkTrendType = query['trendType'] ?? NetworkTrendType.Appdex
        this.networkDimension = query['dimension'] ?? NetworkDimension.Overview
        this.updateSelect2SelectionFromQuery()

        this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
            this.updateSelect2SelectionFromQuery()

            // If network type changed, it means this is the only change
            let newNetworkTrendType = params['trendType'] ?? NetworkTrendType.Appdex
            if (this.networkTrendType != newNetworkTrendType) {
                this.networkTrendType = newNetworkTrendType
                this.refreshNetworkTrends()
                return
            }

            // If network dimension changed, it means this is the only change
            let newNetworkDimension = params['dimension'] ?? NetworkDimension.Overview
            if (this.networkDimension != newNetworkDimension) {
                this.networkDimension = newNetworkDimension
                this.refreshNetworkDimensions()
                return
            }

            // In case time filter changed, or any other filter, refresh everything
            this.selectedDateRange = DateRange.fromParams(params) ?? DateRange.lastDay()
            if (this.networkFiltersResponse) {
                this.refreshNetworkTrends()
                this.refreshNetworkDimensions()
            }
        })

        this.currentBackendEnvironmentSubscription = this.backendEnvironmentService.currentBackendEnvironment.subscribe((environment) => {
            if (environment != null) {
                // This part is needed in order to property recreate select2 components when currentBackendEnvironment changes
                this.networkFiltersResponse = null
                this._didInitSelect2 = false
                this.destroySelect2Components()

                this.refreshFilters()
                this.titleService.setTitle(`${environment.name} | Network`)
            }
        })
    }

    ngOnDestroy() {
        this.currentBackendEnvironmentSubscription.unsubscribe()
        this.queryParamsSubscription.unsubscribe()
    }

    private _didInitSelect2 = false
    ngAfterViewChecked() {
        if (!this.networkFiltersResponse) { return }

        if (!this._didInitSelect2 && $('.select2').length > 0) {
            this.initSelect2Components()
            this.updateSelect2SelectionFromQuery()
            this._didInitSelect2 = true
        }
    }

    private refreshFilters() {
        let needsFilterResponseForFetchingTrends = this.makeCurrentNetworkFilter() == null

        if (!needsFilterResponseForFetchingTrends) {
            this.refreshNetworkTrends()
            this.refreshNetworkDimensions()
        }

        this.networkOperationsService.getWorkspaceNetworkFilters(this.workspace.id).then((response) => {
            this.networkFiltersResponse = response
            this.updateSelect2SelectionFromQuery()

            if (needsFilterResponseForFetchingTrends) {
                this.refreshNetworkTrends()
                this.refreshNetworkDimensions()
            }

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private refreshNetworkTrends() {
        this.networkTrendsResponse = null
        this.networkTrendsSummaryResponse = null

        let networkFilter = this.makeCurrentNetworkFilter()

        this.networkOperationsService.getNetworkTrends(this.workspace.id, this.networkTrendType, networkFilter).then((response) => {
            this.networkTrendsResponse = response
            this.selectedDateRange = response.dateRange
            if (response.summary) {
                this.networkTrendsSummaryResponse = response.summary
            } else {
                this.networkOperationsService.getNetworkTrendsSummary(this.workspace.id, this.networkTrendType, networkFilter).then((response) => {
                    this.networkTrendsSummaryResponse = response
                })
            }
        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private refreshNetworkDimensions() {
        let networkFilter = this.makeCurrentNetworkFilter()
        this.networkOperationsService.getNetworkSegments(this.workspace.id, this.networkDimension, networkFilter).then((response) => {
            this.networkSegments = (response as CollectionResponse<NetworkSegment>).data

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    setTimeFilter(timeFilter: TimeFilter) {
        this.router.navigate([], { queryParams: { history: timeFilter }, queryParamsHandling: 'merge' })
    }

    setNetworkDimension(networkDimension: NetworkDimension) {
        this.networkSegments = null
        this.router.navigate([], { queryParams: { dimension: networkDimension }, queryParamsHandling: 'merge' })
    }

    onNetworkTrendTypeChanged(networkTrendType: NetworkTrendType) {
        this.router.navigate([], { queryParams: { trendType: networkTrendType }, queryParamsHandling: 'merge' })
    }

    onDateRangeChanged(dateRange: DateRange) {
        this.router.navigate([], { queryParams: { startDate: moment(dateRange.startDate).toISOString(), endDate: moment(dateRange.endDate).toISOString() }, queryParamsHandling: 'merge' })
    }

    onFindNetworkLog(networkSegment: NetworkSegment) {
        let networkFilter = this.makeCurrentNetworkFilter()
        this.requestPreviewModal.findByNetworkFilter(networkFilter, networkSegment)
    }

    onEditNetworkOperation(networkOperationId: number) {
        this.editNetworkOperationModal.networkOperationId = networkOperationId
    }

    onNetworkOperationUpdated() {
        this.refreshNetworkDimensions()
    }

    private makeCurrentNetworkFilter(): NetworkFilter {
        let backendEnvironment = this.backendEnvironmentService.currentBackendEnvironmentValue
        if (!backendEnvironment) {
            return null
        }

        let query = this.route.snapshot.queryParams

        let networkFilter = new NetworkFilter()
        networkFilter.startDate = moment(this.selectedDateRange.startDate).toDate()
        networkFilter.endDate = moment(this.selectedDateRange.endDate).toDate()

        if (query['environment']) {
            if (query['environment'] == backendEnvironment.name) {
                networkFilter.backendEnvironmentId = backendEnvironment.id
            } else if (this.networkFiltersResponse) {
                networkFilter.backendEnvironmentId = this.networkFiltersResponse.environments.find((environment) => { return environment.name == query['environment'] }).id
            } else {
                return null
            }
        }

        if (query['environmentVersion']) {
            if (this.networkFiltersResponse) {
                networkFilter.backendEnvironmentVersionId = this.networkFiltersResponse.environmentVersions.find((environmentVersion) => { return environmentVersion.version == query['environmentVersion'] }).id
            } else {
                return null
            }
        }

        if (query['appVersion']) {
            if (this.networkFiltersResponse) {
                networkFilter.applicationVersionId = this.networkFiltersResponse.applicationVersions.find((v) => { return v.bundleShortVersion == query['appVersion'] }).id
            } else {
                return null
            }
        }

        if (query['app']) {
            networkFilter.applicationId = query['app']
        }

        if (query['source']) {
            networkFilter.source = this.networkRequestSourceFormatter.networkRequestSourceWithName(query['source'])
        }

        if (query['networkInterface']) {
            networkFilter.networkInterfaceId = this.networkInterfaceFormatter.networkInterfaceWithName(query['networkInterface'])
        }

        if (query['responseStatusCode']) {
            networkFilter.responseStatusCode = parseInt(query['responseStatusCode'])
        }

        if (query['networkOperation']) {
            networkFilter.networkOperationId = query['networkOperation']
        }

        if (query['networkError']) {
            networkFilter.networkErrorId = query['networkError']
        }

        if (query['networkProtocol']) {
            networkFilter.networkProtocolId = query['networkProtocol']
        }

        if (query['networkHost']) {
            networkFilter.networkHostId = query['networkHost']
        }

        return networkFilter
    }

    private destroySelect2Components() {
        $('#environmentSelect').select2('destroy')
        $('#environmentVersionSelect').select2('destroy')
        $('#applicationSelect').select2('destroy')
        $('#applicationVersionSelect').select2('destroy')
        $('#networkInterfaceSelect').select2('destroy')
        $('#networkOperationSelect').select2('destroy')
        $('#networkErrorSelect').select2('destroy')
        $('#networkProtocolSelect').select2('destroy')
        $('#networkHostSelect').select2('destroy')
        $('#responseStatusCodeSelect').select2('destroy')
    }

    private initSelect2Components() {
        $('#environmentSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '130px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#environmentVersionSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '130px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#applicationSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '150px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#applicationVersionSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '130px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#networkInterfaceSelect').select2({ minimumResultsForSearch: 10, dropdownAutoWidth: true, width: '120px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#networkOperationSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '200px', matcher: this.matchNetworkOperation }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#networkErrorSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '180px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#networkProtocolSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '150px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#networkHostSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '150px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#responseStatusCodeSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '110px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678

        // Fix for select2 being expanded after clicking on clear button
        $('.select2').on("select2:unselecting", (event) => {
            $(event.target).val(null).trigger('change')
            event.preventDefault()
        })

        // Environments
        $('#environmentSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { environment: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#environmentSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { environment: null }, queryParamsHandling: 'merge' })
        })

        // Environment versions
        $('#environmentVersionSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { environmentVersion: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#environmentVersionSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { environmentVersion: null }, queryParamsHandling: 'merge' })
        })

        // Applications
        $('#applicationSelect').on('select2:select', (event) => {
            let application = this.networkFiltersResponse.applications.find((o) => { return `${o.name} ${this.platformFormatter.platformName(o.platform)}` == event.params.data.id })
            this.router.navigate([], { queryParams: { app: application.id }, queryParamsHandling: 'merge' })
        })
        $('#applicationSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { app: null }, queryParamsHandling: 'merge' })
        })

        // Application versions
        $('#applicationVersionSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { appVersion: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#applicationVersionSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { appVersion: null }, queryParamsHandling: 'merge' })
        })

        // Network interfaces
        $('#networkInterfaceSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { networkInterface: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#networkInterfaceSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { networkInterface: null }, queryParamsHandling: 'merge' })
        })

        // Response status code
        $('#responseStatusCodeSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { responseStatusCode: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#responseStatusCodeSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { responseStatusCode: null }, queryParamsHandling: 'merge' })
        })

        // Operations
        $('#networkOperationSelect').on('select2:select', (event) => {
            let networkOperation = this.networkFiltersResponse.networkOperations.find((o) => { return this.networkOperationOptionName(o) == event.params.data.id })
            this.router.navigate([], { queryParams: { networkOperation: networkOperation.id }, queryParamsHandling: 'merge' })
        })
        $('#networkOperationSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { networkOperation: null }, queryParamsHandling: 'merge' })
        })

        // Error
        $('#networkErrorSelect').on('select2:select', (event) => {
            let networkError = this.networkFiltersResponse.networkErrors.find((e) => { return e.specifier == event.params.data.id })
            this.router.navigate([], { queryParams: { networkError: networkError.id }, queryParamsHandling: 'merge' })
        })
        $('#networkErrorSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { networkError: null }, queryParamsHandling: 'merge' })
        })

        // Protocol
        $('#networkProtocolSelect').on('select2:select', (event) => {
            let networkProtocol = this.networkFiltersResponse.networkProtocols.find((e) => { return e.name == event.params.data.id })
            this.router.navigate([], { queryParams: { networkProtocol: networkProtocol.id }, queryParamsHandling: 'merge' })
        })
        $('#networkProtocolSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { networkProtocol: null }, queryParamsHandling: 'merge' })
        })

        // Host
        $('#networkHostSelect').on('select2:select', (event) => {
            let networkHost = this.networkFiltersResponse.networkHosts.find((e) => { return e.name == event.params.data.id })
            this.router.navigate([], { queryParams: { networkHost: networkHost.id }, queryParamsHandling: 'merge' })
        })
        $('#networkHostSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { networkHost: null }, queryParamsHandling: 'merge' })
        })
    }

    private matchNetworkOperation(params: SearchOptions, data: OptionData): OptionData | null {
        let query = $.trim(params.term)

        if (SearchUtils.searchQueryMatchesSearchableFields(query, [data.text])) {
            return data
        }

        return null
    }

    private updateSelect2SelectionFromQuery() {
        if (!this.workspace || !this.networkFiltersResponse) { return }

        let query = this.route.snapshot.queryParams

        $('#environmentSelect').val(query['environment']).trigger('change')
        $('#environmentVersionSelect').val(query['environmentVersion']).trigger('change')
        $('#applicationVersionSelect').val(query['appVersion']).trigger('change')
        $('#networkInterfaceSelect').val(query['networkInterface']).trigger('change')
        $('#responseStatusCodeSelect').val(query['responseStatusCode']).trigger('change')

        if (query['networkOperation']) {
            let networkOperation = this.networkFiltersResponse.networkOperations.find((o) => { return o.id == query['networkOperation'] })
            if (networkOperation) { $('#networkOperationSelect').val(this.networkOperationOptionName(networkOperation)).trigger('change') }
        } else {
            $('#networkOperationSelect').val(null).trigger('change')
        }

        if (query['networkError']) {
            let networkError = this.networkFiltersResponse.networkErrors.find((e) => { return e.id == query['networkError'] })
            if (networkError) { $('#networkErrorSelect').val(networkError.specifier).trigger('change') }
        } else {
            $('#networkErrorSelect').val(null).trigger('change')
        }

        if (query['networkProtocol']) {
            let networkProtocol = this.networkFiltersResponse.networkProtocols.find((p) => { return p.id == query['networkProtocol'] })
            if (networkProtocol) { $('#networkProtocolSelect').val(networkProtocol.name).trigger('change') }
        } else {
            $('#networkProtocolSelect').val(null).trigger('change')
        }

        if (query['networkHost']) {
            let networkHost = this.networkFiltersResponse.networkHosts.find((p) => { return p.id == query['networkHost'] })
            if (networkHost) { $('#networkHostSelect').val(networkHost.name).trigger('change') }
        } else {
            $('#networkHostSelect').val(null).trigger('change')
        }

        if (query['app']) {
            let application = this.networkFiltersResponse.applications.find((a) => { return a.id == query['app'] })
            if (application) { $('#applicationSelect').val(application.name + ' ' + this.platformFormatter.platformName(application.platform)).trigger('change') }
        } else {
            $('#applicationSelect').val(null).trigger('change')
        }
    }

    networkOperationOptionName(networkOperation: NetworkOperation): string {
        var result = `${networkOperation.method} ${networkOperation.urlPattern}`
        if (networkOperation.name) {
            result += ` ${networkOperation.name}`
        }
        return result
    }

}
