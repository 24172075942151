import { EventEmitter } from "@angular/core";

export class Sort {

    private sortOrder = 1;
    private collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
    });


    constructor() {
    }

    public sortingFunction(property: any, order: any, type = "") {
        if (order === "desc") {
            this.sortOrder = -1;
        }
        return (a: any, b: any) => {
            if (type === "date") {
                return this.sortData(new Date(a[property]), new Date(b[property]));
            } else  {
                let propertyType = typeof a[property]

                if (propertyType == 'number') {
                    return this.sortData(a[property], b[property]);

                } else {
                    return this.collator.compare(a[property], b[property]) * this.sortOrder;
                }
            }
        }
    }

    public sortingFunctionWithDataFunction(dataFunction: any, order: any, type = "") {
        if (order === "desc") {
            this.sortOrder = -1;
        }
        return (a: any, b: any) => {
            let valueA: any = dataFunction(a)
            let valueB: any = dataFunction(b)
            let propertyType = typeof valueA

            if (propertyType == 'number') {
                return this.sortData(valueA, valueB);

            } else {
                return this.collator.compare(valueA, valueB) * this.sortOrder;
            }
        }
    }

    private sortData(a: any, b: any) {
        if (a < b) {
            return -1 * this.sortOrder;
        } else if (a > b) {
            return 1 * this.sortOrder;
        } else {
            return 0 * this.sortOrder;
        }
    }
}
