<div class="content-wrapper" id="pageContent">

    <div class="content">
        <div class="row">
            <div class="col-md-12">
                <alert></alert>
            </div>
        </div>

        <div class="justify-content-center text-muted d-flex w-100 align-items-center" style="height: 90vh" *ngIf="!didFinishLoading">
            <div class="align-self-center">
                <i class="icon-spinner4 spinner mr-2"></i> Loading...
            </div>
        </div>

        <div class="row" *ngIf="didFinishLoading">
            <div class="col-md-12">
                <div class="card mb-1">
                    <div class="page-header border-bottom-light">
                        <div class="page-header-content header-elements-md-inline">
                            <div class="page-title">
                                <h1 class="font-weight-thin">
                                    <a [routerLink]="['../../']" class="breadcrumb-item">{{application.name}}</a>
                                    <a [routerLink]="['../']" class="breadcrumb-item">Failure Groups</a>
                                    <span class="breadcrumb-item active">#{{testFailureGroup.serialNumber}}</span>
                                </h1>
                                <ul class="list-inline list-inline-dotted list-inline-condensed font-weight-thin mb-0">
                                    <li class="list-inline-item">{{testFailureGroup.failureLocation}}</li>
                                    <li class="list-inline-item">Initial failure: {{dateFormatter.dateTime(testFailureGroup.creationDate)}}</li>
                                </ul>
                            </div>

                            <div class="header-elements d-flex">
                                <button type="button" (click)="onAnnotateButtonClick()" class="btn btn-outline bg-primary border-primary text-primary-800 btn-icon" data-toggle="modal" data-target="#editTestFailureGroupModal"><i class="icon-pencil6 mr-2"></i> Annotate</button>
                                <div class="dropdown">
                                    <button type="button" class="btn btn-outline bg-primary border-primary text-primary-800 btn-icon dropdown-toggle ml-2" data-toggle="dropdown">{{issueStateFormatter.displayName(testFailureGroup.state)}}</button>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <a href="javascript:void(0)" (click)="updateIssueState(1)" class="dropdown-item" [ngClass]="{ 'active' : testFailureGroup.state == 1 }">{{issueStateFormatter.displayName(1)}}</a>
                                        <a href="javascript:void(0)" (click)="updateIssueState(0)" class="dropdown-item" [ngClass]="{ 'active' : testFailureGroup.state == 0 }">{{issueStateFormatter.displayName(0)}}</a>
                                        <a href="javascript:void(0)" (click)="updateIssueState(2)" class="dropdown-item" [ngClass]="{ 'active' : testFailureGroup.state == 2 }">{{issueStateFormatter.displayName(2)}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <ng-container *ngIf="testFailureGroup.annotation">
                                    <markdown [content]="testFailureGroup.annotation"></markdown>
                                    <div class="text-muted font-size-sm font-weight-light mt-1 mb-0">Updated {{dateFormatter.timeAgo(testFailureGroup.annotationUpdateDate)}}</div>
                                </ng-container>

                                <ng-container *ngIf="testFailureGroup.annotation == null">
                                    <a href="javascript:void(0)" (click)="onAnnotateButtonClick()" class="font-weight-semibold" data-toggle="modal" data-target="#editTestFailureGroupModal">Annotate this failure</a>
                                    <span class="text-muted"> with contextual information and links to an issue tracking system like JIRA or Trello.</span>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="font-weight-semibold">Failure description</div>
                                <div class="text-muted">{{testFailureGroup.failureDescription}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body d-flex align-items-center">
                        <div class="form-group mb-0 d-flex mr-3" *ngIf="environments && environments.length > 1">
                            <label class="col-form-label mr-2">Environment:</label>
                            <select id="environmentNameSelect" select2 class="form-control select2">
                                <option *ngFor="let environment of environments" [ngValue]="environment.name">{{environment.name}}</option>
                            </select>
                        </div>

                        <ul class="ml-auto pagination-flat align-self-center justify-content-center twbs-flat pagination font-size-sm">
                            <li class="page-item" [ngClass]="{ 'active' : selectedTimeFilter == '1W' }">
                                <a href="#" class="page-link p-1 text-muted" [ngClass]="{'text-muted' : selectedTimeFilter != '1W'}" style="min-width: 0" data-toggle="tab" (click)="setTimeFilter('1W')">1W</a>
                            </li>
                            <li class="page-item" [ngClass]="{ 'active' : selectedTimeFilter == '2W' }">
                                <a href="#" class="page-link p-1 text-muted" [ngClass]="{'text-muted' : selectedTimeFilter != '2W'}" style="min-width: 0" data-toggle="tab" (click)="setTimeFilter('2W')">2W</a>
                            </li>
                            <li class="page-item" [ngClass]="{ 'active' : selectedTimeFilter == '1M' }">
                                <a href="#" class="page-link p-1 text-muted" [ngClass]="{'text-muted' : selectedTimeFilter != '1M'}" style="min-width: 0" data-toggle="tab" (click)="setTimeFilter('1M')">1M</a>
                            </li>
                            <li class="page-item" [ngClass]="{ 'active' : selectedTimeFilter == '3M' }">
                                <a href="#" class="page-link p-1 text-muted" [ngClass]="{'text-muted' : selectedTimeFilter != '3M'}" style="min-width: 0" data-toggle="tab" (click)="setTimeFilter('3M')">3M</a>
                            </li>
                            <li class="page-item" [ngClass]="{ 'active' : selectedTimeFilter == '1Y' }">
                                <a href="#" class="page-link p-1 text-muted" [ngClass]="{'text-muted' : selectedTimeFilter != '1Y'}" style="min-width: 0" data-toggle="tab" (click)="setTimeFilter('1Y')">1Y</a>
                            </li>
                        </ul>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6 d-flex" *ngIf="dashboard.testRunScreenshots.length > 0">
                                <div class="mr-2" style="max-width: 100%;" *ngFor="let screenshot of dashboard.testRunScreenshots">
                                    <div class="card-img-actions">
                                        <img class="img-fluid border-light" style="max-height: 210px" src="{{screenshot.url}}" alt="Screenshot">
                                        <div class="card-img-actions-overlay">
                                            <a href="{{screenshot.url}}" class="btn btn-outline bg-white text-white border-white border-1 mr-1" data-fancybox="gallery">
                                                <i class="icon-zoomin3 mr-2"></i> View
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 d-flex flex-column border-bottom-light" *ngIf="dashboard.widgetView.currentPeriodCount > 0">
                                <h3 class="font-weight-semibold mb-0"><count [count]="dashboard.widgetView.currentPeriodCount"></count></h3>
                                <div>{{dashboard.widgetView.currentPeriodInfo}}</div>
                                <div class="text-muted font-size-sm"><count [count]="dashboard.widgetView.totalCount"></count> total</div>

                                <div class="mt-auto">
                                    <canvas height="120" id="chartCanvas"></canvas>
                                </div>
                            </div>

                            <div class="text-center w-100 text-muted d-block" *ngIf="testRunInfos.length == 0">
                                No test failures matching current filter
                            </div>
                        </div>
                    </div>

                    <table class="table border-bottom-light" *ngIf="dashboard.affectedTestCases.length > 0">
                        <thead>
                            <tr role="row">
                                <th class="border-bottom-0 w-100">Affected test cases</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let testCaseInfo of dashboard.affectedTestCases" (click)="navigateToTestCase(testCaseInfo.testCase)">
                                <td class="d-flex">
                                    <div class="mr-3 ml-1">
                                        <span class="badge badge-mark border-pink-400"></span>
                                    </div>
                                    <div>
                                        <a class="font-weight-semibold button-muted nowrap" [routerLink]="['../../test-cases', testCaseInfo.testCase.serialNumber]">
                                            {{testCaseInfo.testCase.suiteName}}.{{testCaseInfo.testCase.name}}
                                        </a>
                                        <div class="text-muted breakall">{{testCaseInfo.failuresCount}} failures</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table class="table" *ngIf="testRunInfos.length > 0">
                        <thead>
                            <tr role="row">
                                <th class="border-bottom-0 nowrap w-100">Failed test runs</th>
                                <th class="border-bottom-0 nowrap">Time</th>
                                <th class="border-bottom-0 nowrap">Environment</th>
                                <th class="border-bottom-0 nowrap">App version</th>
                                <th class="border-bottom-0 nowrap">Device</th>
                                <th class="border-bottom-0 nowrap">OS</th>
                                <th class="border-bottom-0 nowrap">Locale</th>
                                <th class="border-bottom-0 nowrap">Duration</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let testRunInfo of testRunInfos" (click)="navigateToTestRunInfo(testRunInfo)">
                                <td class="d-flex">
                                    <div class="mr-3 ml-1">
                                        <span class="badge badge-mark border-pink-400"></span>
                                    </div>
                                    <div>
                                        <a class="font-weight-semibold button-muted nowrap" [routerLink]="['../../test-executions', testRunInfo.testExecutionSummary.serialNumber, 'runs', testRunInfo.testRunSummary.serialNumber]">
                                            {{testRunInfo.testCase.suiteName}}.{{testRunInfo.testCase.name}}
                                        </a>
                                        <div class="text-muted breakall">{{testRunInfo.testRunSummary.failureDescription}}</div>
                                    </div>
                                </td>
                                <td class="nowrap">{{dateFormatter.dateTimeShort(testRunInfo.testExecutionSummary.creationDate)}}</td>
                                <td class="nowrap">{{testRunInfo.testExecutionSummary.environment}}</td>
                                <td class="nowrap">{{testRunInfo.testExecutionSummary.testBundleVersion}}</td>
                                <td class="nowrap">{{testRunInfo.testExecutor.deviceModelName}}</td>
                                <td class="nowrap">{{testRunInfo.testExecutor.system}}</td>
                                <td>{{testRunInfo.testRunSummary.locale}}</td>
                                <td class="text-right">{{testRunDuration(testRunInfo)}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</div>

<edit-test-failure-group-modal #editTestFailureGroupModal (testFailureGroupUpdated)="onTestFailureGroupUpdated()"></edit-test-failure-group-modal>
