import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Application, DistributionGroup, DistributionGroupBuild, DistributionGroupUser, Role, Workspace } from 'app/_models';
import { AlertService, ApplicationService, AuthenticationService, DistributionGroupsService, InvitationState, InvitationsService, WorkspaceInvitation, WorkspaceMember, WorkspaceService } from 'app/_services';
import { ByteCountFormatter, PlatformFormatter, DateFormatter } from 'app/_helpers';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeUrl, Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment'
import * as bootbox from 'bootbox'
import { TagModel } from 'ngx-chips/core/accessor';
import { DistributionGroupEditorModal } from '../distribution.group.editor/distribution.group.editor.modal';
import { AppConfig } from 'app/app.config';

@Component({
    templateUrl: 'distribution.group.component.html',
    styleUrls: [
        'distribution.group.component.css'
    ]
})
export class DistributionGroupComponent implements OnInit, OnDestroy {

    workspace: Workspace
    application: Application
    distributionGroup: DistributionGroup
    distributionGroupBuilds: DistributionGroupBuild[]
    distributionGroupUsers: DistributionGroupUser[]
    invitations: WorkspaceInvitation[]
    workspaceMembers: WorkspaceMember[]
    workspaceMemberNames: string[] = []
    canManageTesters = false
    newInvitationForm: FormGroup
    groupDistributionRouterComponents: string[]
    groupDistributionDisplayUrl: string
    rolesDocumentationUrl: SafeUrl

    private currentApplicationSubscription: Subscription
    private distributionGroupEditedSubscription: Subscription
    private routeParamsSubscription: Subscription

    @ViewChild('distributionGroupEditor') distributionGroupEditorModal: DistributionGroupEditorModal

    constructor(
        public dateFormatter: DateFormatter,
        public byteCountFormatter: ByteCountFormatter,
        public platformFormatter: PlatformFormatter,
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private invitationsService: InvitationsService,
        private distributionGroupsService: DistributionGroupsService,
        private applicationService: ApplicationService,
        private authenticationService: AuthenticationService,
        private workspaceService: WorkspaceService,
        private alertService: AlertService,
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue

        let rolesDocumentationUrl = `${AppConfig.developerBaseUrl}/documentation/dashboard/user-roles/`
        this.rolesDocumentationUrl = this.sanitizer.bypassSecurityTrustUrl(rolesDocumentationUrl)

        // Compose a list of allowed roles base on the user's role in the workspace
        switch (this.workspace.role) {
            case Role.Agent:
            case Role.Admin:
                this.canManageTesters = true
                break
            default:
                break
        }

        this.newInvitationForm = this.formBuilder.group({
            emailInput: [null, Validators.required],
        })

        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
        })

        this.routeParamsSubscription = this.route.params.subscribe(params => {
            this.reloadDistributionGroup()
        })

        this.distributionGroupEditedSubscription = this.distributionGroupsService.distributionGroupEdited.subscribe((distributionGroup) => {
            this.distributionGroup = distributionGroup
            this.reloadDistributionGroupDetails()
        })

        this.loadWorkspaceMembers()
    }

    ngOnDestroy() {
        this.currentApplicationSubscription.unsubscribe()
        this.distributionGroupEditedSubscription.unsubscribe()
        this.routeParamsSubscription.unsubscribe()
    }

    sendInvitation() {
        var newUserEmails: string[] = []
        var existingUserIds: string[] = []

        let tagModels = (this.newInvitationForm.controls.emailInput.value as TagModel[])
        for (const tagModel of tagModels) {
            let tagValue = tagModel['value']

            if (tagValue.includes("@")) {
                newUserEmails.push(tagValue)
            } else {
                let existingMember = this.workspaceMembers.filter((member) => { return member.name == tagValue }).shift()
                existingUserIds.push(existingMember.id)
            }
        }

        var promises: Promise<any>[] = []

        // Create invitations for new users
        for (const newUserEmail of newUserEmails) {
            let promise = this.invitationsService.sendInvitation(this.application.workspaceId, newUserEmail, Role.Tester, this.distributionGroup.id)
            promises.push(promise)
        }

        // Add existing users to distribution group
        if (existingUserIds.length > 0) {
            let promise = this.distributionGroupsService.addUsersToDistributionGroup(existingUserIds, this.distributionGroup.id)
            promises.push(promise)
        }

        Promise.all(promises).then((response) => {
            // Do nothing
        }).catch((error) => {
            this.alertService.handleError(error)

        }).finally(() => {
            this.newInvitationForm.reset()
            this.reloadDistributionGroupDetails()
        })
    }

    resendInvitation(invitation: WorkspaceInvitation) {
        this.invitationsService.updateInvitation(invitation.id, InvitationState.open).then((response) => {
            this.alertService.success("Invitation sent successfully.")
            this.reloadDistributionGroupDetails()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    cancelInvitation(invitation: WorkspaceInvitation) {
        this.invitationsService.updateInvitation(invitation.id, InvitationState.cacelled).then((response) => {
            this.reloadDistributionGroupDetails()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    invitationExpiration(date: Date): string {
        return moment(date).format("MMM Do YYYY")
    }

    invitationExpired(invitation: WorkspaceInvitation): boolean {
        return invitation.state == InvitationState.expired
    }

    onEditDistributionGroupClick() {
        this.distributionGroupEditorModal.distributionGroup = this.distributionGroup
    }

    onDeleteDistributionGroupClick() {
        let weakThis = this

        bootbox.dialog({
            title: `Delete Distribution Group?`,
            message: "The distribution group will be permanently deleted. The builds and user accounts referenced in this group will be kept, but the users will loose access to builds",
            size: 'small',
            buttons: {
                cancel: { label: 'Cancel', className: 'btn-link' },
                delete: {
                    label: 'Delete', className: 'btn-danger', callback: function () {
                        weakThis.deleteDistributionGroup()
                    }
                }
            }
        })
    }

    deleteDistributionGroup() {
        this.distributionGroupsService.deleteDistributionGroup(this.distributionGroup.id).then((result) => {
            this.distributionGroupsService.distributionGroupDeletedWithId.emit(this.distributionGroup.id)
        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    onRemoveMemberClick(membership: DistributionGroupUser) {
        this.distributionGroupsService.deleteDistributionGroupUser(membership.id).then((response) => {
            this.reloadDistributionGroupDetails()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    removeBuildFromGroup(membership: DistributionGroupBuild) {
        this.distributionGroupsService.deleteDistributionGroupBuild(membership.id).then((response) => {
            this.reloadDistributionGroupDetails()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    buildDistributionRouteComponents(membership: DistributionGroupBuild): string[] {
        return AppConfig.makeApplicationDistributionUrlComponents(this.application.slug, this.workspace.slug, membership.build.serialNumber)
    }

    navigateToBuild(distributionGroupBuild: DistributionGroupBuild) {
        this.router.navigate(['../../builds', distributionGroupBuild.build.serialNumber], { relativeTo: this.route })
    }

    private loadWorkspaceMembers() {
        this.workspaceService.getWorkspaceMembers(this.workspace).then((response) => {
            this.workspaceMembers = response.data
            this.workspaceMemberNames = this.workspaceMembers.map((member) => { return member.name })
        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private reloadDistributionGroup() {
        if (!this.application) { return }

        let distributionGroupSlug = this.route.snapshot.params['distributionGroupSlug']
        if (distributionGroupSlug == null || (this.distributionGroup && this.distributionGroup.serialNumber == distributionGroupSlug)) {
            return
        }

        this.distributionGroupsService.getApplicationDistributionGroupWithSlug(this.application.id, distributionGroupSlug).then((response) => {
            this.distributionGroup = response.data
            this.reloadDistributionGroupDetails()
        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private reloadDistributionGroupDetails() {
        if (!this.distributionGroup) { return }

        this.groupDistributionRouterComponents = AppConfig.makeApplicationDistributionUrlComponents(this.application.slug, this.workspace.slug, null)
        this.groupDistributionDisplayUrl = AppConfig.makeApplicationDistributionUrl(this.application.slug, this.workspace.slug, null)

        let usersPromise = this.distributionGroupsService.getDistributionGroupUsers(this.distributionGroup.id)
        let invitationsPromise = this.invitationsService.getWorkspaceInvitations(this.application.workspaceId, this.distributionGroup.id)
        let buildsPromise = this.distributionGroupsService.getDistributionGroupDistributionGroupBuilds(this.distributionGroup.id)

        Promise.all([usersPromise, invitationsPromise, buildsPromise]).then((results) => {
            this.distributionGroupUsers = results[0].data
            this.invitations = results[1].invitations
            this.distributionGroupBuilds = results[2].data

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
