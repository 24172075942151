<div class="d-flex align-items-baseline">
    <samp>
        <a class="text-default mx-1" data-toggle="collapse" href="#xct_{{logId}}">
            <span class="breakall">{{log.message}}</span>
        </a>·<span class="text-secondary ml-1">{{log.duration}}s</span>
    </samp>
</div>

<div id="xct_{{logId}}" class="collapse mb-1" *ngIf="(log.queryChain && log.queryChain.length > 0) || log.launchArguments != null || log.launchEnvironment != null">
    <ng-container *ngIf="log.launchEnvironment != null">
        <p class="font-weight-semibold mt-2 mb-1">Environment:</p>
        <pre class="language-javascript border-0">{{formatedLaunchEnvironment()}}</pre>
    </ng-container>

    <ng-container *ngIf="log.launchArguments != null">
        <p class="font-weight-semibold mt-2 mb-1">Arguments:</p>
        <pre class="language-javascript border-0">{{formatedLaunchArguments()}}</pre>
    </ng-container>

    <ng-container *ngIf="log.queryChain && log.queryChain.length > 0">
        <p class="font-weight-semibold my-1">Query chain:</p>
        <pre class="language-javascript border-0"><ng-container *ngFor="let query of log.queryChain; let i = index">{{" ".repeat(i)}}{{i > 0 ? "↪︎ " : ""}}{{query}}<br></ng-container></pre>
    </ng-container>
</div>
