<div class="content">

    <div class="row">
        <div class="col-md-12">
            <alert></alert>
        </div>
    </div>

    <div class="card" *ngIf="crashReport && crashGroup && callStack">
        <div class="page-header border-bottom-light">
            <div class="page-header-content header-elements-md-inline">
                <div class="page-title">
                    <h1 class="font-weight-thin">
                        <a [routerLink]="['../../../../']" class="breadcrumb-item">{{application.name}}</a>
                        <a [routerLink]="['../../../']" class="breadcrumb-item">Crash Groups</a>
                        <a [routerLink]="['../../']" class="breadcrumb-item">#{{crashGroup.serialNumber}}</a>
                        <span class="breadcrumb-item active">Report #{{crashReport.serialNumber}}</span>
                    </h1>

                    <div class="mb-0">
                        <span class="badge badge-light badge-pill mr-1">Reported on: {{dateFormatter.dateTime(crashReport.crashDate)}} ({{dateFormatter.timeAgoShort(crashReport.crashDate)}})</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-body pb-0" *ngIf="missingSymbols != null && missingSymbols.length > 0">
            <div class="alert alert-dismissible alert-warning">
                <span>Some debug symbols are missing. <a href="javascript:void(0)" class="alert-link" (click)="showUploadSymbolsModal()" data-target="#uploadSymbolsModal" data-toggle="modal">View details</a></span>
                <button type="button" (click)="dismissMissingSymbolsAlert()" class="close"><span>×</span></button>
            </div>
        </div>

        <!-- ANNOTATION -->
        <div class="card-body">
            <h5 class="mb-0 pb-1">Notes</h5>

            <ng-container *ngIf="crashGroup.annotation">
                <markdown [content]="crashGroup.annotation"></markdown>
                <ul class="list-inline list-inline-dotted list-inline-condensed mt-1 mb-0">
                    <li class="list-inline-item"><a href="javascript:void(0)" (click)="onAnnotateButtonClick()" class="font-weight-semibold" data-toggle="modal" data-target="#editCrashGroupModal">Edit annotation</a></li>
                    <li class="list-inline-item"><span class="text-muted font-size-sm font-weight-light">Updated {{dateFormatter.timeAgo(crashGroup.annotationUpdateDate)}}</span></li>
                </ul>
            </ng-container>

            <ng-container *ngIf="crashGroup.annotation == null">
                <a href="javascript:void(0)" (click)="onAnnotateButtonClick()" class="font-weight-semibold" data-toggle="modal" data-target="#editCrashGroupModal">Annotate crash group</a>
                <span class="text-muted"> with contextual information and links to an issue tracking system like JIRA or Trello.</span>
            </ng-container>
        </div>

        <!-- EXCEPTION -->
        <div class="card-body">
            <h5 class="mb-0 pb-1 mr-2">Exception</h5>
            <div class="align-items-baseline font-size-lg">
                <span class="font-weight-semibold">{{crashGroup.exceptionSymbol}}</span>
                <span class="text-muted ml-1">{{crashGroup.sourceCodeLocation}}</span>
            </div>
            <div class="text-muted">
                <span *ngIf="crashGroup.exceptionName">{{crashGroup.exceptionName}}</span>
                <span *ngIf="crashGroup.exceptionName && crashReport.exceptionMessage">:</span>
                {{crashReport.exceptionMessage}}
            </div>
        </div>

        <!-- DETAILS -->
        <div class="card-body pb-2">
            <h5 class="mb-0 pb-1 mr-2">Details</h5>
            <div class="row">
                <value-item class="col-lg-6 col-xl-4" title="App version" value="{{crashReport.appVersion}}" icon="icon-git-branch"></value-item>
                <value-item class="col-lg-6 col-xl-4" title="App id" value="{{crashReport.bundleIdentifier}}" icon="icon-cube4"></value-item>
                <value-item class="col-lg-6 col-xl-4" title="OS" value="{{crashReport.system}}" icon="{{platformFormatter.platformIconName(crashGroup.platform)}}"></value-item>
                <value-item class="col-lg-6 col-xl-4" title="Device" value="{{crashReport.deviceModelName}}" icon="icon-mobile2"></value-item>
                <value-item class="col-lg-6 col-xl-4" title="Locale" value="{{crashReport.locale}}" icon="icon-earth"></value-item>
                <value-item class="col-lg-6 col-xl-4" title="Crashed after" value="{{crashedAfterTimeText()}}" icon="icon-alarm"></value-item>
                <value-item class="col-lg-6 col-xl-4" title="User identifier" value="{{crashReport.userIdentifier}}" icon="icon-user"></value-item>
            </div>
        </div>

        <!-- CALL STACK, RAW REPORT, ATTACHMENT -->
        <div class="card-body">
            <ul class="nav nav-pills">
                <li class="nav-item">
                    <a href="" [ngClass]="{ 'active' : currentTabState == CrashReportTabState.CallStack}" (click)="setCurrentTabState(CrashReportTabState.CallStack)" class="nav-link" data-toggle="tab">Call Stack</a>
                </li>
                <li class="nav-item">
                    <a href="" [ngClass]="{ 'active' : currentTabState == CrashReportTabState.Raw}" (click)="setCurrentTabState(CrashReportTabState.Raw)" class="nav-link" data-toggle="tab">Raw report</a>
                </li>
                <li class="nav-item" *ngIf="crashReport.attachment">
                    <a href="" [ngClass]="{ 'active' : currentTabState == CrashReportTabState.Attachment}" (click)="setCurrentTabState(CrashReportTabState.Attachment)" class="nav-link" data-toggle="tab">Attachment</a>
                </li>
            </ul>

            <call-stack *ngIf="currentTabState == CrashReportTabState.CallStack && callStack" [callStack]="callStack" [crashedSymbol]="crashGroup.exceptionSymbol"></call-stack>
            <pre class="language-javascript w-100" *ngIf="currentTabState == CrashReportTabState.Raw && rawReport">{{rawReport}}</pre>
            <pre class="language-javascript w-100" *ngIf="currentTabState == CrashReportTabState.Attachment && attachment">{{attachment}}</pre>
        </div>
    </div>

</div>

<edit-crash-group-modal #editCrashGroupModal (crashGroupUpdated)="onCrashGroupUpdated()"></edit-crash-group-modal>
<upload-symbols-modal #uploadSymbolsModal></upload-symbols-modal>
