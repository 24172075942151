<!-- <div class="text-center"> -->
    <!-- The container had to have width close to 100% but not exactly as 100% was causing weird issues and delays when resizing window -->
    <div id="canvasContainer_{{chartUuid}}" style="position: relative; height:240px; width:99%">
        <canvas id="{{chartUuid}}"></canvas>
    </div>

    <!-- <div class="d-flex justify-content-center font-size-xs p-2">
        <div><span class="badge badge-mark border-success-600 ml-2 mr-1"></span>Excellent</div>
        <div><span class="badge badge-mark border-success ml-2 mr-1"></span>Good</div>
        <div><span class="badge badge-mark border-orange-400 ml-2 mr-1"></span>Fair</div>
        <div><span class="badge badge-mark border-warning-400 ml-2 mr-1"></span>Poor</div>
        <div><span class="badge badge-mark border-danger-600 ml-2 mr-1"></span>Unacceptable</div>
    </div>
</div> -->
