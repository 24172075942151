import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrashGroup, IssueState, CrashReport, CallStack, CrashFilter, CrashTrendsResponse, CrashTrendsSummaryResponse, OsVersion, ApplicationVersion, CrashGroupSegment, BundleIdentifier, CrashSegmentsOrder } from 'app/_models';
import { AppConfig } from 'app/app.config';
import { CollectionResponse, ElementResponse } from './response';
import { BehaviorSubject, Observable } from 'rxjs';

export class CrashFiltersResponse {
    applicationVersions: ApplicationVersion[]
    bundleIdentifiers: BundleIdentifier[]
    osVersions: OsVersion[]
}

@Injectable({ providedIn: 'root' })
export class CrashesService {

    private currentCrashGroupSubject: BehaviorSubject<CrashGroup>
    public currentCrashGroup: Observable<CrashGroup>

    setCurrentCrashGroup(crashGroup: CrashGroup | null) {
        if (this.currentCrashGroupSubject.value != crashGroup) {
            this.currentCrashGroupSubject.next(crashGroup)
        }
    }

    public get currentCrashGroupValue(): CrashGroup {
        return this.currentCrashGroupSubject.value
    }

    constructor(private http: HttpClient) {
        this.currentCrashGroupSubject = new BehaviorSubject<CrashGroup>(null)
        this.currentCrashGroup = this.currentCrashGroupSubject.asObservable()

    }

    getApplicationCrashFilters(applicationId: number): Promise<CrashFiltersResponse> {
        return this.http.get<CrashFiltersResponse>(`${AppConfig.apiBaseUrl}/applications/${applicationId}/crashFilters`).toPromise()
    }

    getWorkspaceCrashGroups(workspaceId: number, filter: CrashFilter, orderBy: CrashSegmentsOrder): Promise<CollectionResponse<CrashGroupSegment>> {
        var url = `${AppConfig.apiBaseUrl}/workspaces/${workspaceId}/crashGroups?`
        url = this.urlByApplyingCrashFilter(url, filter)
        if (orderBy) {
            url = url += `&orderBy=${orderBy}`
        }

        return this.http.get<CollectionResponse<CrashGroupSegment>>(url).toPromise()
    }

    getWorkspaceCrashGroupWithSlug(workspaceId: number, slug: string): Promise<ElementResponse<CrashGroup>> {
        return this.http.get<ElementResponse<CrashGroup>>(`${AppConfig.apiBaseUrl}/workspaces/${workspaceId}/crashGroups?slug=${slug}`).toPromise()
    }

    getCrashGroupCrashReports(crashGroupId: number, filter: CrashFilter): Promise<CollectionResponse<CrashReport>> {
        var url = `${AppConfig.apiBaseUrl}/crashGroups/${crashGroupId}/crashReports?`
        url = this.urlByApplyingCrashFilter(url, filter)

        return this.http.get<CollectionResponse<CrashReport>>(url).toPromise()
    }

    getCrashGroupLatestCallStack(crashGroupId: number): Promise<CallStack> {
        var url = `${AppConfig.apiBaseUrl}/crashGroups/${crashGroupId}/latestCallStack`
        return this.http.get<CallStack>(url).toPromise()
    }

    getCrashGroupById(crashGroupId: number): Promise<ElementResponse<CrashGroup>> {
        return this.http.get<ElementResponse<CrashGroup>>(`${AppConfig.apiBaseUrl}/crashGroups/${crashGroupId}`).toPromise()
    }

    updateCrashGroup(crashGroupId: number, annotation: string | null, state: IssueState | null): Promise<ElementResponse<CrashGroup>> {
        let body = {
            annotation: annotation,
            state: state
        }

        return this.http.put<ElementResponse<CrashGroup>>(`${AppConfig.apiBaseUrl}/crashGroups/${crashGroupId}`, body).toPromise()
    }

    getCrashTrends(workspaceId: number, filter: CrashFilter): Promise<CrashTrendsResponse> {
        let url = `${AppConfig.apiBaseUrl}/workspaces/${workspaceId}/crashTrends?`
        url = this.urlByApplyingCrashFilter(url, filter)

        return this.http.get<CrashTrendsResponse>(url).toPromise()
    }

    getCrashTrendsSummary(workspaceId: number, filter: CrashFilter): Promise<CrashTrendsSummaryResponse> {
        let url = `${AppConfig.apiBaseUrl}/workspaces/${workspaceId}/crashTrendsSummary?`
        url = this.urlByApplyingCrashFilter(url, filter)

        return this.http.get<CrashTrendsSummaryResponse>(url).toPromise()
    }

    getCrashGroupCrashReportWithSlug(crashGroupId: number, slug: string): Promise<ElementResponse<CrashReport>> {
        return this.http.get<ElementResponse<CrashReport>>(`${AppConfig.apiBaseUrl}/crashGroups/${crashGroupId}/crashReports?slug=${slug}`).toPromise()
    }

    getCrashReportCallStack(crashReportId: string): Promise<CallStack> {
        var url = `${AppConfig.apiBaseUrl}/crashReports/${crashReportId}/callStack`
        return this.http.get<CallStack>(url).toPromise()
    }

    getRawCrashReportContent(crashReportId: string): Promise<string> {
        var url = `${AppConfig.apiBaseUrl}/crashReports/${crashReportId}/rawReport`
        return this.http.get<string>(url).toPromise()
    }

    private urlByApplyingCrashFilter(baseUrl: string, filter: CrashFilter): string {
        var url = baseUrl

        url += `&startDate=${filter.startDate.toISOString()}`
        url += `&endDate=${filter.endDate.toISOString()}`

        if (filter.applicationId != null) { url += `&applicationId=${filter.applicationId}` }
        if (filter.applicationVersionId != null) { url += `&applicationVersionId=${filter.applicationVersionId}` }
        if (filter.bundleIdentifierId != null) { url += `&bundleIdentifierId=${filter.bundleIdentifierId}` }
        if (filter.crashGroupId != null) { url += `&crashGroupId=${filter.crashGroupId}` }
        if (filter.deviceId != null) { url += `&deviceId=${filter.deviceId}` }
        if (filter.osVersionId != null) { url += `&osVersionId=${filter.osVersionId}` }

        return url
    }

}
