import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AlertService, ApplicationService, AuthenticationService, LocalPreferencesService } from 'app/_services';
import { ActivatedRoute } from '@angular/router';
import { Application, Workspace } from 'app/_models';
import { Subscription } from 'rxjs';
import { PlatformFormatter, DateFormatter } from 'app/_helpers'
import { AppComponent } from 'app/app.component';
import { ApplicationDashboardComponent } from './dashboard/application.dashboard.component';
import { BugReportsComponent } from 'app/bug.reports';
import { TestExecutionsComponent } from 'app/test.executions/test.executions.component';
import { TestCasesComponent } from 'app/test.executions/test.cases.component';
import { TestFailureGroupsComponent } from 'app/test.executions/test.failure.groups.component';
import { AppNetworkComponent } from 'app/app.network.component/app.network.component';
import { AppLaunchComponent } from 'app/app.launch/app.launch.component';
import { BuildsComponent } from 'app/builds/builds.component';
import { ApplicationSettingsComponent } from './application.settings.component';
import { TestExecutionComponent } from 'app/test.executions/test.execution.component';
import { TestRunComponent } from 'app/test.executions/test.run.component';
import { TestFailureGroupComponent } from 'app/test.executions/test.failure.group.component';
import { TestCaseComponent } from 'app/test.executions/test.case.component';
import { BuildComponent } from 'app/builds/build.component';
import { CrashesComponent } from 'app/test.executions/crashes.component';
import { AppConfig } from 'app/app.config';
import { CrashGroupComponent } from 'app/test.executions/crash.group.component';
import { CrashReportsComponent } from 'app/crash.reports/crash.reports.component/crash.reports.component';
import { ErrorsComponent } from 'app/error.reports/errors.component/errors.component';
import { ErrorGroupComponent } from 'app/error.reports/error.group.component/error.group.component';
import { ErrorReportsComponent } from 'app/error.reports/error.reports.component/error.reports.component';
import { DistributionGroupsComponent } from 'app/distribution.groups/distribution.groups.component/distribution.groups.component';
import { QuickJumpModal } from 'app/_components/quick.jump.modal/quick.jump.modal';

enum SelectedAppComponent {
    Unknown = -1,
    Dashboard = 0,
    BugReports = 1,
    TestExecutions = 2,
    TestCases = 3,
    FailureGroups = 4,
    Network = 5,
    Launch = 6,
    Builds = 7,
    Settings = 8,
    Crashes = 9,
    Errors = 10,
    DistributionGroups = 11,
}

@Component({
    templateUrl: 'application.component.html',
    styleUrls: [
        'application.component.css'
    ]
})
export class ApplicationComponent implements OnInit, OnDestroy {

    workspace: Workspace
    selectedComponent: SelectedAppComponent

    private _application: Application
    set application(application: Application) {
        this._application = application
        this.applicationService.setCurrentApplication(application)
    }
    get application() { return this._application }

    private routeUrlSubscription: Subscription
    private routeParamsSubscription: Subscription

    @ViewChild('quickJumpModal')
    private quickJumpModal: QuickJumpModal

    constructor(
        public platformFormatter: PlatformFormatter,
        public dateFormatter: DateFormatter,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private authenticationService: AuthenticationService,
        private applicationService: ApplicationService,
        private localPreferencesService: LocalPreferencesService
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue

        // In case there is already injected application from WorkspaceDashboard or other component
        // set it here so that other dependent pages don't have to load for the request that fetches all applications
        this.application = this.applicationService.currentApplicationValue

        this.routeUrlSubscription = this.route.url.subscribe(() => {
            let component: any = this.route.snapshot.firstChild.component
            let constructor: any = component.prototype.constructor.toString()
            let componentName = constructor.substring(constructor.indexOf(' ') + 1, constructor.indexOf('('))

            if (componentName == ApplicationDashboardComponent.prototype.constructor.name) {
                this.selectedComponent = SelectedAppComponent.Dashboard

            } else if (componentName == BugReportsComponent.prototype.constructor.name) {
                this.selectedComponent = SelectedAppComponent.BugReports

            } else if ([TestExecutionsComponent.prototype.constructor.name, TestExecutionComponent.prototype.constructor.name, TestRunComponent.prototype.constructor.name].includes(componentName)) {
                this.selectedComponent = SelectedAppComponent.TestExecutions

            } else if (componentName == TestCasesComponent.prototype.constructor.name || componentName == TestCaseComponent.prototype.constructor.name) {
                this.selectedComponent = SelectedAppComponent.TestCases

            } else if (componentName == TestFailureGroupsComponent.prototype.constructor.name || componentName == TestFailureGroupComponent.prototype.constructor.name) {
                this.selectedComponent = SelectedAppComponent.FailureGroups

            } else if (componentName == AppNetworkComponent.prototype.constructor.name) {
                this.selectedComponent = SelectedAppComponent.Network

            } else if (componentName == AppLaunchComponent.prototype.constructor.name) {
                this.selectedComponent = SelectedAppComponent.Launch

            } else if (componentName == BuildComponent.prototype.constructor.name || componentName == BuildsComponent.prototype.constructor.name) {
                this.selectedComponent = SelectedAppComponent.Builds

            } else if (componentName == ApplicationSettingsComponent.prototype.constructor.name) {
                this.selectedComponent = SelectedAppComponent.Settings

            } else if (componentName == CrashesComponent.prototype.constructor.name || componentName == CrashGroupComponent.prototype.constructor.name || componentName == CrashReportsComponent.prototype.constructor.name) {
                this.selectedComponent = SelectedAppComponent.Crashes

            } else if (componentName == ErrorsComponent.prototype.constructor.name || componentName == ErrorGroupComponent.prototype.constructor.name || componentName == ErrorReportsComponent.prototype.constructor.name) {
                this.selectedComponent = SelectedAppComponent.Errors

            } else if (componentName == DistributionGroupsComponent.prototype.constructor.name) {
                this.selectedComponent = SelectedAppComponent.DistributionGroups

            } else {
                this.selectedComponent = SelectedAppComponent.Unknown
                if (AppConfig.isDebug) {
                    alert("Unhandled component: " + componentName)
                } else {
                    console.log("Unhandled component: " + componentName)
                }
            }
        })

        this.routeParamsSubscription = this.route.params.subscribe(params => {
            this.resolveApplication()
        })

        // Update to current preference
        if (this.localPreferencesService.applicationSideMenuExpanded == true) {
            $('body').removeClass('sidebar-xs')
        } else {
            $('body').addClass('sidebar-xs')
        }

        // Toggle min sidebar class on click
        let localPreferencesService = this.localPreferencesService
        $('.sidebar-main-toggle').on('click', function (e) {
            e.preventDefault()
            $('body').toggleClass('sidebar-xs')

            // Save user preferences
            localPreferencesService.applicationSideMenuExpanded = !$('body').hasClass('sidebar-xs')
        })
    }

    ngOnDestroy() {
        this.routeUrlSubscription.unsubscribe()
        this.routeParamsSubscription.unsubscribe()
        this.application = null
    }

    showQuickJump() {
        this.quickJumpModal.show()
    }

    private resolveApplication() {
        let applicationSlug = this.route.snapshot.params["applicationSlug"]

        // If we have navigated away, reset the application to null
        if (applicationSlug == null || applicationSlug == '') {
            this.application = null
            return
        }

        // If we already have the right application, nothing needs to be done
        if (this.application && this.application.slug == applicationSlug) {
            return
        }

        // Find the application based on applicationSlug
        this.applicationService.getWorkspaceApplicationsWithSlug(applicationSlug).then((response) => {
            this.application = response.data
        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
