import { Injectable } from "@angular/core"
import { Platform } from "../_models/platform"

@Injectable()
export class PlatformFormatter {

    platformName(platform: Platform): string {
        switch (platform) {
            case Platform.Ios: return "iOS"
            case Platform.Android: return "Android"
            case Platform.Tvos: return "tvOS"
            case Platform.Ipados: return "iPadOS"
            default:
                return ""
        }
    }

    platformIconName(platform: Platform): string {
        switch (platform) {
            case Platform.Ios: return "icon-apple2"
            case Platform.Android: return "icon-android"
            case Platform.Tvos: return "icon-apple2"
            case Platform.Ipados: return "icon-apple2"
            default:
                return ""
        }
    }

    availablePlatforms(): Platform[] {
        return [Platform.Android, Platform.Ios, Platform.Ipados, Platform.Tvos]
    }

}
