import { Component, Input } from '@angular/core';
import { PercentageFormatter } from 'app/_helpers'

@Component({
    selector: 'percentage',
    templateUrl: 'percentage.component.html'
})

export class PercentageComponent {

    @Input() value: number

    constructor(
        public percentageFormatter: PercentageFormatter
    ) { }

}
