export enum NetworkInterface {
    None = 0,

    WiFi = 10,

    WiredEthernet = 20,

    CellularUnknown = 30,
    Cellular2G = 32,
    Cellular3G = 33,
    Cellular4G = 34,
    Cellular5G = 35,

    Loopback = 98,
    Unknown = 99
}
