import { Params } from "@angular/router"
import * as moment from 'moment'

export class DateRange {
    startDate: Date | string
    endDate: Date | string

    static lastDay(): DateRange {
        let dateRange = new DateRange()
        dateRange.endDate = moment().toDate()
        dateRange.startDate = moment(dateRange.endDate).subtract(1, 'day').toDate()
        return dateRange
    }

    static lastWeek(): DateRange {
        let dateRange = new DateRange()
        dateRange.endDate = moment().toDate()
        dateRange.startDate = moment(dateRange.endDate).subtract(1, 'week').toDate()
        return dateRange
    }

    static lastMonth(): DateRange {
        let dateRange = new DateRange()
        dateRange.endDate = moment().toDate()
        dateRange.startDate = moment(dateRange.endDate).subtract(1, 'month').toDate()
        return dateRange
    }

    static fromParams(params: Params): DateRange | null {
        let startDateString = params['startDate']
        let endDateString = params['endDate']
        if (!startDateString || !endDateString) {
            return null
        }

        let startDate = moment(startDateString)
        let endDate = moment(endDateString)
        if (!startDate || !endDate) {
            return null
        }

        let dateRange = new DateRange()
        dateRange.startDate = startDate.toDate()
        dateRange.endDate = endDate.toDate()
        return dateRange
    }

}
