import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Chart, ChartDataset } from 'chart.js';
import { PercentageFormatter, UUIDUtils } from 'app/_helpers';
import * as moment from 'moment';
import { DateRange } from 'app/_models';

@Component({
    selector: 'network-errors-chart',
    templateUrl: 'network.errors.chart.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NetworkErrorsChart implements AfterViewChecked {

    private _clientErrors: number[]
    @Input()
    set clientErrors(clientErrors: number[]) {
        if (clientErrors && this._clientErrors !== clientErrors) {
            this._clientErrors = clientErrors
            if (this.chart) {
                this.reloadChart()
            }
        }
    }
    get clientErrors(): number[] { return this._clientErrors }

    private _businessErrors: number[]
    @Input()
    set businessErrors(businessErrors: number[]) {
        if (businessErrors && this._businessErrors !== businessErrors) {
            this._businessErrors = businessErrors
            if (this.chart) {
                this.reloadChart()
            }
        }
    }
    get businessErrors(): number[] { return this._businessErrors }

    private _serverErrors: number[]
    @Input()
    set serverErrors(serverErrors: number[]) {
        if (serverErrors && this._serverErrors !== serverErrors) {
            this._serverErrors = serverErrors
            if (this.chart) {
                this.reloadChart()
            }
        }
    }
    get serverErrors(): number[] { return this._serverErrors }

    private _datePoints: string[]
    @Input()
    set datePoints(datePoints: string[]) {
        if (datePoints && this._datePoints !== datePoints) {
            this._datePoints = datePoints
            if (this.chart) {
                this.reloadChart()
            }
        }
    }
    get datePoints(): string[] { return this._datePoints }

    @Output() onDateRangeChange = new EventEmitter<DateRange>()

    private chart: Chart
    chartUuid: string = UUIDUtils.uuidv4()

    constructor(
        private changeDetector: ChangeDetectorRef,
        private percentageFormatter: PercentageFormatter
    ) {
    }

    // Charts can only be created once the their canvases are rendered, and canvases
    // will only be shown now after all the data is received
    ngAfterViewChecked() {
        if (this.chart || !this.datePoints || !this.clientErrors || !this.businessErrors || !this.serverErrors) { return }
        if ($(`#${this.chartUuid}`).length > 0) {
            this.reloadChart()
        }
    }

    private reloadChart() {
        if (this.chart) {
            this.chart.destroy()
        }

        if (this.clientErrors == null || this.businessErrors == null || this.serverErrors == null || this.datePoints == null) {
            return
        }


        let labels: string[][] = this.datePoints.map((datePoint) => {
            let date = moment(datePoint)
            return [date.format('MMM Do'), date.format('HH:mm')]
        })

        var max: number = 0
        for (let i = 0; i < this.clientErrors.length; i++) {
            let sum = this.clientErrors[i] + this.businessErrors[i] + this.serverErrors[i]
            if (sum > max) { max = sum }
        }

        // Use 100% as max in case there is no data
        max = max == 0 ? 1 : max

        let percentageFormatter = this.percentageFormatter

        this.chart = new Chart(this.chartUuid, {
            type: 'line',
            data: {
                labels: labels,
                datasets: this.makeChartDatasets()
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    },
                    zoom: {
                        zoom: {
                            drag: { enabled: true },
                            mode: 'x',
                            onZoomComplete: this.onZoomComplete.bind(this)
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                animation: false,
                scales: {
                    x: {
                        display: true,
                        ticks: {
                            maxRotation: 0,
                            minRotation: 0,
                            maxTicksLimit: 15,
                            color: '#aaa',
                            font: { size: 12 }
                        }
                    },
                    y: {
                        display: true,
                        stacked: true,
                        suggestedMax: max,
                        suggestedMin: 0,
                        ticks: {
                            callback: function (value: number): string {
                                return percentageFormatter.percentage(value) + "%"
                            },
                            maxTicksLimit: 8,
                            color: '#aaa',
                            font: { size: 12 }
                        }
                    }
                }
            }
        })
        this.changeDetector.detectChanges()
    }

    onZoomComplete(context: { chart: Chart }) {
        const min = Math.min(Math.max(context.chart.scales.x.min, 0), this.datePoints.length - 1)
        const max = Math.min(Math.max(context.chart.scales.x.max, 0), this.datePoints.length - 1)

        let dateRange = new DateRange()
        dateRange.startDate = moment(this.datePoints[min]).toDate()
        dateRange.endDate = moment(this.datePoints[max]).toDate()
        this.onDateRangeChange.emit(dateRange)
    }

    private makeChartDatasets(): ChartDataset[] {
        // Easy online color adjustments
        // https://mdigi.tools/lighten-color/#6dcdff

        var ctx = (document.getElementById(this.chartUuid) as any).getContext("2d")

        // Getting the chart height using getBoundingClientRect() was giving wrong results
        let chartHeight = 190

        // Additional padding caused by artificially increasing maxValue in chart setup (see reloadChart())
        let chartTopPadding = chartHeight * 0.15

        var clientErrorsGradient = ctx.createLinearGradient(0, chartTopPadding, 0, chartHeight)
        clientErrorsGradient.addColorStop(0, "#aaa")
        clientErrorsGradient.addColorStop(1, "#676767")

        var businessErrorsGradient = ctx.createLinearGradient(0, chartTopPadding, 0, chartHeight);
        businessErrorsGradient.addColorStop(0, "#ffba24");
        businessErrorsGradient.addColorStop(1, "#ffa504");

        var serverErrorsGradient = ctx.createLinearGradient(0, chartTopPadding, 0, chartHeight);
        serverErrorsGradient.addColorStop(0, "#f2735e");
        serverErrorsGradient.addColorStop(1, "#bb1918");

        let chartDatasets: ChartDataset[] = [
            {
                data: this.clientErrors,
                backgroundColor: clientErrorsGradient,
                borderColor: '#ffffff00',
                borderWidth: 0.001,
                fill: true,
                pointRadius: 0,
                tension: 0.4
            },
            {
                data: this.businessErrors,
                backgroundColor: businessErrorsGradient,
                borderColor: '#ffffff00',
                borderWidth: 0.001,
                fill: true,
                pointRadius: 0,
                tension: 0.4
            },
            {
                data: this.serverErrors,
                backgroundColor: serverErrorsGradient,
                borderColor: '#ffffff00',
                borderWidth: 0.001,
                fill: true,
                pointRadius: 0,
                tension: 0.4
            }
        ]

        return chartDatasets
    }

}
