import { Component, Input, OnInit } from '@angular/core';
import { XCTActionLog } from 'app/_models/console.logs.response';

@Component({
    selector: 'xct-action-log-content',
    templateUrl: 'xct.action.log.content.html'
})
export class XCTActionLogContent {

    @Input() public log: XCTActionLog | null
    logId = Math.floor((Math.random() * 1_000_000))

    constructor(
    ) {
    }

    formatedLaunchArguments(): string | null {
        if (this.log == null || this.log.launchArguments == null || this.log.launchArguments.length == 0) {
            return null
        }

        return this.log.launchArguments.join("\n")
    }

    formatedLaunchEnvironment(): string | null {
        if (this.log == null || this.log.launchEnvironment == null) {
            return null
        }

        const variablesMap = new Map<string, string>(Object.entries(this.log.launchEnvironment))
        let sortedLaunchArguments = Array.from(variablesMap.keys()).sort()
        if (sortedLaunchArguments.length == 0) {
            return null
        }

        var components = new Array<string>()
        for (let variableName of sortedLaunchArguments) {
            components.push(`${variableName}: ${variablesMap.get(variableName)}`)
        }

        return components.join("\n")
    }

}
