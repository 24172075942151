import { Injectable } from "@angular/core";

export class FormattedCount {
    value: string
    unit: string
}

@Injectable()
export class CountFormatter {

    countString(count: number): string {
        let formattedCount = this.formattedCount(count)
        return formattedCount.value + formattedCount.unit
    }

    formattedCount(count: number): FormattedCount {
        if (isNaN(count)) {
            return { value: "—", unit: "" }
        }
        if (count < 1_000) {
            // If integer, print without decimal places
            if (parseInt(count.toString()) == count) {
                return { value: `${count}`, unit: "" }
            }

            // For small numbers, allow decimal places
            if (count < 0.01) {
                return { value: `${count.toFixed(3)}`, unit: "" }
            } else if (count < 0.1) {
                return { value: `${count.toFixed(2)}`, unit: "" }
            } else if (count < 10) {
                return { value: `${count.toFixed(1)}`, unit: "" }
            } else {
                return { value: `${count.toFixed(0)}`, unit: "" }
            }

        } else if (count < 1_000_000) {
            let thousandsWithOneDecimal = (count / 1_000).toFixed(1)
            return { value: thousandsWithOneDecimal, unit: "k" }

        } else if (count < 1_000_000_000) {
            let thousandsWithOneDecimal = (count / 1_000_000).toFixed(1)
            return { value: thousandsWithOneDecimal, unit: "M" }

        } else {
            let milionsWithOneDecimal = (count / 1_000_000_000).toFixed(1)
            return { value: milionsWithOneDecimal, unit: "B" }
        }
    }

}
