import { Component, Input } from '@angular/core';
import { ByteCountFormatter } from 'app/_helpers'

@Component({
    selector: 'bytes',
    templateUrl: 'byte.count.component.html'
})

export class ByteCountComponent {

    value: string
    units: string

    private _count: number
    @Input()
    set count(count: number) {
        if (count && this._count !== count) {
            this._count = count

            let components = this.byteCountFormatter.stringFromByteCount(count).split(" ")
            this.value = components[0]
            this.units = components[1]
        }
    }
    get count(): number { return this._count }

    constructor(
        public byteCountFormatter: ByteCountFormatter
    ) { }

}
