import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserLoginResponse, BackendResponse } from 'app/_models';
import { AlertService, UserService, AuthenticationService } from 'app/_services';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { RouteHelper } from 'app/_helpers';
import { Title } from '@angular/platform-browser';

@Component({ templateUrl: 'account.pending.activation.component.html' })
export class AccountPendingActivationComponent implements OnInit {

    activateAccountForm: FormGroup
    userLoginResponse: UserLoginResponse;
    userEmail: string

    constructor(
        private authenticationService: AuthenticationService,
        private formBuilder: FormBuilder,
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        private routeHelper: RouteHelper,
        private alertService: AlertService,
        private titleService: Title
    ) {
        let queryParams = this.route.snapshot.queryParams
        this.userEmail = queryParams['email']

        let activationCode = queryParams['activationCode']

        this.activateAccountForm = this.formBuilder.group({
            activationCode: new FormControl(activationCode, Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6)])),
        });

        if (activationCode != null && activationCode.length > 0) {
            this.onSubmit()
        }
    }

    ngOnInit() {
        this.titleService.setTitle("Account Pending Activation")
    }

    onSubmit() {
        let f = this.activateAccountForm.controls
        this.userService.activateAccount(this.userEmail, f.activationCode.value).then((response) => {
            this.authenticationService.setUserAuthenticated(response)
            this.router.navigate([this.routeHelper.currentWorkspaceHome()])
            this.alertService.success("Account successfully activated.")

        }).catch((error) => {
            if (error.status == 0 || (error.error as BackendResponse).error == null) {
                this.alertService.handleError(error)
                return
            }

            let backendResponse = error.error as BackendResponse

            if (backendResponse.error.httpStatus == 406) {
                this.activateAccountForm.controls.activationCode.reset("")
            }

            this.alertService.handleError(error)
        })
    }

    resendActivationCode() {
        this.userService.resendActivationToken(this.userEmail).then((response) => {
            this.alertService.success("Email was successfully sent.")

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
