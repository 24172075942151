import { Component, Input, OnInit } from '@angular/core';
import { BugReportSummary } from 'app/_models';
import { DateFormatter } from 'app/_helpers';

@Component({
    selector: 'bug-reports-list-item',
    templateUrl: 'bug.reports.list.item.html',
    styleUrls: [
        'bug.reports.list.item.css'
    ]
})
export class BugReportsListItem implements OnInit {

    @Input() public bugReport: BugReportSummary
    public email: string
    public title: string
    public time: string

    constructor(
        public dateFormatter: DateFormatter
    ) {
    }

    ngOnInit() {
        this.title = this.truncateWithEllipses(this.bugReport.title, 77)
        this.time = this.dateFormatter.timeAgoShort(this.bugReport.creationDate)

        if (this.bugReport.reporterEmail) {
            this.email = this.truncateWithEllipses(this.bugReport.reporterEmail, 32)

        } else {
            this.email = "<anonymous>"
        }
    }

    private truncateWithEllipses(text: string, max: number) {
        if (text == null || text.length <= max) {
            return text
        }

        return text.substr(0, max - 2) + '...'
    }

}
