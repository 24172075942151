import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, ChangeDetectorRef, OnInit, HostListener } from '@angular/core';
import { AlertService, AuthenticationService, NetworkOperationsService } from 'app/_services'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NetworkOperation, Role } from 'app/_models';

@Component({
    selector: 'edit-network-operation-modal',
    templateUrl: 'edit.network.operation.modal.html'
})
export class EditNetworkOperationModal implements OnInit {

    form: FormGroup
    canEditNetworkOperation = false
    isEditing = false

    private _networkOperationId: number
    @Input()
    public set networkOperationId(networkOperationId: number) {
        if (networkOperationId != null) {
            this._networkOperationId = networkOperationId
            this.reloadNetworkOperation()
        }
    }
    public get networkOperationId(): number { return this._networkOperationId }

    private networkOperation: NetworkOperation | null

    @Output() networkOperationUpdated = new EventEmitter()
    @ViewChild('closeButton') closeButton: ElementRef

    constructor(
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private networkOperationsService: NetworkOperationsService,
        private authenticationService: AuthenticationService,
        private changeDetector: ChangeDetectorRef,
    ) {
    }

    ngOnInit() {
        this.canEditNetworkOperation = this.authenticationService.currentWorkspaceValue.role >= Role.Admin
    }

    private reloadNetworkOperation() {
        this.isEditing = false
        this.networkOperation = null

        if (this.form) {
            this.form.controls.updateAllNetworkOperations.setValue(false)
        }

        this.networkOperationsService.getNetworkOperation(this.networkOperationId).then((result) => {
            this.networkOperation = result
            this.reloadForm()
            this.changeDetector.detectChanges()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private reloadForm() {
        this.form = this.formBuilder.group({
            name: new FormControl(
                {
                    value: this.networkOperation.name,
                    disabled: !this.canEditNetworkOperation
                }
            ),
            annotation: new FormControl(
                {
                    value: this.networkOperation.annotation,
                    disabled: !this.canEditNetworkOperation
                }
            ),
            targetDuration: new FormControl(
                {
                    value: this.networkOperation.targetDuration.toFixed(1) + "s",
                    disabled: !this.canEditNetworkOperation
                },
                Validators.compose([
                    Validators.required,
                    Validators.minLength(1),
                    Validators.pattern(/^\d{1,3}(\.\d{1,3})?s?$/)])
            ),
            updateAllNetworkOperations: new FormControl(
                {
                    value: false,
                    disabled: !this.canEditNetworkOperation
                },
                Validators.required
            ),
        })
    }

    onEditButtonClick() {
        this.isEditing = true
    }

    saveNetworkOperation() {
        let f = this.form.controls
        let name = f.name.value
        let annotation = f.annotation.value
        let targetDuration = parseFloat(f.targetDuration.value.replace("s", ""))
        let updateAll = f.updateAllNetworkOperations.value

        this.networkOperationsService.updateNetworkOperation(this.networkOperationId, name, annotation, targetDuration, updateAll).then((response) => {
            this.closeButton.nativeElement.click()
            this.networkOperationUpdated.emit()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
