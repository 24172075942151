import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ApplicationService, AuthenticationService, AlertService } from 'app/_services'
import { Application, Workspace, BackendResponse } from 'app/_models';
import { Title } from '@angular/platform-browser';

@Component({
    templateUrl: 'applications.settings.component.html'
})
export class ApplicationsSettingsComponent implements OnInit {

    applications: Application[]
    workspace: Workspace

    @ViewChild('closeButton') closeButton: ElementRef

    constructor(
        private applicationService: ApplicationService,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.titleService.setTitle("Settings | Applications")
        this.workspace = this.authenticationService.currentWorkspaceValue
        this.reloadApplications()
    }

    onApplicationCreated() {
        this.reloadApplications()
    }

    private reloadApplications() {
        this.applicationService.getWorkspaceApplications().then((response) => {
            this.applications = response.data

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
