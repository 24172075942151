import { DateRange, NetworkTrendsResponse, NetworkTrendsSummaryResponse, NetworkTrendType } from 'app/_models';
import { Component, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'network-trends',
    templateUrl: 'network.trends.component.html'
})
export class NetworkTrendsComponent {

    readonly NetworkTrendType = NetworkTrendType

    _networkTrendType: NetworkTrendType
    @Input()
    set networkTrendType(networkTrendType: NetworkTrendType) {
        if (this._networkTrendType != networkTrendType) {
            this._networkTrendType = networkTrendType
            this.reload()
        }
    }
    get networkTrendType(): NetworkTrendType { return this._networkTrendType }

    _networkTrendsResponse: NetworkTrendsResponse
    @Input()
    set networkTrendsResponse(networkTrendsResponse: NetworkTrendsResponse) {
        if (this._networkTrendsResponse != networkTrendsResponse) {
            this._networkTrendsResponse = networkTrendsResponse
            this.reload()
        }
    }
    get networkTrendsResponse(): NetworkTrendsResponse { return this._networkTrendsResponse }

    _networkTrendsSummaryResponse: NetworkTrendsSummaryResponse
    @Input()
    set networkTrendsSummaryResponse(networkTrendsSummaryResponse: NetworkTrendsSummaryResponse) {
        if (this._networkTrendsSummaryResponse != networkTrendsSummaryResponse) {
            this._networkTrendsSummaryResponse = networkTrendsSummaryResponse
            this.reload()
        }
    }
    get networkTrendsSummaryResponse(): NetworkTrendsSummaryResponse { return this._networkTrendsSummaryResponse }

    @Output() onNetworkTrendTypeChanged = new EventEmitter<NetworkTrendType>()
    @Output() onDateRangeChange = new EventEmitter<DateRange>()

    constructor(
        private changeDetector: ChangeDetectorRef
    ) {
    }

    setNetworkTrendType(networkTrendType: NetworkTrendType) {
        this.onNetworkTrendTypeChanged.emit(networkTrendType)
        this.changeDetector.detectChanges()
    }

    onDateRangeChanged(dateRange: DateRange) {
        this.onDateRangeChange.emit(dateRange)
    }

    private reload() {
        this.changeDetector.detectChanges()
    }

}
