<div class="w-100 h-100">
    <img class="w-100 h-100 border-1 rounded-circle border-primary" [src]="imageUrl" (load)="onLoad()" (error)="onLoadError()" [style.display]="imageLoaded ? 'block' : 'none'" *ngIf="imageUrl && imageLoadingFailed == false">

    <div [class.bg-primary]="isHighlighted" class="w-100 h-100 border-1 border-primary d-flex justify-content-center align-items-center rounded-pill" *ngIf="imageUrl == null || imageLoadingFailed">
        <!-- The explicit width, height -->
        <svg width="30" height="30" viewBox="0 0 30 30" >
            <text x="51%" y="54%" [style.fill]="isHighlighted ? 'white' : '#333'" dominant-baseline="middle" text-anchor="middle">{{initials}}</text>
        </svg>
    </div>
</div>
