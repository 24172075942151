import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService, TestExecutionsService, WorkspaceService } from 'app/_services';
import { Workspace } from 'app/_models';
import { Observable, Subscription, timer } from 'rxjs';

@Component({
    templateUrl: 'workspace.resolver.component.html',
})
export class WorkspaceResolverComponent implements OnInit, OnDestroy {

    workspace: Workspace

    private timerObservable: Observable<number> = timer(0, 5000)
    private timerSubscription: Subscription

    titleText: string
    subtitleText: string

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private workspaceService: WorkspaceService,
        private testExecutionService: TestExecutionsService,
    ) {
    }

    ngOnInit() {
        let workspaceSlug = this.route.snapshot.params['workspaceSlug']
        let testsCorrelationUuid = this.route.snapshot.queryParams['testsCorrelationUuid']

        if (workspaceSlug == null || testsCorrelationUuid == null) {
            this.alertService.error("Invalid URL", true)
            return
        }

        this.workspaceService.getWorkspaceWithSlug(workspaceSlug).then((workspace) => {
            this.workspace = workspace

            this.timerSubscription = this.timerObservable.subscribe((seconds) => {
                this.resolveTestExecution(testsCorrelationUuid)
            })

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    ngOnDestroy() {
        this.timerSubscription.unsubscribe()
    }

    private resolveTestExecution(testsCorrelationUuid: string) {
        this.testExecutionService.resolveTestExecution(this.workspace.slug, testsCorrelationUuid).then((response) => {
            // If TestExecution doesn't exist, assume it will be created soon. BE will return 204 with no content in this case.
            if (response == null) {
                this.titleText = "Waiting for test execution to start..."
                this.subtitleText = "You will be automatically redirected once it starts."
                return
            }

            // If TestExecution was found, stop the timer and redirect
            this.timerSubscription.unsubscribe()

            // :workspaceSlug/apps/:appSlug/executions/:testExecutionId/
            this.router.navigate(['/', this.workspace.slug, 'apps', response.applicationSlug, 'test-executions', response.serialNumber])

        }).catch((error) => {
            // For any other error, stop the timer and show the error
            this.timerSubscription.unsubscribe()
            this.alertService.handleError(error)
        })
    }

}
