<div id="quickJumpModal" class="modal" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content card">
            <div class="modal-header">
                <h5 class="modal-title">Jump to</h5>
                <button #closeButton type="button" class="close" (click)="hide()">&times;</button>
            </div>

            <div class="modal-body p-0">
                <form [formGroup]="searchForm" class="px-3 pt-3 mb-1">
                    <div class="form-group form-group-feedback form-group-feedback-right">
                        <input id="queryInput" type="text" class="form-control" formControlName="query" placeholder="Search apps, environments, platforms">
                        <div class="form-control-feedback">
                            <i class="icon-search4 font-size-base text-muted"></i>
                        </div>
                    </div>
                </form>

                <!-- SEARCH RESULTS -->
                <div class="px-3 pb-2" style="max-height: 400px; overflow-y: scroll;">
                    <!-- APPLICATIONS -->
                    <div class="font-size-xs text-uppercase text-muted mb-1" *ngIf="filteredApplications.length > 0">Applications</div>
                    <div class="search-result" [class.search-highlight]="application.id == selectedApplicationId" *ngFor="let application of filteredApplications">
                        <a href="javascript:void(0)" (click)="navigateToApplication(application)" class="text-default">
                            <div class="d-flex align-items-center">
                                <img class="app-icon" src="{{application.icon.url}}" width="32" height="32" *ngIf="application.icon">
                                <img class="app-icon" src="assets/img/default_app_icon.jpeg" width="32" height="32" *ngIf="!application.icon">
                                <h4 class="ml-2 mb-0">{{application.name}}<span class="ml-1 text-muted font-weight-thin">{{platformFormatter.platformName(application.platform)}}</span></h4>
                            </div>
                        </a>
                    </div>
                    <!-- Extra space if we have both apps and envs -->
                    <div class="mt-3" *ngIf="filteredApplications.length > 0 && filteredBackendEnvironments.length > 0"></div>

                    <!-- ENVIRONMENTS -->
                    <div class="font-size-xs text-uppercase text-muted mb-1" *ngIf="filteredBackendEnvironments.length > 0">Environments</div>
                    <div class="search-result" [class.search-highlight]="environment.id == selectedBackendEnvironmentId" *ngFor="let environment of filteredBackendEnvironments">
                        <a href="javascript:void(0)" (click)="navigateToBackendEnvironment(environment)" class="text-default">
                            <h4 class="mb-0">{{environment.name}}<span class="ml-1 text-muted font-weight-thin" *ngIf="environment.latestVersion">{{environment.latestVersion.version}}</span></h4>
                        </a>
                    </div>
                </div>
            </div>

            <div class="modal-footer text-center" *ngIf="filteredApplications.length == 0 && filteredBackendEnvironments.length == 0">
                <div class="text-muted align-items-baseline font-size-xs ml-auto mr-auto">
                    <div *ngIf="searchForm.controls.query.value">
                        No results for search query
                    </div>

                    <div *ngIf="!searchForm.controls.query.value">
                        Easy access this menu using keyboard shortcut <span class="badge badge-light" style="font-size: 100%;">J</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
