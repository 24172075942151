import { Component, OnInit, OnDestroy, ViewChild, AfterViewChecked } from '@angular/core';
import { Application, TestFailureGroup, TestRunInfo3, TimeFilter, TestCase, IssueState, BackendEnvironment } from 'app/_models';
import { AlertService, TestFailureGroupsService, TestFailureGroupDashboardResponse, ApplicationService } from 'app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationFormatter, DateFormatter, IssueStateFormatter } from 'app/_helpers';
import { Subscription } from 'rxjs';
import { EditTestFailureGroupModal } from './edit.test.failure.group.modal/edit.test.failure.group.modal';
import { Chart, ChartDataset, Color } from 'chart.js';
import { Title } from '@angular/platform-browser';

@Component({
    templateUrl: 'test.failure.group.component.html',
    styleUrls: [
        'test.failure.group.component.css'
    ]
})
export class TestFailureGroupComponent implements OnInit, AfterViewChecked, OnDestroy {

    application: Application

    @ViewChild('editTestFailureGroupModal') editTestFailureGroupModal: EditTestFailureGroupModal

    testFailureGroup: TestFailureGroup
    testRunInfos: TestRunInfo3[]
    dashboard: TestFailureGroupDashboardResponse
    chart: Chart
    environments: BackendEnvironment[]
    didFinishLoading = false

    selectedTimeFilter: TimeFilter = TimeFilter.OneMonth

    private queryParamsSubscription: Subscription
    private routeParamsSubscription: Subscription
    private currentApplicationSubscription: Subscription

    constructor(
        public dateFormatter: DateFormatter,
        public issueStateFormatter: IssueStateFormatter,
        private testFailureGroupsService: TestFailureGroupsService,
        private route: ActivatedRoute,
        private router: Router,
        private applicationService: ApplicationService,
        private alertService: AlertService,
        private applicationFormatter: ApplicationFormatter,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
            var newTimeFilter = params['history']
            if (newTimeFilter == null) {
                newTimeFilter = TimeFilter.OneMonth
            }

            this.selectedTimeFilter = newTimeFilter
            this.reloadTestRunsAndDashboard()
            this.updateSelect2SelectionFromQuery()
        })

        this.routeParamsSubscription = this.route.params.subscribe(params => {
            this.reloadTestFailureGroup()
        })

        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
            if (this.application != null) {
                this.reloadTestFailureGroup()
            }
        })
    }

    ngOnDestroy() {
        this.currentApplicationSubscription.unsubscribe()
        this.queryParamsSubscription.unsubscribe()
        this.routeParamsSubscription.unsubscribe()
    }

    // Charts can only be created once the their canvases are rendered, and canvases
    // will only be shown now after all the data is received
    private _didInitSelect2 = false
    ngAfterViewChecked() {
        if (this.environments && this.environments.length > 1 && !this._didInitSelect2 && $('.select2').length > 0) {
            this.initSelect2Components()
            this.updateSelect2SelectionFromQuery()
            this._didInitSelect2 = true
        }

        if (this.dashboard && this.dashboard.widgetView.currentPeriodCount > 0 && !this.chart && $('#chartCanvas').length > 0) {
            this.reloadChart()
        }
    }

    setTimeFilter(timeFilter: TimeFilter) {
        this.router.navigate([], { queryParams: { history: timeFilter }, queryParamsHandling: 'merge' })
    }

    onAnnotateButtonClick() {
        this.editTestFailureGroupModal.testFailureGroup = this.testFailureGroup
    }

    reloadTestFailureGroup() {
        if (!this.application) { return }

        let testFailureGroupSlug = this.route.snapshot.params['testFailureGroupSlug']
        this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | Failure Group #${testFailureGroupSlug}`)

        this.testFailureGroupsService.getApplicationFailureGroupsWithSlug(this.application.id, testFailureGroupSlug).then((response) => {
            this.testFailureGroup = response.data[0]

            this.testFailureGroupsService.getTestFailureGroupFilters(this.testFailureGroup.id, this.selectedTimeFilter).then((response) => {
                this.environments = response.environments
                this.updateSelect2SelectionFromQuery()
            })

            this.reloadTestRunsAndDashboard()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    reloadTestRunsAndDashboard() {
        if (!this.testFailureGroup) { return }

        var environmentId: number = null
        let environmentFromQuery = this.environmentWithName(this.route.snapshot.queryParams['environment'])
        if (environmentFromQuery) {
            environmentId = environmentFromQuery.id
        }

        let dashboardFuture = this.testFailureGroupsService.getTestFailureGroupDashboard(this.testFailureGroup.id, environmentId, this.selectedTimeFilter)
        let testRunsFuture = this.testFailureGroupsService.getTestFailureGroupsTestRuns(this.testFailureGroup.id, environmentId, this.selectedTimeFilter)

        Promise.all([dashboardFuture, testRunsFuture]).then((result) => {
            this.dashboard = result[0]
            this.testRunInfos = result[1].data
            this.didFinishLoading = true

            if (this.dashboard.widgetView.currentPeriodCount > 0) {
                // Refresh the chart if loaded already
                if (this.chart) { this.reloadChart() }
            } else {
                // Remove the chart so that it gets rendered again in ngAfterViewChecked
                this.chart = null
            }

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    onTestFailureGroupUpdated() {
        this.reloadTestFailureGroup()
    }

    testRunDuration(testRun: TestRunInfo3): string {
        return (testRun.testRunSummary.duration !== undefined) ? this.dateFormatter.duration(testRun.testRunSummary.duration) : "-"
    }

    navigateToTestRunInfo(testRunInfo: TestRunInfo3) {
        this.router.navigate(['../../test-executions', testRunInfo.testExecutionSummary.serialNumber, 'runs', testRunInfo.testRunSummary.serialNumber], { relativeTo: this.route })
    }

    navigateToTestCase(testCase: TestCase) {
        this.router.navigate(['../../test-cases', testCase.serialNumber], { relativeTo: this.route })
    }

    updateIssueState(state: IssueState) {
        this.testFailureGroupsService.updateTestFailureGroup(this.testFailureGroup.id, this.testFailureGroup.annotation, state).then((response) => {
            this.testFailureGroup = response.data

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private reloadChart() {
        if (this.chart) {
            this.chart.destroy()
        }

        this.chart = new Chart('chartCanvas', {
            type: 'bar',
            data: {
                labels: this.dashboard.widgetView.barChart.labels,
                datasets: [
                    {
                        label: "Failures",
                        data: this.dashboard.widgetView.barChart.datasets[0].series,
                        backgroundColor: "#03a9f4",
                        fill: true
                    }
                ] as ChartDataset[]
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: true,
                        callbacks: {
                            title: function (tooltipItems): string {
                                if (tooltipItems.length == 0) { return null }
                                return tooltipItems[0].label.replace(",", " ")
                            },
                            label: function (tooltipItem): string {
                                return tooltipItem.parsed.y.toString()
                            }
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                animation: false,
                scales: {
                    x: { display: false },
                    y: {
                        display: true,
                        max: this.dashboard.widgetView.barChart.maxValue,
                        ticks: {
                            callback: function (value: number | string): string | number | null | undefined {
                                return value == 0 ? null : value
                            },
                            maxTicksLimit: 5,
                            precision: 0 // disable non integral values,
                        }
                    }
                }
            }
        })
    }

    private initSelect2Components() {
        $('.select2').select2({ placeholder: 'Any', allowClear: true, dropdownAutoWidth: true, width: '250px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678

        // Fix for select2 being expanded after clicking on clear button
        $('.select2').on("select2:unselecting", (event) => {
            $(event.target).val(null).trigger('change')
            event.preventDefault()
        })

        $('#environmentNameSelect').on('select2:select', (event) => {
            let selectedEnvironment = this.environmentWithName(event.params.data.text)
            this.router.navigate([], { queryParams: { environment: selectedEnvironment.name }, queryParamsHandling: 'merge' })
        })
        $('#environmentNameSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { environment: null }, queryParamsHandling: 'merge' })
        })
    }

    private updateSelect2SelectionFromQuery() {
        if (!this.environments) { return }

        let environmentFromQuery = this.environmentWithName(this.route.snapshot.queryParams['environment'])
        if (environmentFromQuery) {
            $('#environmentNameSelect').val(environmentFromQuery.name).trigger('change')
        } else {
            $('#environmentNameSelect').val(null).trigger('change')
        }
    }

    private environmentWithName(environmentName: string): BackendEnvironment | null {
        if (this.environments == null || name == null) { return null }

        return this.environments.find((environment) => { return environment.name == environmentName })
    }

}
