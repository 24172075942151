import { Component, OnInit, OnDestroy } from '@angular/core';
import { BackendResponse, Application, TestCase, TimeFilter, TestStabilityFilter } from 'app/_models';
import { AlertService, ApplicationService, TestCaseServiceInfo, TestCasesService } from 'app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationFormatter, DateFormatter } from 'app/_helpers';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
    templateUrl: 'test.cases.component.html',
    styleUrls: [
        'test.cases.component.css'
    ]
})
export class TestCasesComponent implements OnInit, OnDestroy {

    application: Application
    testCaseSummaries: TestCaseServiceInfo[]

    timeFilter: TimeFilter = TimeFilter.OneWeek
    testStabilityFilter: TestStabilityFilter = TestStabilityFilter.All

    stableCount: number
    stablePercentage: string
    unstableCount: number
    unstablePercentage: string
    failingCount: number
    failingPercentage: string

    testCaseSuiteNames: string[]
    private testCasesMap: Map<string, Array<TestCaseServiceInfo>>

    private routeParamsSubscription: Subscription
    private queryParamsSubscription: Subscription
    private currentApplicationSubscription: Subscription

    constructor(
        public dateFormatter: DateFormatter,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private applicationService: ApplicationService,
        private router: Router,
        private testCasesService: TestCasesService,
        private applicationFormatter: ApplicationFormatter,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
            var newTimeFilter = params['history']
            if (newTimeFilter == null) {
                newTimeFilter = TimeFilter.OneWeek
            }

            // If the time filter has changed, we need to reload test cases,
            // this will also re-apply the stability filter
            if (this.timeFilter != newTimeFilter) {
                this.timeFilter = newTimeFilter
                this.reloadTestCases()
                return
            }

            var newTestStabilityFilter = params['filter']
            if (newTestStabilityFilter == null) {
                newTestStabilityFilter = TestStabilityFilter.All
            }

            // If only the stability filter has changed, filter the test cases again
            if (this.testStabilityFilter != newTestStabilityFilter) {
                this.testStabilityFilter = newTestStabilityFilter
                this.filterTestCases()
            }
        })

        this.routeParamsSubscription = this.route.params.subscribe(params => {
            this.reloadTestCases()
        })

        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
            if (this.application != null) {
                this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | Test Cases`)
                this.reloadTestCases()
            }
        })
    }

    ngOnDestroy() {
        this.routeParamsSubscription.unsubscribe()
        this.queryParamsSubscription.unsubscribe()
        this.currentApplicationSubscription.unsubscribe()
    }

    setTimeFilter(timeFilter: TimeFilter) {
        this.router.navigate([], { queryParams: { history: timeFilter, filter: this.testStabilityFilter } })
    }

    setTestStabilityFilter(testStabilityFilter: TestStabilityFilter) {
        this.router.navigate([], { queryParams: { history: this.timeFilter, filter: testStabilityFilter } })
    }

    reloadTestCases() {
        if (!this.application) {
            return
        }

        this.testCasesService.getTestCasesSummary(this.application.id, this.timeFilter).then((response) => {
            this.testCaseSummaries = response.data
            this.filterTestCases()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    filterTestCases() {
        if (this.testCaseSummaries == null) {
            return
        }

        this.testCasesMap = new Map([])
        this.stableCount = 0
        this.unstableCount = 0
        this.failingCount = 0

        for (let testCaseSummary of this.testCaseSummaries) {
            var shouldIncludeTestCase = false

            if (testCaseSummary.successRate < 0.8) {
                shouldIncludeTestCase = this.testStabilityFilter == TestStabilityFilter.All || this.testStabilityFilter == TestStabilityFilter.Failing
                this.failingCount += 1

            } else if (testCaseSummary.successRate < 1.0) {
                shouldIncludeTestCase = this.testStabilityFilter == TestStabilityFilter.All || this.testStabilityFilter == TestStabilityFilter.Unstable
                this.unstableCount += 1

            } else {
                shouldIncludeTestCase = this.testStabilityFilter == TestStabilityFilter.All || this.testStabilityFilter == TestStabilityFilter.Stable
                this.stableCount += 1
            }

            if (!shouldIncludeTestCase) {
                continue
            }

            var testCasesArray: TestCaseServiceInfo[] = this.testCasesMap.get(testCaseSummary.testCase.suiteName)
            if (testCasesArray == null) {
                testCasesArray = []
            }
            testCasesArray.push(testCaseSummary)
            this.testCasesMap.set(testCaseSummary.testCase.suiteName, testCasesArray)
        }

        this.testCaseSuiteNames = Array.from(this.testCasesMap.keys()).sort()

        let total = this.testCaseSummaries.length
        this.stablePercentage = (this.stableCount / total) * 100 + "%"
        this.unstablePercentage = (this.unstableCount / total) * 100 + "%"
        this.failingPercentage = (this.failingCount / total) * 100 + "%"
    }

    testCaseSummariesForTestSuite(suiteName: string): TestCaseServiceInfo[] {
        return this.testCasesMap.get(suiteName).sort((t1, t2) => {
            return t1.testCase.name.localeCompare(t2.testCase.name)
        })
    }

    testCaseSummaryClass(testCaseSummary: TestCaseServiceInfo): string {
        if (testCaseSummary.successRate == -1) { return "badge badge-mark border-grey-400" }
        else if (testCaseSummary.successRate < 0.8) { return "badge badge-mark border-pink-400" }
        else if (testCaseSummary.successRate < 1.0) { return "badge badge-mark border-orange-400" }
        else { return "badge badge-mark border-success-400" }
    }

    testCaseSummarySuccessRate(testCaseSummary: TestCaseServiceInfo): string {
        if (testCaseSummary.successRate == -1) {
            return "-"
        }

        return (testCaseSummary.successRate * 100).toFixed(0) + "%"
    }

    navigateToTestCase(testCase: TestCase) {
        this.router.navigate([testCase.serialNumber], { relativeTo: this.route })
    }

}
