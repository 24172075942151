export enum TimeFilter {
    OneHour = '1H',
    OneDay = '1D',
    OneWeek = '1W',
    TwoWeeks = '2W',
    OneMonth = '1M',
    TwoMonths = '2M',
    ThreeMonths = '3M',
    SixMonths = '6M',
    OneYear = '1Y'
}
