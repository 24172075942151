import { AfterViewChecked, Component, EventEmitter, Input, Output } from '@angular/core';
import { Chart, ChartDataset, Color } from 'chart.js';
import { PercentageFormatter, UUIDUtils } from 'app/_helpers';
import * as moment from 'moment';
import { AppVersionLaunchRates } from 'app/_services';
import { DateRange } from 'app/_models';

@Component({
    selector: 'app-version-launch-rate-chart',
    templateUrl: 'app.version.launch.rate.chart.html'
})
export class AppVersionLaunchRateChart implements AfterViewChecked {

    private _appVersionLaunchRates: AppVersionLaunchRates[]
    @Input()
    set appVersionLaunchRates(appVersionLaunchRates: AppVersionLaunchRates[]) {
        if (appVersionLaunchRates && this._appVersionLaunchRates !== appVersionLaunchRates) {
            this._appVersionLaunchRates = appVersionLaunchRates
            if (this.chart) {
                this.reloadChart()
            }
        }
    }
    get appVersionLaunchRates(): AppVersionLaunchRates[] { return this._appVersionLaunchRates }

    private _datePoints: string[]
    @Input()
    set datePoints(datePoints: string[]) {
        if (datePoints && this._datePoints !== datePoints) {
            this._datePoints = datePoints
            if (this.chart) {
                this.reloadChart()
            }
        }
    }
    get datePoints(): string[] { return this._datePoints }

    @Output() onDateRangeChange = new EventEmitter<DateRange>()

    private chart: Chart
    chartUuid: string = UUIDUtils.uuidv4()

    constructor(
        private percentageFormatter: PercentageFormatter
    ) {
    }

    // Charts can only be created once the their canvases are rendered, and canvases
    // will only be shown now after all the data is received
    ngAfterViewChecked() {
        if (!this.appVersionLaunchRates || !this.datePoints || this.chart) { return }
        if ($(`#${this.chartUuid}`).length > 0) {
            this.reloadChart()
        }
    }

    private reloadChart() {
        if (this.chart) {
            this.chart.destroy()
        }

        if (this.appVersionLaunchRates == null || this.datePoints == null) {
            return
        }

        let labels: string[][] = this.datePoints.map((datePoint) => {
            let date = moment(datePoint)
            return [date.format('MMM Do'), date.format('HH:mm')]
        })

        let percentageFormatter = this.percentageFormatter
        let appVersionLaunchRates = this.appVersionLaunchRates

        this.chart = new Chart(this.chartUuid, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: this.makeChartDatasets()
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: true,
                        callbacks: {
                            title: function (tooltipItems): string {
                                if (tooltipItems.length == 0) { return null }
                                return tooltipItems[0].label.replace(",", " ")
                            },
                            label: function (tooltipItem): string {
                                let appVersion = appVersionLaunchRates[tooltipItem.datasetIndex].version
                                let percentage = percentageFormatter.percentage(tooltipItem.parsed.y) + "%"

                                return `${appVersion}: ${percentage}`
                            }
                        }
                    },
                    // zoom: {
                    //     zoom: {
                    //         drag: { enabled: true },
                    //         mode: 'x',
                    //         onZoomComplete: this.onZoomComplete.bind(this)
                    //     }
                    // }
                },
                responsive: true,
                maintainAspectRatio: false,
                animation: false,
                scales: {
                    x: {
                        display: true,
                        stacked: true,
                        ticks: {
                            maxRotation: 0,
                            minRotation: 0,
                            maxTicksLimit: 15,
                            color: '#aaa',
                            font: { size: 12 }
                        }
                    },
                    y: {
                        display: true,
                        max: 1.0,
                        stacked: true,
                        ticks: {
                            callback: function (value: any): string {
                                return percentageFormatter.percentage(value) + "%"
                            },
                            maxTicksLimit: 8,
                            color: '#aaa',
                            font: { size: 12 }
                        }
                    }
                }
            }
        })
    }

    onZoomComplete(context: { chart: Chart }) {
        const min = Math.min(Math.max(context.chart.scales.x.min, 0), this.datePoints.length - 1)
        const max = Math.min(Math.max(context.chart.scales.x.max, 0), this.datePoints.length - 1)

        let dateRange = new DateRange()
        dateRange.startDate = moment(this.datePoints[min]).toDate()
        dateRange.endDate = moment(this.datePoints[max]).toDate()
        this.onDateRangeChange.emit(dateRange)
    }

    private makeChartDatasets(): ChartDataset[] {
        const barColors = [
            '#03C764',
            '#5AA945',
            '#ABB23B',
            '#C7831E',
            '#905E16',
            '#583A0E',
        ];

        var chartDatasets: ChartDataset[] = new Array()

        for (let appVersionLaunchRate of this.appVersionLaunchRates) {
            let colorIndex = Math.min(appVersionLaunchRate.versionRecencyRank, barColors.length - 1)

            let chartDataSet: ChartDataset = {
                data: appVersionLaunchRate.rates,
                backgroundColor: barColors[colorIndex],
                fill: true
            }

            chartDatasets.push(chartDataSet)
        }

        return chartDatasets
    }

}
