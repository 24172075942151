export class SearchUtils {

    static searchQueryMatchesSearchableFields(query: string, fields: string[]): boolean {
        // If there are no search terms, return every backend environment as matching
        if (!query || query.length == 0) {
            return true
        }

        let queryComponents = query.toLowerCase().split(" ")
        if (queryComponents.length == 0) {
            return true
        }

        // Create search tokens from fields by splitting them using space, "-" or "/".
        var searchableFields: string[] = []
        for (let field of fields) {
            let fieldSearchableComponents = field.toLowerCase().split(/[\s-\/]+/)
            searchableFields = searchableFields.concat(fieldSearchableComponents)
        }

        // Try to find the first queryComponent which doesn't have a single match within searchableFields.
        // If one is found, then we can return early because no searchable field matches it.
        for (let queryComponent of queryComponents) {
            let hasMatches = searchableFields.filter((field) => { return field.startsWith(queryComponent) }).length > 0
            if (!hasMatches) {
                return false
            }
        }

        // At this point all of the query components have at least one match from fields
        return true
    }

}
