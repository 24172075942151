import { Component, Input, OnInit } from '@angular/core';
import { RouteHelper } from 'app/_helpers';
import { AuthenticationService } from 'app/_services';
import { AppConfig } from 'app/app.config';

@Component({
    selector: 'navbar-app-feature',
    templateUrl: 'navbar.app.feature.html'
})
export class NavbarAppFeature implements OnInit {

    @Input() appFeatureName: string
    isLoggedIn: boolean

    constructor(
        private routeHelper: RouteHelper,
        private authenticationService: AuthenticationService,
    ) {
    }

    ngOnInit() {
        this.isLoggedIn = this.authenticationService.isUserLoggedIn()
    }

    logoRouterLink(): string {
        return this.routeHelper.currentWorkspaceHome()
    }

    logoHrefForNotLoggedInUser(): string {
        return AppConfig.webBaseUrl
    }

}
