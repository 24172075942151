import { Injectable } from "@angular/core";
import { TimeStyle } from "app/_models";

@Injectable()
export class TimeStyleFormatter {

    displayName(timeStyle: TimeStyle): string {
        switch (timeStyle) {
            case TimeStyle.Absolute: return "Absolute"
            case TimeStyle.Relative: return "Relative"
            default:
                console.warn("Unknown time style: " + timeStyle);
                return `${timeStyle}`
        }
    }

}
