import { AfterViewChecked, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'video-frame',
    templateUrl: 'video.frame.component.html',
    styleUrls: [
        'video.frame.component.css'
    ]
})
export class VideoFrameComponent implements AfterViewChecked {

    private _frameNumber: number = undefined
    @Input()
    set frameNumber(frameNumber: number) {
        if (this._frameNumber === frameNumber) {
            return
        }

        this._frameNumber = frameNumber
        this.onFrameNumberChange()
    }
    get frameNumber() { return this._frameNumber }

    @Input() imageSrc: SafeUrl
    @Input() frameWidth: number
    @Input() frameHeight: number

    @ViewChild('frameImg') private frameImg: ElementRef

    constructor(
        private elementRef: ElementRef
    ) { }

    private _didLoad = false
    ngAfterViewChecked() {
        if (this._didLoad) {
            return
        }

        this._didLoad = true
        this.onFrameNumberChange()
    }

    private onFrameNumberChange() {
        if (this.imageSrc == null || this.frameWidth === undefined || this.frameNumber === undefined) {
            return
        }

        let frameImgElement = this.frameImg.nativeElement
        if (frameImgElement == null) {
            return
        }

        let offset = Math.round(- this.frameNumber * this.frameWidth)
        frameImgElement.style.left = `${offset}px`
    }

}
