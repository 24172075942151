<div class="sidebar sidebar-light sidebar-expand-md align-self-start">

    <!-- Sidebar content -->
    <div class="sidebar-content">

        <!-- Main navigation -->
        <div class="card">
            <div class="card-body text-center card-img-top">
                <avatar style="width: 140px; height: 140px;" class="mb-3 mr-auto ml-auto" [imageUrl]="userImageUrl.toString()" [fallbackText]="userName"></avatar>

                <h6 class="font-weight-semibold mb-0">{{userName}}</h6>
                <span class="d-block opacity-75">{{workspace.name}} <span style="font-size: 100%" class="badge badge-light">{{userRole}}</span></span>
            </div>

            <div class="card-body p-0">
                <ul class="nav nav-sidebar" data-nav-type="accordion">
                    <li class="nav-item-header">Account</li>

                    <li class="nav-item">
                        <a [routerLink]="['/settings/workspace']" class="nav-link" [class.active]="selectedFeatureIndex == 7">
                            <i class="icon-office"></i>Workspace
                        </a>
                    </li>

                    <li class="nav-item">
                        <a [routerLink]="['/settings/subscription']" class="nav-link" [class.active]="selectedFeatureIndex == 8">
                            <i class="icon-rating3"></i>Subscription <span class="badge badge-dark align-self-center ml-auto">{{workspace.subscription.displayName}}</span>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a [routerLink]="['/settings/team']" class="nav-link" [class.active]="selectedFeatureIndex == 0">
                            <i class="icon-users"></i>Team Members
                        </a>
                    </li>

                    <li class="nav-item-header">Profile</li>

                    <li class="nav-item">
                        <a [routerLink]="['/settings/security']" class="nav-link" [class.active]="selectedFeatureIndex == 3">
                            <i class="icon-shield-check"></i>Security
                        </a>
                    </li>

                    <li class="nav-item">
                        <a [routerLink]="['/settings/account-activity']" class="nav-link" [class.active]="selectedFeatureIndex == 4">
                            <i class="icon-user-check"></i>Account Activity
                        </a>
                    </li>

                    <li class="nav-item-header">Configuration</li>

                    <li class="nav-item">
                        <a [routerLink]="['/settings/applications']" class="nav-link" [class.active]="selectedFeatureIndex == 1">
                            <i class="icon-mobile2"></i>Applications
                        </a>
                    </li>

                    <li class="nav-item">
                        <a [routerLink]="['/settings/api']" class="nav-link" [class.active]="selectedFeatureIndex == 6">
                            <i class="icon-embed"></i>API Access
                        </a>
                    </li>

                    <li class="nav-item">
                        <a [routerLink]="['/settings/integrations']" class="nav-link" [class.active]="selectedFeatureIndex == 5">
                            <i class="icon-git-pull-request"></i>Integrations
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- /main navigation -->

    </div>
    <!-- /sidebar content -->

</div>
