export class CrashFilter {
    startDate: Date
    endDate: Date

    applicationId: number | null
    applicationVersionId: number | null
    bundleIdentifierId: number | null
    osVersionId: number | null
    deviceId: number | null
    crashGroupId: number | null
}
