import { AppdexLevel } from 'app/_models';
import { Injectable } from "@angular/core";

@Injectable()
export class AppdexFormatter {

    appdexString(appdex: number): string {
        if (appdex === null || appdex === undefined) {
            return "-"
        }

        return appdex.toFixed(2)
    }

    // Excellent score falls in 1.00 - 0.94.
    // Good score comes around 0.93 - 0.85.
    // Fair score is between 0.84 - 0.70.
    // Poor hits 0.69 to 0.49.
    // Any score below is considered unacceptable.
    // https://www.freshworks.com/website-monitoring/what-is-apdex/
    appdexLevel(appdex: number): AppdexLevel {
        if (appdex === null || appdex === undefined) { return AppdexLevel.Unknown }
        else if (appdex >= 0.94) { return AppdexLevel.Excellent }
        else if (appdex >= 0.85) { return AppdexLevel.Good }
        else if (appdex >= 0.7) { return AppdexLevel.Fair }
        else if (appdex >= 0.49) { return AppdexLevel.Poor }
        else { return AppdexLevel.Unacceptable }
    }

    // Excellent score falls between 1.00 and successThreshold.
    // Fair score is between (1.00 - successThreshold) and (1.00 - 2 * successThreshold).
    // Any score below this is considered unacceptable.
    testsAppdexLevel(successRate: number, successThreshold: number): AppdexLevel {
        if (successRate === null || successRate === undefined) { return AppdexLevel.Unknown }
        else if (successRate >= successThreshold) { return AppdexLevel.Excellent }
        else if (successRate >= (1 - 2 * (1 - successThreshold))) { return AppdexLevel.Fair }
        else { return AppdexLevel.Unacceptable }
    }

    appdexTextClass(appdexLevel: AppdexLevel): string {
        switch (appdexLevel) {
            case AppdexLevel.Excellent: return "text-success"
            case AppdexLevel.Good: return "text-success-600"
            case AppdexLevel.Fair: return "text-orange-400"
            case AppdexLevel.Poor: return "text-warning-400"
            case AppdexLevel.Unacceptable: return "text-danger-600"
            case AppdexLevel.Unknown: return "text-grey-300"
        }
    }

    appdexBadgeClass(appdexLevel: AppdexLevel): string {
        switch (appdexLevel) {
            case AppdexLevel.Excellent: return "border-success bg-success"
            case AppdexLevel.Good: return "border-success-600 bg-success-600"
            case AppdexLevel.Fair: return "border-orange-400 bg-orange-400"
            case AppdexLevel.Poor: return "border-warning-400 bg-warning-400"
            case AppdexLevel.Unacceptable: return "border-danger-600 bg-danger-600"
            case AppdexLevel.Unknown: return "border-grey-300 bg-grey-300"
        }
    }

}
