<div class="d-flex" *ngIf="distributionGroups">
    <div class="sidebar sidebar-light sidebar-secondary sidebar-expand-md" *ngIf="distributionGroups && distributionGroups.length > 0">
        <div class="list-content-wrapper">
            <div class="px-2 py-2 w-100 border-bottom-light font-weight-semibold align-items-baseline d-flex">
                <h5>Groups</h5>
                <a class="btn btn-link ml-auto text-default" data-toggle="modal" data-target="#distributionGroupEditor"><b><i class="icon-plus2"></i></b> Add</a>
            </div>
            <ul class="media-list">
                <li class="media d-flex p-2 m-0" (click)="navigateToDistributionGroup(distributionGroup)" [class.selected]="distributionGroup.serialNumber == selectedDistributionGroupSerialNumber" *ngFor="let distributionGroup of distributionGroups">
                    <avatar style="width: 40px; height: 40px;" class="mr-2" [isHighlighted]="distributionGroup.serialNumber == selectedDistributionGroupSerialNumber" [fallbackText]="distributionGroup.name"></avatar>

                    <div>
                        <a class="text-default" [routerLink]="[distributionGroup.serialNumber]">
                            <h6 class="mb-0 pb-0">{{distributionGroup.name}}</h6>
                        </a>
                        <ul class="list-inline list-inline-dotted list-inline-condensed text-muted">
                            <li class="list-inline-item" *ngIf="distributionGroup.isPublic == true">Public</li>
                            <li class="list-inline-item" *ngIf="distributionGroup.isPublic == false">Private</li>
                            <li class="list-inline-item">{{distributionGroup.membersCount}} members</li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <div class="content-wrapper">
        <div class="w-100 h-100 p-3" *ngIf="selectedDistributionGroupSerialNumber == null">
            <div class="card wizard w-100 h-100 p-3 bg-transparent">
                <div class="card-body text-center d-flex justify-content-center align-items-center">
                    <div class="wizard-content">
                        <i class="icon-plus3 icon-2x text-primary border-primary border-3 rounded-pill p-3 mb-3 mt-1"></i>
                        <h5 class="mt-2 mb-0">Create a distribution group</h5>
                        <p class="mb-3 text-muted">Create a group for distribution of application builds and assign team members.</p>

                        <a class="btn btn-labeled bg-primary-400 btn-labeled-left mt-2" data-toggle="modal" data-target="#distributionGroupEditor"><b><i class="icon-plus2"></i></b> New Distribution Group</a>
                    </div>
                </div>
            </div>
        </div>

        <router-outlet></router-outlet>
    </div>
</div>

<distribution-group-editor #distributionGroupEditor></distribution-group-editor>
