<ng-container *ngIf="!hasNetworkFailures">
    -
</ng-container>

<ng-container *ngIf="hasNetworkFailures">
    <div class="d-flex justify-content-between align-items-sm-center">
        <span class="nowrap">{{statusText}}</span>
        <span class="nowrap text-muted">{{detailText}}</span>
    </div>
    <div class="progress noshadow mt-1" style="height: 0.375rem; width: 120px;">
        <div class="progress-bar" style="background-color: #777" [style.width]="percentageClient"></div>
        <div class="progress-bar" style="background-color: #FFA726" [style.width]="percentageBusiness"></div>
        <div class="progress-bar" style="background-color: #E53935" [style.width]="percentageServer"></div>
    </div>
</ng-container>
