import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'app/app.config';
import { CollectionResponse } from './response';

export class UserSession {
    id: number
    creationDate: Date
    accessDate: Date
    expirationDate: Date
    isActive: boolean
    isCurrent: boolean
    ipAddress: string
    locationInfo: string
    countryFlagUrl: URL
}

@Injectable({ providedIn: 'root' })
export class UserSessionsService {

    constructor(
        private http: HttpClient,
    ) {
    }

    getUserSessions(): Promise<CollectionResponse<UserSession>> {
        return this.http.get<CollectionResponse<UserSession>>(`${AppConfig.apiBaseUrl}/userSessions`).toPromise()
    }

    deleteSession(sessionId:number): Promise<void> {
        return this.http.delete<void>(`${AppConfig.apiBaseUrl}/userSessions/${sessionId}`).toPromise()
    }

}
