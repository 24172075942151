<div id="editNetworkOperationModal" class="modal fade" *ngIf="networkOperationId">
    <div class="modal-dialog">
        <div class="modal-content" *ngIf="networkOperation">

            <div class="modal-header bg-light">
                <div class="d-flex w-100 align-items-center">
                    <h3 class="mb-0" *ngIf="!isEditing">Network operation details</h3>
                    <h3 class="mb-0" *ngIf="isEditing">Edit network operation</h3>
                    <button #closeButton type="button" class="close ml-auto" data-dismiss="modal">&times;</button>
                </div>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <alert></alert>
                    </div>
                </div>

                <ng-container *ngIf="!isEditing">
                    <div class="font-size-xs text-uppercase text-muted" *ngIf="networkOperation.name || canEditNetworkOperation">Name</div>
                    <ng-container *ngIf="networkOperation.name">
                        {{networkOperation.name}}
                    </ng-container>
                    <ng-container *ngIf="networkOperation.name == null && canEditNetworkOperation">
                        <a href="javascript:void(0)" (click)="onEditButtonClick()" class="font-weight-semibold">Add name</a>
                        <span class="text-muted"> that uniquely identifies this operation.</span>
                    </ng-container>

                    <div class="font-size-xs text-uppercase text-muted mt-3">Pattern</div>
                    <samp class="breakall">{{networkOperation.method}}&nbsp;<url-pattern [pattern]="networkOperation.urlPattern"></url-pattern></samp>

                    <ng-container *ngIf="networkOperation.sampleUrl">
                        <div class="font-size-xs text-uppercase text-muted mt-3">Recently matched URL</div>
                        <samp class="breakall text-muted">{{networkOperation.method}} {{networkOperation.sampleUrl}}</samp>
                    </ng-container>

                    <div class="font-size-xs text-uppercase text-muted mt-3" *ngIf="networkOperation.annotation || canEditNetworkOperation">Annotation</div>
                    <ng-container *ngIf="networkOperation.annotation">
                        <markdown [content]="networkOperation.annotation"></markdown>
                    </ng-container>

                    <ng-container *ngIf="networkOperation.annotation == null && canEditNetworkOperation">
                        <a href="javascript:void(0)" (click)="onEditButtonClick()" class="font-weight-semibold">Add annotation</a>
                        <span class="text-muted"> with contextual information and documentation links.</span>
                    </ng-container>

                    <div class="font-size-xs text-uppercase text-muted mt-3">Target response time</div>
                    {{networkOperation.targetDuration.toFixed(1)}} sec
                </ng-container>

                <form [formGroup]="form" *ngIf="isEditing">
                    <div class="form-group">
                        <div class="font-size-xs text-uppercase text-muted mt-2">Name</div>
                        <input type="text" formControlName="name" class="form-control" autocomplete="off">
                        <div class="text-muted mt-1">Unique string used to identify the operation.</div>
                    </div>

                    <div class="font-size-xs text-uppercase text-muted">Pattern</div>
                    <samp class="breakall">{{networkOperation.method}}&nbsp;<url-pattern [pattern]="networkOperation.urlPattern"></url-pattern></samp>

                    <ng-container *ngIf="networkOperation.sampleUrl">
                        <div class="font-size-xs text-uppercase text-muted mt-3">Recently matched URL</div>
                        <samp class="breakall text-muted">{{networkOperation.method}} {{networkOperation.sampleUrl}}</samp>
                    </ng-container>

                    <div class="form-group mt-2">
                        <div class="font-size-xs text-uppercase text-muted mt-3">Annotation</div>
                        <textarea formControlName="annotation" rows="4" cols="4" placeholder="Add annotation" class="form-control"></textarea>
                        <div class="text-muted mt-1">Attach useful contextual information related to this network operation. This may include links to documentation or your <a href="https://swagger.io/specification/" target="_blank" rel="noopener noreferrer" class="text-default">OpenAPI</a> specification. <code>Markdown</code> syntax is supported.</div>
                    </div>

                    <div class="form-group mt-2">
                        <div class="font-size-xs text-uppercase text-muted mt-2">Target response time</div>
                        <input type="text" formControlName="targetDuration" class="form-control" autocomplete="off">
                        <div class="text-muted mt-1">
                            The target response time (T) will be used to calculate appdex for each network request. A network call occurrence is considered:
                            <ul>
                                <li><span class="font-weight-bold">Satisfying:</span> if its duration ≤ T</li>
                                <li><span class="font-weight-bold">Tolerable:</span> if its duration > T and ≤ 4T</li>
                                <li><span class="font-weight-bold">Frustrating:</span> if its duration > 4T or if it fails due to a server-side or client-side error.</li>
                            </ul>
                        </div>
                        <label class="mt-2"><input formControlName="updateAllNetworkOperations" type="checkbox" autocomplete="off"> Apply target response time to all requests</label>
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <button (click)="onEditButtonClick()" type="button" class="btn btn-outline-primary" *ngIf="canEditNetworkOperation && !isEditing">Edit</button>
                <button (click)="saveNetworkOperation()" [disabled]="form.invalid || !canEditNetworkOperation" type="button" class="btn bg-primary-400" *ngIf="isEditing">Save</button>
            </div>
        </div>
    </div>
</div>
