<div style="margin-top: -20px; margin-bottom: -20px;" >
    <div class="d-flex">
        <!-- TRENDS -->
        <div class="mr-1 w-100" style="margin-top: 20px; margin-bottom: 20px;">

            <!-- TRENDS PICKER -->
            <div class="d-flex align-items-baseline">
                <h5 class="mb-0 pb-0">Trends</h5>

                <ul class="nav nav-pills nav-pills-bordered ml-2 mb-0">
                    <li class="nav-item ml-0 mr-1 mb-1">
                        <a href="javascript:void(0)" (click)="setNetworkTrendType(NetworkTrendType.Appdex)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : networkTrendType == NetworkTrendType.Appdex }">
                            Appdex
                        </a>
                    </li>

                    <li class="nav-item ml-0 mb-1">
                        <a href="javascript:void(0)" (click)="setNetworkTrendType(NetworkTrendType.DurationAvg)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : networkTrendType == NetworkTrendType.DurationAvg }" style="border-top-right-radius: 0; border-bottom-right-radius: 0;">
                            <span>Response time<sub>avg</sub></span>
                        </a>
                    </li>

                    <li class="nav-item ml-0 mb-1">
                        <a href="javascript:void(0)" (click)="setNetworkTrendType(NetworkTrendType.DurationP90)" class="nav-link border-left-0 px-2 py-1" [ngClass]="{ 'active' : networkTrendType == NetworkTrendType.DurationP90 }" style="border-radius: 0;">
                            <span>P<sub>90</sub></span>
                        </a>
                    </li>

                    <li class="nav-item ml-0 mb-1">
                        <a href="javascript:void(0)" (click)="setNetworkTrendType(NetworkTrendType.DurationP95)" class="nav-link border-left-0 px-2 py-1" [ngClass]="{ 'active' : networkTrendType == NetworkTrendType.DurationP95 }" style="border-radius: 0;">
                            <span>P<sub>95</sub></span>
                        </a>
                    </li>

                    <li class="nav-item ml-0 mr-1 mb-1">
                        <a href="javascript:void(0)" (click)="setNetworkTrendType(NetworkTrendType.DurationP99)" class="nav-link border-left-0 px-2 py-1" [ngClass]="{ 'active' : networkTrendType == NetworkTrendType.DurationP99 }" style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
                            <span>P<sub>99</sub></span>
                        </a>
                    </li>

                    <li class="nav-item ml-0 mr-1 mb-1">
                        <a href="javascript:void(0)" (click)="setNetworkTrendType(NetworkTrendType.Requests)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : networkTrendType == NetworkTrendType.Requests }">
                            Requests
                        </a>
                    </li>

                    <li class="nav-item ml-0 mr-1 mb-1">
                        <a href="javascript:void(0)" (click)="setNetworkTrendType(NetworkTrendType.Errors)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : networkTrendType == NetworkTrendType.Errors }">
                            Error rates
                        </a>
                    </li>

                    <li class="nav-item ml-0 mr-1 mb-1">
                        <a href="javascript:void(0)" (click)="setNetworkTrendType(NetworkTrendType.Payload)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : networkTrendType == NetworkTrendType.Payload }">
                            Payload
                        </a>
                    </li>
                </ul>
            </div>

            <!-- TRENDS LOADING -->
            <div class="justify-content-center text-muted d-flex w-100 align-items-center" style="min-height: 240px" *ngIf="!networkTrendsResponse">
                <div class="align-self-center">
                    <i class="icon-spinner4 spinner mr-2"></i> Loading network trends...
                </div>
            </div>

            <!-- TRENDS LOADED -->
            <ng-container *ngIf="networkTrendsResponse">
                <div *ngIf="networkTrendType == 'APPDEX'">
                    <appdex-chart [datePoints]="networkTrendsResponse.datePoints" [values]="networkTrendsResponse.appdexes" (onDateRangeChange)="onDateRangeChanged($event)"></appdex-chart>
                </div>

                <div *ngIf="networkTrendType == NetworkTrendType.DurationAvg || networkTrendType == NetworkTrendType.DurationP90 || networkTrendType == NetworkTrendType.DurationP95 || networkTrendType == NetworkTrendType.DurationP99">
                    <response-time-over-time-chart [datePoints]="networkTrendsResponse.datePoints" [responseTimes]="networkTrendsResponse.durations" [serverResponseTimes]="networkTrendsResponse.serverDurations" (onDateRangeChange)="onDateRangeChanged($event)"></response-time-over-time-chart>
                </div>

                <div *ngIf="networkTrendType == NetworkTrendType.Requests">
                    <requests-over-time-chart [datePoints]="networkTrendsResponse.datePoints" [requestCounts]="networkTrendsResponse.requestCounts" (onDateRangeChange)="onDateRangeChanged($event)"></requests-over-time-chart>
                </div>

                <div *ngIf="networkTrendType == NetworkTrendType.Errors">
                    <network-errors-chart [datePoints]="networkTrendsResponse.datePoints" [clientErrors]="networkTrendsResponse.clientErrorRates" [businessErrors]="networkTrendsResponse.businessErrorRates" [serverErrors]="networkTrendsResponse.serverErrorRates" (onDateRangeChange)="onDateRangeChanged($event)"></network-errors-chart>
                </div>

                <div *ngIf="networkTrendType == NetworkTrendType.Payload">
                    <payload-over-time-chart [datePoints]="networkTrendsResponse.datePoints" [requestPayloadSizes]="networkTrendsResponse.requestPayloadSizes" [responsePayloadSizes]="networkTrendsResponse.responsePayloadSizes" (onDateRangeChange)="onDateRangeChanged($event)"></payload-over-time-chart>
                </div>
            </ng-container>
        </div>

        <!-- SUMMARY LOADING -->
        <div class="justify-content-center text-muted d-flex align-items-center pl-3" *ngIf="!networkTrendsSummaryResponse">
            <div class="align-self-center" style="min-width: 180px;">
                <i class="icon-spinner4 spinner mr-1"></i> Loading summary...
            </div>
        </div>

        <!-- SUMMARY LOADED -->
        <div class="border-left-light pl-3" style="padding-top: 20px;" *ngIf="networkTrendsSummaryResponse">
            <h5 class="mb-0 pb-3">Summary</h5>

            <div *ngIf="networkTrendType == NetworkTrendType.Appdex">
                <appdex-summary [networkTrendsSummaryResponse]="networkTrendsSummaryResponse"></appdex-summary>
            </div>

            <div style="width: 180px;" *ngIf="networkTrendType == NetworkTrendType.Requests">
                <request-count-summary [networkTrendsSummaryResponse]="networkTrendsSummaryResponse"></request-count-summary>
            </div>

            <div *ngIf="networkTrendType == NetworkTrendType.DurationAvg">
                <response-time-summary [networkTrendsSummaryResponse]="networkTrendsSummaryResponse"></response-time-summary>
            </div>

            <div class="d-flex" style="width: 180px;" *ngIf="networkTrendType == NetworkTrendType.DurationP90">
                <div class="font-weight-semibold mr-auto mb-1">Response&nbsp;time<sub>P90</sub></div>
                <milliseconds [duration]="networkTrendsSummaryResponse.overallDuration"></milliseconds>
            </div>

            <div class="d-flex" style="width: 180px;" *ngIf="networkTrendType == NetworkTrendType.DurationP95">
                <div class="font-weight-semibold mr-auto mb-1">Response&nbsp;time<sub>P95</sub></div>
                <milliseconds [duration]="networkTrendsSummaryResponse.overallDuration"></milliseconds>
            </div>

            <div class="d-flex" style="width: 180px;" *ngIf="networkTrendType == NetworkTrendType.DurationP99">
                <div class="font-weight-semibold mr-auto mb-1">Response&nbsp;time<sub>P99</sub></div>
                <milliseconds [duration]="networkTrendsSummaryResponse.overallDuration"></milliseconds>
            </div>

            <div *ngIf="networkTrendType == NetworkTrendType.Errors">
                <error-rate-summary [networkTrendsSummaryResponse]="networkTrendsSummaryResponse"></error-rate-summary>
            </div>

            <div *ngIf="networkTrendType == NetworkTrendType.Payload">
                <payload-summary [networkTrendsSummaryResponse]="networkTrendsSummaryResponse"></payload-summary>
            </div>
        </div>
    </div>
</div>
