<div class="content-wrapper" (scroll)="onPageContentScroll($event)" #pageContent>

    <div class="content">
        <div class="row">
            <div class="col-md-12">
                <alert></alert>
            </div>
        </div>

        <div class="row" *ngIf="application && testExecutions && environments">
            <div class="col-md-12">
                <div class="card">
                    <div class="page-header border-bottom-light">
                        <div class="page-header-content header-elements-md-inline">
                            <div class="page-title">
                                <h1 class="font-weight-thin">
                                    <a [routerLink]="['../']" class="breadcrumb-item">{{application.name}} {{platformFormatter.platformName(application.platform)}}</a>
                                    <span class="breadcrumb-item active">Test Executions</span>
                                </h1>
                            </div>
                        </div>
                    </div>

                    <div class="card-body d-flex align-items-center">
                        <h5 class="mb-0 pb-0">Filters</h5>

                        <div class="form-group mb-0 d-flex ml-2" *ngIf="environments.length > 1">
                            <select id="environmentNameSelect" select2 data-placeholder="Environment" data-allow-clear="true" class="form-control select2">
                                <option *ngFor="let environment of environments" [ngValue]="environment.name">{{environment.name}}</option>
                            </select>
                        </div>

                        <div class="form-group mb-0 d-flex ml-2" *ngIf="bundleVersions.length > 1">
                            <select id="testBundleVersionSelect" select2 data-placeholder="App version" data-allow-clear="true" class="form-control select2">
                                <option *ngFor="let bundleVersion of bundleVersions" [ngValue]="bundleVersion">{{bundleVersion}}</option>
                            </select>
                        </div>

                        <div class="form-group mb-0 d-flex ml-2" *ngIf="testPlans && testPlans.length > 1">
                            <select id="testPlanSelect" select2 data-placeholder="Test plan" data-allow-clear="true" class="form-control select2">
                                <option *ngFor="let testPlan of testPlans" [ngValue]="testPlan.name">{{testPlan.name}}</option>
                            </select>
                        </div>
                    </div>

                    <table class="table">
                        <thead>
                            <tr role="row">
                                <th class="border-0">Date</th>
                                <th class="border-0">Environment</th>
                                <th class="border-0 nowrap">App version</th>
                                <th class="border-0 nowrap">Test plan</th>
                                <th class="border-0" style="width: 50%">Results</th>
                                <th class="border-0">Duration</th>
                                <th class="border-0 text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let testExecution of testExecutions" my-tr [testExecution]="testExecution" [showEnvironmentColumn]="true" [showAppColumn]="false" (navigateToTestExecution)="navigateToTestExecution($event)" (testExecutionShouldDelete)="testExecutionShouldDelete($event)"></tr>
                        </tbody>
                    </table>
                </div>

                <div class="text-center">
                    <div class="text-muted d-block" *ngIf="isLoadingMoreRecords">
                        <i class="icon-spinner4 spinner mr-2"></i> Loading...
                    </div>
                    <div class="text-muted d-block" *ngIf="!isLoadingMoreRecords && !hasMoreRecords">
                        Showing all matching test executions
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>
