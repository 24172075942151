import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { AlertService, TestFailureGroupsService } from 'app/_services'
import { FormGroup, FormBuilder } from '@angular/forms';
import { TestFailureGroup } from 'app/_models';

@Component({
    selector: 'edit-test-failure-group-modal',
    templateUrl: 'edit.test.failure.group.modal.html'
})
export class EditTestFailureGroupModal {

    form: FormGroup

    private _testFailureGroup: TestFailureGroup
    @Input()
    public set testFailureGroup(testFailureGroup: TestFailureGroup) {
        this._testFailureGroup = testFailureGroup
        this.reloadForm()
    }
    public get testFailureGroup(): TestFailureGroup {
        return this._testFailureGroup
    }

    @Output() testFailureGroupUpdated = new EventEmitter()

    @ViewChild('closeButton') closeButton: ElementRef

    constructor(
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private testFailureGroupsService: TestFailureGroupsService,
    ) {
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls }

    private reloadForm() {
        this.form = this.formBuilder.group({
            annotation: [this.testFailureGroup.annotation]
        })
    }

    saveTestFailureGroup() {
        this.testFailureGroupsService.updateTestFailureGroup(this.testFailureGroup.id, this.f.annotation.value, this.testFailureGroup.state).then((response) => {
            this.closeButton.nativeElement.click()
            this.testFailureGroupUpdated.emit()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
