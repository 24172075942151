import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Application, DistributionGroup, ErrorGroupSegment } from 'app/_models';
import { AlertService, ApplicationService, DistributionGroupsService } from 'app/_services';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApplicationFormatter, DateFormatter, IssueStateFormatter } from 'app/_helpers';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';

@Component({
    templateUrl: 'distribution.groups.component.html',
    styleUrls: [
        'distribution.groups.component.css'
    ]
})
export class DistributionGroupsComponent implements OnInit, OnDestroy {

    application: Application

    distributionGroups: DistributionGroup[]
    selectedDistributionGroupSerialNumber: number | null

    @ViewChild('pageContent') pageContent: ElementRef

    private navigationChangeSubscription: Subscription
    private currentApplicationSubscription: Subscription
    private distributionGroupEditedSubscription: Subscription
    private distributionGroupDeletedSubscription: Subscription

    constructor(
        public dateFormatter: DateFormatter,
        public issueStateFormatter: IssueStateFormatter,
        private distributionGroupsService: DistributionGroupsService,
        private route: ActivatedRoute,
        private applicationService: ApplicationService,
        private alertService: AlertService,
        private router: Router,
        private applicationFormatter: ApplicationFormatter,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.navigationChangeSubscription = this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(_ => {
            this.updateSelection()
        })

        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
            if (this.application != null) {
                this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | Distribution Groups`)
                this.reloadDistributionGroups()
            }
        })

        this.distributionGroupDeletedSubscription = this.distributionGroupsService.distributionGroupDeletedWithId.subscribe((distributionGroupId: number) => {
            this.distributionGroupDeletedWithId(distributionGroupId)
        })

        this.distributionGroupEditedSubscription = this.distributionGroupsService.distributionGroupEdited.subscribe((distributionGroup) => {
            this.reloadDistributionGroups()
        })
    }

    ngOnDestroy() {
        this.navigationChangeSubscription.unsubscribe()
        this.currentApplicationSubscription.unsubscribe()
        this.distributionGroupEditedSubscription.unsubscribe()
        this.distributionGroupDeletedSubscription.unsubscribe()
    }

    statusClassForDistributionGroup(distributionGroup: DistributionGroup): string {
        if (distributionGroup.serialNumber == this.selectedDistributionGroupSerialNumber) {
            return "btn bg-transparent rounded-pill border-2 btn-icon text-primary border-primary"
        } else {
            return "btn bg-transparent rounded-pill border-2 btn-icon text-grey-400"
        }
    }

    distributionGroupDeletedWithId(deletedDistributionGroupId: number) {
        this.distributionGroups = this.distributionGroups.filter(distributionGroup => { return distributionGroup.id != deletedDistributionGroupId })
        this.selectedDistributionGroupSerialNumber = null

        this.router.navigate(['../distribution-groups'], { relativeTo: this.route })
    }

    private updateSelection() {
        if (this.distributionGroups == null || this.route.firstChild == null) {
            this.selectedDistributionGroupSerialNumber = null
            return
        }

        this.selectedDistributionGroupSerialNumber = this.route.firstChild.snapshot.params['distributionGroupSlug']
    }

    reloadDistributionGroups() {
        if (!this.application) { return }

        this.distributionGroups = null
        this.distributionGroupsService.getApplicationDistributionGroups(this.application.id).then((response) => {
            this.distributionGroups = response.data
            this.updateSelection()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    navigateToDistributionGroup(distributionGroup: DistributionGroup) {
        this.router.navigate([distributionGroup.serialNumber], { relativeTo: this.route } )
    }

    makeInitials(name: string): string {
        // Split the name by spaces
        const words = name.split(' ');

        // Initialize an empty string to hold the initials
        let initials = '';

        // Loop through the words and get the first letter of each
        for (let i = 0; i < words.length && initials.length < 2; i++) {
            if (words[i].length > 0) {
                initials += words[i][0].toUpperCase();
            }
        }

        // Return the initials, ensuring it has a maximum of 2 letters
        return initials.substring(0, 2);
    }

}
