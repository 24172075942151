import { ConsoleLogLevelFormatter } from 'app/_helpers';
import { Component, Input } from '@angular/core';
import { NetworkState, NetworkStateLog } from 'app/_models/console.logs.response';
import { NetworkInterface } from 'app/_models';

@Component({
    selector: 'network-state-log-content',
    templateUrl: 'network.state.log.content.html'
})
export class NetworkStateLogContent {

    private _log: NetworkStateLog
    @Input()
    public set log(log: NetworkStateLog) {
        this._log = log
        this.onLogUpdated()
    }

    public get log(): NetworkStateLog {
        return this._log
    }

    badgeStyle: string
    badgeContent: string
    additionalContent: string | null

    constructor(
        public logLevelFormatter: ConsoleLogLevelFormatter
    ) { }

    private onLogUpdated() {
        switch (this.log.state) {
            case NetworkState.Available:
                this.badgeContent = "Network available"
                this.badgeStyle = "border-success-400 bg-success-400"
                switch (this.log.interface) {
                    case NetworkInterface.None: this.additionalContent = "None"; break
                    case NetworkInterface.WiFi: this.additionalContent = "WiFi"; break
                    case NetworkInterface.CellularUnknown: this.additionalContent = "Cellular"; break
                    case NetworkInterface.Cellular2G: this.additionalContent = "2G"; break
                    case NetworkInterface.Cellular3G: this.additionalContent = "3G"; break
                    case NetworkInterface.Cellular4G: this.additionalContent = "4G"; break
                    case NetworkInterface.Cellular5G: this.additionalContent = "5G"; break
                    case NetworkInterface.WiredEthernet: this.additionalContent = "Wired ethernet"; break
                    case NetworkInterface.Loopback: this.additionalContent = "Loopback"; break
                    case NetworkInterface.Unknown: this.additionalContent = "Unknown"; break
                    default: this.additionalContent = "Unknown interface type"; break
                }
                break

            case NetworkState.Unavailable:
                this.badgeContent = "Network unavailable"
                this.badgeStyle = "border-pink-400 bg-pink-400"
                this.additionalContent = this.log.unavailableReason
                break

            case NetworkState.Unknown:
                this.badgeContent = "Network state unknown"
                this.badgeStyle = "border-warning bg-warning"
                this.additionalContent = null
                break
        }
    }

}
