import { Animations } from 'app/_animations/animations';
import { trigger } from '@angular/animations';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ImageFile, VideoFile } from 'app/_models';

@Component({
    selector: 'image-video',
    templateUrl: 'image.video.component.html',
    styleUrls: [
        'image.video.component.css'
    ],
    animations: [
        trigger('fadeInOutAnimation', Animations.fadeInOut),
        trigger('imageLoaded', Animations.bumpInOut)
    ]
})

export class ImageVideoComponent implements OnInit, AfterViewInit, OnDestroy {

    private _imageFile: ImageFile
    @Input()
    set imageFile(imageFile: ImageFile) {
        if (this._imageFile === imageFile) {
            return
        }
        this.imageLoaded = false
        this._imageFile = imageFile
        this.updateSize()
    }
    get imageFile() { return this._imageFile }

    private _videoFile: VideoFile
    @Input()
    set videoFile(videoFile: VideoFile) {
        if (this._videoFile === videoFile) {
            return
        }

        this._videoFile = videoFile
        this.hideImageOverlay = false
    }
    get videoFile() { return this._videoFile }

    @Output() progressChange = new EventEmitter<number>()

    imageLoaded = false
    hideImageOverlay = false

    private resizeListener: any

    constructor(
    ) {
    }

    ngOnInit() {
        this.resizeListener = this.updateSize.bind(this)
        window.addEventListener("resize", this.resizeListener)
    }

    ngAfterViewInit() {
        this.updateSize()
    }

    ngOnDestroy() {
        window.removeEventListener("resize", this.resizeListener)
    }

    onImgLoad() {
        // Slightly delay animations for better effect
        setTimeout(() => {
            this.imageLoaded = true
        }, 50)
    }

    onPlayVideoClick() {
        if (this.videoFile == null) { return }
        this.hideImageOverlay = true

        let videoElement = this.getVideoElement()
        videoElement.play()
    }

    onVideoTimeUpdate(e: Event) {
        let videoElement = e.target as HTMLMediaElement

        let videoProgress = videoElement.currentTime / videoElement.duration
        this.progressChange.emit(videoProgress)
    }

    setVideoPosition(newVideoPosition: number) {
        let videoElement = this.getVideoElement()

        // Ignore calls before video is ready and
        if (!videoElement || videoElement.readyState != 4) {
            return
        }

        // Ignore the initial call for setting the position to zero
        // because it was causing the image overlay to disappear too early
        if (!this.hideImageOverlay && newVideoPosition == 0) {
            return
        }

        // Make sure video is visible
        if (!this.hideImageOverlay) {
            // Remove the "Play" overlay in safari for videos that were never started
            videoElement.play()
            videoElement.pause()

            this.hideImageOverlay = true
        }

        videoElement.currentTime = videoElement.duration * newVideoPosition
    }

    private getVideoElement(): HTMLMediaElement {
        return document.getElementById("video") as HTMLMediaElement
    }

    private updateSize() {
        let rootContainer = document.getElementById("rootContainer")
        if (!rootContainer) {
            return
        }

        let bodySize = document.body.getBoundingClientRect()

        let maxWidth = Math.min(540, bodySize.width * 0.4)
        let maxHeight = 540

        var w = this.imageFile.size.width
        var h = this.imageFile.size.height

        if (w > maxWidth) {
            h = h * (maxWidth / w)
            w = maxWidth
        }

        if (h > maxHeight) {
            w = w * (maxHeight / h)
            h = maxHeight
        }

        rootContainer.style.width = `${w}px`
        rootContainer.style.height = `${h}px`
    }

}
