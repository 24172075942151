<div *ngIf="networkTrendsSummaryResponse">
    <div class="d-flex">
        <div class="mr-auto font-weight-semibold mb-1">Overall score</div>
        <appdex [value]="networkTrendsSummaryResponse.overallAppdex"></appdex>
    </div>

    <div class="progress noshadow mb-1" style="height: 22px; width: 180px;">
        <div class="progress-bar progress-bar-striped" style="background-color: #45ba78" [style.width]="satisfyingPercentage"></div>
        <div class="progress-bar progress-bar-striped" style="background-color: #FFA726" [style.width]="tolerablePercentage"></div>
        <div class="progress-bar progress-bar-striped" style="background-color: #E53935" [style.width]="frustratingPercentage"></div>
    </div>

    <div class="font-size-sm nowrap">
        <div class="d-flex">
            <div class="mr-auto"><span class="badge badge-mark border-0 mr-1" style="background-color: #45ba78"></span>Satisfying</div>
            <percentage [value]="networkTrendsSummaryResponse.satisfyingRate"></percentage>
        </div>
        <div class="d-flex">
            <div class="mr-auto"><span class="badge badge-mark border-0 mr-1" style="background-color: #FFA726"></span>Tolerable</div>
            <percentage [value]="networkTrendsSummaryResponse.tolerableRate"></percentage>
        </div>
        <div class="d-flex">
            <div class="mr-auto"><span class="badge badge-mark border-0 mr-1" style="background-color: #E53935"></span>Frustrating</div>
            <percentage [value]="networkTrendsSummaryResponse.frustratingRate"></percentage>
        </div>
    </div>
</div>
