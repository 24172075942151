import { Component, Input } from '@angular/core';

@Component({
    selector: 'value-item',
    templateUrl: 'value.item.html',
    styleUrls: [
        'value.item.css'
    ]
})
export class ValueItem {

    @Input() public title: string
    @Input() public value: string | null
    @Input() public icon: string

}
