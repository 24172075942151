import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { ApplicationService, AlertService, AuthenticationService } from 'app/_services'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Workspace } from 'app/_models';
import { PlatformFormatter } from 'app/_helpers';
import { Router } from '@angular/router';

@Component({
    selector: 'new-app-modal',
    templateUrl: 'new.app.component.html'
})
export class NewAppComponent implements OnInit {

    form: FormGroup;
    canCreateApplications: boolean

    @Input() public workspace: Workspace
    @Output() onApplicationCreated = new EventEmitter()

    @ViewChild('closeButton') closeButton: ElementRef

    constructor(
        public platformFormatter: PlatformFormatter,
        private router: Router,
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private applicationService: ApplicationService,
        private authenticationService: AuthenticationService
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue
        this.canCreateApplications = this.workspace.limits.canCreateApplications

        this.form = this.formBuilder.group({
            appName: [null, Validators.required],
            platform: [null, Validators.required]
        })
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls }

    submitAppCreationForm() {

        this.applicationService.createApplication(this.f.appName.value, this.f.platform.value).then((response) => {
            this.f.appName.reset()
            this.f.platform.reset()

            this.onApplicationCreated.emit()

            // Close the modal dialog
            this.closeButton.nativeElement.click()

            return this.authenticationService.refreshCurrentWorkspace()

        }).then((response) => {
            // App updated

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    navigateToSubscriptionSettings() {
        this.closeButton.nativeElement.click()
        setTimeout(() => { this.router.navigate(['/settings/subscription']) }, 200)
    }

}
