import { Params } from "@angular/router";

export enum CrashSegmentsOrder {
    COUNT_ASC = 'COUNT_ASC',
    COUNT_DESC = 'COUNT_DESC',
    AFFECTED_USERS_ASC = 'AFFECTED_USERS_ASC',
    AFFECTED_USERS_DESC = 'AFFECTED_USERS_DESC'
}

export function crashSegmentsOrderFromQuery(params: Params): CrashSegmentsOrder | null {
    let value = params['orderBy']
    if (Object.values(CrashSegmentsOrder).includes(value as CrashSegmentsOrder)) {
        return value as CrashSegmentsOrder
    }

    return null
}
