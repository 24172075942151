import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TimeFilter, Widget, WidgetConfigurationType, WidgetView } from 'app/_models';
import { AppConfig } from 'app/app.config';
import { CollectionResponse, ElementResponse } from './response';

@Injectable({ providedIn: 'root' })
export class WidgetsService {

    constructor(
        private http: HttpClient,
    ) {
    }

    getApplicationWidgets(applicationId: number): Promise<CollectionResponse<Widget>> {
        return this.http.get<CollectionResponse<Widget>>(`${AppConfig.apiBaseUrl}/applications/${applicationId}/widgets`).toPromise()
    }

    bugReportsWidgetPreview(applicationId: number, timeRange: TimeFilter, reporterEmail: string | null): Promise<ElementResponse<WidgetView>> {
        let requestBody = {
            configurationType: WidgetConfigurationType.BugReports,
            timeRange: timeRange,
            reporterEmail: reporterEmail
        }

        return this.http.post<ElementResponse<WidgetView>>(`${AppConfig.apiBaseUrl}/applications/${applicationId}/widgets/preview`, requestBody).toPromise()
    }

    createBugReportsWidget(applicationId: number, name: string, timeRange: TimeFilter, isShared: boolean, reporterEmail: string | null): Promise<Object> {
        let requestBody = {
            name: name,
            configurationType: WidgetConfigurationType.BugReports,
            timeRange: timeRange,
            isShared: isShared,
            reporterEmail: reporterEmail
        }

        return this.http.post(`${AppConfig.apiBaseUrl}/applications/${applicationId}/widgets`, requestBody).toPromise()
    }

    updateBugReportsWidget(widgetId: number, name: string, timeRange: TimeFilter, isShared: boolean, reporterEmail: string | null): Promise<Object> {
        let requestBody = {
            name: name,
            configurationType: WidgetConfigurationType.BugReports,
            timeRange: timeRange,
            isShared: isShared,
            reporterEmail: reporterEmail
        }

        return this.http.put(`${AppConfig.apiBaseUrl}/widgets/${widgetId}`, requestBody).toPromise()
    }

    testExecutionsWidgetPreview(applicationId: number, timeRange: TimeFilter, environmentName: string | null, testPlanName: string | null): Promise<ElementResponse<WidgetView>> {
        let requestBody = {
            configurationType: WidgetConfigurationType.TestExecutions,
            timeRange: timeRange,
            environmentName: environmentName,
            testPlanName: testPlanName
        }

        return this.http.post<ElementResponse<WidgetView>>(`${AppConfig.apiBaseUrl}/applications/${applicationId}/widgets/preview`, requestBody).toPromise()
    }

    createTestExecutionsWidget(applicationId: number, name: string, timeRange: TimeFilter, isShared: boolean, environmentName: string | null, testPlanName: string | null): Promise<Object> {
        let requestBody = {
            name: name,
            configurationType: WidgetConfigurationType.TestExecutions,
            timeRange: timeRange,
            isShared: isShared,
            environmentName: environmentName,
            testPlanName: testPlanName
        }

        return this.http.post(`${AppConfig.apiBaseUrl}/applications/${applicationId}/widgets`, requestBody).toPromise()
    }

    updateTestExecutionsWidget(widgetId: number, name: string, timeRange: TimeFilter, isShared: boolean, environmentName: string | null, testPlanName: string | null): Promise<Object> {
        let requestBody = {
            name: name,
            configurationType: WidgetConfigurationType.TestExecutions,
            timeRange: timeRange,
            isShared: isShared,
            environmentName: environmentName,
            testPlanName: testPlanName
        }

        return this.http.put(`${AppConfig.apiBaseUrl}/widgets/${widgetId}`, requestBody).toPromise()
    }

    buildsWidgetPreview(applicationId: number, timeRange: TimeFilter): Promise<ElementResponse<WidgetView>> {
        let requestBody = {
            configurationType: WidgetConfigurationType.Builds,
            timeRange: timeRange
        }

        return this.http.post<ElementResponse<WidgetView>>(`${AppConfig.apiBaseUrl}/applications/${applicationId}/widgets/preview`, requestBody).toPromise()
    }

    createBuildsWidget(applicationId: number, name: string, timeRange: TimeFilter, isShared: boolean): Promise<Object> {
        let requestBody = {
            name: name,
            configurationType: WidgetConfigurationType.Builds,
            timeRange: timeRange,
            isShared: isShared
        }

        return this.http.post(`${AppConfig.apiBaseUrl}/applications/${applicationId}/widgets`, requestBody).toPromise()
    }

    updateBuildsWidget(widgetId: number, name: string, timeRange: TimeFilter, isShared: boolean): Promise<Object> {
        let requestBody = {
            name: name,
            configurationType: WidgetConfigurationType.Builds,
            timeRange: timeRange,
            isShared: isShared
        }

        return this.http.put(`${AppConfig.apiBaseUrl}/widgets/${widgetId}`, requestBody).toPromise()
    }

    deleteWidget(widget: Widget): Promise<Object> {
        return this.http.delete(`${AppConfig.apiBaseUrl}/widgets/${widget.id}`).toPromise()
    }

}
