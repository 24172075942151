<navbar-settings></navbar-settings>

<div class="page-content">
    <settings-sidebar selectedFeatureIndex="4"></settings-sidebar>

    <!-- Main content -->
    <div class="content-wrapper">

        <!-- Content area -->
        <div class="content">

            <div class="row">
                <div class="col-md-12">
                    <alert></alert>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="card">

                        <div class="card-body pb-2">
                            <h1 class="font-weight-thin mb-0">Active sessions</h1>
                            <div class="font-weight-thin text-muted mb-3">These sessions are currently signed in to your account.</div>
                        </div>

                        <table class="table">
                            <tbody>
                                <tr *ngFor="let userSession of activeSessions">
                                    <td style="width: 30%">
                                        <div><samp class="">{{userSession.ipAddress}}</samp></div>
                                        <div class="text-muted">
                                            {{userSession.locationInfo}}
                                            <img src="{{userSession.countryFlagUrl}}" class="ml-1 mb-1" style="max-height: 12px; width: auto;" *ngIf="userSession.countryFlagUrl">
                                        </div>
                                    </td>
                                    <td style="width: 40%">
                                        <div class="text-muted">Signed in: <span class="font-weight-thin">{{dateFormatter.dateTime(userSession.creationDate)}}</span></div>
                                        <div class="text-muted">Last access: <span class="font-weight-thin">{{dateFormatter.timeAgo(userSession.accessDate)}}</span></div>
                                    </td>
                                    <td class="text-right">
                                        <span class="text-muted" *ngIf="userSession.isCurrent">Your current session</span>
                                        <button type="button" (click)="onActiveSessionDelete(userSession)" class="btn btn-link p-0" *ngIf="!userSession.isCurrent"><i class="icon-cross3"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="card-body pb-2">
                            <h1 class="font-weight-thin mt-3 mb-0">Inactive sessions</h1>
                            <div class="font-weight-thin text-muted">These sessions have expired and can no longer be used.</div>
                        </div>

                        <table class="table">
                            <tbody>
                                <tr *ngFor="let userSession of inactiveSessions">
                                    <td style="width: 30%">
                                        <div><samp class="">{{userSession.ipAddress}}</samp></div>
                                        <div class="text-muted">
                                            {{userSession.locationInfo}}
                                            <img src="{{userSession.countryFlagUrl}}" class="ml-1 mb-1" style="max-height: 12px; width: auto;" *ngIf="userSession.countryFlagUrl">
                                        </div>
                                    </td>
                                    <td style="width: 40%">
                                        <div class="text-muted">Signed in: <span class="font-weight-thin">{{dateFormatter.dateTime(userSession.creationDate)}}</span></div>
                                        <div class="text-muted">Expired: <span class="font-weight-thin">{{dateFormatter.dateTime(userSession.expirationDate)}}</span></div>
                                    </td>
                                    <td class="text-right">
                                        <span class="text-muted">Inactive</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

        </div>
        <!-- /content header -->

    </div>
    <!-- /main content -->

</div>
