import { ProvisioningProfile, ProvisioningProfileType } from "app/_models";

export class ProvisioningProfilePresenter {

    constructor(private profile: ProvisioningProfile) {
    }

    get profileType(): string {
        switch (this.profile.type) {
            case ProvisioningProfileType.IosDevelopment: return "iOS Development"
            case ProvisioningProfileType.IosAdhoc: return "iOS Ad Hoc"
            case ProvisioningProfileType.IosInHouse: return "iOS In House"
            case ProvisioningProfileType.IosAppStore: return "iOS App Store"
            default:
                return "Unknown"
        }
    }
}
