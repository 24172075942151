import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AlertService, CrashesService, ApplicationService } from 'app/_services'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Application, CrashReport, CrashGroup, CrashFilter } from 'app/_models';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { ApplicationFormatter } from 'app/_helpers';
import * as moment from 'moment'

@Component({
    templateUrl: 'crash.reports.component.html',
    styleUrls: [
        'crash.reports.component.css'
    ]
})
export class CrashReportsComponent implements OnInit, OnDestroy {

    application: Application

    private _crashGroup: CrashGroup
    set crashGroup(crashGroup: CrashGroup) {
        this._crashGroup = crashGroup
        this.crashesService.setCurrentCrashGroup(crashGroup)
    }
    get crashGroup() { return this._crashGroup }

    crashReports: CrashReport[]
    selectedCrashReportSerialNumber: number | null

    @ViewChild('crashReportsList') crashReportsList: ElementRef

    private navigationChangeSubscription: Subscription
    private currentApplicationSubscription: Subscription

    constructor(
        private crashesService: CrashesService,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private router: Router,
        private applicationService: ApplicationService,
        private applicationFormatter: ApplicationFormatter,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.navigationChangeSubscription = this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(_ => {
            this.updateSelection()
        })

        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
            if (this.application != null) {
                this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | Crash Reports`)
                this.reloadCrashReports()
            }
        })
    }

    ngOnDestroy() {
        // Resets the currentCrashGroup in CrashesService
        this.crashGroup = null
        this.navigationChangeSubscription.unsubscribe()
        this.currentApplicationSubscription.unsubscribe()
    }

    private updateSelection() {
        if (this.crashReports == null || this.route.firstChild == null) {
            this.selectedCrashReportSerialNumber = null
            return
        }

        this.selectedCrashReportSerialNumber = this.route.firstChild.snapshot.params['crashReportSlug']
    }

    private reloadCrashReports() {
        if (!this.application) { return }

        let crashGroupSlug = this.route.snapshot.params['crashGroupSlug']
        this.crashesService.getWorkspaceCrashGroupWithSlug(this.application.workspaceId, crashGroupSlug).then((response) => {
            this.crashGroup = response.data

            let filter = this.makeCurrentCrashFilter()
            this.crashesService.getCrashGroupCrashReports(this.crashGroup.id, filter).then((response) => {
                this.crashReports = response.data
                this.updateSelection()

            }).catch((error) => {
                this.alertService.handleError(error)
            })

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private makeCurrentCrashFilter(): CrashFilter {
        let crashFilter = new CrashFilter()
        crashFilter.endDate = new Date()
        crashFilter.startDate = moment(crashFilter.endDate).subtract(1, 'year').toDate();
        crashFilter.applicationId = this.application.id
        crashFilter.crashGroupId = this.crashGroup.id

        return crashFilter
    }


}
