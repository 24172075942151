import { Application, ApplicationDistribution } from "app/_models"
import { Injectable } from "@angular/core"
import { PlatformFormatter } from "."

@Injectable()
export class ApplicationFormatter {

    constructor(private platformFormatter: PlatformFormatter) {
    }

    displayName(application: Application | ApplicationDistribution): string {
        return `${application.name} ${this.platformFormatter.platformName(application.platform)}`
    }

}
