<div id="distributionGroupEditor" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header">
                <h5 class="modal-title" *ngIf="distributionGroup == null">New distribution group</h5>
                <h5 class="modal-title" *ngIf="distributionGroup != null">Edit distribution group</h5>
                <button #closeButton type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <alert></alert>
                    </div>
                </div>

                <form [formGroup]="form">
                    <div class="form-group">
                        <div class="font-size-xs text-uppercase text-muted mb-1">Distribution group name:</div>
                        <input type="text" autocomplete="off" formControlName="name" class="form-control">
                        <span class="form-text text-muted">Name your group, something like "Beta Testers" or "Internal"</span>
                    </div>

                    <div class="form-group">
                        <label class="mb-0"><input formControlName="isPublic" type="checkbox"> Public distribution</label>
                        <div class="text-muted">
                            When enabled, the URL will allow anyone to access and download the app. To restrict the availability of builds to members of your organization only, ensure that the distribution remains private.
                        </div>
                    </div>

                    <!-- <div class="form-group">
                        <label class="mb-0"><input formControlName="automaticallyAddNewBuilds" type="checkbox"> Automatically add new builds</label>
                        <div class="text-muted">
                            When enabled, any new build uploaded for this app will be automatically included in this distribution group. Note that testers will not be notified.
                        </div>
                    </div> -->
                </form>
            </div>

            <div class="modal-footer">
                <button (click)="submitForm()" [disabled]="form.invalid" type="button" class="btn bg-primary-400">
                    <span *ngIf="distributionGroup == null">Create</span>
                    <span *ngIf="distributionGroup != null">Save</span>
                </button>
            </div>
        </div>
    </div>
</div>
