import { Component, Input } from '@angular/core';
import { DateFormatter } from 'app/_helpers'

@Component({
    selector: 'milliseconds',
    templateUrl: 'milliseconds.component.html'
})

export class MillisecondsComponent {

    @Input() duration: number

    constructor(
        public dateFormatter: DateFormatter
    ) { }

}
