<div id="edit_auth_token_modal" class="modal fade" *ngIf="authToken">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Edit {{authToken.name}}</h5>
                <button #closeButton type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <form [formGroup]="form">
                    <div class="form-group">
                        <label>API Key name:</label>
                        <input type="text" formControlName="authTokenName" class="form-control">
                        <div class="text-muted mt-1">Set a name for your API Key to make it easier to identify.</div>
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <button (click)="saveAuthToken()" [disabled]="form.invalid" type="button" class="btn bg-primary-400">Save</button>
            </div>
        </div>
    </div>
</div>
