import { Component, OnInit, ViewChild, ElementRef, Input, HostListener } from '@angular/core';
import { AlertService, AuthenticationService, UserService, WorkspaceMember, WorkspaceService } from 'app/_services'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as bootbox from 'bootbox'
import { Role, Workspace } from 'app/_models';
import { RoleFormatter } from 'app/_helpers';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AppConfig } from 'app/app.config';

@Component({
    selector: 'user-editor',
    templateUrl: 'user.editor.modal.html'
})
export class UserEditorModal implements OnInit {

    form: FormGroup;

    private _workspaceMember: WorkspaceMember
    @Input()
    set workspaceMember(workspaceMember: WorkspaceMember) {
        this._workspaceMember = workspaceMember
        this.reloadForm()
    }
    get workspaceMember(): WorkspaceMember { return this._workspaceMember }

    availableRoles: Role[]
    rolesDocumentationUrl: SafeUrl

    private workspace: Workspace

    @ViewChild('closeButton') closeButton: ElementRef

    constructor(
        public roleFormatter: RoleFormatter,
        private alertService: AlertService,
        private userService: UserService,
        private sanitizer: DomSanitizer,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private workspaceService: WorkspaceService
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue
        this.availableRoles = this.roleFormatter.rolesManagedBy(this.workspace.role)
        let rolesDocumentationUrl = `${AppConfig.developerBaseUrl}/documentation/dashboard/user-roles/`
        this.rolesDocumentationUrl = this.sanitizer.bypassSecurityTrustUrl(rolesDocumentationUrl)
        this.reloadForm()
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls }

    saveChanges() {
        let name = this.form.controls.name.value
        let role = this.form.controls.role.value

        this.userService.updateUser(this.workspaceMember.id, name, role).then((response) => {
            this.userService.userEdited.emit()

            // Close the modal dialog
            this.closeButton.nativeElement.click()
            this.form.reset()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    onDeleteUserClick() {
        let weakThis = this

        bootbox.dialog({
            title: `Delete ${this.workspaceMember.name}?`,
            message: "The action will have immediate effect.",
            size: 'small',
            buttons: {
                cancel: { label: 'Cancel', className: 'btn-link' },
                delete: {
                    label: 'Delete', className: 'btn-danger', callback: function () {
                        weakThis.deleteUser()
                    }
                }
            }
        })
    }

    private reloadForm() {
        if (!this.workspaceMember) { return }

        this.form = this.formBuilder.group({
            name: [this.workspaceMember.name, Validators.required],
            role: [this.workspaceMember.role, Validators.required]
        })
    }

    private deleteUser() {
        this.workspaceService.removeWorkspaceMember(this.workspaceMember, this.workspace).then((response) => {
            this.userService.userDeleted.emit()

            // Close the modal dialog
            this.closeButton.nativeElement.click()
            this.form.reset()

        }).catch((error) => {
            this.alertService.handleError(error)
        })

    }

}
