<div class="navbar-header d-flex align-items-center h-100">

    <ng-container *ngIf="isLoggedIn">
        <div class="navbar-brand" style="min-width: 0px;">
            <a [routerLink]="logoRouterLink()" class="d-inline-block">
                <img src="assets/img/logo_square.png" alt="">
            </a>
        </div>
        <div class="font-weight-thin ml-2" style="font-size: 21px;" *ngIf="appFeatureName">
            {{appFeatureName}}
        </div>
    </ng-container>

    <div class="navbar-brand" style="min-width: 0px;" *ngIf="!isLoggedIn">
        <a href="{{logoHrefForNotLoggedInUser()}}" class="d-inline-block">
            <img src="assets/img/logo_dark.png" alt="">
        </a>
    </div>
</div>
