import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService, IntegrationsService } from 'app/_services';
import { BackendResponse } from 'app/_models';

@Component({template: ''})
export class SlackRedirectComponent implements OnInit {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private integrationsService: IntegrationsService
    ) {
    }

    ngOnInit() {
        // code=012345678912.012345678912.a54222bdce696b9dc8d3b41979f61c9c220fdc99d37a4e8b73c515f9f7a4fd28&state=866C1FDA-4F98-485F-9E19-CDE3F1078550
        let slackCode = this.route.snapshot.queryParams['code']
        let slackState = this.route.snapshot.queryParams['state']

        // Check if all the required query parameters are there
        if (!slackCode || slackCode.length <= 10 || !slackState || slackState.length < 10) {
            this.alertService.error("Invalid slack activation url", true)
            this.router.navigate(['/settings/integrations'])
            return
        }

        // Slack state parameter is used as workspaceId
        this.finalizeSlackIntegration(slackCode, slackState)
    }

    private finalizeSlackIntegration(code: string, slackState: number) {
        this.integrationsService.createSlackServiceConnection(code, slackState).subscribe(
            response => {
                this.router.navigate(['/settings/integrations'])
            },
            error => {
                this.alertService.handleError(error)
                this.router.navigate(['/settings/integrations'])
            }
        )
    }

}
