<div id="requestPreviewModal" class="modal fade">
    <div class="modal-dialog" [style.max-width]="isShowingAlternative ? 'calc(100vw - 60px)' : '900px'">
        <div class="modal-content">

            <div class="modal-header bg-light">
                <div class="d-flex w-100 align-items-center">
                    <h3 class="mb-0">Request preview</h3>
                    <button #closeButton type="button" class="close ml-auto" data-dismiss="modal">&times;</button>
                </div>
            </div>

            <div class="modal-body" style="max-height: calc(100vh - 240px); overflow: scroll;">
                <div class="row">
                    <div class="col-md-12">
                        <alert></alert>
                    </div>
                </div>

                <div class="justify-content-center text-muted d-flex w-100 align-items-center" style="height: 200px" *ngIf="isSearching">
                    <div class="align-self-center">
                        <i class="icon-spinner4 spinner mr-2"></i> Searching for matching request...
                    </div>
                </div>

                <div *ngIf="!isSearching">
                    <div *ngIf="!searchResult">
                        No matching requests found in UI test logs.
                    </div>

                    <div *ngIf="searchResult">
                        <div class="d-flex">
                            <div [style.width]="isShowingAlternative ? '50%' : '100%'">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="font-size-xs text-uppercase text-muted">Application</div>
                                        <div>{{searchResult.application.name}} <span *ngIf="searchResult.testExecution.testBundleVersion">{{searchResult.testExecution.testBundleVersion}}</span></div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="font-size-xs text-uppercase text-muted">Environment</div>
                                        <div *ngIf="searchResult.testExecution.environment">{{searchResult.testExecution.environment}}</div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-md-6">
                                        <div class="font-size-xs text-uppercase text-muted">Date and Time</div>
                                        <div>{{dateFormatter.dateTime(searchResult.testRun.startDate)}}</div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="font-size-xs text-uppercase text-muted">Device</div>
                                        <div>{{searchResult.testRun.testExecutor.deviceModelName}}</div>
                                    </div>
                                </div>

                                <div class="font-size-xs text-uppercase text-muted mt-2">Operation</div>
                                <samp class="text-default breakall">{{searchResult.response.method}} {{searchResult.response.url}}</samp>

                                <div class="font-size-xs text-uppercase text-muted mt-2">Result</div>
                                <status-code [statusCode]="searchResult.response.statusCode"></status-code><span class="text-muted mx-1">·</span><span class="text-secondary">{{searchResult.response.duration}}s</span>

                                <div *ngIf="searchResult.request">
                                    <div class="font-size-xs text-uppercase text-muted mt-2">Request</div>
                                    <pre class="language-javascript border-0 mt-1">{{httpFormatter.formatNetworkRequestLogPayload(searchResult.request)}}</pre>
                                </div>

                                <div>
                                    <div class="font-size-xs text-uppercase text-muted mt-2">Response</div>
                                    <pre class="language-javascript border-0 mt-1">{{httpFormatter.formatNetworkResponseLogPayload(searchResult.response)}}</pre>
                                </div>

                                <div *ngIf="searchResult.response.errorInfo">
                                    <div class="font-size-xs text-uppercase text-muted mt-2">Error</div>
                                    <pre class="language-javascript border-0 mt-1">{{httpFormatter.formatHTTPErrorInfo(searchResult.response.errorInfo)}}</pre>
                                </div>
                            </div>
                            <div class="d-flex w-50" *ngIf="isShowingAlternative">
                                <div class="alternative-result-line mx-3"></div>

                                <div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="font-size-xs text-uppercase text-muted">Application</div>
                                            <div>{{alternativeSearchResult.application.name}} <span *ngIf="alternativeSearchResult.testExecution.testBundleVersion">{{alternativeSearchResult.testExecution.testBundleVersion}}</span></div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="font-size-xs text-uppercase text-muted">Environment</div>
                                            <div *ngIf="alternativeSearchResult.testExecution.environment">{{alternativeSearchResult.testExecution.environment}}</div>
                                        </div>
                                    </div>

                                    <div class="row mt-2">
                                        <div class="col-md-6">
                                            <div class="font-size-xs text-uppercase text-muted">Date and Time</div>
                                            <div>{{dateFormatter.dateTime(alternativeSearchResult.testRun.startDate)}}</div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="font-size-xs text-uppercase text-muted">Device</div>
                                            <div>{{alternativeSearchResult.testRun.testExecutor.deviceModelName}}</div>
                                        </div>
                                    </div>

                                    <div class="font-size-xs text-uppercase text-muted mt-2">Operation</div>
                                    <samp class="text-default breakall">{{alternativeSearchResult.response.method}} {{alternativeSearchResult.response.url}}</samp>

                                    <div class="font-size-xs text-uppercase text-muted mt-2">Result</div>
                                    <status-code [statusCode]="alternativeSearchResult.response.statusCode"></status-code><span class="text-muted mx-1">·</span><span class="text-secondary">{{alternativeSearchResult.response.duration}}s</span>

                                    <div *ngIf="alternativeSearchResult.request">
                                        <div class="font-size-xs text-uppercase text-muted mt-2">Request</div>
                                        <pre class="language-javascript border-0 mt-1">{{httpFormatter.formatNetworkRequestLogPayload(alternativeSearchResult.request)}}</pre>
                                    </div>

                                    <div>
                                        <div class="font-size-xs text-uppercase text-muted mt-2">Response</div>
                                        <pre class="language-javascript border-0 mt-1">{{httpFormatter.formatNetworkResponseLogPayload(alternativeSearchResult.response)}}</pre>
                                    </div>

                                    <div *ngIf="alternativeSearchResult.response.errorInfo">
                                        <div class="font-size-xs text-uppercase text-muted mt-2">Error</div>
                                        <pre class="language-javascript border-0 mt-1">{{httpFormatter.formatHTTPErrorInfo(alternativeSearchResult.response.errorInfo)}}</pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer d-flex bg-light" *ngIf="searchResult">
                <div class="d-flex" [class.w-50]="isShowingAlternative">
                    <a href="javascript:void(0)" class="ml-auto mr-2 btn btn-outline-primary" (click)="copyRequestAndResponseToClipboard(searchResult)"><i class="mr-2 icon-files-empty"></i>Copy</a>
                    <a href="javascript:void(0)" class="btn btn-outline-primary mr-2" (click)="showAlternativeLog()" *ngIf="alternativeSearchResult && !isShowingAlternative"><i class="mr-2 icon-image-compare"></i>Compare with successful</a>
                    <a href="javascript:void(0)" class="btn btn-outline-primary" (click)="navigateToSearchResult(searchResult)">View in context <i class="icon-arrow-right13"></i></a>
                </div>

                <div class="w-50 d-flex" *ngIf="isShowingAlternative">
                    <a href="javascript:void(0)" class="ml-auto mr-2 btn bg-transparent btn-outline-primary text-primary" (click)="copyRequestAndResponseToClipboard(alternativeSearchResult)"><i class="mr-2 icon-files-empty"></i>Copy</a>
                    <a href="javascript:void(0)" (click)="navigateToSearchResult(alternativeSearchResult)" class="btn btn-outline-primary" *ngIf="alternativeSearchResult">View in context <i class="icon-arrow-right13"></i></a>
                </div>
            </div>

        </div>
    </div>
</div>
