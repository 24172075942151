import { Component, Input, OnInit } from '@angular/core';
import { CrashReport } from 'app/_models';
import { DateFormatter } from 'app/_helpers';

@Component({
    selector: 'crash-report-list-item',
    templateUrl: 'crash.report.list.item.html',
    styleUrls: [
        'crash.report.list.item.css'
    ]
})
export class CrashReportListItem implements OnInit {

    @Input() public crashReport: CrashReport

    public title: string
    public time: string

    constructor(
        public dateFormatter: DateFormatter
    ) {
    }

    ngOnInit() {
        this.title = this.crashReport.deviceModelName
        this.time = this.dateFormatter.timeAgoShort(this.crashReport.crashDate)
    }

}
