import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Application, BackendEnvironment, ProgressBar, TestCaseSummary, TestExecution, TestExecutionFilter, TestExecutor, TestFailureGroup, TestPlan, TestRunSummary } from 'app/_models';
import { AppConfig } from 'app/app.config';
import { CollectionResponse } from './response';
import { ElementResponse } from '.';

export class TestExecutorAllocation {
    testExecutor: TestExecutor
    timeToFirstTest: number
    numberOfTests: number
    executionTime: number
    executorAllocation: number
    testRunProgress: ProgressBar
    testSuiteProgress: ProgressBar
}

export class TestExecutionResolutionResponse {
    id: string
    serialNumber: number
    applicationSlug: string
}

export class AppTestExecutionFiltersResponse {
    environments: BackendEnvironment[]
    testBundleVersions: string[]
    testPlans: TestPlan[]
}

export class BackendEnvironmentTestExecutionFiltersResponse {
    applications: Application[]
    testPlans: TestPlan[]
}

export class TestExecutionDetails {
    testCases: TestCaseSummary[]
    testRuns: TestRunSummary[]
    testFailureGroups: TestFailureGroup[]
    testExecutorAllocations: TestExecutorAllocation[]
    averageTestRunDuration: number
}

export class TestExecutionMetricsSummary {
    networkAppdex: number | null
    resourceAllocation: number | null
}

@Injectable({ providedIn: 'root' })
export class TestExecutionsService {

    constructor(private http: HttpClient) {
    }

    getTestExecutions(workspaceId: number, filter: TestExecutionFilter): Promise<CollectionResponse<TestExecution>> {
        var url = `${AppConfig.apiBaseUrl}/workspaces/${workspaceId}/testExecutions?`
        url = this.urlByApplyingTestExecutionFilter(url, filter)
        return this.http.get<CollectionResponse<TestExecution>>(url).toPromise()
    }

    getApplicationTestExecutionWithSlug(applicationId: number, testExecutionSlug: string): Promise<ElementResponse<TestExecution>> {
        return this.http.get<ElementResponse<TestExecution>>(`${AppConfig.apiBaseUrl}/applications/${applicationId}/testExecutions?slug=${testExecutionSlug}`).toPromise()
    }

    getAppTestExecutionFilters(applicationId: number): Promise<AppTestExecutionFiltersResponse> {
        return this.http.get<AppTestExecutionFiltersResponse>(`${AppConfig.apiBaseUrl}/applications/${applicationId}/testExecutionFilters`).toPromise()
    }

    getEnvironmentTestExecutionFilters(backendEnvironmentId: number): Promise<BackendEnvironmentTestExecutionFiltersResponse> {
        return this.http.get<BackendEnvironmentTestExecutionFiltersResponse>(`${AppConfig.apiBaseUrl}/backendEnvironments/${backendEnvironmentId}/testExecutionFilters`).toPromise()
    }

    resolveTestExecution(workspaceSlug: string, testsCorrelationUuid: string): Promise<TestExecutionResolutionResponse> {
        return this.http.get<TestExecutionResolutionResponse>(`${AppConfig.apiBaseUrl}/resolveTestExecution/?workspaceSlug=${workspaceSlug}&testsCorrelationUuid=${testsCorrelationUuid}`).toPromise()
    }

    deleteTestExecutionWithId(testExecutionId: number): Promise<Object> {
        return this.http.delete(`${AppConfig.apiBaseUrl}/testExecutions/${testExecutionId}`).toPromise()
    }

    getTestExecutionDetails(testExecutionId: number): Promise<ElementResponse<TestExecutionDetails>> {
        return this.http.get<ElementResponse<TestExecutionDetails>>(`${AppConfig.apiBaseUrl}/testExecutions/${testExecutionId}/testRuns`).toPromise()
    }

    getTestExecutionMetricsSummary(testExecutionId: number): Promise<TestExecutionMetricsSummary> {
        let url = `${AppConfig.apiBaseUrl}/testExecutions/${testExecutionId}/metricsSummary`
        return this.http.get<TestExecutionMetricsSummary>(url).toPromise()
    }

    private urlByApplyingTestExecutionFilter(baseUrl: string, filter: TestExecutionFilter): string {
        var url = baseUrl

        if (filter.applicationId != null) { url += `&applicationId=${filter.applicationId}` }
        if (filter.backendEnvironmentId != null) { url += `&backendEnvironmentId=${filter.backendEnvironmentId}` }
        if (filter.testPlanId != null) { url += `&testPlanId=${filter.testPlanId}` }
        if (filter.testBundleVersion != null) { url += `&testBundleVersion=${filter.testBundleVersion}` }
        if (filter.pageBefore !== null && filter.pageBefore !== undefined) { url += `&pageBefore=${filter.pageBefore.toISOString()}` }

        if (filter.from !== null && filter.from !== undefined) {
            url += `&from=${filter.from.toISOString()}`
        } else {
            url += `&limit=20`
        }

        return url
    }


}
