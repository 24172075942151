<div id="dropzoneContainer"></div>

<div *ngIf="progressVisible">
    <div class="text-center">
        <h6 class="font-weight-semibold">{{uploadTitle}}</h6>
    </div>
    <div class="progress" style="height: 1.375rem">
        <div [style.width]="percentageUploaded" class="progress-bar progress-bar-striped progress-bar-animated">
            <span class="font-weight-semibold">{{percentageUploaded}}</span>
        </div>
    </div>
</div>
