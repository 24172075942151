<div class="content-wrapper" (scroll)="onPageContentScroll($event)" #pageContent>

    <div class="content">
        <div class="row">
            <div class="col-md-12">
                <alert></alert>
            </div>
        </div>

        <div class="row" *ngIf="application && builds && subtitle">
            <div class="col-md-12">
                <div class="card mb-1">
                    <div class="page-header border-bottom-light">
                        <div class="page-header-content header-elements-md-inline">
                            <div class="page-title">
                                <h1 class="font-weight-thin">
                                    <a [routerLink]="['../']" class="breadcrumb-item">{{application.name}}<span class="ml-1 font-weight-thin">{{platformFormatter.platformName(application.platform)}}</span></a>
                                    <span class="breadcrumb-item active">Builds</span>
                                </h1>
                                <ul class="list-inline list-inline-dotted list-inline-condensed mb-0">
                                    <li class="list-inline-item text-muted text-sm font-weight-thin">{{subtitle}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <table class="table">
                        <thead>
                            <tr role="row">
                                <th class="border-0">#</th>
                                <th class="border-0 text-left">App identifier</th>
                                <th class="border-0 text-center">Version</th>
                                <th class="border-0 text-center">Upload date</th>
                                <th class="border-0 text-center">Availability</th>
                                <th class="border-0 text-right">Downloads</th>
                                <th class="border-0 text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let build of builds">
                                <td (click)="showBuild(build)"><a [routerLink]="[build.serialNumber]" class="button-muted">#{{build.serialNumber}}</a></td>
                                <td (click)="showBuild(build)" class="nowrap breakall text-left">{{build.bundleIdentifier}}</td>
                                <td (click)="showBuild(build)" class="nowrap text-center">{{build.shortVersion}} ({{build.version}})</td>
                                <td (click)="showBuild(build)" class="nowrap text-center">{{dateFormatter.dateTimeShort(build.creationDate)}}</td>
                                <td (click)="showBuild(build)" class="nowrap text-center">
                                    <span class="badge badge-mark border-pink-400 mr-1" *ngIf="isBuildExpired(build) == true"></span>
                                    <span class="badge badge-mark border-success-400 mr-1" *ngIf="isBuildExpired(build) == false"></span>
                                    <span>{{buildExpirationStatus(build)}}</span>
                                </td>
                                <td (click)="showBuild(build)" class="text-right"><count [count]="build.downloads"></count></td>
                                <td class="text-right">
                                    <div class="list-icons">
                                        <div class="list-icons-item dropdown">
                                            <a href="#" class="list-icons-item dropdown-toggle caret-0" data-toggle="dropdown"><i class="icon-menu9"></i></a>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <a (click)="buildShouldDelete(build)" class="dropdown-item text-pink-400"><i class="icon-cross2"></i> Delete Build</a>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="text-center">
                    <div class="text-muted d-block" *ngIf="isLoadingMoreRecords">
                        <i class="icon-spinner4 spinner mr-2"></i> Loading...
                    </div>
                    <div class="text-muted d-block" *ngIf="!hasMoreRecords">
                        Showing all builds
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
