<div id="uploadSymbolsModal" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header">
                <h5 class="modal-title">Upload debug symbols</h5>
                <button #closeButton type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <alert></alert>
                    </div>
                </div>

                <ng-container *ngIf="missingSymbols && missingSymbols.length > 0">
                    <h6>Missing symbols</h6>
                    <ul>
                        <li *ngFor="let missingSymbol of missingSymbols">
                            <div class="font-weight-semibold align-items-baseline">
                                <span *ngIf="missingSymbol.dsymImageName">{{missingSymbol.dsymImageName}}</span>
                                <span>{{missingSymbol.applicationVersion}} ({{missingSymbol.applicationBuildNumber}})</span>
                                <span class="badge badge-light font-size-sm ml-1" *ngIf="missingSymbol.dsymArchitecture">{{missingSymbol.dsymArchitecture}}</span>
                            </div>
                            <samp class="text-muted" *ngIf="missingSymbol.dsymUuid">{{missingSymbol.dsymUuid}}</samp>
                        </li>
                    </ul>

                    <h6>Upload symbols</h6>
                </ng-container>

                <form [formGroup]="form" *ngIf="form">
                    <div class="form-group">
                        <label>Version Name:</label>
                        <input type="text" autocomplete="off" formControlName="versionName" [attr.disabled]="isUploading ? 'disabled' : null" class="form-control">
                    </div>
                    <div class="form-group">
                        <label>Version Code:</label>
                        <input type="text" autocomplete="off" formControlName="versionCode" [attr.disabled]="isUploading ? 'disabled' : null" class="form-control">
                    </div>
                </form>

                <label>{{fileUploadTitle}}:</label>
                <dropzone #dropzone (fileAdded)="onFileAdded()" (uploadSucceeded)="onUploadSucceeded()" (uploadFailed)="onUploadFailed($event)" [uploadUrl]="uploadUrl" [uploadParamName]="'symbolsFile'"></dropzone>
            </div>

            <div class="modal-footer" *ngIf="form">
                <button (click)="submitFiles()" [disabled]="form.invalid || !didSelectFile || isUploading" type="button" class="btn bg-primary-400">Upload</button>
            </div>
        </div>
    </div>
</div>
