import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { AlertService, TestCasesService } from 'app/_services'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BackendResponse, TestCase } from 'app/_models';

@Component({
    selector: 'edit-test-case-modal',
    templateUrl: 'edit.test.case.modal.html'
})
export class EditTestCaseModal {

    form: FormGroup

    private _testCase: TestCase
    @Input()
    public set testCase(testCase: TestCase) {
        this._testCase = testCase
        this.reloadForm()
    }
    public get testCase(): TestCase {
        return this._testCase
    }

    @Output() testCaseUpdated = new EventEmitter()

    @ViewChild('closeButton') closeButton: ElementRef

    constructor(
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private testCasesService: TestCasesService,
    ) {
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls }

    private reloadForm() {
        this.form = this.formBuilder.group({
            annotation: [this.testCase.annotation]
        })
    }

    saveTestCase() {
        this.testCasesService.updateTestCase(this.testCase.id, this.f.annotation.value).then((response) => {
            this.closeButton.nativeElement.click()
            this.testCaseUpdated.emit()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
