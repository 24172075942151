<navbar-unregistered></navbar-unregistered>

<div class="page-content">
    <div class="content-wrapper">

        <div class="content d-flex justify-content-center align-items-center">

            <form class="login-form" [formGroup]="requestPasswordResetForm" (ngSubmit)="onRequestPasswordResetFormSubmit()" *ngIf="showRequestPasswordResetForm">
                <div class="card mb-0">
                    <div class="card-body">

                        <!-- REGULAR TITLE -->
                        <div class="text-center mb-3">
                            <i class="icon-spinner11 icon-2x text-warning border-warning border-3 rounded-round p-3 mb-3 mt-1"></i>
                            <h5 class="mb-0">Password recovery</h5>
                            <span class="d-block text-muted">We'll send you a code for resetting password in email</span>
                        </div>

                        <!-- ALERT FOR ERRORS -->
                        <alert></alert>

                        <!-- EMAIL INPUT -->
                        <div class="form-group form-group-feedback form-group-feedback-left">
                            <input autofocus type="email" formControlName="email" class="form-control" placeholder="Your email">
                            <div class="form-control-feedback">
                                <i class="icon-mail5 text-muted"></i>
                            </div>
                        </div>

                        <button type="submit" [disabled]="requestPasswordResetForm.invalid" class="btn bg-blue btn-block">
                            <i class="icon-spinner11 mr-2"></i> Initiate password reset
                        </button>
                    </div>
                </div>
            </form>

            <form class="login-form" [formGroup]="newPasswordForm" (ngSubmit)="onNewPasswordFormSubmit()" *ngIf="showNewPasswordForm">
                <div class="card mb-0">
                    <div class="card-body">

                        <!-- REGULAR TITLE -->
                        <div class="text-center mb-3" *ngIf="twoFactorAuthenticationSecret == null">
                            <i class="icon-spinner11 icon-2x text-warning border-warning border-3 rounded-round p-3 mb-3 mt-1"></i>
                            <h5 class="mb-0">Password recovery</h5>
                            <span class="d-block text-muted">Please enter the code from your email and then create a new password</span>
                        </div>

                        <!-- 2FA ACTIVATION TITLE -->
                        <div class="text-center mb-3" *ngIf="twoFactorAuthenticationSecret != null">
                            <i class="icon-shield-check icon-2x text-primary-400 border-primary-400 border-3 rounded-round p-3 mb-3 mt-1"></i>
                            <h5 class="mb-0">2FA Activation Required</h5>
                            <span class="d-block text-muted mb-2">2FA is mandatory for your organization. Please scan the QR code below with your 2FA app, then fill in the activation token.</span>
                        </div>

                        <!-- ALERT FOR ERRORS -->
                        <alert></alert>

                        <!-- PASSWORD RESET CODE INPUT -->
                        <div class="form-group form-group-feedback form-group-feedback-left" *ngIf="twoFactorAuthenticationSecret == null">
                            <input autofocus type="text" formControlName="passwordResetCode" class="form-control" placeholder="Password reset code">
                            <div class="form-control-feedback">
                                <i class="icon-hash text-muted"></i>
                            </div>
                        </div>

                        <!-- NEW PASSWORD INPUT -->
                        <div class="form-group form-group-feedback form-group-feedback-left" *ngIf="twoFactorAuthenticationSecret == null">
                            <input type="password" formControlName="newPassword" class="form-control" placeholder="New password">
                            <div class="form-control-feedback">
                                <i class="icon-lock4 text-muted"></i>
                            </div>
                            <span class="form-text text-muted">Minimum 12 characters and should include uppercase and lowercase letters</span>
                        </div>

                        <!-- OTP INPUT -->
                        <div class="form-group form-group-feedback form-group-feedback-left" *ngIf="showOneTimePasswordInput">
                            <input autofocus type="text" formControlName="oneTimePassword" maxlength="6" class="form-control" placeholder="One time password">
                            <div class="form-control-feedback">
                                <i class="icon-lock4 text-muted"></i>
                            </div>
                        </div>

                        <!-- 2FA SETUP INSTRUCTIONS -->
                        <div *ngIf="twoFactorAuthenticationSecret">
                            <div class="text-center mb-1">
                                <qrcode [qrCodeData]="twoFactorAuthenticationUrl"></qrcode>
                            </div>

                            <div class="text-center mb-2">
                                <a href="#activation-code-link" class="font-weight-semibold text-default py-2" data-toggle="collapse">
                                    Can't scan the code?
                                </a>
                            </div>

                            <div class="collapse text-center mb-2" id="activation-code-link">
                                <div class="mb-1">You can add the code to your application manually using the following <strong>Activation Key:</strong></div>
                                <code>{{twoFactorAuthenticationSecret}}</code>
                            </div>

                            <div class="form-group form-group-feedback form-group-feedback-left">
                                <input autofocus type="text" formControlName="twoFactorAuthenticationToken" maxlength="6" class="form-control" placeholder="2FA activation token">
                                <div class="form-control-feedback">
                                    <i class="icon-lock4 text-muted"></i>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <button type="submit" [disabled]="newPasswordForm.invalid" class="btn bg-blue btn-block">
                                <span *ngIf="twoFactorAuthenticationSecret == null">Update password <i class="icon-circle-right2 ml-2"></i></span>
                                <span *ngIf="twoFactorAuthenticationSecret != null">Enable 2FA <i class="icon-circle-right2 ml-2"></i></span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>

        </div>

    </div>
</div>
