import { VideoFile, VideoTimelineFile } from 'app/_models';
import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChildren } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { AlertService } from 'app/_services';
import { VideoFrameComponent } from './video.frame.component';
import { animate, keyframes, style, transition, trigger } from '@angular/animations';

// Inspiration:
// https://jsfiddle.net/t169fzwb/

@Component({
    selector: 'video-timeline',
    templateUrl: 'video.timeline.component.html',
    styleUrls: [
        'video.timeline.component.css'
    ],
    animations: [
        trigger(
            'fadeInOutAnimation', [
                transition(
                    ':enter', [
                        style({ opacity: 0, transform: 'scale(1.25) translateX(50%)'}),
                        animate("0.2s {{delay}}s ease-in-out", keyframes([
                            style({ opacity: 0.8, transform: 'scale(0.95) translateX(25%)', offset: 0.2 }),
                            style({ opacity: 1, transform: 'scale(1) translateX(0%)', offset: 1 })
                        ]))
                    ], { params: {delay: 0} }
                ),
                transition(
                    ':leave',[
                        style({ opacity: 1 }),
                        animate("0.4s {{delay}}s ease-in-out", style({ opacity: 0 }))
                    ], { params: { delay: 0 } }
                )
            ]
        )
    ]
})
export class VideoTimelineComponent implements OnInit, AfterViewChecked, OnDestroy {

    private _videoTimelineFile: VideoTimelineFile
    @Input()
    set videoTimelineFile(videoTimelineFile: VideoTimelineFile) {
        if (this._videoTimelineFile == videoTimelineFile) {
            return
        }

        this.frameNumbers = null
        this.timelineImageSrc = null
        this._videoTimelineFile = videoTimelineFile
        this.loadVideoTimeline()
    }
    get videoTimelineFile() { return this._videoTimelineFile }

    private _position: number
    @Input()
    set position(position: number) {
        position = Math.min(Math.max(0, position), 1)
        if (position === this._position) {
            return
        }

        this._position = position
        this.updateScrubberAndFrames()
    }
    get position() { return this._position }

    timelineImageSrc: SafeUrl

    private timelineFrameWidth: number
    private timelineFrameHeight: number
    private timelineFramesCount: number

    frameNumbers: number[]
    frameWidth: number
    frameHeight: number

    @Output() positionChanged = new EventEmitter<number>()

    @ViewChildren('videoFrame') videoFrames: VideoFrameComponent[]

    private scrubberElement: HTMLElement
    private timelineElement: HTMLElement

    private resizeListener: any

    constructor(
        private http: HttpClient,
        private alertService: AlertService,
        private changeDetector: ChangeDetectorRef,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit() {
        this.timelineElement = document.getElementById("timeline")

        this.resizeListener = this.onWindowResize.bind(this)
        window.addEventListener("resize", this.resizeListener)
    }

    ngAfterViewChecked() {
        this.scrubberElement = document.getElementById("scrubber")

        // If frames are displayed but without initial position set
        if (this.videoFrames) {
            let undefinedVideFrames = this.videoFrames.filter(videoFrame => { return videoFrame.frameNumber === undefined })

            undefinedVideFrames.forEach((videoFrame, index) => {
                let containerRect = this.timelineElement.getBoundingClientRect()
                let positionAtMinX = index * this.frameWidth / containerRect.width
                videoFrame.frameNumber = this.videoFrameNumberForPosition(positionAtMinX)
            })
        }

        // If frames are loaded, update everything
        if (this.frameNumbers) {
            this.updateScrubberAndFrames()
        }
    }

    ngOnDestroy() {
        window.removeEventListener("resize", this.resizeListener)
    }

    onImageMouseDown(e: PointerEvent) {
        this.onImageDragged(e)

        document.onmousemove = this.onDocumentMouseMove.bind(this)
        document.onmouseup = this.onDocumentImageMouseUp.bind(this)
    }

    private onWindowResize() {
        // Reset frames and trigger few rounds of content refresh,
        // because without this the content would not always refresh correctly.
        this.frameNumbers = null
        this.videoFrames.forEach(videFrame => { videFrame.frameNumber = undefined })
        this.updateScrubberAndFrames()
        this.changeDetector.detectChanges()
        this.updateScrubberAndFrames()
    }

    private loadVideoTimeline() {
        if (this.videoTimelineFile == null) {
            return
        }

        this.http.get(this.videoTimelineFile.url, { observe: 'response', responseType: 'blob' }).toPromise().then((response) => {
            let objectURL = URL.createObjectURL(response.body)
            this.timelineFrameWidth = this.videoTimelineFile.width / this.videoTimelineFile.framesCount
            this.timelineFrameHeight = this.videoTimelineFile.height
            this.timelineFramesCount = this.videoTimelineFile.framesCount
            this.timelineImageSrc = this.sanitizer.bypassSecurityTrustUrl(objectURL)

        }).catch((error) => {
            this.alertService.handleError(error)

        }).finally(() => {
            this.updateScrubberAndFrames()
        })
    }

    private onDocumentMouseMove(e: PointerEvent) {
        this.onImageDragged(e)
    }

    private onDocumentImageMouseUp(e: PointerEvent) {
        document.onmousemove = null
        document.onmouseup = null

        this.onImageDragged(e)
    }

    private onImageDragged(e: PointerEvent) {
        this.position = this.percentageForImgPositionX(e.clientX)
        this.positionChanged.emit(this.position)
    }

    private percentageForImgPositionX(x: number): number {
        let containerRect = this.timelineElement.getBoundingClientRect()

        let minX = containerRect.x
        let maxX = containerRect.x + containerRect.width

        this.position = (x - minX) / (maxX - minX)
        return this.position
    }

    private updateScrubberAndFrames() {
        // Build frames if we have everything
        if (this.frameNumbers == null && this.timelineFrameWidth != null) {
            let timelineRect = this.timelineElement.getBoundingClientRect()
            let originalFrameWidth = this.timelineFrameWidth
            this.frameHeight = Math.round(timelineRect.height - 2) // px border padding
            let frameScale = this.frameHeight / this.timelineFrameHeight
            this.frameWidth = Math.round(originalFrameWidth * frameScale)
            let numberOfFrames = Math.ceil(timelineRect.width / this.frameWidth)
            this.frameNumbers = Array.from(Array(numberOfFrames).keys())
            return
        }

        // Update frames and scrubber
        if (this.videoFrames && this.scrubberElement) {
            let containerRect = this.timelineElement.getBoundingClientRect()
            var scrubberX = containerRect.width * this.position - 2 // 2px for better fitting the hand cursor
            scrubberX = Math.min(Math.max(0, scrubberX), containerRect.width - 3) // px also to fix some rounding issues
            this.scrubberElement.style.left = `${scrubberX}px`

            /// Update current frame index for each frame container
            this.videoFrames.forEach((videoFrame, index) => {
                let videoFrameMinX = index * this.frameWidth
                let videoFrameMaxX = (index + 1) * this.frameWidth
                let videoFrameInFocus = scrubberX >= videoFrameMinX && scrubberX < videoFrameMaxX

                if (videoFrameInFocus) {
                    videoFrame.frameNumber = this.videoFrameNumberForPosition(this.position)
                    return
                }
            })
        }
    }

    private videoFrameNumberForPosition(position: number) {
        if (position <= 0) {
            return 0

        } else if (position >= 1) {
            return this.timelineFramesCount - 1
        }

        return Math.round((this.timelineFramesCount - 1) * position)
    }

}
