import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthToken } from 'app/_models';
import { AppConfig } from 'app/app.config';
import { CollectionResponse } from './response';

export class CreateAuthTokenResponse {
    authToken: AuthToken
    originalToken: string
}

@Injectable({ providedIn: 'root' })
export class AuthTokenService {

    constructor(
        private http: HttpClient,
    ) {
    }

    createAuthToken(name: string, workspaceId: number): Promise<CreateAuthTokenResponse> {
        let requestBody = {
            name: name,
            workspaceId: workspaceId
        }

        return this.http.post<CreateAuthTokenResponse>(`${AppConfig.apiBaseUrl}/authTokens`, requestBody).toPromise()
    }

    getWorkspaceAuthTokens(workspaceId: number): Promise<CollectionResponse<AuthToken>> {
        return this.http.get<CollectionResponse<AuthToken>>(`${AppConfig.apiBaseUrl}/workspaces/${workspaceId}/authTokens`).toPromise()
    }

    updateAuthToken(authTokenId: number, name: string): Promise<object> {
        let body = {
            name: name,
        }

        return this.http.put(`${AppConfig.apiBaseUrl}/authTokens/${authTokenId}`, body).toPromise()
    }

    deleteAuthToken(authTokenId: number): Promise<Object> {
        return this.http.delete(`${AppConfig.apiBaseUrl}/authTokens/${authTokenId}`).toPromise()
    }

}
