import { NetworkTrendsSummaryResponse } from 'app/_models';
import { Component, Input, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'response-time-summary',
    templateUrl: 'response.time.summary.component.html'
})
export class ResponseTimeSummaryComponent {

    dnsPercentage: string
    connectionPercentage: string
    requestPercentage: string
    serverPercentage: string
    responsePercentage: string
    clientPercentage: string

    _networkTrendsSummaryResponse: NetworkTrendsSummaryResponse
    @Input()
    set networkTrendsSummaryResponse(networkTrendsSummaryResponse: NetworkTrendsSummaryResponse) {
        if (this._networkTrendsSummaryResponse != networkTrendsSummaryResponse) {
            this._networkTrendsSummaryResponse = networkTrendsSummaryResponse
            this.reload()
        }
    }
    get networkTrendsSummaryResponse(): NetworkTrendsSummaryResponse { return this._networkTrendsSummaryResponse }

    constructor(
        private changeDetector: ChangeDetectorRef
    ) {
    }

    private reload() {
        let summary = this.networkTrendsSummaryResponse as any
        if (summary == null) { return }

        let overallDuration = summary.overallDuration

        this.dnsPercentage = (summary.dnsDuration / overallDuration) * 100 + "%"
        this.connectionPercentage = (summary.connectionDuration / overallDuration) * 100 + "%"
        this.connectionPercentage = (summary.connectionDuration / overallDuration) * 100 + "%"
        this.requestPercentage = (summary.requestDuration / overallDuration) * 100 + "%"
        this.serverPercentage = (summary.serverDuration / overallDuration) * 100 + "%"
        this.responsePercentage = (summary.responseDuration / overallDuration) * 100 + "%"
        this.clientPercentage = (summary.clientDuration / overallDuration) * 100 + "%"
        this.changeDetector.detectChanges()
    }

}
