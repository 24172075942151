import { DateFormatter } from 'app/_helpers';
import { Component, Input, OnDestroy } from '@angular/core';
import { TestExecution } from 'app/_models';
import { Subscription, Observable, timer } from 'rxjs';

@Component({
    selector: 'test-execution-progress',
    templateUrl: 'test.execution.progress.item.html'
})
export class TestExecutionProgressItem implements OnDestroy {

    _testExecution: TestExecution
    @Input()
    set testExecution(testExecution: TestExecution) {
        this._testExecution = testExecution

        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe()
        }

        // Call immediately as the second call in a timer is causing progress to be animated
        this.recalculateState()
        this.timerSubscription = this.timerObservable.subscribe((seconds) => {
            this.recalculateState()
        })
    }
    get testExecution() { return this._testExecution }

    statusText: string
    detailText: string | null
    percentagePassed: string
    percentagePassedClass: string
    percentageFailed: string
    percentageFailedClass: string

    private timerObservable: Observable<number> = timer(0, 1000)
    private timerSubscription: Subscription

    constructor(
        private dateFormatter: DateFormatter
    ) {
    }

    ngOnDestroy() {
        if (this.timerSubscription != null) {
            this.timerSubscription.unsubscribe()
        }
    }

    private recalculateState() {
        let stats = this.testExecution.stats

        if (this.testExecution.endDate == null) {
            this.percentagePassed = (stats.completelyPassed / stats.total) * 100 + "%"
            this.percentageFailed = (stats.completelyFailed / stats.total) * 100 + "%"

            var statusText = `${stats.total} tests, ${(stats.successRate * 100).toFixed(1)}% passed`
            this.statusText = statusText

            if (this.testExecution.estimatedTimeRemaining > 60) {
                this.detailText = `${this.dateFormatter.duration(this.testExecution.estimatedTimeRemaining)} remaining`
            } else {
                this.detailText = "ends soon"
            }

            this.percentagePassedClass = "progress-bar progress-bar-striped progress-bar-animated bg-success-400"
            this.percentageFailedClass = "progress-bar progress-bar-striped progress-bar-animated bg-pink-400"

        } else {
            // Once the test execution is finished, there are no more pending and in progress test runs
            this.percentagePassed = stats.successRate * 100 + "%"
            this.percentageFailed = (1 - stats.successRate) * 100 + "%"

            this.statusText = `${stats.total} tests, ${(stats.successRate * 100).toFixed(1)}% passed`
            this.detailText = null

            this.percentagePassedClass = "progress-bar progress-bar-striped bg-success-400"
            this.percentageFailedClass = "progress-bar progress-bar-striped bg-pink-400"

            // Stop the timer if execution is finished
            if (this.timerSubscription) {
                this.timerSubscription.unsubscribe()
            }
        }
    }

}
