import { AppConfig } from 'app/app.config';
import { Component } from '@angular/core';

@Component({
    selector: "mobile-warning",
    templateUrl: 'mobile.warning.component.html'
})
export class MobileWarningComponent {

    websiteUrl: string
    developerPortalUrl: string

    constructor(
    ) {
        this.websiteUrl = AppConfig.webBaseUrl
        this.developerPortalUrl = AppConfig.developerBaseUrl
    }

}
