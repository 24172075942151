<div class="d-flex">
    <div class="sidebar sidebar-light sidebar-secondary sidebar-expand-md">
        <div class="card-body border-bottom-light">
            <form [formGroup]="filterForm">
                <div class="form-group form-group-feedback form-group-feedback-right">
                    <input type="email" class="form-control" formControlName="reporterEmail" placeholder="Reporter email">
                    <div class="form-control-feedback">
                        <i class="icon-search4 font-size-base text-muted"></i>
                    </div>
                </div>
            </form>
        </div>

        <div (scroll)="onBugReportsListScroll($event)" #bugReportsList class="reports-content-wrapper">
            <ul class="media-list media-list-linked">
                <div *ngFor="let bugReport of bugReports">
                    <li [class.selected]="bugReport.serialNumber == selectedBugReportSerialNumber">
                        <a [routerLink]="[bugReport.serialNumber]" class="media">
                            <bug-reports-list-item [bugReport]="bugReport"></bug-reports-list-item>
                        </a>
                    </li>
                </div>
            </ul>

            <div class="text-center">
                <div class="text-muted d-block" *ngIf="isLoadingMoreRecords">
                    <i class="icon-spinner4 spinner mr-2"></i> Loading...
                </div>
                <div class="text-muted d-block mt-2 mb-2" *ngIf="!hasMoreRecords">
                    Showing all matching reports
                </div>
            </div>
        </div>
    </div>

    <div class="content-wrapper">
        <div class="w-100 h-100 text-center d-flex justify-content-center align-items-center" *ngIf="selectedBugReportSerialNumber == null">
            <h4 class="text-muted m-0">No Bug Report Selected</h4>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>
