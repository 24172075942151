<div class="d-flex">
    <div class="sidebar sidebar-light sidebar-secondary sidebar-expand-md">

        <div #crashReportsList class="reports-content-wrapper">
            <ul class="media-list media-list-linked">
                <div *ngFor="let crashReport of crashReports">
                    <li [class.selected]="crashReport.serialNumber == selectedCrashReportSerialNumber">
                        <a [routerLink]="[crashReport.serialNumber]" class="media">
                            <crash-report-list-item [crashReport]="crashReport"></crash-report-list-item>
                        </a>
                    </li>
                </div>
            </ul>

        </div>
    </div>

    <div class="content-wrapper">
        <div class="w-100 h-100 text-center d-flex justify-content-center align-items-center" *ngIf="selectedCrashReportSerialNumber == null">
            <h4 class="text-muted m-0">No Crash Report Selected</h4>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>
