<navbar-unregistered></navbar-unregistered>

<div class="page-content">
    <div class="content-wrapper">

        <div class="content d-flex justify-content-center align-items-center">
            <form class="login-form desktop-only" [formGroup]="demoForm" (ngSubmit)="onSubmit()">
                <div class="card mb-0">
                    <div class="card-body">
                        <div class="text-center">
                            <i class="icon-rocket icon-2x text-primary border-primary border-3 rounded-round p-3 mb-3 mt-1"></i>
                            <h5 class="mb-0">Live Demo</h5>
                            <span class="d-block text-muted mb-3">Please fill in the form</span>
                        </div>

                        <alert></alert>

                        <fieldset [disabled]="demoModeLoading">
                            <div class="form-group form-group-feedback form-group-feedback-left">
                                <input type="text" formControlName="workspaceName" class="form-control" placeholder="Workspace or Company Name">
                                <div class="form-control-feedback">
                                    <i class="icon-office text-muted"></i>
                                </div>
                            </div>

                            <div class="form-group form-group-feedback form-group-feedback-left">
                                <input type="email" formControlName="email" class="form-control" placeholder="Email (optional)">
                                <div class="form-control-feedback">
                                    <i class="icon-mention text-muted"></i>
                                </div>

                                <span class="form-text text-muted">Just an optional email in case you wish to share your contant information.</span>
                            </div>
                        </fieldset>

                        <button [disabled]="demoForm.invalid || demoModeLoading" type="submit" class="btn bg-primary-400 btn-block">
                            <span *ngIf="demoModeLoading == false">Launch <i class="icon-circle-right2 ml-2"></i></span>
                            <span *ngIf="demoModeLoading"><i class="icon-spinner4 spinner mr-2"></i>Creating Demo Account...</span>
                        </button>
                    </div>
                </div>
            </form>
            <mobile-warning class="mobile-only"></mobile-warning>
        </div>

    </div>
</div>
