import { AuthenticationService } from 'app/_services';
import { Injectable } from '@angular/core';
import { Role } from 'app/_models';

@Injectable({ providedIn: 'root' })
export class RouteHelper {

    constructor(
        private authenticationService: AuthenticationService,
    ) {
    }

    currentWorkspaceHome(): string {
        if (this.authenticationService.currentWorkspaceValue.role == Role.Tester) {
            return `/${this.authenticationService.currentWorkspaceValue.slug}/app-distributions`
        } else {
            return `/${this.authenticationService.currentWorkspaceValue.slug}`
        }
    }

}
