<div id="editIntegrationTriggerModal" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header">
                <h5 class="modal-title" *ngIf="integrationTrigger == null && serviceConnection != null">New Trigger for {{serviceConnection.name}}</h5>
                <h5 class="modal-title" *ngIf="integrationTrigger != null">Edit Trigger</h5>
                <button #closeButton type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="row">
                    <div class="col-md-12">
                        <alert></alert>
                    </div>
                </div>

                <div class="form-group row align-items-baseline" *ngIf="integrationTrigger == null">
                    <label class="col-sm-3 font-weight-semibold">When:</label>
                    <ul class="nav nav-pills col-sm-9 pl-2 m-0">
                        <li class="nav-item"><a href="#bugReportsTab" id="bugReportsTabButton" class="nav-link p-1 mr-1" data-toggle="tab">New bug is reported</a></li>
                        <li class="nav-item"><a href="#testExecutionsTab" id="testExecutionsTabButton" class="nav-link p-1 mr-1" data-toggle="tab">Test execution finishes</a></li>
                    </ul>
                </div>

                <div class="tab-content">
                    <div class="tab-pane fade" id="bugReportsTab">
                        <form [formGroup]="bugReportsForm" *ngIf="bugReportsForm">
                            <div class="form-group row">
                                <label class="col-sm-3 mt-2">Reporter email</label>
                                <div class="col-sm-9">
                                    <input type="email" formControlName="reporterEmail" class="form-control">
                                    <div class="text-muted mt-1">When specified, only the reports matching this email address will trigger the integration.</div>
                                </div>
                            </div>

                            <div class="form-group row align-items-baseline">
                                <label class="col-sm-3">Applications</label>
                                <div class="col-sm-9">
                                    <div *ngFor="let application of applications">
                                        <input type="checkbox" formControlName="application_{{application.id}}"> {{application.name}}<span class="ml-1 text-muted">{{platformFormatter.platformName(application.platform)}}</span>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="w-100 d-flex" *ngIf="bugReportsForm">
                            <button type="button" class="btn btn-link text-default ml-auto mr-2" data-dismiss="modal">Cancel</button>
                            <button (click)="createBugReportsIntegrationTrigger()" [disabled]="bugReportsForm.invalid" type="button" class="btn bg-primary-400" *ngIf="integrationTrigger == null">Create</button>
                            <button (click)="updateBugReportsIntegrationTrigger()" [disabled]="bugReportsForm.invalid" type="button" class="btn bg-primary-400" *ngIf="integrationTrigger != null">Save</button>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="testExecutionsTab">
                        <form [formGroup]="testExecutionsForm" *ngIf="testExecutionsForm">
                            <div class="form-group row">
                                <label class="col-sm-3 mt-2">Environments</label>
                                <div class="col-sm-9">
                                    <tag-input style="height: auto;" formControlName="environmentNames" secondaryPlaceholder="Environment name" placeholder='+ Environment' [onTextChangeDebounce]="0" [maxItems]='10' [separatorKeyCodes]="[9, 44, 188]" [animationDuration]="{enter: '0ms', leave: '0ms'}" theme='minimal' class="form-control pl-1 py-0">
                                        <tag-input-dropdown [displayBy]="'display'" [identifyBy]="'value'" [autocompleteItems]="availableEnvironmentNames"></tag-input-dropdown>
                                    </tag-input>
                                    <div class="text-muted mt-1">If specified, only the test executions with matching environment will trigger the integration. Use comma or tab to enter multiple value.</div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-sm-3 mt-2">Test plans</label>
                                <div class="col-sm-9">
                                    <tag-input style="height: auto;" formControlName="testPlanNames" secondaryPlaceholder="Test plan name" placeholder='+ Test plan' [onTextChangeDebounce]="0" [maxItems]='10' [separatorKeyCodes]="[9, 44, 188]" [animationDuration]="{enter: '0ms', leave: '0ms'}" theme='minimal' class="form-control pl-1 py-0">
                                        <tag-input-dropdown [displayBy]="'display'" [identifyBy]="'value'" [autocompleteItems]="availableTestPlanNames"></tag-input-dropdown>
                                    </tag-input>
                                    <div class="text-muted mt-1">If specified, only the test executions with matching test plan will trigger the integration. Use comma or tab to enter multiple value.</div>
                                </div>
                            </div>

                            <div class="form-group row align-items-baseline">
                                <label class="col-sm-3">Applications</label>
                                <div class="col-sm-9">
                                    <div *ngFor="let application of applications">
                                        <input type="checkbox" formControlName="application_{{application.id}}"> {{application.name}}<span class="ml-1 text-muted">{{platformFormatter.platformName(application.platform)}}</span>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="w-100 d-flex" *ngIf="testExecutionsForm">
                            <button type="button" class="btn btn-link text-default ml-auto mr-2" data-dismiss="modal">Cancel</button>
                            <button (click)="createTestExecutionsIntegrationTrigger()" [disabled]="testExecutionsForm.invalid" type="button" class="btn bg-primary-400" *ngIf="integrationTrigger == null">Create</button>
                            <button (click)="updateTestExecutionsIntegrationTrigger()" [disabled]="testExecutionsForm.invalid" type="button" class="btn bg-primary-400" *ngIf="integrationTrigger != null">Update</button>
                        </div>
                    </div>
                </div>

        </div>
    </div>
</div>
