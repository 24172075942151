import { AppLaunchDimension, AppLaunchSegment } from 'app/_models';
import { Component, Input } from '@angular/core';
import { DateFormatter, PlatformFormatter } from 'app/_helpers';

@Component({
    selector: 'app-launch-segments',
    templateUrl: 'app.launch.segments.component.html',
    styleUrls: ['app.launch.segments.component.css']
})

export class AppLaunchSegmentsComponent {

    AppLaunchDimension = AppLaunchDimension

    @Input() appLaunchDimension: AppLaunchDimension
    @Input() appLaunchSegments: AppLaunchSegment[]
    @Input() allowFiltering: boolean = true

    constructor(
        public dateFormatter: DateFormatter,
        public platformFormatter: PlatformFormatter
    ) {
    }

}
