<div class="content-wrapper" id="pageContent">

    <div class="content">
        <div class="row">
            <div class="col-md-12">
                <alert></alert>
            </div>
        </div>

        <div class="row" *ngIf="application && testCaseSummaries">
            <div class="col-md-12">
                <div class="card mb-2">
                    <div class="page-header">
                        <div class="page-header-content header-elements-md-inline">
                            <div class="page-title">
                                <h1 class="font-weight-thin">
                                    <a [routerLink]="['../']" class="breadcrumb-item">{{application.name}}</a>
                                    <span class="breadcrumb-item active">Test Cases</span>
                                </h1>
                            </div>

                            <div class="header-elements">
                                <header-value-item title="TOTAL" value="{{testCaseSummaries.length}}"></header-value-item>
                            </div>
                        </div>

                        <div class="progress noshadow rounded-0" style="height: 7px;">
                            <div [style.width]="stablePercentage" class="progress-bar bg-success-400"></div>
                            <div [style.width]="unstablePercentage" class="progress-bar bg-orange-400"></div>
                            <div [style.width]="failingPercentage" class="progress-bar bg-pink-400"></div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row col-md-12 ml-0 p-0">
                            <ul class="nav nav-pills mb-0">
                                <li class="nav-item">
                                    <a href="#" [ngClass]="{ 'active' : testStabilityFilter == 'all' }" (click)="setTestStabilityFilter('all')" class="nav-link" data-toggle="tab">
                                        All
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" [ngClass]="{ 'active' : testStabilityFilter == 'stable' }" (click)="setTestStabilityFilter('stable')" class="nav-link" data-toggle="tab">
                                        <span class="badge badge-mark border-success-400 mr-1"></span>
                                        Stable
                                        <span class="badge badge-light ml-2">{{stableCount}}</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" [ngClass]="{ 'active' : testStabilityFilter == 'unstable' }" (click)="setTestStabilityFilter('unstable')" class="nav-link" data-toggle="tab">
                                        <span class="badge badge-mark border-orange-400 mr-1"></span>
                                        Unstable
                                        <span class="badge badge-light ml-2">{{unstableCount}}</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" [ngClass]="{ 'active' : testStabilityFilter == 'failing' }" (click)="setTestStabilityFilter('failing')" class="nav-link" data-toggle="tab">
                                        <span class="badge badge-mark border-pink-400 mr-1"></span>
                                        Failing
                                        <span class="badge badge-light ml-2">{{failingCount}}</span>
                                    </a>
                                </li>
                            </ul>

                            <ul class="ml-auto pagination-flat align-self-center justify-content-center twbs-flat pagination font-size-sm">
                                <li class="page-item" [ngClass]="{ 'active' : timeFilter == '1W' }">
                                    <a href="#" class="page-link p-1 text-muted" [ngClass]="{'text-muted' : timeFilter != '1W'}" style="min-width: 0" data-toggle="tab" (click)="setTimeFilter('1W')">1W</a>
                                </li>
                                <li class="page-item" [ngClass]="{ 'active' : timeFilter == '2W' }">
                                    <a href="#" class="page-link p-1 text-muted" [ngClass]="{'text-muted' : timeFilter != '2W'}" style="min-width: 0" data-toggle="tab" (click)="setTimeFilter('2W')">2W</a>
                                </li>
                                <li class="page-item" [ngClass]="{ 'active' : timeFilter == '1M' }">
                                    <a href="#" class="page-link p-1 text-muted" [ngClass]="{'text-muted' : timeFilter != '1M'}" style="min-width: 0" data-toggle="tab" (click)="setTimeFilter('1M')">1M</a>
                                </li>
                                <li class="page-item" [ngClass]="{ 'active' : timeFilter == '3M' }">
                                    <a href="#" class="page-link p-1 text-muted" [ngClass]="{'text-muted' : timeFilter != '3M'}" style="min-width: 0" data-toggle="tab" (click)="setTimeFilter('3M')">3M</a>
                                </li>
                                <li class="page-item" [ngClass]="{ 'active' : timeFilter == '1Y' }">
                                    <a href="#" class="page-link p-1 text-muted" [ngClass]="{'text-muted' : timeFilter != '1Y'}" style="min-width: 0" data-toggle="tab" (click)="setTimeFilter('1Y')">1Y</a>
                                </li>
                            </ul>
                        </div>

                    </div>

                    <table class="table">
                        <thead>
                            <tr role="row">
                                <th class="border-bottom-0 w-100">Test Case</th>
                                <th class="border-bottom-0 nowrap">Failures</th>
                                <th class="border-bottom-0 nowrap">Total Runs</th>
                                <th class="border-bottom-0 nowrap">Success rate</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let testSuite of testCaseSuiteNames">
                                <tr class="table-active">
                                    <td colspan="4" class="font-weight-semibold">
                                        <a href="#{{testSuite}}" class="text-default" data-toggle="collapse">
                                            <h6 class="mb-0">{{testSuite}}</h6>
                                        </a>
                                    </td>
                                </tr>

                                <tr id="{{testSuite}}" *ngFor="let testCaseSummary of testCaseSummariesForTestSuite(testSuite)" class="collapse show" (click)="navigateToTestCase(testCaseSummary.testCase)">
                                    <td class="d-flex">
                                        <div class="mr-3 ml-1">
                                            <span [class]="testCaseSummaryClass(testCaseSummary)"></span>
                                        </div>

                                        <div class="font-weight-semibold button-muted">
                                            <a class="font-weight-semibold button-muted nowrap" [routerLink]="[testCaseSummary.testCase.serialNumber]">
                                                {{testCaseSummary.testCase.name}}
                                            </a>
                                        </div>
                                    </td>
                                    <td>{{testCaseSummary.failuresCount}}</td>
                                    <td>{{testCaseSummary.testRunsCount}}</td>
                                    <td>{{testCaseSummarySuccessRate(testCaseSummary)}}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

    </div>
</div>
