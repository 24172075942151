<div *ngIf="networkTrendsSummaryResponse">
    <div class="d-flex">
        <div class="mr-auto font-weight-semibold">Total requests</div>
        <count [count]="totalRequestsCount"></count>
    </div>

    <hr class="my-1">

    <div class="font-size-sm nowrap">
        <div class="d-flex">
            <div class="mr-auto">Per day:</div>
            <count [count]="requestsPerDay"></count>
        </div>
        <div class="d-flex">
            <div class="mr-auto">Per hour:</div>
            <count [count]="requestsPerHour"></count>
        </div>
        <div class="d-flex">
            <div class="mr-auto">Per minute:</div>
            <count [count]="requestsPerMinute"></count>
        </div>
        <div class="d-flex">
            <div class="mr-auto">Per second:</div>
            <count [count]="requestsPerSecond"></count>
        </div>
    </div>
</div>
