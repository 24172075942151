import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Application, DateRange, ErrorFilter, ErrorGroupSegment, ErrorSegmentsOrder, errorSegmentsOrderFromQuery, ErrorTrendsResponse, ErrorTrendsSummaryResponse } from 'app/_models';
import { AlertService, ApplicationService, ErrorFiltersResponse, ErrorsService } from 'app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationFormatter, DateFormatter, IssueStateFormatter } from 'app/_helpers';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment'

@Component({
    templateUrl: 'errors.component.html',
    styleUrls: [
        'errors.component.css'
    ]
})
export class ErrorsComponent implements OnInit, OnDestroy {

    ErrorSegmentsOrder = ErrorSegmentsOrder

    application: Application
    errorGroupSegments: ErrorGroupSegment[]

    errorFiltersResponse: ErrorFiltersResponse
    errorTrendsResponse: ErrorTrendsResponse
    errorTrendsSummaryResponse: ErrorTrendsSummaryResponse

    @ViewChild('pageContent') pageContent: ElementRef

    selectedDateRange: DateRange

    private currentApplicationSubscription: Subscription
    private queryParamsSubscription: Subscription
    private previousQueryParams: any = {}

    constructor(
        public dateFormatter: DateFormatter,
        public issueStateFormatter: IssueStateFormatter,
        private errorsService: ErrorsService,
        private route: ActivatedRoute,
        private applicationService: ApplicationService,
        private alertService: AlertService,
        private router: Router,
        private applicationFormatter: ApplicationFormatter,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        let query = this.route.snapshot.queryParams
        this.selectedDateRange = DateRange.fromParams(query) ?? DateRange.lastMonth()
        this.updateSelect2SelectionFromQuery()

        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
            if (this.application != null) {
                this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | Errors`)
                this.refreshFilters()
            }
        })

        this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
            this.updateSelect2SelectionFromQuery()

            // If we don't have the filters response yet, no need to do anything as all the data will
            // get reloaded once the filters call finishes.
            if (!this.errorFiltersResponse) { return }

            let newDateRange = DateRange.fromParams(params) ?? DateRange.lastMonth()
            let changedParamNames = this.getChangedParamNames(params, this.previousQueryParams)

            // If ordering didn't change, it means that either date range or the filters changed,
            // and then we need to reload everything.
            if (!changedParamNames.includes('orderBy')) {
                this.selectedDateRange = newDateRange
                this.refreshErrorTrends()
                this.reloadErrorGroups()

                // If only the ordering changed, reload just the crash groups
            } else {
                this.reloadErrorGroups()
            }

            this.previousQueryParams = { ...params }
        })
    }

    ngOnDestroy() {
        this.currentApplicationSubscription.unsubscribe()
        this.queryParamsSubscription.unsubscribe()
    }

    private _didInitSelect2 = false
    ngAfterViewChecked() {
        if (!this.errorFiltersResponse) { return }

        if (!this._didInitSelect2 && $('.select2').length > 0) {
            this.initSelect2Components()
            this.updateSelect2SelectionFromQuery()
            this._didInitSelect2 = true
        }
    }

    refreshFilters() {
        this.errorFiltersResponse = null
        this.errorsService.getApplicationErrorFilters(this.application.id).then((response) => {
            this.errorFiltersResponse = response
            this.refreshErrorTrends()
            this.reloadErrorGroups()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    refreshErrorTrends() {
        if (!this.application) { return }

        // Will also switch the trends chart into loading mode
        this.errorTrendsResponse = null
        this.errorTrendsSummaryResponse = null

        let errorFilter = this.makeCurrentErrorFilter()
        let getErrorTrends = this.errorsService.getErrorTrends(this.application.workspaceId, errorFilter)
        let getErrorTrendsSummary = this.errorsService.getErrorTrendsSummary(this.application.workspaceId, errorFilter)

        Promise.all([getErrorTrends, getErrorTrendsSummary]).then((response) => {
            this.errorTrendsResponse = response[0]
            this.selectedDateRange = response[0].dateRange
            this.errorTrendsSummaryResponse = response[1]

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    reloadErrorGroups() {
        if (!this.application) { return }

        this.errorGroupSegments = null

        let errorFilter = this.makeCurrentErrorFilter()
        let orderBy = errorSegmentsOrderFromQuery(this.route.snapshot.queryParams) ?? ErrorSegmentsOrder.COUNT_DESC
        return this.errorsService.getWorkspaceErrorGroups(this.application.workspaceId, errorFilter, orderBy).then((response) => {
            this.errorGroupSegments = response.data

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    onDateRangeChanged(dateRange: DateRange) {
        this.router.navigate([], { queryParams: { startDate: moment(dateRange.startDate).toISOString(), endDate: moment(dateRange.endDate).toISOString() }, queryParamsHandling: 'merge' })
    }

    navigateToErrorGroup(errorGroupSegment: ErrorGroupSegment) {
        this.router.navigate([errorGroupSegment.serialNumber], { relativeTo: this.route, queryParamsHandling: 'merge' })
    }

    private makeCurrentErrorFilter(): ErrorFilter {
        let query = this.route.snapshot.queryParams

        let errorFilter = new ErrorFilter()
        errorFilter.applicationId = this.application.id
        errorFilter.startDate = moment(this.selectedDateRange.startDate).toDate()
        errorFilter.endDate = moment(this.selectedDateRange.endDate).toDate()

        if (this.errorFiltersResponse) {
            if (query['bundleIdentifier']) {
                errorFilter.bundleIdentifierId = this.errorFiltersResponse.bundleIdentifiers.find((bi) => { return bi.value == query['bundleIdentifier'] }).id
            }

            if (query['applicationVersion']) {
                errorFilter.applicationVersionId = this.errorFiltersResponse.applicationVersions.find((v) => { return v.bundleShortVersion == query['applicationVersion'] }).id
            }

            if (query['osVersion']) {
                errorFilter.osVersionId = this.errorFiltersResponse.osVersions.find((osVersion) => { return osVersion.version == query['osVersion'] }).id
            }
        }

        return errorFilter
    }

    private initSelect2Components() {
        $('#bundleIdentifierSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '240px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#applicationVersionSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '160px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#osVersionSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '140px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678

        // Fix for select2 being expanded after clicking on clear button
        $('.select2').on("select2:unselecting", (event) => {
            $(event.target).val(null).trigger('change')
            event.preventDefault()
        })

        $('#bundleIdentifierSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { bundleIdentifier: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#bundleIdentifierSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { bundleIdentifier: null }, queryParamsHandling: 'merge' })
        })

        $('#applicationVersionSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { applicationVersion: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#applicationVersionSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { applicationVersion: null }, queryParamsHandling: 'merge' })
        })

        $('#osVersionSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { osVersion: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#osVersionSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { osVersion: null }, queryParamsHandling: 'merge' })
        })
    }

    private updateSelect2SelectionFromQuery() {
        if (!this.application || !this.errorFiltersResponse) { return }

        let query = this.route.snapshot.queryParams

        $('#bundleIdentifierSelect').val(query['bundleIdentifier']).trigger('change')
        $('#applicationVersionSelect').val(query['applicationVersion']).trigger('change')
        $('#osVersionSelect').val(query['osVersion']).trigger('change')
    }

    private getChangedParamNames(newParams: any, oldParams: any): string[] {
        var changedParamNames = []
        for (const key in newParams) {
            if (newParams[key] !== oldParams[key]) {
                changedParamNames.push(key)
            }
        }
        return changedParamNames
    }

}
