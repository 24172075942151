<navbar-settings></navbar-settings>

<mobile-warning class="mobile-only"></mobile-warning>

<div class="page-content desktop-only">
    <settings-sidebar selectedFeatureIndex="7"></settings-sidebar>

    <!-- Main content -->
    <div class="content-wrapper">

        <!-- Content area -->
        <div class="content">

            <div class="row">
                <div class="col-md-12">
                    <alert></alert>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h1 class="font-weight-thin mb-0">Workspace settings</h1>
                            <div class="text-muted text-sm font-weight-thin">Created on {{dateFormatter.date(workspace.creationDate)}}</div>
                        </div>

                        <div class="card-body">
                            <form [formGroup]="editWorkspaceForm">
                                <div class="form-group">
                                    <div class="font-size-xs text-uppercase text-muted mb-1">Workspace name <span class="text-danger">*</span></div>
                                    <input type="text" formControlName="workspaceName" class="form-control">
                                    <div class="text-muted mt-1" *ngIf="!canEditWorkspace">
                                        Note that only workspace Agent can change workspace
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="align-items-baseline">
                                        <input formControlName="twoFactorAuthenticationEnforcedForDashboardAccess" type="checkbox">
                                        <span class="ml-1">2FA authentication enforced</span>
                                    </label>

                                    <div class="text-muted" *ngIf="canEditWorkspace && !this.workspace.twoFactorAuthenticationEnforcedForDashboardAccess && this.editWorkspaceForm.controls.twoFactorAuthenticationEnforcedForDashboardAccess.value">
                                        WARNING: Enforcing 2FA will immediately invalidate all active sessions for users which have Appelium Dashboard access (Agent, Admin and Member roles) but have not enabled 2FA yet. These users will be required to set up 2FA during next account login.
                                    </div>
                                </div>

                                <div class="text-right" *ngIf="canEditWorkspace">
                                    <button (click)="onUpdateWorkspace()" [disabled]="editWorkspaceForm.invalid" type="button" class="btn bg-primary-400 ml-auto">Save Changes</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header">
                            <h1 class="font-weight-thin mb-0">Billing information</h1>
                            <div class="text-muted text-sm font-weight-thin">Enter the address and contact information you would like to appear on your invoice</div>
                        </div>

                        <div class="card-body bp-2">
                            <form [formGroup]="billingForm">
                                <div class="form-group">
                                    <div class="font-size-xs text-uppercase text-muted mb-1">Company name</div>
                                    <input type="email" formControlName="billingCompanyName" class="form-control">
                                </div>

                                <div class="form-group">
                                    <div class="font-size-xs text-uppercase text-muted mb-1">Billing contact name <span class="text-danger">*</span></div>
                                    <input type="email" formControlName="billingContactName" class="form-control">
                                </div>

                                <div class="form-group">
                                    <div class="font-size-xs text-uppercase text-muted mb-1">Address line 1 <span class="text-danger">*</span></div>
                                    <input type="text" formControlName="addressLine1" class="form-control">
                                </div>

                                <div class="form-group">
                                    <div class="font-size-xs text-uppercase text-muted mb-1">Address line 2</div>
                                    <input type="text" formControlName="addressLine2" class="form-control">
                                </div>

                                <div class="form-group">
                                    <div class="font-size-xs text-uppercase text-muted mb-1">City <span class="text-danger">*</span></div>
                                    <input type="text" formControlName="city" class="form-control">
                                </div>

                                <div class="form-group">
                                    <div class="font-size-xs text-uppercase text-muted mb-1">State or region</div>
                                    <input type="text" formControlName="stateRegion" class="form-control">
                                </div>

                                <div class="form-group">
                                    <div class="font-size-xs text-uppercase text-muted mb-1">Postal code (ZIP) <span class="text-danger">*</span></div>
                                    <input type="text" formControlName="postalCode" class="form-control">
                                </div>

                                <div class="form-group">
                                    <div class="font-size-xs text-uppercase text-muted mb-1">Country <span class="text-danger">*</span></div>
                                    <ul class="navbar-nav">
                                        <li class="nav-item dropdown" style="border: 1px solid #ddd; border-radius: .1875rem;">
                                            <div class="btn-group w-100">
                                                <button type="button" class="btn btn-link dropdown-toggle" style="color: black;" data-toggle="dropdown">
                                                    <span *ngIf="this.billingForm.controls.country.value">{{this.billingForm.controls.country.value}}</span>
                                                    <span *ngIf="!this.billingForm.controls.country.value">Select country</span>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <div *ngFor="let country of countries">
                                                        <a (click)="this.billingForm.controls.country.setValue(country)" class="dropdown-item">
                                                            {{country}}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div class="form-group">
                                    <div class="font-size-xs text-uppercase text-muted mb-1">Billing contact email <span class="text-danger">*</span></div>
                                    <input type="email" formControlName="billingContactEmail" class="form-control">
                                </div>

                                <div class="text-right" *ngIf="canEditWorkspace">
                                    <button (click)="updateWorkspaceBilling()" [disabled]="billingForm.invalid" type="button" class="btn bg-primary-400 ml-auto">Save Billing Information</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>

        </div>
        <!-- /content header -->

    </div>
    <!-- /main content -->

</div>
