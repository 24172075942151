<div id="newTeamsWebhookModal" class="modal fade" tabindex="-1" *ngIf="workspace">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Create a Microsoft Teams webhook for {{workspace.name}}</h4>
                <button #closeButton type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <h6 class="font-weight-semibold">1. Add "Incoming Webhook" app to Microsoft Teams</h6>
                <p>In order to initiate connection with Microsoft Teams, follow these steps:</p>
                <ul>
                    <li>Open Teams app</li>
                    <li>Navigate to "Apps"</li>
                    <li>Find "Incoming Webhook" app by Microsoft Corporation</li>
                    <li>Click on "Add to a team"</li>
                    <li>Select the channel you wish to connect</li>
                    <li>Click on "Set up a connector"</li>
                    <li>Provide a name</li>
                    <li>Click on "Create"</li>
                    <li>Copy the generated Webhook URL and fill it in below</li>
                </ul>

                <h6 class="font-weight-semibold">2. Create Integration</h6>
                <form [formGroup]="form">
                    <div class="form-group">
                        <label>Integration name:</label>
                        <input type="text" formControlName="integrationName" class="form-control">
                        <span class="form-text text-muted">This name doesn't have to match the name you used in Microsoft Teams when creating a connection.</span>
                    </div>
                    <div class="form-group">
                        <label>Webhook URL:</label>
                        <input type="text" formControlName="teamsWebhookUrl" class="form-control">
                        <span class="form-text text-muted">Paste the URL you obtained from Microsoft Teams in step 1</span>
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <button (click)="submitForm()" [disabled]="form.invalid" type="button" class="btn bg-primary-400">Create</button>
            </div>
        </div>
    </div>
</div>
