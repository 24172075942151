import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'app/app.config';

export class TwoFactorAuthenticationStatusResponse {
    enabled: boolean
    account: string
    secret: string
    authUrl: string
}

@Injectable({ providedIn: 'root' })
export class TwoFactorAuthenticationService {
    constructor(private http: HttpClient) { }

    getStatus(): Promise<TwoFactorAuthenticationStatusResponse> {
        return this.http.get<TwoFactorAuthenticationStatusResponse>(`${AppConfig.apiBaseUrl}/profile/twoFactorAuthentication`).toPromise()
    }

    enable2FA(token: string): Promise<Object> {
        let requestBody = {
            token: token
        }

        return this.http.patch(`${AppConfig.apiBaseUrl}/profile/twoFactorAuthentication/enable`, requestBody).toPromise()
    }

    disable2FA(password: string, token: string): Promise<Object> {
        let requestBody = {
            password: password,
            token: token
        }

        return this.http.patch(`${AppConfig.apiBaseUrl}/profile/twoFactorAuthentication/disable`, requestBody).toPromise()
    }

}
