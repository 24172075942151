import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Workspace, Role, Application, PostalAddress, BackendEnvironment } from 'app/_models';
import { AppConfig } from 'app/app.config';
import { CollectionResponse } from './response';

export class WorkspaceMember {
    id: string
    name: string
    email: string
    role: Role
    mfaEnabled: boolean | null | undefined
    imageUrl: URL
}

export class WorkspaceDashboardResponse {
    applications: Application[]
    environments: BackendEnvironment[]
}

@Injectable({ providedIn: 'root' })
export class WorkspaceService {

    constructor(
        private http: HttpClient,
    ) {
    }

    getWorkspaceWithSlug(workspaceSlug: string): Promise<Workspace> {
        return this.http.get<Workspace>(`${AppConfig.apiBaseUrl}/workspaces?slug=${workspaceSlug}`).toPromise()
    }

    getCurrentWorkspace(): Promise<Workspace> {
        return this.http.get<Workspace>(`${AppConfig.apiBaseUrl}/workspaces/current`).toPromise()
    }

    getWorkspaceMembers(workspace: Workspace): Promise<CollectionResponse<WorkspaceMember>> {
        return this.http.get<CollectionResponse<WorkspaceMember>>(`${AppConfig.apiBaseUrl}/workspaces/${workspace.id}/users`).toPromise()
    }

    removeWorkspaceMember(member: WorkspaceMember, workspace: Workspace): Promise<Object> {
        return this.http.delete(`${AppConfig.apiBaseUrl}/workspaces/${workspace.id}/users/${member.id}`).toPromise()
    }

    getWorkspaceDashboard(workspaceId: number): Promise<WorkspaceDashboardResponse> {
        return this.http.get<WorkspaceDashboardResponse>(`${AppConfig.apiBaseUrl}/workspaces/${workspaceId}/dashboard`).toPromise()
    }

    updateWorkspace(workspaceId: number, workspaceName: string, twoFactorAuthenticationEnforcedForDashboardAccess: boolean): Promise<Workspace> {
        let requestBody = {
            workspaceName: workspaceName,
            twoFactorAuthenticationEnforcedForDashboardAccess: twoFactorAuthenticationEnforcedForDashboardAccess
        }

        return this.http.put<Workspace>(`${AppConfig.apiBaseUrl}/workspaces/${workspaceId}`, requestBody).toPromise()
    }

    updateWorkspaceBilling(workspaceId: number, billingAddress: PostalAddress, billingCompanyName: string | null, billingContactName: string, billingContactEmail: string): Promise<Workspace> {
        let requestBody = {
            billingCompanyName: billingCompanyName,
            billingContactName: billingContactName,
            billingAddress: billingAddress,
            billingContactEmail: billingContactEmail
        }

        return this.http.put<Workspace>(`${AppConfig.apiBaseUrl}/workspaces/${workspaceId}/billing`, requestBody).toPromise()
    }

}
