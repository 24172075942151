import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertService, ApplicationDistributionsService, AuthenticationService } from 'app/_services'
import { ActivatedRoute } from '@angular/router';
import { ApplicationDistribution, BuildDistribution, Workspace } from 'app/_models';
import { Subscription } from 'rxjs';
import { ApplicationFormatter, DateFormatter, PlatformFormatter } from 'app/_helpers';
import { DomSanitizer, SafeUrl, Title } from '@angular/platform-browser';

@Component({
    templateUrl: 'build.install.component.html',
})
export class BuildInstallComponent implements OnInit, OnDestroy {

    workspace: Workspace | null
    applicationDistribution: ApplicationDistribution
    build: BuildDistribution

    buildInstallUrl: SafeUrl
    buildDistributionUrl: string

    private routeParamsSubscription: Subscription

    constructor(
        public platformFormatter: PlatformFormatter,
        public dateFormatter: DateFormatter,
        private applicationDistributionsService: ApplicationDistributionsService,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private sanitizer: DomSanitizer,
        private applicationFormatter: ApplicationFormatter,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.routeParamsSubscription = this.route.params.subscribe(params => {
            this.workspace = this.authenticationService.currentWorkspaceValue
            this.reloadApplicationDistribution()
        })
    }

    ngOnDestroy() {
        this.routeParamsSubscription.unsubscribe()
    }

    private reloadApplicationDistribution() {
        let applicationSlug = this.route.snapshot.params['applicationSlug']
        let workspaceSlug = this.route.snapshot.params['workspaceSlug']

        this.applicationDistributionsService.resolveApplicationDistribution(workspaceSlug, applicationSlug).then((response) => {
            this.applicationDistribution = response.data
            this.reloadBuild()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private reloadBuild() {
        if (!this.applicationDistribution) {
            return
        }

        let buildSlug = this.route.snapshot.params['buildSlug']

        this.applicationDistributionsService.resolveApplicationDistributionBuild(this.applicationDistribution.id, buildSlug).then((response) => {
            this.build = response.data
            this.buildInstallUrl = this.sanitizer.bypassSecurityTrustUrl(this.build.installUrl.toString())
            this.buildDistributionUrl = window.location.href

            this.titleService.setTitle(`${this.applicationFormatter.displayName(this.applicationDistribution)} | Distribution`)

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
