export class AppConfig {

    public static apiBaseUrl = AppConfig.deriveApiBaseUrlFromWindowLocation()
    public static dashboardBaseUrl = AppConfig.windowLocation()
    public static webBaseUrl = AppConfig.deriveWebBaseUrlFromWindowLocation()
    public static developerBaseUrl = AppConfig.deriveDeveloperBaseUrlFromWindowLocation()
    public static documentationBaseUrl = AppConfig.developerBaseUrl + "/documentation"
    public static googleAnalyticsKey = AppConfig.getGoogleAnalyticsKey()
    public static isDebug: boolean = AppConfig.getIsLocalhost()

    static makeApplicationDistributionUrl(applicationSlug: string, workspaceSlug: string, buildSerialNumber: number | null): string {
        // this includes the initial `/` item, that we want to remove before constructing absolute url
        let pathComponents = this.makeApplicationDistributionUrlComponents(applicationSlug, workspaceSlug, buildSerialNumber)
        pathComponents.shift()

        let path = pathComponents.join('/')
        return `${AppConfig.dashboardBaseUrl}/${path}`
    }

    static makeApplicationDistributionUrlComponents(applicationSlug: string, workspaceSlug: string, buildSerialNumber: number | null): string[] {
        var components = ['/', workspaceSlug, 'app-distributions', applicationSlug]
        if (buildSerialNumber !== null && buildSerialNumber !== undefined) {
            components.push('builds')
            components.push(buildSerialNumber.toString())
        }
        return components
    }

    private static windowLocation(): string {
        // If we are running locally
        if (this.getIsLocalhost()) {
            return "http://localhost:8080"
        }

        return `${window.location.protocol}//${window.location.host}`
    }

    private static deriveApiBaseUrlFromWindowLocation(): string {
        // If we are running locally
        if (this.getIsLocalhost()) {
            return "http://localhost:9000/v1"
        }

        // Else, derive the API base url from the host name
        let host = window.location.host.replace("dashboard", "api")
        return `${window.location.protocol}//${host}/v1`
    }

    private static deriveWebBaseUrlFromWindowLocation(): string {
        // If we are running locally
        if (this.getIsLocalhost()) {
            return "http://localhost:4200"
        }

        // Else, derive the API base url from the host name
        let host = window.location.host.replace("dashboard", "www")
        return `${window.location.protocol}//${host}`
    }

    private static deriveDeveloperBaseUrlFromWindowLocation(): string {
        // If we are running locally
        if (this.getIsLocalhost()) {
            return "http://localhost:8000"
        }

        // Else, derive the API base url from the host name
        let host = window.location.host.replace("dashboard", "developer")
        return `${window.location.protocol}//${host}`
    }

    private static getGoogleAnalyticsKey(): string | null {
        let host = window.location.host
        if (this.getIsLocalhost()) {
            return null
        }

        if (host.includes("staging")) {
            return "G-FGE3RGVRHV"
        } else {
            return "G-JDMFFCJS1M"
        }
    }

    private static getIsLocalhost(): boolean {
        return window.location.host.includes("localhost")
    }

 }
