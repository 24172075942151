import { LogSource } from 'app/_models/console.logs.response';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'log-source',
    templateUrl: 'log.source.component.html'
})
export class LogSourceComponent {

    @Input() source: LogSource

}
