<div id="timeline" class="video-timeline noselect handcursor" (mousedown)="onImageMouseDown($event)">
    <div id="scrubber" class="scrubber" *ngIf="frameNumbers"></div>

    <div class="d-flex" style="transform: translateX(-1px);" *ngIf="frameNumbers">
        <video-frame #videoFrame [@fadeInOutAnimation]="{value: triggerValue, params: {delay: index * 0.011} }" [frameWidth]="frameWidth" [frameHeight]="frameHeight" [imageSrc]="timelineImageSrc" *ngFor="let index of frameNumbers"></video-frame>
    </div>

    <div class="justify-content-center text-muted d-flex w-100 align-items-center" *ngIf="frameNumbers == null">
        <div class="align-self-center">
            <i class="icon-spinner4 spinner mr-2"></i> Loading video timeline...
        </div>
    </div>
</div>
