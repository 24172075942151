import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Application, Platform, TestPlan } from 'app/_models';
import { AppConfig } from 'app/app.config';
import { CollectionResponse, ElementResponse } from './response';
import { BehaviorSubject, Observable } from 'rxjs';

export class ApplicationFilterInfos {
    testPlans: TestPlan[]
}

@Injectable({ providedIn: 'root' })
export class ApplicationService {

    private currentApplicationSubject: BehaviorSubject<Application>
    public currentApplication: Observable<Application>

    setCurrentApplication(application: Application | null) {
        // Update the value only if we don't have any yet, or if the ID is different
        if (this.currentApplicationSubject.value == null || application == null || this.currentApplicationSubject.value.id != application.id) {
            this.currentApplicationSubject.next(application)
        }
    }

    public get currentApplicationValue(): Application {
        return this.currentApplicationSubject.value
    }

    constructor(
        private http: HttpClient,
    ) {
        this.currentApplicationSubject = new BehaviorSubject<Application>(null)
        this.currentApplication = this.currentApplicationSubject.asObservable()
    }

    createApplication(name: string, platform: Platform): Promise<Object> {
        let requestBody = {
            name: name,
            platform: platform
        }

        return this.http.post(`${AppConfig.apiBaseUrl}/applications`, requestBody).toPromise()
    }

    getWorkspaceApplications(): Promise<CollectionResponse<Application>> {
        return this.http.get<CollectionResponse<Application>>(`${AppConfig.apiBaseUrl}/applications`).toPromise()
    }

    getWorkspaceApplicationsWithSlug(applicationSlug: string): Promise<ElementResponse<Application>> {
        return this.http.get<ElementResponse<Application>>(`${AppConfig.apiBaseUrl}/applications?slug=${applicationSlug}`).toPromise()
    }

    deleteApplication(application: Application): Promise<Object> {
        return this.http.delete(`${AppConfig.apiBaseUrl}/applications/${application.id}`).toPromise()
    }

    updateApplication(
        application: Application,
        name: string,
        applicationIcon: File | null,
        testsSuccessThreshold: number,
        testPlansAffectingStatus: string[],
        targetLaunchDuration: number
    ): Promise<ElementResponse<Application>> {
        const formData = new FormData()
        formData.append('name', name)
        formData.append('testsSuccessThreshold', testsSuccessThreshold.toFixed(2))
        formData.append('testPlansAffectingStatus', testPlansAffectingStatus.join(","))
        formData.append('targetLaunchDuration', targetLaunchDuration.toFixed(3))

        if (applicationIcon != null) {
            formData.append('icon', applicationIcon)
        }

        return this.http.put<ElementResponse<Application>>(`${AppConfig.apiBaseUrl}/applications/${application.id}`, formData).toPromise()
    }

    getFilterInfos(): Promise<ApplicationFilterInfos> {
        return this.http.get<ApplicationFilterInfos>(`${AppConfig.apiBaseUrl}/applications/filterInfos`).toPromise()
    }

}
