import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TestFailureGroup, TimeFilter, TestRunInfo3, TestCase, IssueState, WidgetView, ImageFile, BackendEnvironment } from 'app/_models';
import { AppConfig } from 'app/app.config';
import { CollectionResponse, ElementResponse } from './response';

export class TestFailureGroupFiltersResponse {
    environments: [BackendEnvironment]
}

export class TestCaseInfo {
    testCase: TestCase
    failuresCount: number
}

export class TestFailureGroupDashboardResponse {
    widgetView: WidgetView
    historyNumberOfFailure: number
    testRunScreenshots: ImageFile[]
    affectedTestCases: TestCaseInfo[]
}

@Injectable({ providedIn: 'root' })
export class TestFailureGroupsService {

    constructor(private http: HttpClient) {
    }

    getApplicationFailureGroups(applicationId: number, from: Date | null): Promise<CollectionResponse<TestFailureGroup>> {
        var url = `${AppConfig.apiBaseUrl}/applications/${applicationId}/testFailureGroups`
        if (from !== null) {
            url += `?from=${from.toISOString()}`
        } else {
            url += `?limit=20`
        }

        return this.http.get<CollectionResponse<TestFailureGroup>>(url).toPromise()
    }

    getApplicationFailureGroupsPageBefore(applicationId: number, pageBefore: Date): Promise<CollectionResponse<TestFailureGroup>> {
        var url = `${AppConfig.apiBaseUrl}/applications/${applicationId}/testFailureGroups`
        if (pageBefore !== null) {
            url += `?pageBefore=${pageBefore.toISOString()}`
        }

        return this.http.get<CollectionResponse<TestFailureGroup>>(url).toPromise()
    }

    getApplicationFailureGroupsWithSlug(applicationId: number, slug: string): Promise<CollectionResponse<TestFailureGroup>> {
        return this.http.get<CollectionResponse<TestFailureGroup>>(`${AppConfig.apiBaseUrl}/applications/${applicationId}/testFailureGroups?slug=${slug}`).toPromise()
    }

    getTestFailureGroupsTestRuns(testFailureGroupId: number, backendEnvironmentId: number | null, history: TimeFilter): Promise<CollectionResponse<TestRunInfo3>> {
        var url = `${AppConfig.apiBaseUrl}/testFailureGroups/${testFailureGroupId}/testRuns?history=${history}`

        if (backendEnvironmentId) { url += `&backendEnvironmentId=${backendEnvironmentId}` }

        return this.http.get<CollectionResponse<TestRunInfo3>>(url).toPromise()
    }

    getTestFailureGroupById(testFailureGroupId: number): Promise<ElementResponse<TestFailureGroup>> {
        return this.http.get<ElementResponse<TestFailureGroup>>(`${AppConfig.apiBaseUrl}/testFailureGroups/${testFailureGroupId}`).toPromise()
    }

    getTestFailureGroupDashboard(testFailureGroupId: number, backendEnvironmentId: number | null, history: TimeFilter): Promise<TestFailureGroupDashboardResponse> {
        var url = `${AppConfig.apiBaseUrl}/testFailureGroups/${testFailureGroupId}/dashboard?history=${history}`

        if (backendEnvironmentId) { url += `&backendEnvironmentId=${backendEnvironmentId}` }

        return this.http.get<TestFailureGroupDashboardResponse>(url).toPromise()
    }

    getTestFailureGroupWidget(testFailureGroupId: number): Promise<WidgetView> {
        var url = `${AppConfig.apiBaseUrl}/testFailureGroups/${testFailureGroupId}/widget`
        return this.http.get<WidgetView>(url).toPromise()
    }

    getTestFailureGroupFilters(testFailureGroupId: number, history: TimeFilter): Promise<TestFailureGroupFiltersResponse> {
        return this.http.get<TestFailureGroupFiltersResponse>(`${AppConfig.apiBaseUrl}/testFailureGroups/${testFailureGroupId}/filters?history=${history}`).toPromise()
    }

    updateTestFailureGroup(testFailureGroupId: number, annotation: string | null, state: IssueState | null): Promise<ElementResponse<TestFailureGroup>> {
        let body = {
            annotation: annotation,
            state: state
        }

        return this.http.put<ElementResponse<TestFailureGroup>>(`${AppConfig.apiBaseUrl}/testFailureGroups/${testFailureGroupId}`, body).toPromise()
    }

}
