import { NetworkTrendsSummaryResponse } from 'app/_models';
import { Component, Input, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'appdex-summary',
    templateUrl: 'appdex.summary.component.html'
})
export class AppdexSummaryComponent {

    satisfyingPercentage: string
    tolerablePercentage: string
    frustratingPercentage: string

    _networkTrendsSummaryResponse: NetworkTrendsSummaryResponse
    @Input()
    set networkTrendsSummaryResponse(networkTrendsSummaryResponse: NetworkTrendsSummaryResponse) {
        if (this._networkTrendsSummaryResponse != networkTrendsSummaryResponse) {
            this._networkTrendsSummaryResponse = networkTrendsSummaryResponse
            this.reload()
        }
    }
    get networkTrendsSummaryResponse(): NetworkTrendsSummaryResponse { return this._networkTrendsSummaryResponse }

    constructor(
        private changeDetector: ChangeDetectorRef
    ) {
    }

    private reload() {
        let summary = this.networkTrendsSummaryResponse as any
        if (summary == null) { return }

        this.satisfyingPercentage = summary.satisfyingRate * 100 + "%"
        this.tolerablePercentage = summary.tolerableRate * 100 + "%"
        this.frustratingPercentage = summary.frustratingRate * 100 + "%"

        this.changeDetector.detectChanges()
    }

}
