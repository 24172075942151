import { Injectable } from "@angular/core";
import { InvoiceState } from "app/_models";

@Injectable()
export class InvoiceStateFormatter {

    stateName(invoiceState: InvoiceState): string {
        switch (invoiceState) {
            case InvoiceState.Issued: return "Issued"
            case InvoiceState.PartiallyPaid: return "Partial"
            case InvoiceState.FullyPaid: return "Paid"
            case InvoiceState.Overdue: return "Overdue"
            default:
                console.warn("Unknown state: " + invoiceState);
                return "Unknown"
        }
    }

    stateClass(invoiceState: InvoiceState): string {
        switch (invoiceState) {
            case InvoiceState.Issued: return "badge badge-light"
            case InvoiceState.PartiallyPaid: return "badge badge-warning"
            case InvoiceState.FullyPaid: return "badge badge-light"
            case InvoiceState.Overdue: return "badge bg-pink-400"
            default:
                console.warn("Unknown state: " + invoiceState);
                return "badge badge-light"
        }
    }

}
