import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserLoginResponse, Workspace } from 'app/_models';
import { AppConfig } from 'app/app.config';
import { SlugResolverService } from './slug.resolver.service/slug.resolver.service';
import { LocalPreferencesService } from './local.preferences.service';
import { InvitationsService } from './invitations.service';
import { WorkspaceService } from './workspace.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<UserLoginResponse>
    public userLoginResponse: Observable<UserLoginResponse>

    private currentWorkspaceSubject: BehaviorSubject<Workspace>
    public currentWorkspace: Observable<Workspace>

    constructor(
        private http: HttpClient,
        private localPreferencesService: LocalPreferencesService,
        private slugResolverService: SlugResolverService,
        private invitationsService: InvitationsService,
        private workspaceService: WorkspaceService
        ) {
            this.currentUserSubject = new BehaviorSubject<UserLoginResponse>(JSON.parse(localStorage.getItem('currentUser')))
            this.userLoginResponse = this.currentUserSubject.asObservable()
            this.currentWorkspaceSubject = new BehaviorSubject<Workspace>(JSON.parse(localStorage.getItem('currentWorkspace')))
            this.currentWorkspace = this.currentWorkspaceSubject.asObservable()
    }

    public get currentUserValue(): UserLoginResponse {
        return this.currentUserSubject.value
    }

    public isUserLoggedIn(): boolean {
        return this.currentWorkspaceValue != null
    }

    public get currentWorkspaceValue(): Workspace {
        return this.currentWorkspaceSubject.value
    }

    public setUserAuthenticated(response: UserLoginResponse) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(response))
        this.currentUserSubject.next(response)
        this.currentWorkspaceUpdated(response.workspace)
    }

    public currentWorkspaceUpdated(workspace: Workspace) {
        localStorage.setItem('currentWorkspace', JSON.stringify(workspace))
        this.currentWorkspaceSubject.next(workspace)
    }

    public refreshCurrentWorkspace(): Promise<Workspace> {
        return new Promise<Workspace>((resolve, reject) => {
            this.workspaceService.getCurrentWorkspace().then((response) => {
                this.currentWorkspaceUpdated(response)
                resolve(response)

            }).catch((error) => {
                reject(error)
            })
        })
    }

    login(username: string, password: string, oneTimePassword: string | null, twoFactorAuthenticationToken: string | null): Promise<UserLoginResponse> {
        let headers = new HttpHeaders()
        headers = headers.append("Authorization", "Basic " + btoa(`${username}:${password}`))

        let requestBody = {
            oneTimePassword: oneTimePassword,
            twoFactorAuthenticationToken: twoFactorAuthenticationToken
        }

        return this.http.post<UserLoginResponse>(`${AppConfig.apiBaseUrl}/authenticate`, requestBody, {headers: headers}).toPromise()
    }

    logout() {
        this.http.post(`${AppConfig.apiBaseUrl}/logout`, null).toPromise().then((response) => {
        }).catch((error) => {
        })

        // remove user from local storage to log user out
        localStorage.removeItem('currentUser')
        localStorage.removeItem('currentWorkspace')
        this.currentUserSubject.next(null)
        this.currentWorkspaceSubject.next(null)
        this.invitationsService.clearLocalStorage()

        this.localPreferencesService.logout()
        this.slugResolverService.clearCache()
    }
}
