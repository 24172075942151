import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Chart, ChartDataset, Color } from 'chart.js';
import { UUIDUtils } from 'app/_helpers';
import * as Chroma from 'chroma-js';

// https://cssgradient.io
// https://gka.github.io/chroma.js/
const histogramColorScale = Chroma.scale([
    '#03c764',
    '#03c764',
    '#FFA726',
    '#FF7043',
    '#E53935'
]);

@Component({
    selector: 'histogram',
    templateUrl: 'histogram.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistogramComponent implements AfterViewChecked {

    private _values: number[]
    @Input()
    set values(values: number[]) {
        if (values && this._values !== values) {
            this._values = values
            if (this.chart) {
                this.reloadChart()
            }
        }
    }
    get values(): number[] { return this._values }

    private chart: Chart
    chartUuid: string = UUIDUtils.uuidv4()

    constructor(
        private changeDetector: ChangeDetectorRef
    ) {
    }

    // Charts can only be created once the their canvases are rendered, and canvases
    // will only be shown now after all the data is received
    ngAfterViewChecked() {
        if (!this.values || this.chart) { return }
        if ($(`#${this.chartUuid}`).length > 0) {
            this.reloadChart()
        }
    }

    private reloadChart() {
        if (this.chart) {
            this.chart.destroy()
        }

        this.chart = new Chart(this.chartUuid, {
            type: 'bar',
            data: {
                // Using series as labels as an optimization, labels are anyways never shown
                labels: this.values,
                datasets: this.makeChartDatasets()
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                animation: false,
                scales: {
                    x: {
                        display: false
                    },
                    y: {
                        display: false,
                        max: Math.max(...this.values),
                    }
                }
            }
        })
        this.changeDetector.detectChanges()
    }

    private makeChartDatasets(): ChartDataset[] {
        var backgroundColors: Color[] = []
        let scaleLength = this.values.length
        for (let i = 0; i < scaleLength; i++) {
            backgroundColors.push(histogramColorScale(i / scaleLength).hex())
        }

        let chartDatasets: ChartDataset[] = [
            {
                data: this.values,
                backgroundColor: backgroundColors,
                fill: true,
                minBarLength: 1,
            }
        ]

        return chartDatasets
    }

}
