import { Injectable } from "@angular/core";
import { IssueState } from "app/_models";

@Injectable()
export class IssueStateFormatter {

    displayName(state: IssueState): string {
        switch (state) {
            case IssueState.Closed: return "Closed"
            case IssueState.Open: return "Open"
            case IssueState.Ignored: return "Ignored"
            default:
                console.warn("Unknown IssueState: " + state);
                return "Unknown"
        }
    }

}
