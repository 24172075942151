<div class="content-wrapper" #pageContent>

    <div class="content">
        <div class="row">
            <div class="col-md-12">
                <alert></alert>
            </div>
        </div>

        <div class="row" *ngIf="application && appLaunchFiltersResponse">
            <div class="col-md-12">
                <div class="card">
                    <div class="page-header border-bottom-light">
                        <div class="page-header-content header-elements-md-inline">
                            <div class="page-title">
                                <h1 class="font-weight-thin">
                                    <a [routerLink]="['../']" class="breadcrumb-item">{{application.name}} {{platformFormatter.platformName(application.platform)}}</a>
                                    <span class="breadcrumb-item active">App Launch</span>
                                </h1>
                            </div>
                        </div>
                    </div>

                    <div class="card-body d-flex align-items-center align-items-baseline">
                        <div class="d-flex align-items-baseline">
                            <h5 class="mb-0 pb-0 mr-2">Filters</h5>

                            <div class="form-group mb-0 d-flex mr-2" *ngIf="appLaunchFiltersResponse.applicationVersions.length > 0">
                                <select id="applicationVersionSelect" select2 data-placeholder="App version" data-allow-clear="true" class="form-control select2">
                                    <option *ngFor="let applicationVersion of appLaunchFiltersResponse.applicationVersions">{{applicationVersion.bundleShortVersion}}</option>
                                </select>
                            </div>

                            <div class="form-group mb-0 d-flex mr-2" *ngIf="appLaunchFiltersResponse.osVersions.length > 0">
                                <select id="osVersionSelect" select2 data-placeholder="OS version" data-allow-clear="true" class="form-control select2">
                                    <option *ngFor="let osVersion of appLaunchFiltersResponse.osVersions">{{osVersion.version}}</option>
                                </select>
                            </div>

                            <div class="form-group mb-0 d-flex mr-2" *ngIf="appLaunchFiltersResponse.deviceModels.length > 0">
                                <select id="deviceSelect" select2 data-placeholder="Device" data-allow-clear="true" class="form-control select2">
                                    <option *ngFor="let deviceModel of appLaunchFiltersResponse.deviceModels">{{deviceModel.name}}</option>
                                </select>
                            </div>
                        </div>

                        <ul class="ml-auto pagination">
                            <li class="page-item active">
                                <date-range [dateRange]="this.selectedDateRange" (onDateRangeChange)="onDateRangeChanged($event)"></date-range>
                            </li>
                        </ul>
                    </div>

                    <div class="card-body mb-3">
                        <app-launch-trends (onAppLaunchTrendTypeChanged)="onAppLaunchTrendTypeChanged($event)" (onDateRangeChange)="onDateRangeChanged($event)" [appLaunchTrendType]="appLaunchTrendType" [appLaunchTrendsResponse]="appLaunchTrendsResponse"></app-launch-trends>
                    </div>

                    <div class="card-body d-flex align-items-baseline">
                        <h5 class="mb-0 pb-0">Dimensions</h5>
                        <ul class="nav nav-pills nav-pills-bordered ml-2 my-0">
                            <li class="nav-item ml-1">
                                <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : appLaunchDimension == AppLaunchDimension.ApplicationVersion }" (click)="setAppLaunchDimension(AppLaunchDimension.ApplicationVersion)">App versions</a>
                            </li>

                            <li class="nav-item ml-1">
                                <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : appLaunchDimension == AppLaunchDimension.OsVersion }" (click)="setAppLaunchDimension(AppLaunchDimension.OsVersion)" style="border-top-right-radius: 0; border-bottom-right-radius: 0;">OS version</a>
                            </li>

                            <li class="nav-item ml-0">
                                <a href="javascript:void(0)" class="nav-link border-left-0 px-2 py-1" [ngClass]="{ 'active' : appLaunchDimension == AppLaunchDimension.OsVersionMajor }" (click)="setAppLaunchDimension(AppLaunchDimension.OsVersionMajor)" style="border-radius: 0;">Major</a>
                            </li>

                            <li class="nav-item ml-0">
                                <a href="javascript:void(0)" class="nav-link border-left-0 px-2 py-1" [ngClass]="{ 'active' : appLaunchDimension == AppLaunchDimension.OsVersionMinor }" (click)="setAppLaunchDimension(AppLaunchDimension.OsVersionMinor)" style="border-top-left-radius: 0; border-bottom-left-radius: 0;">Minor</a>
                            </li>

                            <li class="nav-item ml-1">
                                <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : appLaunchDimension == AppLaunchDimension.Device }" (click)="setAppLaunchDimension(AppLaunchDimension.Device)">Device</a>
                            </li>
                        </ul>
                    </div>

                    <app-launch-segments [appLaunchDimension]="appLaunchDimension" [appLaunchSegments]="appLaunchSegments"></app-launch-segments>
                </div>
            </div>
        </div>
    </div>

</div>
