import { ConsoleLogLevel } from "app/_models/console.logs.response";
import { Injectable } from "@angular/core";

@Injectable()
export class ConsoleLogLevelFormatter {

    symbol(logLevel: ConsoleLogLevel): string {
        switch (logLevel) {
            case ConsoleLogLevel.Verbose: return "V"
            case ConsoleLogLevel.Debug: return "D"
            case ConsoleLogLevel.Info: return "I"
            case ConsoleLogLevel.Warning: return "W"
            case ConsoleLogLevel.Error: return "E"
            case ConsoleLogLevel.Assert: return "A"
            default:
                console.warn("Unknown log level: " + logLevel);
                return `${logLevel}`
        }
    }

    displayName(logLevel: ConsoleLogLevel): string {
        switch (logLevel) {
            case ConsoleLogLevel.Verbose: return "Verbose (All)"
            case ConsoleLogLevel.Debug: return "Debug"
            case ConsoleLogLevel.Info: return "Info"
            case ConsoleLogLevel.Warning: return "Warning"
            case ConsoleLogLevel.Error: return "Error"
            case ConsoleLogLevel.Assert: return "Assert"
            default:
                console.warn("Unknown log level: " + logLevel);
                return `${logLevel}`
        }
    }

    logLevelBadgeStyle(logLevel: ConsoleLogLevel): string {
        if (logLevel == ConsoleLogLevel.Error) return "badge-danger"
        else if (logLevel == ConsoleLogLevel.Warning) return "badge-warning"
        return "badge-light text-muted"
    }

}
