import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sortable',
    templateUrl: 'sortable.component.html'
})

export class SortableComponent implements OnInit, OnDestroy {

    @Input() name: string
    @Input() default: string
    @Input() ascValue: string
    @Input() descValue: string

    public sortClass: string

    private queryParamsSubscription: Subscription

    constructor(
        private router: Router,
        private route: ActivatedRoute,
    ) {
    }

    ngOnInit() {
        this.updateSortClass(this.route.snapshot.queryParams)

        this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
            this.updateSortClass(params)
        })
    }

    ngOnDestroy() {
        this.queryParamsSubscription.unsubscribe()
    }

    onClick() {
        const newOrderByValue = this.sortClass == 'sortdesc' ? this.ascValue : this.descValue
        this.router.navigate([], { queryParams: { orderBy: newOrderByValue }, queryParamsHandling: 'merge' })
    }

    private updateSortClass(queryParams: Params) {
        if (!this.ascValue || !this.descValue) {
            this.sortClass = 'nosort'
            return
        }

        const orderByValue = queryParams['orderBy']

        // If there is no orderBy value in the query, check if one of our asc/desc values should be preselected
        if ((orderByValue === null || orderByValue === undefined) && this.default) {
            if (this.default == this.ascValue) { this.sortClass = 'sortasc' }
            else if (this.default == this.descValue) { this.sortClass = 'sortdesc' }
            return
        }

        if (orderByValue == this.ascValue) { this.sortClass = 'sortasc' }
        else if (orderByValue == this.descValue) { this.sortClass = 'sortdesc' }
        else { this.sortClass = 'nosort' }
    }

}
