import { NetworkInterface } from "./network.interface"
import { NetworkRequestSource } from "./network.request.source"

export class NetworkFilter {
    startDate: Date
    endDate: Date

    source: NetworkRequestSource | null
    applicationId: number | null
    testExecutionId: number | null
    applicationVersionId: number | null
    backendEnvironmentId: number | null
    backendEnvironmentVersionId: number | null
    networkInterfaceId: NetworkInterface | null
    networkOperationId: number | null
    networkErrorId: number | null
    networkProtocolId: number | null
    networkHostId: number | null
    responseStatusCode: number | null
}
