import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppLaunchDimension, AppLaunchFilter, AppLaunchTrendsResponse, AppLaunchTrendType, ApplicationVersion, DeviceModel, OsVersion, Platform } from 'app/_models';
import { AppConfig } from 'app/app.config';

export class AppLaunchFiltersResponse {
    applicationVersions: ApplicationVersion[]
    osVersions: OsVersion[]
    deviceModels: DeviceModel[]
}

export class OsLaunchRates {
    platform: Platform
    version: string
    versionRecencyRank: number
    rates: number[]
}

export class AppVersionLaunchRates {
    version: string
    versionRecencyRank: number
    rates: number[]
}

@Injectable({ providedIn: 'root' })
export class AppLaunchMetricsService {

    constructor(private http: HttpClient) {
    }

    getAppLaunchFilters(applicationId: number): Promise<AppLaunchFiltersResponse> {
        return this.http.get<AppLaunchFiltersResponse>(`${AppConfig.apiBaseUrl}/applications/${applicationId}/applicationLaunches/filters`).toPromise()
    }

    getAppLaunchTrends(applicationId: number, type: AppLaunchTrendType, filter: AppLaunchFilter): Promise<AppLaunchTrendsResponse> {
        var url = `${AppConfig.apiBaseUrl}/applications/${applicationId}/applicationLaunches/trends?type=${type}`
        url = this.urlByApplyingAppLaunchFilter(url, filter)

        return this.http.get<AppLaunchTrendsResponse>(url).toPromise()
    }

    getAppLaunchInfoByDimension(applicationId: number, filter: AppLaunchFilter, dimension: AppLaunchDimension | null): Promise<Object> {
        let url = `${AppConfig.apiBaseUrl}/applications/${applicationId}/applicationLaunches/byDimension?dimension=${dimension}`
        url = this.urlByApplyingAppLaunchFilter(url, filter)

        return this.http.get<Object>(url).toPromise()
    }

    private urlByApplyingAppLaunchFilter(baseUrl: string, filter: AppLaunchFilter): string {
        var url = baseUrl

        url += `&startDate=${filter.startDate.toISOString()}`
        url += `&endDate=${filter.endDate.toISOString()}`

        if (filter.applicationId != null) { url += `&applicationId=${filter.applicationId}` }
        if (filter.applicationVersionId != null) { url += `&applicationVersionId=${filter.applicationVersionId}` }
        if (filter.deviceId != null) { url += `&deviceId=${filter.deviceId}` }
        if (filter.osVersionId != null) { url += `&osVersionId=${filter.osVersionId}` }

        return url
    }

}
