<div class="content">

    <div class="row">
        <div class="col-md-12">
            <alert></alert>
        </div>
    </div>

    <div class="card" *ngIf="errorReport">
        <div class="page-header border-bottom-light">
            <div class="page-header-content header-elements-md-inline">
                <div class="page-title">
                    <h1 class="font-weight-thin">
                        <a [routerLink]="['../../../../']" class="breadcrumb-item">{{application.name}}</a>
                        <a [routerLink]="['../../../']" class="breadcrumb-item">Error Groups</a>
                        <a [routerLink]="['../../']" class="breadcrumb-item">#{{errorGroup.serialNumber}}</a>
                        <span class="breadcrumb-item active">Report #{{errorReport.serialNumber}}</span>
                    </h1>

                    <div class="mb-0">
                        <span class="badge badge-light badge-pill mr-1">Reported: {{dateFormatter.timeAgoShort(errorReport.errorDate)}}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- ANNOTATION -->
        <div class="card-body">
            <h5 class="mb-0 pb-1">Notes</h5>

            <ng-container *ngIf="errorGroup.annotation">
                <markdown [content]="errorGroup.annotation"></markdown>
                <ul class="list-inline list-inline-dotted list-inline-condensed mt-1 mb-0">
                    <li class="list-inline-item"><a href="javascript:void(0)" (click)="onAnnotateButtonClick()" class="font-weight-semibold" data-toggle="modal" data-target="#editErrorGroupModal">Edit annotation</a></li>
                    <li class="list-inline-item"><span class="text-muted font-size-sm font-weight-light">Updated {{dateFormatter.timeAgo(errorGroup.annotationUpdateDate)}}</span></li>
                </ul>
            </ng-container>

            <ng-container *ngIf="errorGroup.annotation == null">
                <a href="javascript:void(0)" (click)="onAnnotateButtonClick()" class="font-weight-semibold" data-toggle="modal" data-target="#editErrorGroupModal">Annotate error group</a>
                <span class="text-muted"> with contextual information and links to an issue tracking system like JIRA or Trello.</span>
            </ng-container>
        </div>

        <!-- DETAILS -->
        <div class="card-body pb-2">
            <h5 class="mb-0 pb-1 mr-2">Details</h5>
            <div class="row">
                <value-item class="col-lg-6 col-xl-4" title="App version" value="{{errorReport.appVersion}}" icon="icon-git-branch"></value-item>
                <value-item class="col-lg-6 col-xl-4" title="App id" value="{{errorReport.bundleIdentifier}}" icon="icon-cube4"></value-item>
                <value-item class="col-lg-6 col-xl-4" title="OS" value="{{errorReport.system}}" icon="{{platformFormatter.platformIconName(errorGroup.platform)}}"></value-item>
                <value-item class="col-lg-6 col-xl-4" title="Device" value="{{errorReport.deviceModelName}}" icon="icon-mobile2"></value-item>
                <value-item class="col-lg-6 col-xl-4" title="Locale" value="{{errorReport.locale}}" icon="icon-earth"></value-item>
                <value-item class="col-lg-6 col-xl-4" title="User identifier" value="{{errorReport.userIdentifier}}" icon="icon-user"></value-item>
            </div>
        </div>

        <!-- ERROR MESSAGE -->
        <div class="card-body">
            <h5 class="mb-0 pb-1 mr-2">Error message</h5>
            <div class="mb-1"><samp>{{errorReport.errorMessage}}</samp></div>

            <call-stack [callStack]="callStack" *ngIf="callStack"></call-stack>
        </div>

        <!-- ATTACHMENT -->
        <div class="card-body" *ngIf="attachment">
            <h5 class="mb-0 pb-1 mr-2">Attachment</h5>
            <pre class="language-javascript w-100">{{attachment}}</pre>
        </div>
    </div>

</div>

<edit-error-group-modal #editErrorGroupModal (errorGroupUpdated)="onErrorGroupUpdated()"></edit-error-group-modal>
