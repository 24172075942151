import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AlertService, ErrorsService, ApplicationService } from 'app/_services'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Application, ErrorReport, ErrorGroup, ErrorFilter } from 'app/_models';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { ApplicationFormatter } from 'app/_helpers';
import * as moment from 'moment'

@Component({
    templateUrl: 'error.reports.component.html',
    styleUrls: [
        'error.reports.component.css'
    ]
})
export class ErrorReportsComponent implements OnInit, OnDestroy {

    application: Application

    private _errorGroup: ErrorGroup
    set errorGroup(errorGroup: ErrorGroup) {
        this._errorGroup = errorGroup
        this.errorsService.setCurrentErrorGroup(errorGroup)
    }
    get errorGroup() { return this._errorGroup }

    errorReports: ErrorReport[]
    selectedErrorReportSerialNumber: number | null

    @ViewChild('errorReportsList') errorReportsList: ElementRef

    private navigationChangeSubscription: Subscription
    private currentApplicationSubscription: Subscription

    constructor(
        private errorsService: ErrorsService,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private router: Router,
        private applicationService: ApplicationService,
        private applicationFormatter: ApplicationFormatter,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.navigationChangeSubscription = this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(_ => {
            this.updateSelection()
        })

        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
            if (this.application != null) {
                this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | Error Reports`)
                this.reloadErrorReports()
            }
        })
    }

    ngOnDestroy() {
        // Resets the currentErrorGroup in ErrorsService
        this.errorGroup = null
        this.navigationChangeSubscription.unsubscribe()
        this.currentApplicationSubscription.unsubscribe()
    }

    private updateSelection() {
        if (this.errorReports == null || this.route.firstChild == null) {
            this.selectedErrorReportSerialNumber = null
            return
        }

        this.selectedErrorReportSerialNumber = this.route.firstChild.snapshot.params['errorReportSlug']
    }

    private reloadErrorReports() {
        if (!this.application) { return }

        let errorGroupSlug = this.route.snapshot.params['errorGroupSlug']
        this.errorsService.getWorkspaceErrorGroupWithSlug(this.application.workspaceId, errorGroupSlug).then((response) => {
            this.errorGroup = response.data

            let filter = this.makeCurrentErrorFilter()
            this.errorsService.getErrorGroupErrorReports(this.errorGroup.id, filter).then((response) => {
                this.errorReports = response.data
                this.updateSelection()

            }).catch((error) => {
                this.alertService.handleError(error)

            })

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }


    private makeCurrentErrorFilter(): ErrorFilter {
        let query = this.route.snapshot.queryParams

        let errorFilter = new ErrorFilter()
        errorFilter.endDate = new Date()
        errorFilter.startDate = moment(errorFilter.endDate).subtract(1, 'year').toDate();
        errorFilter.applicationId = this.application.id
        errorFilter.errorGroupId = this.errorGroup.id

        return errorFilter
    }


}
