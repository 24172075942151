<div class="navbar navbar-expand navbar-light">

    <div class="navbar-header d-none align-items-center" style="display: flex !important;">
        <div class="navbar-brand">
            <a href="{{logoHref()}}" class="d-inline-block">
                <img src="assets/img/logo_dark.png" alt="">
            </a>
        </div>
    </div>

    <div class="navbar-collapse border-0 desktop-only">
        <ul class="navbar-nav ml-md-auto desktop-only">
            <li class="nav-item">
                <a [routerLink]="['/login']" class="navbar-nav-link">Login</a>
            </li>
        </ul>
    </div>

</div>
