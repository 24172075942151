import { AppLaunchTrendsResponse, AppLaunchTrendType, DateRange } from 'app/_models';
import { Component, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-launch-trends',
    templateUrl: 'app.launch.trends.component.html'
})
export class AppLaunchTrendsComponent {

    readonly AppLaunchTrendType = AppLaunchTrendType

    _appLaunchTrendType: AppLaunchTrendType
    @Input()
    set appLaunchTrendType(appLaunchTrendType: AppLaunchTrendType) {
        if (this._appLaunchTrendType != appLaunchTrendType) {
            this._appLaunchTrendType = appLaunchTrendType
            this.reload()
        }
    }
    get appLaunchTrendType() { return this._appLaunchTrendType }

    _appLaunchTrendsResponse: AppLaunchTrendsResponse
    @Input()
    set appLaunchTrendsResponse(appLaunchTrendsResponse: AppLaunchTrendsResponse) {
        if (this._appLaunchTrendsResponse != appLaunchTrendsResponse) {
            this._appLaunchTrendsResponse = appLaunchTrendsResponse
            this.reload()
        }
    }
    get appLaunchTrendsResponse() { return this._appLaunchTrendsResponse }

    @Output() onAppLaunchTrendTypeChanged = new EventEmitter<AppLaunchTrendType>()
    @Output() onDateRangeChange = new EventEmitter<DateRange>()

    constructor(
        private changeDetector: ChangeDetectorRef
    ) {
    }

    setAppLaunchTrendType(appLaunchTrendType: AppLaunchTrendType) {
        this.onAppLaunchTrendTypeChanged.emit(appLaunchTrendType)
        this.changeDetector.detectChanges()
    }

    onDateRangeChanged(dateRange: DateRange) {
        this.onDateRangeChange.emit(dateRange)
    }

    private reload() {
        if (this.appLaunchTrendsResponse == null) { return }
        this.changeDetector.detectChanges()
    }

}
