import { Params } from "@angular/router";

export enum ErrorSegmentsOrder {
    COUNT_ASC = 'COUNT_ASC',
    COUNT_DESC = 'COUNT_DESC',
    AFFECTED_USERS_ASC = 'AFFECTED_USERS_ASC',
    AFFECTED_USERS_DESC = 'AFFECTED_USERS_DESC'
}

export function errorSegmentsOrderFromQuery(params: Params): ErrorSegmentsOrder | null {
    let value = params['orderBy']
    if (Object.values(ErrorSegmentsOrder).includes(value as ErrorSegmentsOrder)) {
        return value as ErrorSegmentsOrder
    }

    return null
}
