import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'app/_services';
import { RoleFormatter } from '../_helpers/role.formatter';
import { Subscription } from 'rxjs';
import { Workspace } from 'app/_models';

@Component({
    selector: 'settings-sidebar',
    templateUrl: 'settings.sidebar.component.html'
})
export class SettingsSidebarComponent implements OnInit, OnDestroy {

    @Input() selectedFeatureIndex: number

    userName: string
    userImageUrl: URL
    userRole: string
    workspace: Workspace

    private currentWorkspaceSubscription: Subscription

    constructor(
        public roleFormatter: RoleFormatter,
        private authenticationService: AuthenticationService,
    ) {
    }

    ngOnInit() {
        this.userName = this.authenticationService.currentUserValue.userName
        this.userImageUrl = this.authenticationService.currentUserValue.userImageUrl

        this.currentWorkspaceSubscription = this.authenticationService.currentWorkspace.subscribe(workspace => {
            this.workspace = workspace
            if (workspace != null) {
                this.userRole = this.roleFormatter.roleName(workspace.role)
            }
        })
    }

    ngOnDestroy() {
        this.currentWorkspaceSubscription.unsubscribe()
    }

}
