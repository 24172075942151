import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Chart, ChartDataset, Color } from 'chart.js';
import { UUIDUtils } from 'app/_helpers';
import * as moment from 'moment';
import { DateRange } from 'app/_models';

@Component({
    selector: 'appdex-chart',
    templateUrl: 'appdex.chart.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppdexChartComponent implements AfterViewChecked {

    // TODO: Add support for chart zooming
    // https://stackoverflow.com/a/58918945

    private _values: number[]
    @Input()
    set values(values: number[]) {
        if (values && this._values !== values) {
            this._values = values
            if (this.chart) {
                this.reloadChart()
            }
        }
    }
    get values(): number[] { return this._values }

    private _datePoints: string[]
    @Input()
    set datePoints(datePoints: string[]) {
        if (datePoints && this._datePoints !== datePoints) {
            this._datePoints = datePoints
            if (this.chart) {
                this.reloadChart()
            }
        }
    }
    get datePoints(): string[] { return this._datePoints }

    @Output() onDateRangeChange = new EventEmitter<DateRange>()

    private chart: Chart
    chartUuid: string = UUIDUtils.uuidv4()

    constructor(
        private changeDetector: ChangeDetectorRef
    ) {
    }

    // Charts can only be created once the their canvases are rendered, and canvases
    // will only be shown now after all the data is received
    ngAfterViewChecked() {
        if (!this.values || this.chart) { return }
        if ($(`#${this.chartUuid}`).length > 0) {
            this.reloadChart()
        }
    }

    private reloadChart() {
        if (this.values == null || this.datePoints == null) { return }

        if (this.chart) {
            this.chart.destroy()
        }

        let labels: string[][] = this.datePoints.map((datePoint) => {
            let date = moment(datePoint)
            return [date.format('MMM Do'), date.format('HH:mm')]
        })

        this.chart = new Chart(this.chartUuid, {
            type: 'line',
            data: {
                labels: labels,
                datasets: this.makeChartDatasets()
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: true,
                        callbacks: {
                            title: function (tooltipItems): string {
                                if (tooltipItems.length == 0) { return null }
                                return tooltipItems[0].label.replace(",", " ")
                            },
                            label: function (tooltipItem): string {
                                return tooltipItem.parsed.y.toFixed(2)
                            }
                        }
                    },
                    zoom: {
                        zoom: {
                            drag: { enabled: true },
                            mode: 'x',
                            onZoomComplete: this.onZoomComplete.bind(this)
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                animation: false,
                scales: {
                    x: {
                        display: true,
                        ticks: {
                            maxRotation: 0,
                            minRotation: 0,
                            maxTicksLimit: 15,
                            color: '#aaa',
                            font: {
                                size: 12
                            }
                        }
                    },
                    y: {
                        display: true,
                        max: 1,
                        min: 0,
                        ticks: {
                            callback: function (value: number): string {
                                let formattedValue = value.toFixed(2)
                                return ["0.00", "0.50", "0.70", "0.85", "0.94", "1.00"].includes(formattedValue) ? formattedValue : undefined
                            },
                            maxTicksLimit: 200,
                            stepSize: 0.01,
                            color: '#aaa',
                            font: {
                                size: 12
                            }
                        }
                    }
                }
            }
        })
        this.changeDetector.detectChanges()
    }

    onZoomComplete(context: { chart: Chart }) {
        const min = Math.min(Math.max(context.chart.scales.x.min, 0), this.datePoints.length - 1)
        const max = Math.min(Math.max(context.chart.scales.x.max, 0), this.datePoints.length - 1)

        let dateRange = new DateRange()
        dateRange.startDate = moment(this.datePoints[min]).toDate()
        dateRange.endDate = moment(this.datePoints[max]).toDate()
        this.onDateRangeChange.emit(dateRange)
    }

    private makeChartDatasets(): ChartDataset[] {
        var pointColors: Color[] = this.values.map(value => {
            if (value >= 0.94) return "#03c764"
            else if (value >= 0.85) return "#4CAF50"
            else if (value >= 0.70) return "#FFA726"
            else if (value >= 0.50) return "#FF7043"
            else return "#E53935"
        })

        let chartDatasets: ChartDataset[] = [
            {
                data: this.values,
                fill: false,
                pointBackgroundColor: pointColors,
                tension: 0.4
            }
        ]

        return chartDatasets
    }

}
