export enum AppLaunchTrendType {
    Appdex = 'APPDEX',
    DurationAvg = 'DURATION_AVG',
    DurationP90 = 'DURATION_P90',
    DurationP95 = 'DURATION_P95',
    DurationP99 = 'DURATION_P99',
    Launches = 'LAUNCHES',
    OsVersionMajor = 'OS_VERSION_MAJOR',
    AppVersion = 'APP_VERSION',
}
