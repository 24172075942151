import { Component, OnInit } from '@angular/core';
import { AuthenticationService, WorkspaceService, AlertService } from 'app/_services'
import { Workspace, BackendResponse, Role, PostalAddress } from 'app/_models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { DateFormatter } from 'app/_helpers';
import { Title } from '@angular/platform-browser';

@Component({
    templateUrl: 'workspace.settings.component.html'
})
export class WorkspaceSettingsComponent implements OnInit {

    workspace: Workspace
    editWorkspaceForm: FormGroup
    billingForm: FormGroup
    canEditWorkspace: boolean

    countries = ["Switzerland"]
    selectedCountry: string

    constructor(
        private formBuilder: FormBuilder,
        private workspaceService: WorkspaceService,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        public dateFormatter: DateFormatter,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.titleService.setTitle("Settings | Workspace")

        this.workspace = this.authenticationService.currentWorkspaceValue
        this.canEditWorkspace = this.workspace.role == Role.Agent

        let w = this.workspace

        this.editWorkspaceForm = this.formBuilder.group({
            workspaceName: new FormControl({ value: w.name, disabled: !this.canEditWorkspace }, Validators.required),
            twoFactorAuthenticationEnforcedForDashboardAccess: new FormControl({ value: w.twoFactorAuthenticationEnforcedForDashboardAccess, disabled: !this.canEditWorkspace }, Validators.required),
        })

        this.billingForm = this.formBuilder.group({
            billingCompanyName: new FormControl({ value: w.billingCompanyName, disabled: !this.canEditWorkspace }, null),
            billingContactName: new FormControl({ value: w.billingContactName, disabled: !this.canEditWorkspace }, Validators.required),
            addressLine1: new FormControl({ value: w.billingAddress == null ? null : w.billingAddress.addressLine1, disabled: !this.canEditWorkspace }, Validators.required),
            addressLine2: new FormControl({ value: w.billingAddress == null ? null : w.billingAddress.addressLine2, disabled: !this.canEditWorkspace }, null),
            city: new FormControl({ value: w.billingAddress == null ? null : w.billingAddress.city, disabled: !this.canEditWorkspace }, Validators.required),
            stateRegion: new FormControl({ value: w.billingAddress == null ? null : w.billingAddress.stateRegion, disabled: !this.canEditWorkspace }, null),
            postalCode: new FormControl({ value: w.billingAddress == null ? null : w.billingAddress.postalCode, disabled: !this.canEditWorkspace }, Validators.required),
            country: new FormControl({ value: w.billingAddress == null ? null : w.billingAddress.country, disabled: !this.canEditWorkspace }, Validators.required),
            billingContactEmail: new FormControl({ value: w.billingContactEmail, disabled: !this.canEditWorkspace }, Validators.required)
        })

        if (w.billingAddress) {
            this.billingForm.controls.country.setValue(w.billingAddress.country)
        }
    }

    onUpdateWorkspace() {
        let f = this.editWorkspaceForm.controls

        this.workspaceService.updateWorkspace(this.workspace.id, f.workspaceName.value, f.twoFactorAuthenticationEnforcedForDashboardAccess.value).then((response) => {
            this.workspace = response
            this.authenticationService.currentWorkspaceUpdated(response)
            this.alertService.success("Workspace successfully updated")

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    setSelectedCountry(country: string) {
        this.selectedCountry = country
        this.billingForm.controls.country.setValue(country)
    }

    updateWorkspaceBilling() {
        let f = this.billingForm.controls

        var postalAddress = new PostalAddress()
        postalAddress.addressLine1 = f.addressLine1.value
        postalAddress.addressLine2 = f.addressLine2.value == '' ? null : f.addressLine2.value
        postalAddress.city = f.city.value
        postalAddress.stateRegion = f.stateRegion.value
        postalAddress.postalCode = f.postalCode.value
        postalAddress.country = f.country.value

        let billingCompanyName = f.billingCompanyName.value == '' ? null : f.billingCompanyName.value

        this.workspaceService.updateWorkspaceBilling(this.workspace.id, postalAddress, billingCompanyName, f.billingContactName.value, f.billingContactEmail.value).then((response) => {
            this.workspace = response
            this.authenticationService.currentWorkspaceUpdated(response)
            this.alertService.success("Workspace successfully updated")

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
