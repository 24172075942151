import { Component, OnInit } from '@angular/core';
import { AlertService } from 'app/_services'
import { Workspace, BackendResponse } from 'app/_models';
import * as bootbox from 'bootbox'
import { UserSession, UserSessionsService } from 'app/_services/user.sessions.service';
import { DateFormatter } from 'app/_helpers';
import { Title } from '@angular/platform-browser';

@Component({
    templateUrl: 'account.activity.component.html'
})
export class AccountActivityComponent implements OnInit {

    activeSessions: UserSession[] = []
    inactiveSessions: UserSession[] = []
    workspace: Workspace

    constructor(
        public dateFormatter: DateFormatter,
        private userSessionsService: UserSessionsService,
        private alertService: AlertService,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.titleService.setTitle("Settings | Account Activity")
        this.reloadUserSessions()
    }

    onActiveSessionDelete(userSession: UserSession) {
        let weakThis = this

        bootbox.dialog({
            title: `Sign Out?`,
            message: "The session be signed out immediatelly.",
            size: 'small',
            buttons: {
                cancel: { label: 'Cancel', className: 'btn-link' },
                delete: {
                    label: 'Sign Out', className: 'btn-danger', callback: function () {
                        weakThis.deleteActiveSession(userSession)
                    }
                }
            }
        })
    }

    deleteActiveSession(userSession: UserSession) {
        this.userSessionsService.deleteSession(userSession.id).then((response) => {
            this.reloadUserSessions()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private reloadUserSessions() {
        this.userSessionsService.getUserSessions().then((response) => {
            this.activeSessions = []
            this.inactiveSessions = []

            for (let userSession of response.data) {
                if (userSession.isActive) {
                    this.activeSessions.push(userSession)
                } else {
                    this.inactiveSessions.push(userSession)
                }
            }

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
