import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Application, Build, Platform, ProvisioningProfile, Workspace, Role } from 'app/_models';
import { AlertService, BuildService, ApplicationService, AuthenticationService } from 'app/_services';
import { ActivatedRoute } from '@angular/router';
import { ApplicationFormatter, DateFormatter, PlatformFormatter } from 'app/_helpers';
import { ProvisioningProfilePresenter } from 'app/_presenters/provisioning.profile.presenter';
import { ByteCountFormatter } from 'app/_helpers';
import { ProvisioningProfilesService } from 'app/_services/provisioning.profiles.service';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { DistributeBuildModal } from './distribute.build.modal/distribute.build.modal';

@Component({
    selector: 'build',
    templateUrl: 'build.component.html'
})
export class BuildComponent implements OnInit, OnDestroy {

    workspace: Workspace
    application: Application
    build: Build
    provisioningProfile: ProvisioningProfile
    prettyEntitlements: string
    applicationBinaryName: string

    @ViewChild("distributeBuildModal") distributeBuildModal: DistributeBuildModal

    private currentApplicationSubscription: Subscription

    constructor(
        public byteCountFormatter: ByteCountFormatter,
        public dateFormatter: DateFormatter,
        public platformFormatter: PlatformFormatter,
        private applicationService: ApplicationService,
        private alertService: AlertService,
        private buildService: BuildService,
        private provisioningProfilesService: ProvisioningProfilesService,
        private route: ActivatedRoute,
        private applicationFormatter: ApplicationFormatter,
        private authenticationService: AuthenticationService,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue

        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
            if (this.application != null) {
                this.onApplicationLoaded()
            }
        })
    }

    ngOnDestroy() {
        this.currentApplicationSubscription.unsubscribe()
    }

    onApplicationLoaded() {
        let buildSlug = this.route.snapshot.params['buildSlug']

        this.buildService.getBuildsWithSlug(this.application.id, buildSlug).then((response) => {
            this.build = response.data[0]
            this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | Builds | ${this.build.shortVersion}(${this.build.version})`)

            if (this.build.provisioningProfileId != null) {
                this.provisioningProfilesService.getProvisioningProfile(this.build.provisioningProfileId).then((responseProfile) => {
                    this.provisioningProfile = responseProfile.data
                    this.updateEntitlements()

                }).catch((error) => {
                    this.alertService.handleError(error)
                })
            }

            // Extract application binary name from binary object. If it doesn't exist, fall back to generic name based on the platform.
            this.applicationBinaryName = this.build.binary.fileName
            if (this.applicationBinaryName == null && this.application) {
                switch (this.application.platform) {
                    case Platform.Ios: this.applicationBinaryName = `${this.application.name}.ipa`; break
                    case Platform.Android: this.applicationBinaryName = `${this.application.name}.apk`; break
                    case Platform.Tvos: this.applicationBinaryName = `${this.application.name}.app`; break
                }
            }

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    canDistributeBuild(): boolean {
        return this.workspace.role > Role.Member
    }

    onDistributeBuildClick() {
        this.distributeBuildModal.build = this.build
        this.distributeBuildModal.application = this.application
    }

    private updateEntitlements() {
        if (this.provisioningProfile == null) {
            return
        }

        // Pretty format entitlements if present
        let entitlementsJson = JSON.parse(this.provisioningProfile.entitlements)
        if (entitlementsJson != null && Object.keys(entitlementsJson).length > 0) {
            this.prettyEntitlements = JSON.stringify(entitlementsJson, Object.keys(entitlementsJson).sort(), 2)
        }
    }

    profileType(): string {
        if (this.provisioningProfile == null) {
            return null
        }

        let presenter = new ProvisioningProfilePresenter(this.provisioningProfile)
        return presenter.profileType
    }

}
