import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TestRun, TestRunInfo2, TimeFilter } from 'app/_models';
import { AppConfig } from 'app/app.config';
import { CollectionResponse } from './response';

@Injectable({ providedIn: 'root' })
export class TestRunsService {

    constructor(private http: HttpClient) {
    }

    getTestCaseTestRuns(testCaseId: number, backendEnvironmentId: number | null, history: TimeFilter): Promise<CollectionResponse<TestRunInfo2>> {
        var url = `${AppConfig.apiBaseUrl}/testCases/${testCaseId}/testRuns?history=${history}`

        if (backendEnvironmentId) { url += `&backendEnvironmentId=${backendEnvironmentId}` }

        return this.http.get<CollectionResponse<TestRunInfo2>>(url).toPromise()
    }

    getTestRunsWithSlug(testExecutionId: number, testRunSlug: string): Promise<CollectionResponse<TestRun>> {
        return this.http.get<CollectionResponse<TestRun>>(`${AppConfig.apiBaseUrl}/testExecutions/${testExecutionId}/testRuns?slug=${testRunSlug}`).toPromise()
    }

}
