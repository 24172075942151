import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Workspace, ServiceConnectionType, ServiceConnection, IntegrationTrigger, TriggerConfigurationType, IntegrationTriggerEvent, ApplicationFilter } from 'app/_models';
import { AppConfig } from 'app/app.config';
import { CollectionResponse } from './response';

export class IntegrationsConfig {
    slackAuthorizationUrl: string
}

export class IntegrationFilterInfos {
    environmentNames: string[]
    testPlanNames: string[]
}

@Injectable({ providedIn: 'root' })
export class IntegrationsService {

    constructor(private http: HttpClient) { }

    /// ServiceConnection

    getConfig(workspace: Workspace): Promise<IntegrationsConfig> {
        return this.http.get<IntegrationsConfig>(`${AppConfig.apiBaseUrl}/workspaces/${workspace.id}/serviceConnections/config`).toPromise()
    }

    getFilterInfos(workspaceId: number): Promise<IntegrationFilterInfos> {
        return this.http.get<IntegrationFilterInfos>(`${AppConfig.apiBaseUrl}/workspaces/${workspaceId}/serviceConnections/filterInfos`).toPromise()
    }

    getWorkspaceServiceConnections(workspace: Workspace): Promise<CollectionResponse<ServiceConnection>> {
        return this.http.get<CollectionResponse<ServiceConnection>>(`${AppConfig.apiBaseUrl}/workspaces/${workspace.id}/serviceConnections`).toPromise()
    }

    createSlackServiceConnection(slackCode: string, workspaceId: number) {
        let body = {
            workspaceId: workspaceId,
            type: ServiceConnectionType.Slack,
            slackCode: slackCode,
        }

        return this.http.post(`${AppConfig.apiBaseUrl}/serviceConnections`, body)
    }

    createTeamsServiceConnection(name: string, webhookUrl: string, workspaceId: number): Promise<object> {
        let body = {
            workspaceId: workspaceId,
            type: ServiceConnectionType.Teams,
            name: name,
            webhookUrl: webhookUrl,
        }

        return this.http.post(`${AppConfig.apiBaseUrl}/serviceConnections`, body).toPromise()
    }

    deleteServiceConnection(serviceConnection: ServiceConnection): Promise<object> {
        return this.http.delete(`${AppConfig.apiBaseUrl}/serviceConnections/${serviceConnection.id}`).toPromise()
    }

    /// IntegrationTrigger

    createBugReportsIntegrationTrigger(serviceConnectionId: number, applicationFilters: ApplicationFilter[], reporterEmail: string | null): Promise<Object> {
        let requestBody = {
            triggerEvent: IntegrationTriggerEvent.BugReportCreated,
            applicationFilters: applicationFilters,
            configurationType: TriggerConfigurationType.BugReports,
            reporterEmail: reporterEmail
        }

        return this.http.post(`${AppConfig.apiBaseUrl}/serviceConnections/${serviceConnectionId}/integrationTriggers`, requestBody).toPromise()
    }

    updateBugReportsIntegrationTrigger(integrationTriggerId: number, applicationFilters: ApplicationFilter[], reporterEmail: string | null): Promise<Object> {
        let requestBody = {
            applicationFilters: applicationFilters,
            configurationType: TriggerConfigurationType.BugReports,
            reporterEmail: reporterEmail
        }

        return this.http.put(`${AppConfig.apiBaseUrl}/integrationTriggers/${integrationTriggerId}`, requestBody).toPromise()
    }

    createTestExecutionIntegrationTrigger(serviceConnectionId: number, applicationFilters: ApplicationFilter[], environmentNames: string[], testPlanNames: string[]): Promise<Object> {
        let requestBody = {
            triggerEvent: IntegrationTriggerEvent.TestExecutionFinished,
            applicationFilters: applicationFilters,
            configurationType: TriggerConfigurationType.TestExecutions,
            environmentNames: environmentNames,
            testPlanNames: testPlanNames
        }

        return this.http.post(`${AppConfig.apiBaseUrl}/serviceConnections/${serviceConnectionId}/integrationTriggers`, requestBody).toPromise()
    }

    updateTestExecutionIntegrationTrigger(integrationTriggerId: number, applicationFilters: ApplicationFilter[], environmentNames: string[], testPlanNames: string[]): Promise<Object> {
        let requestBody = {
            applicationFilters: applicationFilters,
            configurationType: TriggerConfigurationType.TestExecutions,
            environmentNames: environmentNames,
            testPlanNames: testPlanNames
        }

        return this.http.put(`${AppConfig.apiBaseUrl}/integrationTriggers/${integrationTriggerId}`, requestBody).toPromise()
    }

    deleteIntegrationTrigger(integrationTrigger: IntegrationTrigger): Promise<object> {
        return this.http.delete(`${AppConfig.apiBaseUrl}/integrationTriggers/${integrationTrigger.id}`).toPromise()
    }

}
