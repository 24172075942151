import { NetworkTrendsSummaryResponse } from 'app/_models';
import { Component, Input, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'payload-summary',
    templateUrl: 'payload.summary.component.html'
})
export class PayloadSummaryComponent {

    requestPercentage: string
    responsePercentage: string

    _networkTrendsSummaryResponse: NetworkTrendsSummaryResponse
    @Input()
    set networkTrendsSummaryResponse(networkTrendsSummaryResponse: NetworkTrendsSummaryResponse) {
        if (this._networkTrendsSummaryResponse != networkTrendsSummaryResponse) {
            this._networkTrendsSummaryResponse = networkTrendsSummaryResponse
            this.reload()
        }
    }
    get networkTrendsSummaryResponse(): NetworkTrendsSummaryResponse { return this._networkTrendsSummaryResponse }

    constructor(
        private changeDetector: ChangeDetectorRef
    ) {
    }

    private reload() {
        let summary = this.networkTrendsSummaryResponse as any
        if (summary == null) { return }

        this.requestPercentage = (summary.totalRequestPayloadSize / summary.totalPayloadSize) * 100 + "%"
        this.responsePercentage = (summary.totalResponsePayloadSize / summary.totalPayloadSize) * 100 + "%"
        this.changeDetector.detectChanges()
    }

}
