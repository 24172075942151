<div id="create_auth_token_modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog">

        <!-- Auth token creation form -->
        <div class="modal-content" *ngIf="!createdAuthToken">
            <div class="modal-header">
                <h5 class="modal-title">Create a new API Key for {{workspace.name}}</h5>
                <button #closeButton type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <form [formGroup]="form">
                    <div class="form-group">
                        <label>API Key name:</label>
                        <input type="text" formControlName="authTokenName" class="form-control">
                        <div class="text-muted mt-1">Set a name for your API Key to make it easier to identify.</div>
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <button (click)="submitForm()" [disabled]="form.invalid" type="button" class="btn bg-primary-400">Create</button>
            </div>
        </div>

        <!-- Auth token created -->
        <div class="modal-content" *ngIf="createdAuthToken">
            <div class="modal-header">
                <h5 class="modal-title">Here’s your API Key.</h5>
                <button #closeButton type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <p class="card-text">Please copy your token and keep it secret. You won’t be able to see it again in Dashboard.</p>

                <div class="input-group mb-2">
                    <input #authTokenInputField type="text" value="{{createdAuthToken}}" readonly class="form-control">
                    <span class="input-group-append">
                        <button (click)="copyToClipboard()" class="btn btn-light" type="button"><i class="icon-copy3"></i></button>
                    </span>
                </div>
                <p class="text-muted">We will only store a fingerprint of your API Key.</p>
            </div>

            <div class="modal-footer">
                <button (click)="closeForm()" type="button" class="btn bg-primary-400">Dismiss</button>
            </div>
        </div>

    </div>
</div>
