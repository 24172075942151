import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AlertService, BackendEnvironmentService, AuthenticationService, LocalPreferencesService } from 'app/_services';
import { ActivatedRoute } from '@angular/router';
import { BackendEnvironment, Workspace } from 'app/_models';
import { Subscription } from 'rxjs';
import { PlatformFormatter, DateFormatter } from 'app/_helpers'
import { BackendEnvironmentNetworkComponent } from './backend.environment.network.component';
import { BackendEnvironmentTestExecutionsComponent } from './backend.environment.test.executions.component';
import { BackendEnvironmentSettingsComponent } from './backend.environment.settings.component';
import { QuickJumpModal } from 'app/_components/quick.jump.modal/quick.jump.modal';

enum SelectedComponent {
    Unknown = -1,
    Network = 1,
    TestExecutions = 2,
    Settings = 3
}

@Component({
    templateUrl: 'backend.environment.component.html',
    styleUrls: [
        'backend.environment.component.css'
    ]
})
export class BackendEnvironmentComponent implements OnInit, OnDestroy {

    workspace: Workspace
    canEditEnvironment = false
    selectedComponent: SelectedComponent

    private _backendEnvironment: BackendEnvironment
    set backendEnvironment(backendEnvironment: BackendEnvironment) {
        this._backendEnvironment = backendEnvironment
        this.backendEnvironmentService.setCurrentBackendEnvironment(backendEnvironment)
    }
    get backendEnvironment() { return this._backendEnvironment }

    @ViewChild('quickJumpModal')
    private quickJumpModal: QuickJumpModal

    private routeUrlSubscription: Subscription
    private routeParamsSubscription: Subscription

    constructor(
        public platformFormatter: PlatformFormatter,
        public dateFormatter: DateFormatter,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private authenticationService: AuthenticationService,
        private backendEnvironmentService: BackendEnvironmentService,
        private localPreferencesService: LocalPreferencesService
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue

        // In case there is already injected BackendEnvironment from WorkspaceDashboard or other component
        // set it here so that other dependent pages don't have to load for the request that fetches all backend environments
        this.backendEnvironment = this.backendEnvironmentService.currentBackendEnvironmentValue

        this.routeUrlSubscription = this.route.url.subscribe(() => {
            let component: any = this.route.snapshot.firstChild.component
            let constructor: any = component.prototype.constructor.toString()
            let componentName = constructor.substring(constructor.indexOf(' ') + 1, constructor.indexOf('('))

            if (componentName == BackendEnvironmentNetworkComponent.prototype.constructor.name) {
                this.selectedComponent = SelectedComponent.Network

            } else if (componentName == BackendEnvironmentTestExecutionsComponent.prototype.constructor.name) {
                this.selectedComponent = SelectedComponent.TestExecutions

            } else if (componentName == BackendEnvironmentSettingsComponent.prototype.constructor.name) {
                this.selectedComponent = SelectedComponent.Settings

            } else {
                this.selectedComponent = SelectedComponent.Unknown
                console.log("Unhandled component: " + componentName)
            }
        })

        this.routeParamsSubscription = this.route.params.subscribe(params => {
            this.resolveBackendEnvironment()
        })

        // Update to current preference
        if (this.localPreferencesService.applicationSideMenuExpanded == true) {
            $('body').removeClass('sidebar-xs')
        } else {
            $('body').addClass('sidebar-xs')
        }

        // Toggle min sidebar class on click
        let localPreferencesService = this.localPreferencesService
        $('.sidebar-main-toggle').on('click', function (e) {
            e.preventDefault()
            $('body').toggleClass('sidebar-xs')

            // Save user preferences
            localPreferencesService.applicationSideMenuExpanded = !$('body').hasClass('sidebar-xs')
        })
    }

    ngOnDestroy() {
        this.routeUrlSubscription.unsubscribe()
        this.routeParamsSubscription.unsubscribe()
        this.backendEnvironment = null
    }

    showQuickJump() {
        this.quickJumpModal.show()
    }

    private resolveBackendEnvironment() {
        let backendEnvironmentSlug = this.route.snapshot.params["backendEnvironmentSlug"]

        // If we have navigated away, reset the backendEnvironment to null
        if (backendEnvironmentSlug == null || backendEnvironmentSlug == '') {
            this.backendEnvironment = null
            return
        }

        // If we already have the right backendEnvironment, nothing needs to be done
        if (this.backendEnvironment && this.backendEnvironment.slug == backendEnvironmentSlug) {
            return
        }

        // Find the application based on applicationSlug
        this.backendEnvironmentService.getWorkspaceEnvironmentWithSlug(backendEnvironmentSlug).then((response) => {
            this.backendEnvironment = response.data[0]
        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
