import { AfterViewChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from 'moment'
import { AlertService, LocalPreferencesService } from 'app/_services';
import { LogFile, BackendResponse, VideoFile, TimeStyle, VideoTimelineFile } from 'app/_models';
import { Log, ConsoleLogsResponse, NetworkRequestLog, LogType, NetworkResponseLog, LogSource, ConsoleLog, ConsoleLogLevel } from 'app/_models/console.logs.response';
import { HttpClient } from '@angular/common/http';
import { ConsoleLogLevelFormatter, DateFormatter, TimeStyleFormatter } from 'app/_helpers';
import { trigger } from '@angular/animations';
import { Animations } from 'app/_animations/animations';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'application-logs',
    templateUrl: 'application.logs.component.html',
    styleUrls: [
        'application.logs.component.css'
    ],
    animations: [
        trigger('fadeInOutAnimation', Animations.fadeInOut),
    ]
})

export class ApplicationLogsComponent implements OnInit, AfterViewChecked, OnDestroy {

    _videoFile: VideoFile
    @Input()
    set videoFile(videoFile: VideoFile) {
        this._videoFile = videoFile
        if (this._videoFile) {
            this.updateVideoTime()
        }
    }
    get videoFile() { return this._videoFile }

    _videoTimelineFile: VideoTimelineFile
    @Input()
    set videoTimelineFile(videoTimelineFile: VideoTimelineFile) {
        this._videoTimelineFile = videoTimelineFile
        if (this._videoTimelineFile) {
            this.updateVideoTime()
        }
    }
    get videoTimelineFile() { return this._videoTimelineFile }

    _logFile: LogFile
    @Input()
    set logFile(logFile: LogFile) {
        if (this._logFile === logFile) { return }
        this.filteredLogs = null
        this.filteredLogsReversed = null
        this.timelinePosition = 0
        this.selectedTimelineLog = null
        this._viewSetupDone = false

        this._logFile = logFile
        this.reloadLogs()
    }
    get logFile() { return this._logFile }

    @Output() timelinePositionChanged = new EventEmitter<number>()
    @Output() analyzeRequestHandler = new EventEmitter<NetworkResponseLog>()

    private allLogs: Log[]
    filteredLogs: Log[]
    filteredLogsReversed: Log[]

    allLogLevels: ConsoleLogLevel[] = [
        ConsoleLogLevel.Verbose,
        ConsoleLogLevel.Debug,
        ConsoleLogLevel.Info,
        ConsoleLogLevel.Warning,
        ConsoleLogLevel.Error,
        ConsoleLogLevel.Assert
    ]

    hasApplicationLogs = false
    applicationLogsEnabled = true
    hasTestLogs = false
    hasXCTestLogs = false
    testLogsEnabled = true
    hasLogcatLogs = false
    logcatLogsEnabled = false
    showSources = false
    xctActionLogsEnabled = true
    xctQueryLogsEnabled = false
    selectedLogLevel = ConsoleLogLevel.Verbose
    selectedTimeStyle: TimeStyle

    currentVideoTime: string
    totalVideoTime: string

    logSource = LogSource
    consoleLogLevel = ConsoleLogLevel
    timeStyle = TimeStyle
    timelinePosition: number = 0
    private selectedTimelineLog: Log

    private earliestDate: moment.Moment

    private networkRequestsMap: Map<string, NetworkRequestLog>
    private networkResponsesMap: Map<string, NetworkRequestLog>

    private resizeListener: any

    constructor(
        private http: HttpClient,
        private alertService: AlertService,
        private dateFormatter: DateFormatter,
        private route: ActivatedRoute,
        private localPreferencesService: LocalPreferencesService,
        public logLevelFormatter: ConsoleLogLevelFormatter,
        public timeStyleFormatter: TimeStyleFormatter,
    ) { }

    ngOnInit(): void {
        this.selectedTimeStyle = this.localPreferencesService.applicationLogsTimeStyle

        this.resizeListener = this.onWindowResize.bind(this)
        window.addEventListener("resize", this.resizeListener)
    }

    private _viewSetupDone = false
    ngAfterViewChecked() {
        if (this._viewSetupDone || this.selectedTimelineLog == null) {
            return
        }

        this.updateTimelineMarker()
        this._viewSetupDone = true
    }

    ngOnDestroy() {
        window.removeEventListener("resize", this.resizeListener)
    }

    private onWindowResize() {
        this.updateTimelineMarker()
    }

    private reloadLogs() {
        if (this.logFile == null) {
            return
        }

        this.http.get(this.logFile.url, { responseType: 'text' }).toPromise().then((result => {
            let logsData = JSON.parse(result)
            if (logsData['version'] != 1) {
                return
            }
            this.allLogs = (logsData as ConsoleLogsResponse).logs

            this.updateEarliestDate()
            this.updateLogSources()
            this.updateNetworkRequestsMap()
            this.filterLogs()
            this.resolveLogReferenceFromQuery()

        })).catch(error => {
            this.alertService.handleError(error)
        })
    }

    logTime(log: Log): string {
        let logDate = moment(log.date)

        switch(this.selectedTimeStyle) {
            case TimeStyle.Absolute: return logDate.format('HH:mm:ss.SSS')
            case TimeStyle.Relative: {
                let diff = logDate.diff(this.earliestDate) / 1000
                return this.dateFormatter.stopwatchTimeShort(diff)
            }
        }
    }

    logIdentifier(log: Log): string {
        let date = moment(log.date)
        return `${date.unix()}${date.milliseconds()}`
    }

    networkRequestLogWithId(id: string): NetworkRequestLog | null {
        return this.networkRequestsMap.get(id)
    }

    networkResponseLogWithId(id: string): NetworkRequestLog | null {
        return this.networkResponsesMap.get(id)
    }

    toggleApplicationLogs() {
        this.applicationLogsEnabled = !this.applicationLogsEnabled
        this.filterLogs()
    }

    toggleTestLogs() {
        this.testLogsEnabled = !this.testLogsEnabled
        this.filterLogs()
    }

    toggleLogcatLogs() {
        this.logcatLogsEnabled = !this.logcatLogsEnabled
        this.filterLogs()
    }

    setConsoleLogLevel(logLevel: ConsoleLogLevel) {
        this.selectedLogLevel = logLevel
        this.filterLogs()
    }

    setTimeStyle(timeStyle: TimeStyle) {
        this.selectedTimeStyle = timeStyle
        this.localPreferencesService.applicationLogsTimeStyle = timeStyle

        this.updateVideoTime()
        this.filterLogs()
    }

    toggleXCTActionLogs() {
        this.xctActionLogsEnabled = !this.xctActionLogsEnabled
        this.filterLogs()
    }

    toggleXCTQueryLogs() {
        this.xctQueryLogsEnabled = !this.xctQueryLogsEnabled
        this.filterLogs()
    }

    sourcesSummaryText(): string {
        if (!this.showSources) return null

        let enabledSourcesCount = (this.applicationLogsEnabled ? 1 : 0) + (this.testLogsEnabled ? 1 : 0) + (this.logcatLogsEnabled ? 1 : 0)
        if (enabledSourcesCount == 3) return "All"

        if (enabledSourcesCount == 2) {
            if (!this.applicationLogsEnabled) return "Test, Logcat"
            if (!this.testLogsEnabled) return "App, Logcat"
            if (!this.logcatLogsEnabled) return "App, Test"
        }

        if (enabledSourcesCount == 1) {
            if (this.applicationLogsEnabled) return "App"
            if (this.testLogsEnabled) return "Test"
            if (this.logcatLogsEnabled) return "Logcat"
        }
    }

    xctLogsSummaryText(): string {
        if (this.xctQueryLogsEnabled && this.xctActionLogsEnabled) return "Actions, Queries"
        else if (this.xctQueryLogsEnabled) return "Queries only"
        else if (this.xctActionLogsEnabled) return "Actions only"
        else return "None"
    }

    setTimelinePosition(position: number, force: boolean) {
        if (this.videoFile) {
            this.updateVideoTime()
        }

        // Prevent circular calls between application logs and video player
        if (!force && this.timelinePosition == position) {
            return
        }
        this.timelinePosition = position

        let videoStartDate = moment(this.videoFile.startDate)
        let timelineDate = videoStartDate.add(this.videoFile.duration * position, 's')

        let closestLog = this.logForDate(timelineDate)
        if (this.selectedTimelineLog != closestLog) {
            this.selectedTimelineLog = closestLog
            this.updateTimelineMarker()
        }
    }

    onTimelinePositionChanged(position: number) {
        this.setTimelinePosition(position, false)
        this.timelinePositionChanged.emit(position)
    }

    onLogDateClick(log: Log) {
        this.selectedTimelineLog = log
        this.updateTimelineMarker()

        if (this.videoFile) {
            let logDate = moment(log.date)
            let videoStartDate = moment(this.videoFile.startDate)
            let offset = logDate.diff(videoStartDate) / 1000

            this.timelinePosition = Math.min(Math.max(0, offset / this.videoFile.duration), 1)
            this.timelinePositionChanged.emit(this.timelinePosition)
        }
    }

    onTimeButtonClick() {
        if (!this.selectedTimelineLog) {
            return
        }

        let logIdentifier = this.logIdentifier(this.selectedTimelineLog)
        let logElement = document.getElementById(logIdentifier)
        logElement.scrollIntoView({ behavior: "smooth", block: "nearest" })
    }

    onAnalyzeRequest(response: NetworkResponseLog) {
        this.analyzeRequestHandler.emit(response)
    }

    private resolveLogReferenceFromQuery() {
        if (!this.allLogs) { return }

        let requestId = this.route.snapshot.queryParams['requestId']
        if (!requestId || !requestId.length) { return }

        let responseLog = this.allLogs.find((log) => { return log.type == LogType.NetworkResponse && (log as NetworkResponseLog).requestId == requestId })
        if (!responseLog) { return }

        // Start clicking only with a delay, after log is rendered
        setTimeout(() => {
            this.onLogDateClick(responseLog)

            let logElement = document.getElementById(`response_log_href_${requestId}`)
            logElement.click()

            // Start scrolling with a delay - after log is expanded
            setTimeout(() => {
                document.getElementById(`response_${requestId}`).scrollIntoView({ behavior: "smooth", block: "center" })
            }, 250)
        }, 150)
    }

    private updateTimelineMarker() {
        var offset: number

        // If there is no selected log, move the marker out of the visible rect
        if (!this.selectedTimelineLog) {
            offset = -30

        } else {
            let logIdentifier = this.logIdentifier(this.selectedTimelineLog)
            let logElement = document.getElementById(logIdentifier)
            offset = logElement.offsetTop + 5
        }

        let timeMarkerElement = document.getElementById("timeMarker")
        if (timeMarkerElement) {
            timeMarkerElement.style.transform = `translateY(${offset}px)`
        }
    }

    private updateVideoTime() {
        let videoStartDate = moment(this.videoFile.startDate)
        switch (this.selectedTimeStyle) {
            case TimeStyle.Absolute: {
                let currentVideoTime = videoStartDate.add(this.videoFile.duration * this.timelinePosition, "seconds")
                this.currentVideoTime = currentVideoTime.format('HH:mm:ss.SSS')

                let videoEndDate = videoStartDate.add(this.videoFile.duration, "seconds")
                this.totalVideoTime = videoEndDate.format('HH:mm:ss.SSS')
                break
            }
            case TimeStyle.Relative: {
                let videoStartOffset = videoStartDate.diff(this.earliestDate, "seconds")

                this.currentVideoTime = this.dateFormatter.stopwatchTimeShort(videoStartOffset + this.videoFile.duration * this.timelinePosition)
                this.totalVideoTime = this.dateFormatter.stopwatchTimeShort(videoStartOffset + this.videoFile.duration)
                break
            }
        }
    }

    private logForDate(lookupDate: moment.Moment): Log | null {
        let lookupDateMs = lookupDate.unix() + lookupDate.milliseconds() / 1000

        // Find the first log from the end with a date < of lookupDate.
        return this.filteredLogsReversed.find( log => {
            let logDate = moment(log.date)
            let logDateMs = logDate.unix() + logDate.milliseconds() / 1000
            return logDateMs <= lookupDateMs
        })
    }

    private updateLogSources() {
        this.hasApplicationLogs = false
        this.hasTestLogs = false
        this.hasLogcatLogs = false
        this.hasXCTestLogs = false

        for (let log of this.allLogs) {
            if (!this.hasApplicationLogs && log.source == LogSource.App) {
                this.hasApplicationLogs = true
            }
            if (!this.hasTestLogs && log.source == LogSource.Test) {
                this.hasTestLogs = true
            }
            if (!this.hasLogcatLogs && log.source == LogSource.Logcat) {
                this.hasLogcatLogs = true
            }
            if (!this.hasXCTestLogs && (log.type == LogType.XctAction || log.type == LogType.XctQuery)) {
                this.hasXCTestLogs = true
            }
        }

        let sourcesCount = (this.hasApplicationLogs ? 1 : 0) + (this.hasTestLogs ? 1 : 0) + (this.hasLogcatLogs ? 1 : 0)
        this.showSources = sourcesCount > 1
    }

    private updateNetworkRequestsMap() {
        this.networkRequestsMap = new Map([])
        this.networkResponsesMap = new Map([])

        for (let log of this.allLogs) {
            if (log.type == LogType.NetworkRequest) {
                let requestLog = log as NetworkResponseLog
                this.networkRequestsMap.set(requestLog.requestId, requestLog)

            } else if (log.type == LogType.NetworkResponse) {
                let responseLog = log as NetworkResponseLog
                this.networkResponsesMap.set(responseLog.requestId, responseLog)
            }
        }
    }

    private filterLogs() {
        var filteredLogs: Log[] = []

        for (let log of this.allLogs) {
            var sourceMatches = false
            switch (log.source) {
                case LogSource.App: sourceMatches = this.applicationLogsEnabled; break
                case LogSource.Test: sourceMatches = this.testLogsEnabled; break
                case LogSource.Logcat: sourceMatches = this.logcatLogsEnabled; break
                default: sourceMatches = this.applicationLogsEnabled; break // Compatibility with old SDKs which didn't have source
            }

            if (!sourceMatches) {
                continue
            }

            if (log.type == LogType.Console) {
                let levelEnabled = this.shouldShowLogsOfLevel((log as ConsoleLog).level)
                if (!levelEnabled) {
                    continue
                }
            }

            if (!this.xctActionLogsEnabled && log.type == LogType.XctAction) {
                continue
            }

            if (!this.xctQueryLogsEnabled && log.type == LogType.XctQuery) {
                continue
            }

            filteredLogs.push(log)
        }

        this.filteredLogs = filteredLogs
        this.filteredLogsReversed = filteredLogs.slice().reverse()

        // If timeline is shown, refresh the timeMarkerElement after a delay (because changing the filteredLogs triggers view refresh)
        // and take into account the animation of the appearance of the logs themselves - if we trigger the animation of the marker
        // at the same time, it can cause a UI glitch
        if (this.videoFile) {
            setTimeout(() => {
                this.selectedTimelineLog = null
                this.setTimelinePosition(this.timelinePosition, true)
            }, 400)
        }
    }

    private updateEarliestDate() {
        if (this.allLogs.length > 0) {
            this.earliestDate = moment(this.allLogs[0].date)
            if (this.videoFile) {
                let videoStartDate = moment(this.videoFile.startDate)
                if (videoStartDate.isBefore(this.earliestDate)) {
                    this.earliestDate = videoStartDate
                }
            }
        } else if (this.videoFile) {
            let videoStartDate = moment(this.videoFile.startDate)
            this.earliestDate = videoStartDate
        }
    }

    private shouldShowLogsOfLevel(logLevel: ConsoleLogLevel): boolean {
        let logLevelIndex = this.allLogLevels.indexOf(logLevel)
        let selectedLogLevelIndex = this.allLogLevels.indexOf(this.selectedLogLevel)
        return logLevelIndex >= selectedLogLevelIndex
    }

}
