import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Application, Build, DistributionGroup, DistributionGroupBuild, DistributionGroupUser } from 'app/_models';
import { AppConfig } from 'app/app.config';
import { CollectionResponse, ElementResponse } from './response';

@Injectable({ providedIn: 'root' })
export class DistributionGroupsService {

    @Output() distributionGroupDeletedWithId = new EventEmitter<number>()
    @Output() distributionGroupEdited = new EventEmitter<DistributionGroup>()

    constructor(private http: HttpClient) {
    }

    getApplicationDistributionGroups(applicationId: number): Promise<CollectionResponse<DistributionGroup>> {
        let url = `${AppConfig.apiBaseUrl}/applications/${applicationId}/distributionGroups`
        return this.http.get<CollectionResponse<DistributionGroup>>(url).toPromise()
    }

    getApplicationDistributionGroupWithSlug(applicationId: number, slug: string): Promise<ElementResponse<DistributionGroup>> {
        return this.http.get<ElementResponse<DistributionGroup>>(`${AppConfig.apiBaseUrl}/applications/${applicationId}/distributionGroups?slug=${slug}`).toPromise()
    }

    createDistributionGroup(applicationId: number, name: string, isPublic: boolean, automaticallyAddNewBuilds: boolean): Promise<ElementResponse<DistributionGroup>> {
        let body = {
            name: name,
            isPublic: isPublic,
            automaticallyAddNewBuilds: automaticallyAddNewBuilds
        }

        return this.http.post<ElementResponse<DistributionGroup>>(`${AppConfig.apiBaseUrl}/applications/${applicationId}/distributionGroups`, body).toPromise()
    }

    updateDistributionGroup(distributionGroupId: number, name: string, isPublic: boolean, automaticallyAddNewBuilds: boolean): Promise<ElementResponse<DistributionGroup>> {
        let body = {
            name: name,
            isPublic: isPublic,
            automaticallyAddNewBuilds: automaticallyAddNewBuilds
        }

        return this.http.put<ElementResponse<DistributionGroup>>(`${AppConfig.apiBaseUrl}/distributionGroups/${distributionGroupId}`, body).toPromise()
    }

    addUsersToDistributionGroup(userIds: string[], distributionGroupId: number): Promise<Object> {
        let body = {
            userIds: userIds
        }

        return this.http.post<ElementResponse<DistributionGroup>>(`${AppConfig.apiBaseUrl}/distributionGroups/${distributionGroupId}/addUsers`, body).toPromise()
    }

    deleteDistributionGroup(distributionGroupId: number): Promise<Object> {
        return this.http.delete<ElementResponse<Object>>(`${AppConfig.apiBaseUrl}/distributionGroups/${distributionGroupId}`).toPromise()
    }

    deleteDistributionGroupUser(distributionGroupUserId: number): Promise<Object> {
        return this.http.delete<ElementResponse<Object>>(`${AppConfig.apiBaseUrl}/distributionGroupUsers/${distributionGroupUserId}`).toPromise()
    }

    deleteDistributionGroupBuild(distributionGroupBuildId: number): Promise<Object> {
        return this.http.delete<ElementResponse<Object>>(`${AppConfig.apiBaseUrl}/distributionGroupBuilds/${distributionGroupBuildId}`).toPromise()
    }

    getDistributionGroupUsers(distributionGroupId: number): Promise<CollectionResponse<DistributionGroupUser>> {
        let url = `${AppConfig.apiBaseUrl}/distributionGroups/${distributionGroupId}/users`
        return this.http.get<CollectionResponse<DistributionGroupUser>>(url).toPromise()
    }

    getDistributionGroupDistributionGroupBuilds(distributionGroupId: number): Promise<CollectionResponse<DistributionGroupBuild>> {
        let url = `${AppConfig.apiBaseUrl}/distributionGroups/${distributionGroupId}/distributionGroupBuilds`
        return this.http.get<CollectionResponse<DistributionGroupBuild>>(url).toPromise()
    }

}
