import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy, HostListener } from '@angular/core';
import { ApplicationService, AlertService, DistributionGroupsService, ElementResponse } from 'app/_services'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Application, DistributionGroup } from 'app/_models';
import { ApplicationFormatter, PlatformFormatter } from 'app/_helpers';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'distribution-group-editor',
    templateUrl: 'distribution.group.editor.modal.html'
})
export class DistributionGroupEditorModal implements OnInit, OnDestroy {

    application: Application
    form: FormGroup;

    private _distributionGroup: DistributionGroup | null
    @Input()
    set distributionGroup(distributionGroup: DistributionGroup) {
        this._distributionGroup = distributionGroup
        this.reloadForm()
    }
    get distributionGroup(): DistributionGroup { return this._distributionGroup }

    @ViewChild('closeButton') closeButton: ElementRef

    private currentApplicationSubscription: Subscription

    constructor(
        public platformFormatter: PlatformFormatter,
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private applicationService: ApplicationService,
        private applicationFormatter: ApplicationFormatter,
        private titleService: Title,
        private distributionGroupsService: DistributionGroupsService,
    ) {
    }

    ngOnInit() {
        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
            if (this.application != null) {
                this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | Network`)
                this.reloadForm()
            }
        })

        this.reloadForm()
    }

    ngOnDestroy() {
        this.currentApplicationSubscription.unsubscribe()
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls }

    submitForm() {
        let promise: Promise<ElementResponse<DistributionGroup>>
        if (this.distributionGroup) {
            promise = this.distributionGroupsService.updateDistributionGroup(this.distributionGroup.id, this.f.name.value, this.f.isPublic.value, this.f.automaticallyAddNewBuilds.value)
        } else {
            promise = this.distributionGroupsService.createDistributionGroup(this.application.id, this.f.name.value, this.f.isPublic.value, this.f.automaticallyAddNewBuilds.value)
        }

        promise.then((response) => {
            this.distributionGroupsService.distributionGroupEdited.emit(response.data)

            // Close the modal dialog
            this.closeButton.nativeElement.click()
            this.form.reset()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private reloadForm() {
        this.form = this.formBuilder.group({
            name: [this.distributionGroup ? this.distributionGroup.name : null, Validators.required],
            isPublic: [this.distributionGroup ? this.distributionGroup.isPublic : false, Validators.required],
            automaticallyAddNewBuilds: [this.distributionGroup ? this.distributionGroup.automaticallyAddNewBuilds : false, Validators.required]
        })
    }

}
