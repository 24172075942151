import { Component } from '@angular/core';
import { AppConfig } from 'app/app.config';

@Component({
    selector: 'navbar-unregistered',
    templateUrl: 'navbar.unregistered.html'
})
export class NavbarUnregistered {

    logoHref(): string {
        return AppConfig.webBaseUrl
    }

}
