import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NetworkResponseLog, NetworkRequestLog } from 'app/_models/console.logs.response';
import { HTTPFormatter } from 'app/_helpers';
import { AppConfig } from 'app/app.config';

@Component({
    selector: 'network-log-content',
    templateUrl: 'network.log.content.html',
    styleUrls: ['network.log.content.css']
})
export class NetworkLogContent implements OnInit {

    @Input() public requestLog: NetworkRequestLog | null
    @Input() public responseLog: NetworkResponseLog

    @Output() analyzeRequestHandler = new EventEmitter<NetworkResponseLog>()

    requestPayloadContent: string
    responsePayloadContent: string
    errorContent: string

    constructor(
        private httpFormatter: HTTPFormatter,
    ) {
    }

    ngOnInit() {
        if (this.requestLog != null) {
            this.requestPayloadContent = this.httpFormatter.formatNetworkRequestLogPayload(this.requestLog)
        }

        this.responsePayloadContent = this.httpFormatter.formatNetworkResponseLogPayload(this.responseLog)
        this.errorContent = this.httpFormatter.formatHTTPErrorInfo(this.responseLog.errorInfo)
    }

    copyUrlToClipboard() {
        const content = `${this.responseLog.url}`
        navigator.clipboard.writeText(content)
    }

    copyRequestAndResponseToClipboard() {
        const content = this.httpFormatter.formatNetworkRequestAndResponseLogsForClipboard(this.requestLog, this.responseLog)
        navigator.clipboard.writeText(content)
    }

    copyLogLink() {
        // pathname already has a `/`
        let content = `${AppConfig.dashboardBaseUrl}${window.location.pathname}?requestId=${this.responseLog.requestId}`
        navigator.clipboard.writeText(content)
    }

    onAnalyzeRequest() {
        this.analyzeRequestHandler.emit(this.responseLog)
    }

}
