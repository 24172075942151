<div class="content">

    <div class="row">
        <div class="col-md-12">
            <alert></alert>
        </div>
    </div>

    <div class="card" *ngIf="bugReport">
        <div class="card-header bg-transparent header-elements-inline">
            <div class="media">
                <div class="mr-3"><img class="rounded-circle" src="{{bugReport.reporterImageUrl}}" width="40" height="40" alt=""></div>
                <div class="media-body">
                    <div class="media-title font-weight-semibold" *ngIf="bugReport.reporterEmail">{{bugReport.reporterEmail}}</div>
                    <div class="media-title font-weight-semibold" *ngIf="bugReport.reporterEmail == null">Anonymous reporter</div>
                    <span class="text-muted">{{dateFormatter.dateTime(bugReport.creationDate)}}</span>
                </div>
            </div>

            <div class="header-elements">
                <div class="dropdown">
                    <a href="#" class="list-icons-item dropdown-toggle" data-toggle="dropdown"><i class="icon-menu7"></i></a>
                    <div class="dropdown-menu dropdown-menu-right">
                        <a (click)="onBugReportDeleteButtonClick()" class="dropdown-item text-danger">Delete Bug Report</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-body d-flex">
            <image-video #imageVideo class="mb-0 mr-3" [imageFile]="bugReport.screenshot" [videoFile]="bugReport.autoScreenRecording" (progressChange)="onVideoProgressChange($event)"  *ngIf="bugReport.screenshot"></image-video>
            <div class="w-100">
                <div class="row">
                    <div class="col-sm-12">
                        <div *ngIf="bugReport.title">{{bugReport.title}}</div>
                        <div class="text-muted" *ngIf="bugReport.title == null">No description provided</div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-12">
                        <h6 class="font-weight-semibold mb-1">Bug Report Details</h6>
                    </div>
                </div>
                <div class="row mb-2">
                    <value-item class="col-lg-6 col-xl-4" title="App version" value="{{bugReport.appShortVersion}} ({{bugReport.appVersion}})" icon="icon-git-branch"></value-item>
                    <value-item class="col-lg-6 col-xl-4" title="Environment" value="{{bugReport.environmentName}} {{bugReport.environmentVersion}}" icon="icon-insert-template" *ngIf="bugReport.environmentName"></value-item>
                    <value-item class="col-lg-6 col-xl-4" title="Device" value="{{bugReport.deviceModelName}}" icon="icon-mobile2"></value-item>
                    <value-item class="col-lg-6 col-xl-4" title="Locale" value="{{bugReport.locale}}" icon="icon-earth"></value-item>
                    <value-item class="col-lg-6 col-xl-4" title="OS" value="{{bugReport.system}}" icon="{{platformFormatter.platformIconName(bugReport.platform)}}"></value-item>
                    <value-item class="col-lg-6 col-xl-4" title="Current view" value="{{bugReport.currentView}}" icon="icon-stack4"></value-item>
                    <value-item class="col-lg-6 col-xl-4" title="Screen size" value="{{bugReport.screenSize}}" icon="icon-enlarge"></value-item>
                    <value-item class="col-lg-6 col-xl-4" title="Screen density" value="{{bugReport.screenDensity}}" icon="icon-grid"></value-item>
                    <value-item class="col-lg-6 col-xl-4" title="App launch time" value="{{appLaunchTimeText()}}" icon="icon-alarm"></value-item>
                    <value-item class="col-lg-6 col-xl-4" title="User identifier" value="{{bugReport.userIdentifier}}" icon="icon-user"></value-item>
                </div>
                <div class="row border-top">
                    <div class="col-sm-12">
                        <h6 class="font-weight-semibold mt-2 mb-0">Downloads</h6>

                        <ul class="list-inline mb-0">
                            <!-- Screenshot attachment -->
                            <li class="list-inline-item mt-2" *ngIf="bugReport.screenshot && bugReport.screenshot.url">
                                <div class="media">
                                    <div class="mr-2 align-self-center">
                                        <i class="icon-file-picture icon-2x text-slate-400 top-0"></i>
                                    </div>

                                    <div class="media-body">
                                        <a download="BugReport_{{bugReport.serialNumber}}.jpeg" href="{{bugReport.screenshot.url}}" class="font-weight-semibold">Screenshot.jpeg</a>
                                        <ul class="list-inline list-inline-dotted list-inline-condensed font-size-sm text-muted">
                                            <li class="list-inline-item">Size: {{byteCountFormatter.stringFromByteCount(bugReport.screenshot.fileSize)}}</li>
                                        </ul>
                                    </div>
                                </div>
                            </li>

                            <!-- Screen recording attachment -->
                            <li class="list-inline-item mt-2" *ngIf="bugReport.autoScreenRecording">
                                <div class="media">
                                    <div class="mr-2 align-self-center">
                                        <i class="icon-file-play icon-2x text-slate-400 top-0"></i>
                                    </div>

                                    <div class="media-body">
                                        <a download="BugReportRecording_{{bugReport.serialNumber}}.mp4" href="{{bugReport.autoScreenRecording.url}}" class="font-weight-semibold">Screen Recording.mp4</a>
                                        <ul class="list-inline list-inline-dotted list-inline-condensed font-size-sm text-muted">
                                            <li class="list-inline-item">Duration: {{dateFormatter.duration(bugReport.autoScreenRecording.duration)}}</li>
                                            <li class="list-inline-item">Size: {{byteCountFormatter.stringFromByteCount(bugReport.autoScreenRecording.fileSize)}}</li>
                                        </ul>
                                    </div>
                                </div>
                            </li>

                            <!-- Custom attachments -->
                            <li class="list-inline-item mt-2" *ngFor="let attachment of bugReport.attachments">
                                <div class="media">
                                    <div class="mr-2 align-self-center">
                                        <i class="icon-file-empty icon-2x text-slate-400 top-0"></i>
                                    </div>

                                    <div class="media-body">
                                        <a download="{{attachment.name}}" href="{{attachment.url}}" class="font-weight-semibold">{{attachment.name}}</a>
                                        <ul class="list-inline list-inline-dotted list-inline-condensed font-size-sm text-muted">
                                            <li class="list-inline-item">Size: {{byteCountFormatter.stringFromByteCount(attachment.fileSize)}}</li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="border-top" *ngIf="bugReport.consoleLog">
            <application-logs #applicationLogs
                [logFile]="bugReport.consoleLog"
                [videoFile]="bugReport.autoScreenRecording"
                [videoTimelineFile]="bugReport.autoScreenRecordingTimeline"
                (timelinePositionChanged)="onTimelinePositionChanged($event)"
                (analyzeRequestHandler)="onAnalyzeRequest($event)">
            </application-logs>
        </div>
    </div>

</div>
