import { Injectable } from '@angular/core';

import { ApplicationService } from '../application.service';
import { IdSlugCache } from './id.slug.cache';

@Injectable({ providedIn: 'root' })
export class SlugResolverService {

    private idSlugsCache: IdSlugCache

    constructor(
        private applicationService: ApplicationService
    ) {
        this.idSlugsCache = new IdSlugCache()
    }

    resolveApplicationId(applicationSlug: string): Promise<number> {
        let scope = `workspaceApps`

        return new Promise((resolve, reject) => {
            let cachedId = this.idSlugsCache.idForSlugInScope(applicationSlug, scope)
            if (cachedId) {
                resolve(cachedId)

            } else {
                this.applicationService.getWorkspaceApplicationsWithSlug(applicationSlug).then((response) => {
                    let application = response.data
                    this.idSlugsCache.record(application.id, application.slug, scope)
                    resolve(application.id)

                }).catch((error) => {
                    reject(error)
                })
            }
        })
    }

    clearCache() {
        this.idSlugsCache.clearCache()
    }

}
