import { DateRange } from 'app/_models';
import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateFormatter } from 'app/_helpers'
import { Observable, Subscription, timer } from 'rxjs';
import * as daterangepicker from 'daterangepicker'
import * as moment from 'moment';

@Component({
    selector: 'date-range',
    templateUrl: 'date.range.component.html'
})

export class DateRangeComponent implements OnInit, AfterViewChecked {

    dateRangeText: string

    private _dateRange: DateRange
    @Input()
    set dateRange(dateRange: DateRange) {
        if (this._dateRange === dateRange) {
            return
        }
        this._dateRange = dateRange
        this.updateDateRange()
    }
    get dateRange() { return this._dateRange }

    @Output() onDateRangeChange = new EventEmitter<DateRange>()

    private dateRangePicker: daterangepicker
    private timerObservable: Observable<number>
    private timerSubscription: Subscription | null

    constructor(
        private dateFormatter: DateFormatter
    ) { }

    ngOnInit() {
        this.timerObservable = timer(0, 5000)
        this.timerSubscription = this.timerObservable.subscribe((seconds) => {
            this.updateDateRange()
        })
    }

    ngOnDestroy() {
        this.timerSubscription?.unsubscribe()
        this.dateRangePicker = null
    }

    ngAfterViewChecked() {
        if (this.dateRangePicker) { return }

        let element = document.getElementById('daterange-picker')
        if (!element) { return }

        let weakThis = this
        this.dateRangePicker = new daterangepicker(element,
            {
                minDate: moment().subtract(12, 'months'),
                maxDate: moment(),
                opens: "left",
                linkedCalendars: false,
                alwaysShowCalendars: true,
                showCustomRangeLabel: true,
                timePicker: true,
                timePicker24Hour: true,
                ranges: {
                    'Last Day': [moment().subtract(24, 'hours'), moment()],
                    'Last Week': [moment().subtract(1, 'week'), moment()],
                    'Last Month': [moment().subtract(1, 'month'), moment()],
                    'Last Year': [moment().subtract(12, 'months'), moment()],
                },
                applyButtonClasses: "btn btn-primary"
            },
            function (start, end) {
                let dateRange = new DateRange()
                dateRange.startDate = start.toDate()
                dateRange.endDate = end.toDate()
                weakThis.onDateRangeChanged(dateRange)
            }
        )

        this.updateDateRange()
    }

    private updateDateRange() {
        // Update date range text label
        this.dateRangeText = this.dateRange == null ? null : this.dateFormatter.dateRange(this.dateRange)

        // Update date range in the picker
        if (this.dateRangePicker && this.dateRange) {
            this.dateRangePicker.setStartDate(moment(this.dateRange.startDate))
            this.dateRangePicker.setEndDate(moment(this.dateRange.endDate))
        }
    }

    private onDateRangeChanged(newDateRange: DateRange) {
        this.onDateRangeChange.emit(newDateRange)
    }

}
