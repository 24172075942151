<div class="content-wrapper" id="pageContent">

    <div class="content">
        <div class="row">
            <div class="col-md-12">
                <alert></alert>
            </div>
        </div>

        <div class="row" *ngIf="build && application">
            <div class="col-md-12">
                <div class="card">
                    <div class="page-header border-bottom-light">
                        <div class="page-header-content header-elements-md-inline">
                            <div class="page-title">
                                <h1 class="font-weight-thin">
                                    <a [routerLink]="['../../']" class="breadcrumb-item">{{application.name}} {{platformFormatter.platformName(application.platform)}}</a>
                                    <a [routerLink]="['../']" class="breadcrumb-item">Builds</a>
                                    <span class="breadcrumb-item active">{{build.shortVersion}} ({{build.version}})</span>
                                </h1>
                                <ul class="list-inline list-inline-dotted list-inline-condensed mb-0">
                                    <li class="list-inline-item text-muted text-sm font-weight-thin">{{dateFormatter.dateTime(build.updateDate)}}</li>
                                </ul>
                            </div>

                            <div class="header-elements align-items-baseline d-flex">
                                <div class="mr-3" *ngIf="build.downloads != undefined">
                                    <span class="text-muted font-size-sm font-weight-light">DOWNLOADS</span>
                                    <h5 class="font-weight-light">{{build.downloads}}</h5>
                                </div>
                                <a href="javascript:void(0)" (click)="onDistributeBuildClick()" data-toggle="modal" data-target="#distributeBuildModal" class="btn btn-outline-primary btn-float" *ngIf="canDistributeBuild()"><i class="icon-paperplane"></i><span>Distribute</span></a>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <h4>Binary info</h4>
                        <div class="row mb-3">
                            <div class="col-lg-6">
                                <div class="font-weight-semibold">App identifier</div>
                                <div class="text-muted mb-3">{{build.bundleIdentifier}}</div>

                                <div class="font-weight-semibold">Download</div>
                                <a download="{{applicationBinaryName}}" href="{{build.binary.url}}" class="font-weight-semibold">{{applicationBinaryName}}</a>
                                <span class="text-muted font-size-sm font-weight-light"> (file size: {{byteCountFormatter.stringFromByteCount(build.binary.fileSize)}})</span>
                            </div>

                            <div class="col-lg-6">
                                <div class="font-weight-semibold">Minimum OS Version</div>
                                <div class="text-muted mb-3">{{build.minimumOsVersion}}</div>

                                <div class="font-weight-semibold">SDK</div>
                                <div class="text-muted" *ngIf="build.sdkName">{{build.sdkName}}</div>
                                <div class="text-muted" *ngIf="build.sdkName == null">-</div>
                            </div>
                        </div>

                        <div *ngIf="provisioningProfile">
                            <h4>Provisioning Profile</h4>
                            <div class="row mb-3">
                                <div class="col-lg-6">
                                    <div class="font-weight-semibold">Name</div>
                                    <a href="{{provisioningProfile.file.url}}" class="font-weight-semibold">{{provisioningProfile.name}}.mobileprovision</a>

                                    <div class="font-weight-semibold mt-3">Team</div>
                                    <div class="text-muted">{{provisioningProfile.teamName}} ({{provisioningProfile.teamId}})</div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="font-weight-semibold">Type</div>
                                    <div class="text-muted">{{profileType()}}</div>

                                    <div class="font-weight-semibold mt-3">Expiration date</div>
                                    <div class="text-muted">{{provisioningProfile.expirationDate}}</div>
                                </div>
                            </div>

                            <div class="font-weight-semibold mt-3">Entitlements</div>
                            <pre class="language-javascript w-100 mb-3">{{prettyEntitlements}}</pre>
                        </div>

                        <h4 class="card-title">Release notes</h4>
                        <pre class="language-javascript w-100" *ngIf="build.releaseNotes">{{build.releaseNotes}}</pre>
                        <div class="text-muted" *ngIf="!build.releaseNotes">None</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<distribute-build-modal #distributeBuildModal></distribute-build-modal>
