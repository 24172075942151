<div class="content-wrapper" id="pageContent">

    <div class="content">
        <div class="row">
            <div class="col-md-12">
                <alert></alert>
            </div>
        </div>

        <div class="row" *ngIf="workspace && application">
            <div class="col-md-12">
                <div class="card">
                    <div class="page-header border-bottom-0">
                        <div class="page-header-content header-elements-md-inline">
                            <div class="page-title">
                                <h1 class="font-weight-thin">
                                    <a [routerLink]="['/', workspace.slug]" class="breadcrumb-item">{{workspace.name}}</a>
                                    <span class="breadcrumb-item active">{{application.name}}<span class="ml-1 text-muted font-weight-thin">{{platformFormatter.platformName(application.platform)}}</span></span>
                                </h1>
                            </div>

                            <!-- <div class="ml-auto text-right mt-3 mb-auto">
                                <div class="font-size-xs text-uppercase text-muted mb-1">Distribution</div>
                                <div class="text-grey-600" *ngIf="application.publicDistributionEnabled">
                                    <i class="icon-unlocked2 font-size-sm"></i> Public
                                </div>
                                <div class="text-grey-600" *ngIf="!application.publicDistributionEnabled">
                                    <i class="icon-lock2 font-size-sm"></i> Private
                                </div>
                            </div> -->

                        </div>
                    </div>

                    <div class="card-footer d-flex justify-content-between align-items-sm-center">
                        <div class="d-flex align-items-center">
                            <a [routerLink]="AppConfig.makeApplicationDistributionUrlComponents(application.slug, workspace.slug, null)">Distribution page</a>
                        </div>

                        <ul class="list-inline mb-0 mt-2 mt-sm-0">
                            <li class="list-inline-item">
                                <a href="#" data-toggle="modal" data-target="#upload_build_modal" class="text-default"><i class="icon-file-plus mr-2"></i>Upload Build</a>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>


        <!--div class="alert alert-info  bg-white alert-dismissible alert-styled-left border-top-0 border-bottom-0 border-right-0">
            <span class="font-weight-semibold">Here we go!</span> Example of an alert inside modal.
            <button type="button" class="close" data-dismiss="alert">×</button>
        </div>
        <div class="card p-3 border-primary">
            UPDATE!
        </div> -->

        <div class="d-flex row flex-wrap" *ngIf="application && widgets">

            <!-- WIDGETS -->
            <ng-container *ngFor="let widget of widgets">
                <div class="col-md-4">
                    <div class="card">
                        <div class="handcursor mt-2">
                            <widget-view [widgetView]="widget.view" (click)="onWidgetClick(widget)"></widget-view>

                            <div class="d-flex" style="position: absolute; right: 22px; top: 25px;">
                                <div class="align-items-center text-secondary mr-2" *ngIf="!widget.isShared"><i class="icon-lock2 font-size-sm"></i> Private</div>

                                <div class="dropdown">
                                    <a href="#" class="list-icons-item dropdown-toggle" data-toggle="dropdown"><i class="icon-cog3"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <a href="javascript:void(0)" (click)="onWidgetEdit(widget)" class="dropdown-item">Edit</a>
                                        <a href="javascript:void(0)" (click)="onWidgetDelete(widget)" class="dropdown-item">Delete</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <a href="javascript:void(0)" (click)="onWidgetClick(widget)" class="card-footer py-2 align-items-center justify-content-between d-flex">
                            <div class="font-size-sm text-uppercase font-weight-semibold">{{widget.name}}</div>
                            <i class="text-grey-400 icon-chevron-right"></i>
                        </a>
                    </div>
                </div>
            </ng-container>

            <!-- NEW WIDGET -->
            <div class="col-md-4">
                <a href="#" #newWidgetButton class="text-default" data-toggle="modal" data-target="#new_widget_modal">
                    <div class="card" style="background-color: transparent; border: 2px dashed #ddd; border-radius: 0.3750rem; min-height: 229px;">
                        <div class="card-body text-center d-flex align-items-center justify-content-center">
                            <div>
                                <i class="icon-plus3 icon-3x p3 mb-3" style="color: #ccc;"></i>
                                <h5 class="text-grey mb-0">Add Widget</h5>
                            </div>
                        </div>
                    </div>
                </a>
            </div>

        </div>
    </div>
</div>

<div id="upload_build_modal" class="modal fade" tabindex="-1" *ngIf="application">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Upload New Build</h5>
                <button #closeButton type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <dropzone #dropzone (fileAdded)="onFileAdded()" (uploadSucceeded)="onUploadSucceeded()" (uploadFailed)="onUploadFailed($event)" [uploadUrl]="uploadUrl" [uploadParamName]="uploadParamName" [uploadParams]="{'applicationAlias': applicationAlias}" class="w-100 h-100"></dropzone>
            </div>
        </div>
    </div>
</div>

<new-widget-modal #newWidgetModal (onWidgetCreated)="onWidgetCreated()" (onWidgetUpdated)="onWidgetUpdated()"></new-widget-modal>
