export class Dataset {
    style: BarChartStyle
    series: Array<number>
    total: number
}

export class BarChartData {
    labels: string[]
    datasets: Dataset[]
    minValue: number
    maxValue: number
}

export enum BarChartStyle {
    Danger = 1,
    Success = 2,
    Neutral = 3,
}
