<navbar-settings></navbar-settings>

<div class="page-content">
    <settings-sidebar selectedFeatureIndex="6"></settings-sidebar>

    <!-- Main content -->
    <div class="content-wrapper">

        <!-- Content area -->
        <div class="content">

            <div class="row">
                <div class="col-md-12">
                    <alert></alert>
                </div>
            </div>

            <!-- API Keys row -->
            <div class="row" *ngIf="authTokens">
                <div class="col-md-12">
                    <div class="card">

                        <!-- Card header -->
                        <div class="card-header bg-transparent header-elements-inline">
                            <div>
                                <h1 class="font-weight-thin mb-0">{{authTokens.length}} API Keys</h1>
                                <div class="text-muted">View <a href="{{docsUrl}}/api/apis">REST API documentation</a> for more info.</div>
                            </div>

                            <div class="header-elements">
                                <div class="list-icons">
                                    <div class="header-elements">
                                        <button type="button" data-toggle="modal" data-target="#create_auth_token_modal" class="btn btn-labeled bg-primary-400 btn-labeled-left"><b><i class="icon-plus2"></i></b> New API Key</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /card header -->

                        <!-- Card body -->
                        <div class="card-body" *ngIf="authTokens.length > 0">
                            <ul class="media-list">
                                <li class="media" *ngFor="let authToken of authTokens">
                                    <div class="media-body">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div>{{authToken.name}}</div>
                                                <div class="text-muted">{{dateFormatter.dateTime(authToken.creationDate)}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="align-self-center">
                                        <div class="list-icons-item dropdown">
                                            <a href="#" class="list-icons-item dropdown-toggle caret-0" data-toggle="dropdown"><i class="icon-menu9"></i></a>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <a (click)="onEditAuthTokenButtonClick(authToken)" class="dropdown-item" data-toggle="modal" data-target="#edit_auth_token_modal">Edit API Key</a>
                                                <a (click)="onDeleteAuthTokenButtonClick(authToken)" class="dropdown-item">Delete API Key</a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <!-- /Card body -->

                    </div>
                </div>
            </div>
            <!-- /authTokens row -->

        </div>
        <!-- /content header -->

    </div>
    <!-- /main content -->

</div>

<new-auth-token-modal (onAuthTokenCreated)="onAuthTokenCreated()"></new-auth-token-modal>
<edit-auth-token-modal #editAuthTokenModal (authTokenUpdated)="onAuthTokenUpdated()"></edit-auth-token-modal>
