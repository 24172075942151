export enum TriggerConfigurationType {
    BugReports = 1,
    TestExecutions = 2,
}

export class BugReportsTriggerConfiguration {
    reporterEmail: string | null
}

export class TestExecutionsTriggerConfiguration {
    environmentNames: string[]
    testPlanNames: string[]
}

export class BuildsTriggerConfiguration {
}
