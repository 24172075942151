<div class="navbar navbar-expand navbar-light d-flex justify-content-between">
    <navbar-app-feature></navbar-app-feature>

    <h3 class="m-0 d-flex align-items-center font-weight-semibold">App Distributions</h3>

    <navbar-user-menu *ngIf="workspace"></navbar-user-menu>
    <!-- Empty element just so that the title is centered using justify-content-between -->
    <span *ngIf="!workspace"></span>
</div>

<div class="page-content">

    <div class="content-wrapper" id="pageContent">

        <!-- Content area -->
        <div class="content justify-content-center">
            <div class="row">
                <div class="col-md-12">
                    <alert></alert>
                </div>
            </div>

            <div class="row d-flex ml-auto mr-auto" style="max-width: 760px" *ngIf="applicationDistribution && build">
                <div class="col-md-12">
                    <div class="card mb-1">
                        <div class="page-header border-bottom-light">
                            <div class="page-header-content header-elements-md-inline">
                                <div class="page-title media d-flex w-100">
                                    <div class="mr-3">
                                        <img class="app-icon" src="{{applicationDistribution.icon.url}}" width="64" height="64" *ngIf="applicationDistribution.icon">
                                        <img class="app-icon" src="assets/img/default_app_icon.jpeg" width="64" height="64" *ngIf="!applicationDistribution.icon">
                                    </div>

                                    <div class="mr-auto">
                                        <a [routerLink]="['../../']" class="text-default">
                                            <h1 class="font-weight-light">
                                                {{applicationDistribution.name}}
                                                <span class="text-muted font-weight-thin">{{platformFormatter.platformName(applicationDistribution.platform)}}</span>
                                            </h1>
                                        </a>
                                        <div class="text-muted text-sm font-weight-light">
                                            <pre class="border-0 breakall m-0 p-0">{{build.bundleIdentifier}}</pre>
                                        </div>
                                    </div>

                                    <button type="button" data-toggle="modal" data-target="#qr_modal" class="btn btn-outline bg-primary text-primary-800 btn-icon desktop-only"><i class="icon-qrcode"></i></button>
                                </div>
                            </div>
                        </div>

                        <div class="card-header header-elements-inline">
                            <div class="card-title m-0">
                                <h4 class="m-0">Version {{build.shortVersion}} ({{build.version}})</h4>
                            </div>

                            <div class="header-elements" *ngIf="buildInstallUrl">
                                <a [href]="buildInstallUrl" class="btn btn-outline-primary rounded-round btn-sm">Install</a>
                            </div>
                        </div>

                        <div class="card-body mb-0">
                            <div>
                                <div class="font-size-xs text-uppercase text-muted mb-1">Release date</div>
                                <div class="">{{dateFormatter.dateTime(build.updateDate)}}</div>
                            </div>

                            <div class="mt-3" *ngIf="build.expirationDate">
                                <div class="font-size-xs text-uppercase text-muted mb-1">Expiration date</div>
                                <div class="">{{dateFormatter.dateTime(build.expirationDate)}}</div>
                            </div>

                            <div class="mt-3" *ngIf="build.releaseNotes">
                                <div class="font-size-xs text-uppercase text-muted mb-1">Release notes</div>
                                <pre class="border-0 breakall m-0 p-0">{{build.releaseNotes}}</pre>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>


<div id="qr_modal" class="modal fade" tabindex="-1" *ngIf="applicationDistribution && build">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{applicationDistribution.name}} {{build.shortVersion}} ({{build.version}})</h5>
                <button type="button" class="close" data-dismiss="modal">×</button>
            </div>

            <div class="modal-body">
                <p class="mb-3">
                    Scan the following QR code in order to open the download page on your phone:
                </p>
                <div class="text-center">
                    <qrcode [qrCodeData]="buildDistributionUrl" [scale]="1.5"></qrcode>
                </div>
            </div>
        </div>
    </div>
</div>
