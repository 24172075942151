import { Component, ViewChild, ElementRef, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { AlertService } from 'app/_services'
import { Application, MissingSymbolsFile, Platform } from 'app/_models';
import { AppConfig } from 'app/app.config';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropzoneComponent } from 'app/_components/dropzone.component';

@Component({
    selector: 'upload-symbols-modal',
    templateUrl: 'upload.symbols.component.html',
    styleUrls: [
        'upload.symbols.component.css'
    ]
})
export class UploadSymbolsComponent {

    public Platform = Platform
    public isUploading = false

    _application: Application
    @Input()
    set application(application: Application) {
        this._application = application
        this.reloadForm()
    }
    get application() { return this._application }

    @Input() missingSymbols: MissingSymbolsFile[]
    @Output() onSymbolsUploaded = new EventEmitter()

    @ViewChild('closeButton') closeButton: ElementRef
    @ViewChild('dropzone') dropzone: DropzoneComponent

    uploadUrl = `${AppConfig.apiBaseUrl}/symbolsFiles`
    fileUploadTitle: string
    didSelectFile = false

    form: FormGroup;

    constructor(
        private alertService: AlertService,
        private formBuilder: FormBuilder,
    ) {
    }

    onFileAdded() {
        this.didSelectFile = true

        // For iOS, there is no form, we can just start with upload immediately
        if (this.application.platform != Platform.Android) {
            this.submitFiles()
        }
    }

    onUploadFailed(errorMessage: string) {
        this.alertService.error(errorMessage)
        this.isUploading = false
        this.didSelectFile = false
    }

    onUploadSucceeded() {
        this.closeButton.nativeElement.click()
        this.alertService.success("Symbols successfully uploaded")
        this.isUploading = false
        this.didSelectFile = false
    }

    submitFiles() {
        this.isUploading = true

        let additionalUploadParams: Object
        if (this.application.platform == Platform.Android) {
            additionalUploadParams = {
                "os": "android",
                "versionName": this.form.controls.versionName.value,
                "versionCode": this.form.controls.versionCode.value
            }
        } else {
            additionalUploadParams = {
                "os": "ios"
            }
        }

        this.dropzone.startUpload(additionalUploadParams)
    }

    private reloadForm() {
        this.isUploading = false
        this.didSelectFile = false

        if (this.application.platform == Platform.Android) {
            this.fileUploadTitle = "Mapping .txt file"

            this.form = this.formBuilder.group({
                versionName: [null, Validators.required],
                versionCode: [null, Validators.required]
            })

        } else {
            this.fileUploadTitle = "dSYMs .zip file"
            this.form = null
        }
    }

}
