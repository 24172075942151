import { ConsoleLogLevelFormatter } from 'app/_helpers';
import { Component, Input } from '@angular/core';
import { ConsoleLog, ConsoleLogLevel } from 'app/_models/console.logs.response';

@Component({
    selector: 'console-log-content',
    templateUrl: 'console.log.content.html'
})
export class ConsoleLogContent {

    private _log: ConsoleLog
    @Input()
    public set log(log: ConsoleLog) {
        this._log = log
        this.onLogUpdated()
    }

    public get log(): ConsoleLog {
        return this._log
    }

    logLevelBadgeStyle: string
    logLevelSymbol: string

    constructor(
        public logLevelFormatter: ConsoleLogLevelFormatter
    ) { }

    private onLogUpdated() {
        this.logLevelBadgeStyle = this.logLevelFormatter.logLevelBadgeStyle(this.log.level)
        this.logLevelSymbol = this.logLevelFormatter.symbol(this.log.level)
    }

}
