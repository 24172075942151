import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Workspace, Role } from 'app/_models';
import { AppConfig } from 'app/app.config';

export enum Relationship {
    AlreadyMember = 0,
    NotUser = 1
}

export enum InvitationState {
    open = 0,
    accepted = 1,
    // declined = 2,
    expired = 3,
    cacelled = 4
}

export class WorkspaceInvitation {
    id: number
    email: string
    expirationDate: Date
    state: InvitationState
    imageUrl: URL
}

export class InvitationInfoResponse {
    id: string
    workspace: Workspace
    email: string
    relationship: Relationship
}

export class WorkspaceInvitationsResponse {
    invitations: [WorkspaceInvitation]
}

@Injectable({ providedIn: 'root' })
export class InvitationsService {

    get pendingInvitationToAccept(): InvitationInfoResponse {
        return JSON.parse(localStorage.getItem('pendingInvitation'))
    }

    set pendingInvitationToAccept(invitation: InvitationInfoResponse) {
        localStorage.setItem('pendingInvitation', JSON.stringify(invitation))
    }

    constructor(
        private http: HttpClient,
    ) {
    }

    getWorkspaceInvitations(workspaceId: number, distributionGroupId: number | null): Promise<WorkspaceInvitationsResponse> {
        var url = `${AppConfig.apiBaseUrl}/workspaces/${workspaceId}/userInvitations`
        if (distributionGroupId != null) {
            url += `?distributionGroupId=${distributionGroupId}`
        }

        return this.http.get<WorkspaceInvitationsResponse>(url).toPromise()
    }

    getInvitationInfo(token: string): Promise<InvitationInfoResponse> {
        return this.http.get<InvitationInfoResponse>(`${AppConfig.apiBaseUrl}/userInvitations?token=${token}`).toPromise()
    }

    sendInvitation(workspaceId: number, email: string, role: Role, distributionGroupId: number | null): Promise<Object> {
        let requestBody = {
            workspaceId: workspaceId,
            email: email,
            role: role,
            distributionGroupId: distributionGroupId
        }

        return this.http.post(`${AppConfig.apiBaseUrl}/userInvitations`, requestBody).toPromise()
    }

    updateInvitation(invitationId: number, state: InvitationState): Promise<Object> {
        let requestBody = {
            state: state,
        }

        return this.http.patch(`${AppConfig.apiBaseUrl}/userInvitations/${invitationId}`, requestBody, { observe: 'response'}).toPromise()
    }

    clearLocalStorage() {
        this.pendingInvitationToAccept = null
    }

}
