import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { BackendResponse, AuthToken } from 'app/_models';
import * as bootbox from 'bootbox'
import { EditAuthTokenModal } from './edit.auth.token.modal';
import { AuthTokenService, AuthenticationService, AlertService } from 'app/_services';
import { DateFormatter } from 'app/_helpers';
import { AppConfig } from 'app/app.config';
import { Title } from '@angular/platform-browser';

@Component({
    templateUrl: 'api.settings.component.html'
})
export class ApiSettingsComponent implements OnInit {

    authTokens: AuthToken[]

    docsUrl: String

    @ViewChild('closeButton') closeButton: ElementRef
    @ViewChild('editAuthTokenModal') editAuthTokenModal: EditAuthTokenModal

    constructor(
        public dateFormatter: DateFormatter,
        private authTokenService: AuthTokenService,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private titleService: Title
    ) {
        this.docsUrl = AppConfig.documentationBaseUrl
    }

    ngOnInit() {
        this.titleService.setTitle("Settings | API Access")
        this.reloadAuthTokens()
    }

    onDeleteAuthTokenButtonClick(authToken: AuthToken) {
        let weakThis = this

        bootbox.dialog({
            title: `Delete ${authToken.name}?`,
            message: "No further calls with this API Key will be possible.",
            size: 'small',
            buttons: {
                cancel: { label: 'Cancel', className: 'btn-link' },
                delete: {
                    label: 'Delete API Key', className: 'btn-danger', callback: function () {
                        weakThis.deleteAuthToken(authToken)
                    }
                }
            }
        })
    }

    deleteAuthToken(authToken: AuthToken) {
        this.authTokenService.deleteAuthToken(authToken.id).then((response) => {
            this.reloadAuthTokens()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    onEditAuthTokenButtonClick(authToken: AuthToken) {
        this.editAuthTokenModal.authToken = authToken
    }

    onAuthTokenUpdated() {
        this.reloadAuthTokens()
    }

    onAuthTokenCreated() {
        this.reloadAuthTokens()
    }

    private reloadAuthTokens() {
        let currentWorkspace = this.authenticationService.currentWorkspaceValue

        this.authTokenService.getWorkspaceAuthTokens(currentWorkspace.id).then((response) => {
            this.authTokens = response.data
        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
